import { useState, useEffect } from 'react';

/**
 * Returns a state value that as long as the input value changes will not be changed.
 * The returned state value will only change after the delay.
 * @param value The input value we need to debounce
 * @param delay Duration in ms for debounce
 * @returns
 */
export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if either value or delay changes value changes
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
