/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface FileIconProps extends Omit<SVGBaseProps, 'color'> {}

const FileIcon = (props: FileIconProps) => {
  return (
    <SVGBase viewBox='0 0 24 24' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21 0H3C1.34314 0 0 1.34314 0 3V21C0 22.6569 1.34314 24 3 24H21C22.6569 24 24 22.6569 24 21V3C24 1.34314 22.6569 0 21 0ZM10.8696 6.49219H4.14498C3.52065 6.49219 3.01454 6.99832 3.01454 7.62265C3.01454 8.24696 3.52065 8.75308 4.14498 8.75308H10.8696C11.4939 8.75308 12 8.24696 12 7.62265C12 6.99832 11.4939 6.49219 10.8696 6.49219ZM4.14498 10.8984H13.9421C14.5664 10.8984 15.0725 11.4046 15.0725 12.0289C15.0725 12.6532 14.5664 13.1593 13.9421 13.1593H4.14498C3.52065 13.1593 3.01454 12.6532 3.01454 12.0289C3.01454 11.4046 3.52065 10.8984 4.14498 10.8984ZM17.4784 15.4204H4.14498C3.52065 15.4204 3.01454 15.9265 3.01454 16.5508C3.01454 17.1751 3.52065 17.6812 4.14498 17.6812H17.4784C18.1027 17.6812 18.6088 17.1751 18.6088 16.5508C18.6088 15.9265 18.1027 15.4204 17.4784 15.4204ZM17.2388 2.22876H20.2644C21.0929 2.22876 21.7644 2.90034 21.7644 3.72876V6.7545C21.7644 7.02177 21.4413 7.15562 21.2523 6.96663L17.0267 2.74089C16.8377 2.55191 16.9715 2.22876 17.2388 2.22876Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default FileIcon;
