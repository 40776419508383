import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

interface RecordsTabWrapperContainerProps {
  children: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '0px 1rem '
  }
}));

const RecordsTabWrapperContainer = (props: RecordsTabWrapperContainerProps) => {
  const { children } = props;
  const classes = useStyles();

  return <div className={clsx(classes.root, 'full-height')}>{children}</div>;
};

export default RecordsTabWrapperContainer;
