/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface CaretIconProps extends SVGBaseProps {}

const CaretIcon = (props: CaretIconProps) => {
  return (
    <SVGBase viewBox='0 0 16 10' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.6654 0.361453C15.2191 -0.120484 14.4955 -0.120484 14.0491 0.361453L8.00012 6.89329L1.9511 0.361453C1.50479 -0.120484 0.781171 -0.120484 0.334858 0.361453C-0.111456 0.84339 -0.111456 1.62476 0.334858 2.1067L7.15016 9.46599C7.61958 9.97288 8.38066 9.97288 8.85009 9.46599L15.6654 2.1067C16.1117 1.62476 16.1117 0.84339 15.6654 0.361453Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default CaretIcon;
