import { useState, useMemo, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Input from './Input';
import Divider from './Divider';
import useOutsideClick from '../../hooks/useOutsideClick';
import { PartnerClient } from '../../types';
import { useDebounce } from '../../hooks/useDebounce';
import { isBlankString, isNotUndefined } from '../../utils/utils';
import { useTranslate } from '../../hooks/useTranslate';
import Text from './Typography';
import Spinner from './Spinner';
import useQuery from '../../hooks/useQuery';
import { AuthContext } from '../../contexts/auth-context';
import { SCOPES } from '../../utils/scopes';

const useStyles = makeStyles(() => ({
  inputWrapper: {
    margin: '1rem 0 !important'
  }
}));

type UserDropDownItemProps = {
  user: PartnerClient;
  handleClick: (user: PartnerClient) => any;
  query: string;
  isActive: boolean;
  scrollIntoView: boolean;
  block: ScrollLogicalPosition;
};

const UserDropDownItem = ({ user, handleClick, query, isActive, scrollIntoView, block }: UserDropDownItemProps) => {
  const { t } = useTranslate('clientSelect');
  const getUserName = `${user.first_name} ${user.last_name}`;

  const handleSelection = (user: PartnerClient) => {
    handleClick(user);
  };

  const handleHighlightText = (name: string, highlight: string) => {
    const formattedHightlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const parts = name.split(new RegExp(`(${formattedHightlight})`, 'gi'));

    return (
      <span>
        {parts.map((part: string, i: number) => (
          <span key={`${part}-${i}`} style={part.toLowerCase() === highlight.toLowerCase() ? { color: '#5B53FF' } : {}}>
            {part}
          </span>
        ))}
      </span>
    );
  };

  return (
    <div
      className={clsx('dropdown-item selectable', isActive && 'selected')}
      onClick={() => {
        handleSelection(user);
      }}
      ref={(ref) => {
        if (scrollIntoView && ref) ref.scrollIntoView({ block });
      }}
    >
      <div className={clsx('dropdown-item')}>
        {handleHighlightText(getUserName, query)}

        <div className={clsx('dropdown-subitem')}>
          {user.email ? handleHighlightText(user.email, query) : t('minorClient.minorClient')}
        </div>
      </div>
    </div>
  );
};

export interface ClientPickerProps {
  onSelect: (user: PartnerClient) => void;
  addNewProviderButtonText?: string;
  hidden?: boolean;
  placeholder?: string;
  institutionUsersOnly?: boolean;
  legalAgeOnly?: boolean;
}

const ClientSelect = (props: ClientPickerProps) => {
  const classes = useStyles();
  const [query, setQuery] = useState('');
  const [active, setActive] = useState<{ id: number; index: number } | undefined>();
  const [clients, setClients] = useState<PartnerClient[]>([]);
  const { isScopeEnabled } = useContext(AuthContext);
  const hasMinorFlowScope = isScopeEnabled(SCOPES.MINOR_FLOW);

  const debounceQuery = useDebounce(
    useMemo(() => (isBlankString(query) ? '' : query), [query]),
    200
  );

  const getClientUrl = () => {
    const legalAgeParam = `isLegalAge=${props.legalAgeOnly || 'false'}`;

    if (props.institutionUsersOnly) {
      return `/institutions/users?include=insurance,address&search=${debounceQuery}`;
    }

    if (hasMinorFlowScope) {
      // eslint-disable-next-line max-len
      return `/users?include=insurance,address,designated_users,appointed_users&search=${debounceQuery}&${legalAgeParam}`;
    }

    return `/users?include=insurance,address&search=${debounceQuery}&${legalAgeParam}`;
  };

  const { loading: isUsersLoading, makeRequest } = useQuery(getClientUrl(), 'GET');

  useEffect(() => {
    const searchClients = async () => {
      const response = await makeRequest();
      const { users: clientsFound } = response;

      setClients(clientsFound);
    };

    if (isBlankString(debounceQuery)) {
      setClients([]);
    } else {
      searchClients();
    }
    // eslint-disable-next-line
  }, [debounceQuery]);
  const { t } = useTranslate('clientSelect');

  const { ref } = useOutsideClick<HTMLDivElement>(() => {
    setQuery('');
    setActive(undefined);
  });

  const isActive = (clientId: number, index: number) => {
    return !!(active && active.id === clientId && active.index === index);
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const setUser = (user: PartnerClient) => {
    props.onSelect(user);
    setQuery('');
  };

  const next = () => {
    if (!active?.id && !active?.index) {
      const firstClient = clients[0];
      return setActive({ id: firstClient.id, index: 0 });
    }

    const newAddressIndex = active.index! + 1;
    const newClient = clients[newAddressIndex];
    if (newClient) {
      return setActive({ id: newClient.id, index: newAddressIndex });
    }
  };

  const prev = () => {
    if (!active?.index && !active?.id) {
      const lastClientIndex = clients.length - 1;
      const lastClient = clients[lastClientIndex];
      if (lastClient) {
        setActive({ id: lastClient.id, index: lastClientIndex });
      }
    } else {
      const previousClientIndex = active.index! - 1;
      if (previousClientIndex >= 0) {
        const prevClient = clients[previousClientIndex];
        if (prevClient) {
          setActive({ id: prevClient.id, index: previousClientIndex });
        }
      }
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    if (!clients.length) {
      return;
    }
    if (key === 'ArrowUp') {
      prev();
      e.preventDefault();
    } else if (key === 'ArrowDown') {
      next();
      e.preventDefault();
    } else if (key === 'Enter') {
      if (active && isNotUndefined(active.id) && isNotUndefined(active.index)) {
        setUser(clients[active.index]);
      }
    } else {
      setActive(undefined);
    }
  };

  if (props.hidden) return null;

  return (
    <div ref={ref}>
      <div className='dropdown'>
        <Input
          maxWidth
          focusOnMount
          wrapperProps={{ className: classes.inputWrapper }}
          autoComplete='off'
          iconName='search'
          value={query}
          onChange={onSearch}
          onKeyDown={onKeyDown}
          placeholder={props.placeholder || 'Search by name or email address'}
          className='is-rounded center'
          name='Client'
          style={{ padding: '13px 28px' }}
        />
        {!isBlankString(debounceQuery) && (
          <div className='dropdown-menu'>
            <div className='dropdown-content'>
              <div className='dropdown-content-container'>
                {isUsersLoading ? (
                  <Text paragraph style={{ padding: '21px 36px' }}>
                    <Spinner size='small' />
                  </Text>
                ) : (
                  <>
                    {clients.length > 0 ? (
                      clients.map((client, index) => {
                        return (
                          <div key={`${client.email}-${client.id}-${index}`}>
                            <UserDropDownItem
                              scrollIntoView={index === active?.index || (!active?.id && index === 0)}
                              user={client}
                              handleClick={setUser}
                              query={query}
                              isActive={isActive(client.id, index)}
                              block={active?.index === index && index + 1 === clients.length ? 'start' : 'nearest'}
                            />
                            <Divider />
                          </div>
                        );
                      })
                    ) : (
                      <Text paragraph color='grey-60' style={{ padding: '21px 36px' }}>
                        {t('errors.noPatientsFound', { searchQuery: debounceQuery })}
                      </Text>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientSelect;
