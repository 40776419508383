import { makeStyles } from '@material-ui/core';
import React from 'react';

interface TimelineItemBaseProps {
  iconComponent?: React.ReactElement;
  children: React.ReactNode;
}

const useTimelineItemBaseStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '1rem 0px 1.5rem',
    alignItems: 'center',
    position: 'relative',
    '&::before': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 1,
      display: 'block',
      width: '1px',
      content: '""',
      backgroundColor: theme.palette.grey[90],
      zIndex: 1
    }
  }
}));

const TimelineItemBase = ({ children }: TimelineItemBaseProps) => {
  const classes = useTimelineItemBaseStyles();

  return (
    <div className={classes.root}>
      <div>{children}</div>
    </div>
  );
};
export default TimelineItemBase;
