/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface TextFileIconProps extends Omit<SVGBaseProps, 'color'> {}

const TextFileIcon = (props: TextFileIconProps) => {
  return (
    <SVGBase viewBox='0 0 24 24' {...props}>
      <path
        d='M0.194336 3.27767C0.194336 1.86361 1.34066 0.717285 2.75472 0.717285H17.6339C19.048 0.717285 20.1943 1.86361 20.1943 3.27767V18.1569C20.1943 19.571 19.048 20.7173 17.6339 20.7173H2.75472C1.34066 20.7173 0.194336 19.571 0.194336 18.1569V3.27767Z'
        fill='#672BC2'
      />
      <path
        d='M6.03975 6.99748H2.46487C2.09134 6.99748 1.78854 7.30028 1.78854 7.67381V8.08775C1.78854 8.46127 2.09134 8.76408 2.46487 8.76408H3.1895C3.26955 8.76408 3.33443 8.82896 3.33443 8.909V13.7608C3.33443 14.1343 3.63723 14.4371 4.01076 14.4371H4.27646C4.64999 14.4371 4.95279 14.1343 4.95279 13.7608V8.909C4.95279 8.82896 5.01768 8.76408 5.09772 8.76408H6.03975C6.41327 8.76408 6.71607 8.46127 6.71607 8.08775V7.67381C6.71607 7.30028 6.41327 6.99748 6.03975 6.99748Z'
        fill='white'
      />
      <path
        d='M17.6823 6.99748H14.1074C13.7339 6.99748 13.4311 7.30028 13.4311 7.67381V8.08775C13.4311 8.46127 13.7339 8.76408 14.1074 8.76408H14.832C14.9121 8.76408 14.9769 8.82896 14.9769 8.909V13.7608C14.9769 14.1343 15.2797 14.4371 15.6533 14.4371H15.919C16.2925 14.4371 16.5953 14.1343 16.5953 13.7608V8.909C16.5953 8.82896 16.6602 8.76408 16.7402 8.76408H17.6823C18.0558 8.76408 18.3586 8.46127 18.3586 8.08775V7.67381C18.3586 7.30028 18.0558 6.99748 17.6823 6.99748Z'
        fill='white'
      />
      <path
        d='M8.53402 7.0941H8.21191C7.76574 7.0941 7.44183 7.51852 7.55955 7.94888L8.26646 10.5331C8.29941 10.6535 8.29837 10.7808 8.26345 10.9007L7.49955 13.5233C7.3735 13.9561 7.69812 14.3888 8.1489 14.3888H8.63254C8.93865 14.3888 9.20659 14.1832 9.28582 13.8875L9.94632 11.4228C9.97181 11.3277 10.1066 11.3272 10.1328 11.4221L10.8142 13.8923C10.8951 14.1856 11.1619 14.3888 11.4662 14.3888H11.8451C12.3123 14.3888 12.6388 13.9263 12.4824 13.4861L11.5725 10.9244C11.5247 10.7898 11.5207 10.6435 11.5611 10.5065L12.3122 7.96188C12.4401 7.52857 12.1153 7.0941 11.6635 7.0941H11.4544C11.1557 7.0941 10.8924 7.29002 10.8066 7.57612L10.1259 9.84564C10.0988 9.93597 9.97157 9.93795 9.94168 9.84851L9.17547 7.55604C9.08326 7.28013 8.82493 7.0941 8.53402 7.0941Z'
        fill='white'
      />
    </SVGBase>
  );
};

export default TextFileIcon;
