import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Text from './Typography';
import { conditionalProps, hexToRGB, isNotUndefined } from '../../utils/utils';
import useId from '../../hooks/useId';

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: '100%'
  },
  helperText: {
    margin: '1rem',
    padding: '1.5rem',
    borderRadius: '1rem',
    background: hexToRGB(theme.palette.catSkillWhite.main, 0.5)
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '1rem'
  },
  label: {
    fontSize: '1.5rem',
    color: theme.palette.text.main,
    fontWeight: 300
  }
}));

interface DropDownOption extends React.OptionHTMLAttributes<HTMLOptionElement> {
  label: string;
  value: string | number;
}

export interface DropdownInputProps
  extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  options: DropDownOption[];
  name: string;
  label?: string;
  placeholderText?: React.ReactNode;
  defaultValue?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
  required?: boolean;
  type?: string;
  error?: string;
  helpText?: React.ReactNode;
  children?: any /* meant for search suggestions list */;
  focusOnMount?: boolean;
  edit?: boolean;
  /* default: help text hovers to right until bmobile breakpoint. adding this makes it below form always */
  helpTextBelow?: boolean;
  maxWidth?: boolean;
  labelProps?: Omit<React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>, 'htmlFor'>;
  wrapperProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  isDisabled?: boolean;
}

const DropdownInput = React.forwardRef<HTMLSelectElement, DropdownInputProps>((props, ref) => {
  const [showHelpText, setShowHelpText] = useState(false);

  const {
    onChange,
    options,
    name,
    children,
    helpText,
    placeholderText,
    error,
    maxWidth,
    defaultValue,
    value,
    onFocus,
    onBlur,
    label,
    className,
    labelProps,
    wrapperProps,
    isDisabled,
    ...others
  } = props;

  const classes = useStyles();
  const errorMessageContainerId = useId({ prefix: 'dropdown-input' });
  const selectId = useId({ prefix: `drop-down-input-${name}`, defaultValue: others.id });

  return (
    <div className={clsx(classes.root, wrapperProps?.className)}>
      <label {...labelProps} className={clsx(labelProps?.className, classes.label)} htmlFor={selectId}>
        {label}
      </label>
      <div
        {...wrapperProps}
        className={clsx(maxWidth && classes.maxWidth, wrapperProps?.className, 'select-container')}
      >
        <select
          className={clsx(
            'input',
            maxWidth && 'is-expanded',
            isDisabled && 'is-disabled-opacity',
            error && 'has-error',
            value === '' && 'is-invalid',
            className
          )}
          onChange={(e) => {
            setShowHelpText(false);
            onChange(e);
          }}
          disabled={isDisabled}
          defaultValue={defaultValue}
          value={value}
          id={selectId}
          name={name}
          ref={ref}
          onFocus={(e) => {
            setShowHelpText(true);
            if (isNotUndefined(onFocus)) onFocus(e);
          }}
          onBlur={(e) => {
            setShowHelpText(false);
            if (isNotUndefined(onBlur)) onBlur(e);
          }}
          {...conditionalProps({ 'aria-describedby': errorMessageContainerId, 'aria-invalid': !!error }, !!error)}
          {...others}
        >
          {placeholderText && (
            <option value='' disabled hidden>
              {placeholderText}
            </option>
          )}
          {options.map((option, i) => {
            const { label, value, ...rest } = option;
            return (
              <option {...rest} value={value} key={`${i}-${option.value}`}>
                {label}
              </option>
            );
          })}
        </select>
        {showHelpText && !!helpText && (
          <Text className={classes.helperText} small>
            {helpText}
          </Text>
        )}
      </div>
      {!!children && children}
      {error && (
        <p className={clsx('error-text')} id={errorMessageContainerId}>
          {error}
        </p>
      )}
    </div>
  );
});

export default DropdownInput;
