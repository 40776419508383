import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment';
import { forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { SnackbarContext } from '../../contexts/snackbar-context';
import { SubmitFunction, useForm } from '../../hooks/useForm';
import useId from '../../hooks/useId';
import { useTranslate } from '../../hooks/useTranslate';
import { useUpdatePatientInformation } from '../../request-hooks/shared/useSharedRequest';
import { PartnerClient } from '../../types';
import { deepEqual, getTitleCase, isNotUndefined, useFormatDate } from '../../utils/utils';
import Card from './Card';
import Divider from './Divider';
import Grid from './Grid';
import Icon from './Icon';
import IconButton from './IconButton';
import { CloseIcon, DatePickerIcon } from './Icons';
import Skeleton from './Skeleton';
import Spinner from './Spinner';
import Text from './Typography';

interface PatientDetailsProps {
  patient: PartnerClient;
  onUpdate?: (newUser: PartnerClient) => void;
}

const usePatientDetailStyles = makeStyles((theme) => ({
  root: {
    '& p': {
      wordBreak: 'break-word'
    }
  },
  input: {
    border: 'none',
    borderBottom: `1px solid ${theme.palette.catSkillWhite.main}`,
    outline: 'none',
    borderRadius: '0px',
    width: '100%',
    padding: '1px 0px 7px',
    '&:focus,&:hover': {
      border: 'none',
      borderBottom: `1px solid ${theme.palette.primary.main}`
    },
    '&:disabled': {
      cursor: 'not-allowed'
    }
  },
  largeInput: {
    fontSize: '3.2rem',
    margin: '0.5rem 0px',
    border: 'none',
    width: '100%',
    outline: 'none',
    '&:focus,&:hover': {
      border: 'none'
    },
    '&:disabled': {
      cursor: 'not-allowed'
    }
  },
  dateContainer: {
    margin: '8px 0px 5px',
    display: 'flex',
    alignItems: 'baseline',
    '& .react-datepicker-wrapper': {
      width: 'auto'
    }
  },
  dateInput: {
    cursor: 'pointer',
    background: 'none',
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    '& svg': {
      marginLeft: '1rem'
    },
    '&:hover, &:focus': {
      '& svg': {
        color: theme.palette.primary.main
      }
    }
  },
  divider: {
    marginTop: '1.5rem'
  },
  editableDivider: {
    margin: '1.5rem 0px'
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.4rem 0px 2.2rem',
    paddingLeft: '1rem',
    '& h5': {
      fontWeight: 'normal'
    }
  },
  nameEditContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '3rem'
  },
  saveButton: {
    background: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.hover
    }
  },
  card: {
    width: '100%'
  },
  gridItem: {
    margin: '2rem 0px 0px'
  },
  closeIconButton: {
    fontSize: '1.5rem',
    background: 'inherit',
    '&>svg': {
      fontSize: '1rem'
    }
  },
  gridItemSkeleton: {
    margin: '2rem 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  }
}));

export const PatientDetailsCardSkeleton = () => {
  const classes = usePatientDetailStyles();
  return (
    <div className={classes.root}>
      <div className={classes.headingContainer}>
        <Skeleton width='15ch' height='20px' />
      </div>
      <Card noShadow className={classes.card}>
        <div style={{ display: 'flex', gap: '2rem' }}>
          <Skeleton height='100%' width='12ch' /> <Skeleton height='100%' width='12ch' />
        </div>
        <Divider className={classes.divider} />
        <Grid container isMultiline>
          <Grid item size='is-half' className={classes.gridItemSkeleton}>
            <Text paragraph color='ship-cove'>
              <Skeleton height='100%' width='10ch' />
            </Text>
            <Text paragraph>
              <Skeleton height='100%' width='8ch' />
            </Text>
          </Grid>
          <Grid item size='is-half' className={classes.gridItemSkeleton}>
            <Text paragraph color='ship-cove'>
              <Skeleton height='100%' width='13ch' />
            </Text>
            <Text paragraph>
              <Skeleton height='100%' width='13ch' />
            </Text>
          </Grid>
          <Grid item size='is-half' className={classes.gridItemSkeleton}>
            <Text paragraph color='ship-cove'>
              <Skeleton height='100%' width='13ch' />
            </Text>
            <Text paragraph underlined>
              <Skeleton height='100%' width='20ch' />
            </Text>
          </Grid>
          <Grid item size='is-half' className={classes.gridItemSkeleton}>
            <Text paragraph color='ship-cove'>
              <Skeleton height='100%' width='20ch' />
            </Text>
            <Text paragraph>
              <Skeleton height='100%' width='13ch' />
            </Text>
          </Grid>
          <Grid item size='is-half' className={classes.gridItemSkeleton}>
            <Text paragraph color='ship-cove'>
              <Skeleton height='100%' width='8ch' />
            </Text>
            <Text paragraph>
              <Skeleton height='100%' width='18ch' />
            </Text>
            <Text paragraph>
              <Skeleton height='100%' width='16ch' />
            </Text>
            <Text paragraph>
              <Skeleton height='100%' width='6ch' />
            </Text>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

const PatientDetailsCard = ({ patient, onUpdate }: PatientDetailsProps) => {
  const formatDate = useFormatDate();
  const [isEditable, setIsEditable] = useState(false);
  const classes = usePatientDetailStyles();
  const { updatePatientInformation, isUpdatingPatientInformation } = useUpdatePatientInformation(patient.id);
  const { addNotification, removeNotification } = useContext(SnackbarContext);
  const { t } = useTranslate('patientDetailsCard');
  const datePickerDropdownButtonId = useId();
  const id = useId();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const PatientDetailsIntiialState = useMemo(() => {
    return {
      email: patient.email,
      first_name: patient.first_name,
      dob: moment(patient.dob).isValid() ? moment(patient.dob).toDate() : undefined,
      last_name: patient.last_name,
      insurance_number: patient.insurance?.insurance_number,
      gender: patient.gender,
      streetAddress: patient.address?.street_address,
      city: patient.address?.city,
      postalCode: patient.address?.postal_code,
      province: patient.address?.province
    };
  }, [patient]);

  const { getFieldProps, handleSubmit, values, setValue, resetForm } = useForm(PatientDetailsIntiialState);

  const onSubmit: SubmitFunction<typeof PatientDetailsIntiialState> = (values) => {
    const areValuesEqual = deepEqual(values, PatientDetailsIntiialState);
    if (areValuesEqual) {
      // Compare initialState and values,if not changed then return without sending a backend request.
      setIsEditable(false);
      return;
    }
    values.insurance_number = values.insurance_number ? values.insurance_number.toUpperCase() : '';
    values.postalCode = values.postalCode ? values.postalCode.toUpperCase() : '';
    values.first_name = values.first_name ? getTitleCase(values.first_name) : '';
    values.last_name = values.last_name ? getTitleCase(values.last_name) : '';
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { email, first_name, last_name, dob, insurance_number, gender, streetAddress, city, postalCode, province } =
      values;

    updatePatientInformation({
      user: {
        email,
        first_name,
        last_name,
        dob: dob?.toString(),
        gender,
        insurance_number,
        address: { street_address: streetAddress, city, postal_code: postalCode, province }
      }
    }).then((res) => {
      if (isNotUndefined(onUpdate)) {
        onUpdate(res.user);
      }
      addNotification(
        'Profile updated successfully',
        'success',
        <button className={classes.closeIconButton} type='button' onClick={() => removeNotification()}>
          <CloseIcon color='white' />
        </button>,
        { autoHideDuration: 1000000 }
      );
      setIsEditable(false);
    });
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setValue('dob', date);
    }
  };

  useEffect(() => {
    Object.keys(PatientDetailsIntiialState).forEach((key) => {
      const typedKey = key as any as keyof typeof PatientDetailsIntiialState;
      setValue(typedKey, PatientDetailsIntiialState[typedKey]);
    });
  }, [PatientDetailsIntiialState, setValue]);

  useEffect(() => {
    if (!isEditable) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditable]);

  useEffect(() => {
    const datePickerWrapperPopper = document.getElementsByClassName('react-datepicker')[0];
    const datePickerMonthDescriptionContainer = document.getElementsByClassName('react-datepicker__current-month')[0];
    if (datePickerWrapperPopper && datePickerMonthDescriptionContainer) {
      if (isCalendarOpen) {
        datePickerMonthDescriptionContainer.setAttribute('id', id);
        datePickerMonthDescriptionContainer.setAttribute('aria-live', 'polite');
        datePickerWrapperPopper.setAttribute('aria-labelledby', id);
      } else {
        datePickerMonthDescriptionContainer.removeAttribute('id');
        datePickerMonthDescriptionContainer.removeAttribute('aria-live');
        datePickerWrapperPopper.removeAttribute('aria-labelledby');
      }
    }
  }, [id, isCalendarOpen]);

  const CustomInput = forwardRef((props: any, ref: any) => {
    const translationKey = !values.dob ? 'datepickerIconButtonLabel.empty' : 'datepickerIconButtonLabel.withDate';

    return (
      <button
        type='button'
        ref={ref}
        className={classes.dateInput}
        onClick={props.onClick}
        id={datePickerDropdownButtonId}
        aria-label={t(translationKey, {
          patientName: `${patient.first_name} ${patient.last_name}`,
          date: values.dob?.toDateString()
        })}
      >
        {!values.dob && <Text paragraph>{t('labels.dateOfBirth.select')} </Text>}
        <DatePickerIcon color={(theme) => theme.palette.grey[80]} />
      </button>
    );
  });

  if (isEditable) {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <div className={classes.headingContainer}>
          <Text h5>{t('title')}</Text>
          <button type='submit' className={classes.saveButton}>
            <span>{isUpdatingPatientInformation ? <Spinner /> : t('saveButtonText')}</span>
          </button>
        </div>
        <Card noShadow className={classes.card}>
          <div className={classes.nameEditContainer}>
            <div>
              <Text paragraph color='ship-cove'>
                {t('labels.firstName')}
              </Text>
              <input
                disabled={isUpdatingPatientInformation}
                title={t('labels.firstName')}
                required
                {...getFieldProps('first_name', {
                  onChange: (e) => {
                    e.persist();
                    setValue('first_name', e.target.value);
                  }
                })}
                size={Math.max(values.first_name.length, 5)}
                className={clsx(classes.largeInput)}
              />
            </div>
            <div>
              <Text paragraph color='ship-cove'>
                {t('labels.lastName')}
              </Text>
              <input
                disabled={isUpdatingPatientInformation}
                title={t('labels.lastName')}
                required
                {...getFieldProps('last_name', {
                  onChange: (e) => {
                    e.persist();
                    setValue('last_name', e.target.value);
                  }
                })}
                size={Math.max(values.last_name.length, 5)}
                className={clsx(classes.largeInput)}
              />
            </div>
          </div>
          <Divider className={classes.editableDivider} />
          <Grid container isMultiline>
            <Grid item size='is-half'>
              <Text paragraph color='ship-cove'>
                {t('labels.gender')}
              </Text>
              <input
                disabled={isUpdatingPatientInformation}
                title={t('labels.gender')}
                {...getFieldProps('gender')}
                className={clsx('input', classes.input)}
              />
            </Grid>
            <Grid item size='is-half'>
              <Text paragraph color='ship-cove'>
                {t('labels.dateOfBirth.title')}
              </Text>
              <span className={classes.dateContainer}>
                {values.dob && (
                  <Text paragraph color='grey-40' fontWeight={400}>
                    {formatDate(values.dob, 'MMM DD, YYYY')}
                  </Text>
                )}
                <ReactDatePicker
                  popperPlacement='bottom'
                  title={t('labels.dateOfBirth.title')}
                  focusSelectedMonth
                  preventOpenOnFocus
                  onCalendarOpen={() => {
                    setIsCalendarOpen(true);
                  }}
                  onCalendarClose={() => {
                    const button = document.getElementById(datePickerDropdownButtonId);
                    if (button) {
                      /**
                       * Using settimeout to focus on iconbutton because it seems to be causing an issue on some browser
                       * with the element not being foccused when the calendar is closed
                       */
                      setTimeout(() => {
                        document.getElementById(datePickerDropdownButtonId)?.focus({ preventScroll: false });
                      }, 0);
                    }
                    setIsCalendarOpen(false);
                  }}
                  customInput={<CustomInput />}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  minDate={moment().subtract(120, 'years').toDate()}
                  maxDate={moment().subtract(18, 'years').toDate()}
                  onChange={handleDateChange}
                  selected={values.dob ?? moment(new Date()).subtract(18, 'years').toDate()}
                  className={clsx(classes.dateInput)}
                />
              </span>
            </Grid>
            <Grid item size='is-full'>
              <Text paragraph color='ship-cove'>
                {t('labels.email')}
              </Text>
              <Text paragraph underlined>
                <input
                  disabled
                  title={t('labels.email')}
                  required
                  className={clsx('input', classes.input, 'is-underlined')}
                  {...getFieldProps('email')}
                />
              </Text>
            </Grid>
            <Grid item size='is-full'>
              <Text paragraph color='ship-cove'>
                {t('labels.healthCardNumber')}{' '}
              </Text>
              <Text paragraph>
                <input
                  disabled={isUpdatingPatientInformation}
                  title={t('labels.healthCardNumber')}
                  className={clsx('input', classes.input)}
                  {...getFieldProps('insurance_number', {
                    onChange: (e) => {
                      e.persist();
                      setValue('insurance_number', e.target.value);
                    }
                  })}
                />
              </Text>
            </Grid>
            <Grid item size='is-full'>
              <Text paragraph color='ship-cove'>
                {t('labels.address.title')}
              </Text>
              <Grid container isMultiline>
                <Grid item size='is-full'>
                  <input
                    disabled={isUpdatingPatientInformation}
                    title={t('labels.address.streetName')}
                    {...getFieldProps('streetAddress')}
                    className={clsx('input', classes.input)}
                    placeholder={t('labels.address.streetName')}
                  />
                </Grid>
                <Grid item size='is-half'>
                  <input
                    disabled={isUpdatingPatientInformation}
                    title={t('labels.address.city')}
                    {...getFieldProps('city')}
                    className={clsx('input', classes.input)}
                    placeholder={t('labels.address.city')}
                  />
                </Grid>
                <Grid item size='is-half'>
                  <input
                    disabled={isUpdatingPatientInformation}
                    title={t('labels.address.province')}
                    {...getFieldProps('province')}
                    className={clsx('input', classes.input)}
                    placeholder={t('labels.address.province')}
                  />
                </Grid>
                <Grid item size='is-half'>
                  <input
                    disabled={isUpdatingPatientInformation}
                    title={t('labels.address.postalCode')}
                    {...getFieldProps('postalCode', {
                      onChange: (e) => {
                        e.persist();
                        setValue('postalCode', e.target.value);
                      }
                    })}
                    className={clsx('input', classes.input)}
                    placeholder={t('labels.address.postalCode')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </form>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.headingContainer}>
        <Text h5>{t('title')}</Text>
        {!patient.registered && (
          <IconButton label={t('editPatientButtonlabel')} onClick={() => setIsEditable((prev) => !prev)}>
            <Icon name='edit-purple' />
          </IconButton>
        )}
      </div>
      <Card noShadow className={classes.card}>
        <Text h1>
          {patient.first_name} {patient.last_name}
        </Text>
        <Divider className={classes.divider} />
        <Grid container isMultiline>
          {patient.gender && (
            <Grid item size='is-half' className={classes.gridItem}>
              <Text paragraph color='ship-cove'>
                {t('labels.gender')}
              </Text>
              <Text paragraph>{patient.gender}</Text>
            </Grid>
          )}
          {patient.dob && (
            <Grid item size='is-half' className={classes.gridItem}>
              <Text paragraph color='ship-cove'>
                {t('labels.dateOfBirth.title')}
              </Text>
              <Text paragraph>{formatDate(patient.dob)}</Text>
            </Grid>
          )}
          {patient.email && (
            <Grid item size='is-half' className={classes.gridItem}>
              <Text paragraph color='ship-cove'>
                {t('labels.email')}
              </Text>
              <Text paragraph underlined>
                {patient.email}
              </Text>
            </Grid>
          )}
          {isNotUndefined(patient.insurance?.insurance_number) && (
            <Grid item size='is-half' className={classes.gridItem}>
              <Text paragraph color='ship-cove'>
                {t('labels.healthCardNumber')}
              </Text>
              <Text paragraph>{patient.insurance?.insurance_number}</Text>
            </Grid>
          )}
          {isNotUndefined(patient.address) && (
            <Grid item size='is-half' className={classes.gridItem}>
              <Text paragraph color='ship-cove'>
                {t('labels.address.title')}
              </Text>
              {patient.address?.street_address && <Text paragraph>{patient.address?.street_address}</Text>}
              {patient.address?.city && (
                <Text paragraph>
                  {patient.address?.city}, {patient.address?.province && <Text span>{patient.address?.province}</Text>}
                </Text>
              )}
              {patient.address?.postal_code && <Text paragraph>{patient.address?.postal_code}</Text>}
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
};

export default PatientDetailsCard;
