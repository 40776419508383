/* eslint-disable max-len */
/**
 * The routes object is created here as a regular constant and not as a json file
 * like all other translations because the routes are important to be loaded first
 * and sometimes loading them asynchronously(by having them as json) i18n would fallback to
 * the translation key which shouldn't happen under any scenario.
 *
 * NOTE: Add any routes translation file as a resource bundle to i18n in './i18n.ts' file similar to how routesENCA is added.
 * NOTE: Routes accessing a client, request or resource should use internal id
 */

export const routesENCA = {
  routerPaths: {
    '/404': '/404',
    '/login': '/login',
    '/logout': '/logout',
    '/forgot-password': '/forgot-password',
    '/update-password/:token': '/update-password/:token',
    '/verify/:token': '/verify/:token',
    '/invitation/:token': '/invitation/:token',
    '/settings/:selectedTab': '/settings/:selectedTab',
    '/requests/new': '/requests/new',
    '/requests/all': '/requests/all',
    '/requests/incoming': '/requests/incoming',
    '/requests/:internalId': '/requests/:internalId',
    '/clients/:internalId': '/clients/:internalId',
    '/clients/:internalId/recent-activities': '/clients/:internalId/recent-activities',
    '/clients/:internalId/records': '/clients/:internalId/records',
    '/clients': '/clients',
    '/requests': '/requests',
    '/': '/',
    '/create-patients': '/create-patients',
    '/create-patient': '/create-patient',
    '/add-note/:internalId': '/add-note/:internalId',
    '/tutorial/:slug': '/tutorial/:slug',
    '/upload/:internalId': '/upload/:internalId',
    '/upload': '/upload',
    '/clients/:internalId/profile': '/clients/:internalId/profile',
    '/notifications': '/notifications'
  },
  redirectPaths: {
    '/404': '/404',
    '/login': '/login',
    '/logout': '/logout',
    '/forgot-password': '/forgot-password',
    '/update-password/:token': '/update-password/{{token}}',
    '/verify/:token': '/verify/{{token}}',
    '/invitation/:token': '/invitation/{{token}}',
    '/clients/:internalId': '/clients/{{internalId}}',
    '/clients/:internalId/recent-activities': '/clients/{{internalId}}/recent-activities',
    '/clients/:internalId/records': '/clients/{{internalId}}/records',
    '/settings/overview': '/settings/overview',
    '/settings/billing': '/settings/billing',
    '/settings/institution': '/settings/institution',
    '/settings/security': '/settings/security',
    '/tutorial/create-new-request': '/tutorial/create-new-request',
    '/tutorial/dashboard-stats': '/tutorial/dashboard-stats',
    '/tutorial/create-new-patient': '/tutorial/create-new-patient',
    '/tutorial/request-and-client-tab': '/tutorial/request-and-client-tab',
    '/tutorial/institution-roles': '/tutorial/institution-roles',
    '/tutorial/add-clinical-note': '/tutorial/add-clinical-note',
    '/tutorial/understanding-request-statuses': '/tutorial/understanding-request-statuses',
    '/tutorial/read-and-unread-row': '/tutorial/read-and-unread-row',
    '/requests/:internalId': '/requests/{{internalId}}',
    '/clients': '/clients',
    '/requests': '/requests',
    '/requests/new': '/requests/new',
    '/requests/all': '/requests/all',
    '/requests/incoming': '/requests/incoming',
    '/create-patients': '/create-patients',
    '/create-patient': '/create-patient',
    '/add-note/:internalId': '/add-note/{{internalId}}',
    '/tutorial-page/:slug': '/tutorial/{{slug}}',
    '/upload/:internalId': '/upload/{{internalId}}',
    '/clients/:internalId/profile': '/clients/{{internalId}}/profile',
    '/notifications': '/notifications'
  },
  stringToReplaceForRouteParam: {
    'setting-tabs': '/settings/',
    tutorial: '/tutorial/'
  }
};
