import { makeStyles } from '@material-ui/core/';
import clsx from 'clsx';
import { ReactNode, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRoutes } from '../../../hooks/useRoutes';
import useTitle from '../../../hooks/useTitle';
import { useTranslate } from '../../../hooks/useTranslate';
import Icon from '../../shared/Icon';
import Modal from '../../shared/Modal';

interface WalkthroughStep {
  description: ReactNode;
  redirectPathTranslationKey: string;
  displayNameTranslationKey: string;
}

interface WalkthroughModalProps {
  steps: WalkthroughStep[];
  onClose: () => void;
  open: boolean;
  index: number;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '700px',
    position: 'relative',
    height: '72vh',
    overflow: 'auto',
    margin: 'auto',
    padding: '1rem 2.5rem',
    '& p': {
      margin: '0px !important'
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: '75%'
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      width: '95%'
    },
    //Style for the header
    '&>h6': {
      margin: '1rem 0px'
    }
  },
  imageContainer: {
    marginTop: '1rem',
    position: 'relative',
    backgroundColor: theme.palette.catSkillWhite.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '5px',
    padding: '1rem 0px 0px'
  },
  image: {
    margin: '1rem 0px',
    width: '90%',
    height: 'auto',
    borderRadius: '5px'
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 1rem'
  },
  hidden: {
    visibility: 'hidden'
  },
  root: {
    maxWidth: '700px',
    margin: '0px auto'
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1.1rem 0px',
    background: 'white',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  },
  articleContent: {
    overflowY: 'auto',
    position: 'absolute',
    top: '57px', //height of the heading
    bottom: '41px', //height of the footer
    left: 0,
    right: 0,
    padding: '0px 2.5rem',
    '& img': {
      margin: '1rem 0px',
      width: '100%',
      height: 'auto',
      borderRadius: '5px',
      border: `1px solid ${theme.palette.catSkillWhite.main}`
    }
  }
}));

const WalkthroughModal: React.FC<WalkthroughModalProps> = ({ steps, onClose, open, index }) => {
  const classes = useStyles();
  const location = useLocation();
  const { routesTFunction } = useRoutes();

  const currentStepIndex = index;

  const isPreviousStepDisabled = useMemo(() => currentStepIndex === 0, [currentStepIndex]);

  const isNextStepDisabled = useMemo(() => currentStepIndex === steps.length - 1, [currentStepIndex, steps.length]);

  const { description, displayNameTranslationKey } = useMemo(() => steps[currentStepIndex], [steps, currentStepIndex]);
  const { t: tutorialListTFunction, ready } = useTranslate('tutorialList');
  const { t } = useTranslate('walkthroughModal');

  useTitle((t) => {
    if (!ready) return t('dotConnect');
    return t('walkthroughTitle', { tutorialTitle: tutorialListTFunction(displayNameTranslationKey as any) });
  });

  const previousStep = useMemo(
    () => (isPreviousStepDisabled ? null : steps[currentStepIndex - 1]),
    [steps, isPreviousStepDisabled, currentStepIndex]
  );

  const nextStep = useMemo(
    () => (isNextStepDisabled ? null : steps[currentStepIndex + 1]),
    [steps, isNextStepDisabled, currentStepIndex]
  );

  return (
    <Modal
      align='left'
      className={classes.modal}
      open={open}
      onClose={onClose}
      header={tutorialListTFunction('withTutorial', {
        tutorialName: tutorialListTFunction(displayNameTranslationKey as any)
      })}
    >
      <article className={classes.root}>
        <div className={classes.articleContent}>{description}</div>
        <div className={classes.footer}>
          <Link
            aria-label={t('previousTutorialLinklabel')}
            to={
              isPreviousStepDisabled
                ? '#'
                : {
                    pathname: routesTFunction(previousStep?.redirectPathTranslationKey as any),
                    state: { background: location.state?.background }
                  }
            }
            className={clsx(classes.link, isPreviousStepDisabled && classes.hidden)}
          >
            <Icon name='arrow-back is-purple' />
          </Link>
          {currentStepIndex + 1}/{steps.length}
          <Link
            aria-label={t('nextTutorialLinklabel')}
            to={
              isNextStepDisabled
                ? '#'
                : {
                    pathname: routesTFunction(nextStep?.redirectPathTranslationKey as any),
                    state: { background: location.state?.background }
                  }
            }
            className={clsx(classes.link, isNextStepDisabled && classes.hidden)}
          >
            <Icon name='arrow-forward is-purple' />
          </Link>
        </div>
      </article>
    </Modal>
  );
};

export default WalkthroughModal;
