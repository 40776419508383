import { makeStyles } from '@material-ui/core/styles';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { matchPath, useHistory, useLocation, useParams } from 'react-router-dom';
import { useRoutes } from '../../../hooks/useRoutes';
import { useTranslate } from '../../../hooks/useTranslate';
import { useFetchPatientDetails } from '../../../request-hooks/partners/usePartnerRequests';
import { PartnerClient } from '../../../types';
import Button from '../../shared/Button';
import Card from '../../shared/Card';
import ClinicalNotesListCard, { ClinicalNotesListCardSkeleton } from './ClinicalNotesListCard';
import Container from '../../shared/Container';
import Grid from '../../shared/Grid';
import { ArrowLeftIcon, UploadRecordIcon } from '../../shared/Icons';
import PageContainer from '../../shared/PageContainer';
import PatientDetailsCard, { PatientDetailsCardSkeleton } from '../../shared/PatientDetailsCard';
import Tab from '../../shared/Tab';
import TabsList from '../../shared/TabsList';
import Tabs from '../../shared/Tabs';
import Text from '../../shared/Typography';
import Skeleton from '../../shared/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh-73px)'
  },
  tabRoot: {
    borderBottomWidth: 0
  },
  tabItem: {
    width: 'auto',
    fontSize: theme.typography.h5.fontSize,
    marginLeft: '0.75rem',
    marginRight: '0.75rem',
    color: theme.palette.grey[80],
    fontWeight: 350
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1.3rem',
    border: 'none'
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'flex-start',
    '& svg': {
      marginRight: '0.4rem'
    },
    '&:hover,&:focus': {
      '& svg': {
        color: theme.palette.primary.hover
      }
    }
  },
  childContainerCard: {
    width: '100%',
    marginBottom: 0
  },
  childContainer: {
    paddingLeft: '1rem',
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '0.75rem',
    '& .full-height': {
      [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
        minHeight: '74vh'
      }
    }
  },
  link: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    margin: '1.3rem 0px -1.2rem',
    '&:hover,&:focus': {
      '& svg': {
        color: theme.palette.primary.hover
      }
    },
    '& svg': {
      color: theme.palette.primary.main,
      margin: '0px 0.75rem 0px 0.3rem'
    }
  },
  linkSkeleton: {
    margin: '1.3rem 0px -1.2rem'
  },
  gridItem: {
    paddingBottom: 0
  }
}));

export interface ClientDetailsViewProps {
  children: ReactNode;
}

const ClientDetailViewContext = React.createContext<{
  isFetchingPatientDetails: boolean;
  patientDetails?: PartnerClient;
}>({ isFetchingPatientDetails: false });

export const useClientDetailViewContext = () => React.useContext(ClientDetailViewContext);

const ClientDetailsViewSkeleton = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  const location = useLocation();
  const pathname = useMemo(() => location.pathname, [location.pathname]);
  const { routesTFunction } = useRoutes();

  const view = useMemo(() => {
    if (
      matchPath(pathname, { path: routesTFunction('routerPaths./clients/:internalId/recent-activities'), exact: true })
    ) {
      return 'activities';
    }
    if (matchPath(pathname, { path: routesTFunction('routerPaths./clients/:internalId/records'), exact: true })) {
      return 'records';
    }
  }, [routesTFunction, pathname]);

  return (
    <Grid container isMultiline className={classes.root}>
      <Grid item size='is-full'>
        <Skeleton width='20ch' className={classes.linkSkeleton} />
      </Grid>

      <>
        <Grid item size='is-4'>
          <PatientDetailsCardSkeleton />
          <ClinicalNotesListCardSkeleton />
        </Grid>
        <Grid item size='is-8'>
          <Tabs value={view} className={classes.tabRoot}>
            <TabsList className={classes.tabContainer}>
              <div>
                <Tab value='activities' label={<Skeleton width='20ch' />} className={classes.tabItem} />
                <Tab value='records' label={<Skeleton width='20ch' />} className={classes.tabItem} />
              </div>
              <div>
                <Skeleton width='12ch' />
              </div>
            </TabsList>
            <Card noShadow className={classes.childContainerCard}>
              <div className={classes.childContainer}>{children}</div>
            </Card>
          </Tabs>
        </Grid>
      </>
    </Grid>
  );
};

const ClientDetailsView = ({ children }: ClientDetailsViewProps) => {
  const { routesTFunction } = useRoutes();
  const { internalId } = useParams<{ internalId: string }>();
  const { patientDetails, isFetchingPatientDetails, fetchPatientDetails, errorWhileFetchingPatientDetails } =
    useFetchPatientDetails(internalId);
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { t, ready } = useTranslate('clientDetailView');
  const { t: errorsTFunction } = useTranslate('errors');

  const [user, setUser] = useState<PartnerClient>();

  useEffect(() => {
    if (patientDetails) {
      setUser(patientDetails.user);
    }
  }, [patientDetails]);

  useEffect(() => {
    if (errorWhileFetchingPatientDetails === errorsTFunction('errorCode.NO_SUBJECT_FOUND')) {
      history.push('/404');
    }
  }, [errorWhileFetchingPatientDetails, errorsTFunction, history]);

  const pathname = useMemo(() => location.pathname, [location.pathname]);

  const view = useMemo(() => {
    if (
      matchPath(pathname, { path: routesTFunction('routerPaths./clients/:internalId/recent-activities'), exact: true })
    ) {
      return 'activities';
    }
    if (matchPath(pathname, { path: routesTFunction('routerPaths./clients/:internalId/records'), exact: true })) {
      return 'records';
    }
  }, [routesTFunction, pathname]);

  return (
    <ClientDetailViewContext.Provider value={{ isFetchingPatientDetails, patientDetails: user }}>
      {!ready || isFetchingPatientDetails ? (
        <ClientDetailsViewSkeleton>{children}</ClientDetailsViewSkeleton>
      ) : (
        <Grid container isMultiline className={classes.root}>
          <Grid item size='is-full'>
            <Text link className={classes.link} href={routesTFunction('redirectPaths./clients')}>
              <ArrowLeftIcon />
              {t('backToPatientButtonText')}
            </Text>
          </Grid>
          {errorWhileFetchingPatientDetails ? (
            <Grid item size='is-full'>
              <PageContainer>
                <Container justifyContent='center' padding='1rem 0px'>
                  <div>
                    <Text paragraph color='grey-60'>
                      {t('errorState.message')}
                    </Text>
                  </div>
                  <Container margin='1rem 0px' justifyContent='center'>
                    <Button label={t('errorState.tryAgainButtonText')} onClick={() => fetchPatientDetails()} />
                  </Container>
                </Container>
              </PageContainer>
            </Grid>
          ) : (
            <>
              <Grid item size='is-4'>
                {!!patientDetails && (
                  <PatientDetailsCard
                    onUpdate={(user) => {
                      setUser(user);
                      fetchPatientDetails();
                    }}
                    patient={patientDetails.user}
                  />
                )}
                {!!patientDetails && <ClinicalNotesListCard patient={patientDetails.user} />}
              </Grid>
              <Grid item size='is-8'>
                <Tabs
                  value={view}
                  onValueChange={(value) => {
                    if (value === 'activities') {
                      history.push(
                        routesTFunction('redirectPaths./clients/:internalId/recent-activities', { internalId })
                      );
                    } else if (value === 'records') {
                      history.push(routesTFunction('redirectPaths./clients/:internalId/records', { internalId }));
                    }
                  }}
                  className={classes.tabRoot}
                >
                  <TabsList className={classes.tabContainer}>
                    <div>
                      <Tab value='activities' label={t('tabLabels.recentActivities')} className={classes.tabItem} />
                      <Tab value='records' label={t('tabLabels.records')} className={classes.tabItem} />
                    </div>
                    <div>
                      <Text
                        link
                        className={classes.uploadButton}
                        href={{
                          pathname: routesTFunction('redirectPaths./upload/:internalId', {
                            internalId: patientDetails?.user.internal_id
                          }),
                          state: {
                            background: location,
                            internalId
                          }
                        }}
                      >
                        <UploadRecordIcon color={(theme) => theme.palette.primary.main} fontSize='1.7rem' />
                        <span>{t('uploadRecordButtonText')}</span>
                      </Text>
                    </div>
                  </TabsList>
                  <Card noShadow className={classes.childContainerCard}>
                    <div className={classes.childContainer}>{children}</div>
                  </Card>
                </Tabs>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </ClientDetailViewContext.Provider>
  );
};

export function withClientDetailsView<T = any>(Component: any) {
  const HOC = (props: T) => {
    return (
      <ClientDetailsView>
        <Component {...props} />
      </ClientDetailsView>
    );
  };

  return HOC;
}

export default ClientDetailsView;
