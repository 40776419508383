import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import React, { RefObject } from 'react';
import { conditionalProps, generateKey, hexToRGB, isNotUndefined } from '../../../utils/utils';
import Skeleton from '../Skeleton';
import Container from '../Container';
import Spinner from '../Spinner';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: '0 5px',
    margin: '1rem 0 5rem 0',
    color: theme.palette.text.main,
    fontWeight: 300,
    '& th': {
      color: theme.palette.grey[80],
      fontWeight: 350,
      fontSize: theme.typography.heading?.fontSize
    }
  },
  tableDataElement: {
    textAlign: 'left',
    padding: '1.7rem 2rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&:first-child': {
      paddingLeft: '4.4rem'
    },
    '&:last-child': {
      paddingRight: '4.4rem'
    }
  },
  tbody: {
    color: theme.palette.text.main
  },
  tbodyAlternating: {
    color: theme.palette.text.main,
    '&>tr:nth-child(odd)': {
      background: hexToRGB(theme.palette.catSkillWhite.main, 0.5)
    }
  },
  coloredHeader: {
    backgroundColor: theme.palette.catSkillWhite.main
  },
  coloredHeaderText: {
    color: `${theme.palette.grayscale['30']} !important`
  },
  alternateRowBorder: {
    '&>tr': {
      borderBottom: `1px solid ${theme.palette.grey[90]}`
    }
  }
}));

type RowPropsFunction<T> = (data: T, index: number) => React.HTMLAttributes<HTMLTableRowElement> | undefined;

type RowPropsArg<T> = React.HTMLAttributes<HTMLTableRowElement> | RowPropsFunction<T>;

interface ColumnProps<T> {
  name?: string;
  value?: ((data: T, index: number) => React.ReactNode) | React.ReactNode;
  hidden?: boolean;
  props?: React.HTMLAttributes<HTMLTableDataCellElement>;
}

interface DataTableProps<T> {
  loading?: boolean;
  data: T[];
  wrapperClassName?: string;
  className?: string;
  alternateRowColours?: boolean;
  alternateRowBorder?: boolean;
  coloredHeader?: boolean;
  rowProps?: RowPropsArg<T>;
  columns: ColumnProps<T>[];
  /**
   * Using this prop would override the default behaviour to show fields from the columns array
   */
  renderBody?: () => React.ReactNode;
  animateRows?: boolean;
  rowId: (data: T, rowIndex: number) => string | number;
  overflowStyle?: 'auto' | 'hidden' | 'visible';
  wrapperOnScroll?: () => void;
  wrapperRef?: RefObject<HTMLDivElement>;
  tBodyOnScroll?: () => void;
  tBodyRef?: RefObject<HTMLTableSectionElement>;
  hasNextPage?: boolean;
}

function isPropsObject<T>(arg: RowPropsArg<T>): arg is React.HTMLAttributes<HTMLTableRowElement> {
  return typeof arg !== 'function';
}

/**
 * The way that this component adds
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const DataTable = <T extends {}>(
  {
    loading,
    data,
    wrapperClassName,
    className,
    alternateRowColours,
    alternateRowBorder,
    coloredHeader,
    rowProps,
    columns,
    rowId,
    renderBody,
    animateRows = false,
    overflowStyle = 'auto',
    wrapperOnScroll,
    wrapperRef,
    tBodyOnScroll,
    tBodyRef,
    hasNextPage
  }: DataTableProps<T>,
  ref: React.ForwardedRef<HTMLTableElement> | RefObject<HTMLTableElement>
) => {
  const classes = useStyles();
  const RowComponent = animateRows ? motion.tr : 'tr';

  return (
    <div
      style={{ overflowX: overflowStyle, width: '100%' }}
      className={wrapperClassName}
      ref={wrapperRef}
      onScroll={wrapperOnScroll}
    >
      <table className={clsx(classes.table, className)} ref={ref}>
        <thead>
          <tr className={clsx(coloredHeader && classes.coloredHeader)}>
            {columns.map((column, index) => {
              if (column.hidden) return null;
              return (
                <th
                  {...column.props}
                  className={clsx(
                    classes.tableDataElement,
                    column.props?.className,
                    coloredHeader && classes.coloredHeaderText
                  )}
                  key={generateKey('data-table', column.name || index, column.value || index)}
                >
                  {column.name || !loading ? (
                    column.name
                  ) : (
                    <Skeleton style={{ display: 'block', marginBottom: '7px' }} height='24px' width='100px' />
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody
          ref={tBodyRef}
          className={clsx(
            alternateRowColours && classes.tbodyAlternating,
            alternateRowBorder && classes.alternateRowBorder,
            !alternateRowBorder && !alternateRowColours && classes.tbody
          )}
          onScroll={tBodyOnScroll}
        >
          {isNotUndefined(renderBody)
            ? renderBody()
            : data.map((d, index) => {
                let trProps: any;
                if (rowProps) {
                  if (isPropsObject(rowProps)) {
                    trProps = rowProps;
                  } else {
                    trProps = rowProps(d, index);
                  }
                }
                const rowKey = rowId(d, index);

                return (
                  <RowComponent
                    {...conditionalProps({ layout: true, layoutId: rowKey }, animateRows)}
                    key={rowKey}
                    {...trProps}
                  >
                    {columns.map((column) => {
                      const { value, hidden } = column;
                      if (hidden) return null;
                      const key = `row-${rowKey}-${column.name}`;
                      const className = clsx(classes.tableDataElement, column.props?.className);
                      let cell: React.ReactNode = null;
                      if (typeof value === 'function') {
                        const t: React.ReactNode = value(d, index);
                        cell = t;
                      } else {
                        cell = value;
                      }
                      return (
                        <td {...column.props} key={key} className={className}>
                          {cell || !loading ? (
                            cell
                          ) : (
                            <Skeleton style={{ display: 'block', marginBottom: '7px' }} height='24px' width='100px' />
                          )}
                        </td>
                      );
                    })}
                  </RowComponent>
                );
              })}
          {hasNextPage && (
            <RowComponent key={generateKey(hasNextPage, rowId)}>
              <Container isFlex justifyContent='center' style={{ minHeight: '6rem' }}>
                <Spinner />
              </Container>
            </RowComponent>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default React.forwardRef(DataTable);
