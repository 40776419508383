import { useCallback } from 'react';
import { TFuncKey, TFunction } from 'react-i18next';
import { useTranslate } from './useTranslate';

export const useRoutes = () => {
  const { t, ...other } = useTranslate('routes');

  const routesTFunction: TFunction<'routes'> = useCallback(
    (key: TFuncKey<'routes'>, options?: any) => {
      return t(key, { ...options, nsSeparator: '|' });
    },
    [t]
  );

  return { routesTFunction, ...other };
};
