import { makeStyles } from '@material-ui/core/styles';
import { TableSkeleton } from '../pages/Settings/Users/UsersTable';
import Container from '../shared/Container';
import DataTable from '../shared/DataTable/DataTable';
import Divider from '../shared/Divider';
import PageContainer from '../shared/PageContainer';
import Skeleton from '../shared/Skeleton';

import Text from '../shared/Typography';

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: '305px',
    padding: '35px 30px',
    height: '100%'
  },
  section: {
    minHeight: '50vh'
  },
  billingDetails: {
    boxShadow: 'none',
    background: 'none'
  },
  tabListContainer: {
    display: 'flex',
    width: '600px',
    maxWidth: '600px'
  },

  /* Billing Details Skeleton Styling */
  detailContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column'
    }
  },
  root: {
    width: '100%'
  },
  accordionDivider: {
    margin: '15px 0'
  },
  planDetailsSection: {
    display: 'flex',
    gap: '36px',
    marginBottom: '26px'
  },
  accordionCard: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.catSkillWhite.main}`,
    overflow: 'hidden'
  },
  table: {
    margin: '0'
  }
  /* **** */
}));

export const UsersSkeleton = () => {
  return (
    <>
      <div className='m40 flex space-between start'>
        <div>
          <Text h6 color='grey-60'>
            <Skeleton style={{ height: '25px', width: '12ch' }} />
          </Text>
          <Text h3 className='mt12'>
            <Skeleton style={{ height: '28px', width: '12ch' }} />
          </Text>
        </div>

        <Skeleton style={{ height: '46px', width: '127px' }} />
      </div>

      <div style={{ width: '100%' }}>
        <TableSkeleton />
      </div>
    </>
  );
};

export const PendingTransactionSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Skeleton style={{ display: 'block', marginBottom: '7px' }} height='32px' width='300px' />
      <Skeleton style={{ display: 'block', marginBottom: '7px' }} height='24px' width='700px' />
      <Divider className={classes.accordionDivider} />
    </>
  );
};

export const TransactionHistorySkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Skeleton style={{ display: 'block', marginBottom: '7px' }} height='25px' width='300px' />
      <Skeleton style={{ display: 'block', marginBottom: '7px' }} height='24px' width='700px' />
      <Divider className={classes.accordionDivider} />
      <PageContainer className={classes.accordionCard}>
        <DataTable
          alternateRowBorder
          coloredHeader
          loading
          data={new Array(3).fill(1)}
          className={classes.table}
          columns={new Array(3)}
          rowId={(d, index) => `skeleton-table-${d}-${index}`}
        />
      </PageContainer>
    </>
  );
};

export const SectionTitleSkeleton = () => <Skeleton style={{ display: 'block' }} height='25px' width='150px' />;

export const LabelValueSkeleton = () => (
  <Skeleton style={{ display: 'block', marginBottom: '7px' }} height='62px' width='130px' />
);

export const BillingDetailsSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.detailContainer}>
        <div style={{ width: '65%' }}>
          <div className={classes.section}>
            <SectionTitleSkeleton />
            <PageContainer className={classes.card}>
              <Container className={classes.planDetailsSection}>
                <LabelValueSkeleton />
                <LabelValueSkeleton />
              </Container>
              <LabelValueSkeleton />
              <Skeleton style={{ display: 'block', margin: '15px 0 15px' }} height='24px' width='150px' />
              <Skeleton style={{ display: 'block' }} height='24px' width='500px' />
            </PageContainer>
          </div>
        </div>
        <div style={{ width: '35%' }}>
          <div className={classes.section}>
            <SectionTitleSkeleton />
            <PageContainer className={classes.card}>
              <LabelValueSkeleton />
              <LabelValueSkeleton />
            </PageContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
