import { makeStyles } from '@material-ui/core/';
import clsx from 'clsx';
import Text from './Typography';

export interface TagProps {
  color: 'warning' | 'error' | 'success';
  text: string;
}

const useTagStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '1rem',
    padding: '0px 1rem',
    marginLeft: '1rem'
    // display: 'flex',
    // alignItems: 'center'
  },
  textBase: {
    textTransform: 'uppercase',
    color: 'inherit',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: 1.5
  },
  warning: {
    color: theme.palette.orange.main,
    background: 'rgba(240, 148, 86, 0.1);'
  },
  success: {
    color: theme.palette.forestGreen.main,
    background: 'rgba(18, 204, 159, 0.1)'
  },
  error: {
    color: theme.palette.error.main,
    background: 'rgba(239, 86, 108, 0.1);'
  }
}));

const Tag = ({ color, text }: TagProps) => {
  const classes = useTagStyles();
  return (
    <div className={clsx(classes.root, classes[color])}>
      <Text small className={classes.textBase}>
        {text}
      </Text>
    </div>
  );
};

export default Tag;
