/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface MedicationRequestIconProps extends SVGBaseProps {}

const MedicationRequestIcon = (props: MedicationRequestIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M14.0001 2C14.0001 0.895431 13.1047 0 12.0001 0C10.8955 0 10.0001 0.89543 10.0001 2V10H2C0.895431 10 0 10.8955 0 12C0 13.1046 0.89543 14 2 14H10.0001V22C10.0001 23.1046 10.8955 24 12.0001 24C13.1047 24 14.0001 23.1046 14.0001 22V14H22C23.1046 14 24 13.1046 24 12C24 10.8955 23.1046 10 22 10H14.0001V2Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default MedicationRequestIcon;
