import { PartnerClient, Representative } from '../../../types';
import { getTitleCase, deepEqual } from '../../../utils/utils';
import { GuardianFormState, PatientFormInitialState } from './ICreateClientForm';

export const createPatientFormState = (patientDetails: PartnerClient): Partial<typeof PatientFormInitialState> => {
  return {
    id: String(patientDetails?.id || ''),
    internal_id: String(patientDetails?.internal_id || ''),
    first_name: patientDetails?.first_name || '',
    last_name: patientDetails?.last_name || '',
    email: patientDetails?.email || '',
    insurance_number: patientDetails?.insurance?.insurance_number || '',
    dob: patientDetails?.dob || '',
    phone_number: patientDetails?.phone || '',
    street_address: patientDetails?.address?.street_address || '',
    city: patientDetails?.address?.city || '',
    province: patientDetails?.address?.province || '',
    postal_code: patientDetails?.address?.postal_code || '',
    extra_line_1: patientDetails?.address?.extra_line_1 || ''
  };
};

export const createRepresentativeFormState = (representative: Representative): GuardianFormState => {
  return {
    RepresentativeId: String(representative.id || ''),
    guardian_email: representative?.email || '',
    guardian_first_name: representative?.first_name || '',
    guardian_last_name: representative?.last_name || '',
    guardian_phone_number: representative?.phone || '',
    representativeRelationship: representative?.relation?.relationship || ''
  };
};

type FormAddress = {
  city: string;
  province: string;
  postal_code: string;
  street_address: string;
  extra_line_1?: string;
};

export const getAddressFromForm = (values: typeof PatientFormInitialState): FormAddress => {
  const formattedPostal = values.postal_code.toUpperCase();

  return {
    city: values.city,
    province: values.province,
    postal_code: formattedPostal,
    street_address: values.street_address,
    extra_line_1: values.extra_line_1
  };
};

export const getRepresentativeFromForm = (
  values: typeof PatientFormInitialState,
  guardianId?: string
): GuardianFormState => {
  return {
    RepresentativeId: guardianId as string,
    guardian_email: values.guardian_email,
    guardian_first_name: values.guardian_first_name,
    guardian_last_name: values.guardian_last_name,
    guardian_phone_number: values.guardian_phone_number,
    representativeRelationship: values.representativeRelationship
  };
};

type FormUser = {
  id?: string;
  internal_id?: string;
  email?: string;
  phone?: string;
  first_name: string;
  last_name: string;
  dob?: string;
  insurance_number: string;
  address: FormAddress;
  RepresentativeId?: string;
  representativeRelationship?: string;
};

export const getFormattedClientFromForm = ({
  values,
  isMinorClient,
  guardianId
}: {
  values: typeof PatientFormInitialState;
  isMinorClient?: boolean;
  guardianId?: string;
}) => {
  const formattedInsurance = values.insurance_number.toUpperCase();
  const formattedFirstName = getTitleCase(values?.first_name);
  const formattedLastName = getTitleCase(values?.last_name);

  const user: FormUser = {
    first_name: formattedFirstName,
    last_name: formattedLastName,
    dob: values.dob,
    insurance_number: formattedInsurance,
    address: getAddressFromForm(values)
  };

  if (!isMinorClient) {
    user.email = values.email;
    user.phone = values.phone_number ? values.phone_number.trim().replace(/[^0-9]/g, '') : '';
  }

  if (isMinorClient) {
    user.RepresentativeId = guardianId;
    user.representativeRelationship = values.representativeRelationship;
  }

  if (values.id) {
    user.id = values.id;
  }

  if (values.internal_id) {
    user.internal_id = values.internal_id;
  }

  return { user };
};

export const getFormattedClientFromData = (patientDetails: PartnerClient) => {
  const user: FormUser = {
    email: patientDetails.email,
    phone: patientDetails.phone,
    first_name: patientDetails.first_name,
    last_name: patientDetails.last_name,
    dob: patientDetails.dob,
    insurance_number: patientDetails.insurance.insurance_number,
    address: {
      city: patientDetails.address.city,
      province: patientDetails.address.province,
      postal_code: patientDetails.address.postal_code,
      street_address: patientDetails.address.street_address,
      extra_line_1: patientDetails.address.extra_line_1
    }
  };

  return { user };
};

export const getGuardianFromData = (user: PartnerClient, relationship?: string) => {
  return {
    RepresentativeId: String(user?.id) || '',
    guardian_email: user?.email,
    guardian_first_name: user?.first_name,
    guardian_last_name: user?.last_name,
    guardian_phone_number: user?.phone,
    representativeRelationship: relationship || ''
  };
};

export const resetGuardianFields = (setValues: any) => {
  if (setValues) {
    setValues({
      RepresentativeId: '',
      guardian_email: '',
      guardian_first_name: '',
      guardian_last_name: '',
      guardian_phone_number: '',
      representativeRelationship: ''
    });
  }
};

export const createPartnerClient = (response: any, values: typeof PatientFormInitialState) => {
  const formattedInsurance = values?.insurance_number?.toUpperCase();
  const address = values && getAddressFromForm(values);

  return {
    ...response.user,
    insurance: {
      insurance_number: formattedInsurance
    },
    address: address
  };
};

export const shouldUpdateClient = (
  values: typeof PatientFormInitialState,
  patientDetails: PartnerClient,
  guardianDetails?: GuardianFormState
) => {
  const updatedClient = {
    firstName: values.first_name,
    lastName: values.last_name,
    dob: values.dob,
    insurance_number: values.insurance_number,
    phone: values?.phone_number || '',
    city: values.city,
    province: values.province,
    extra_line_1: values.extra_line_1,
    postal_code: values.postal_code,
    street_address: values.street_address,
    RepresentativeId: values.RepresentativeId || '',
    representativeRelationship: values.representativeRelationship || ''
  };

  const originalClient = {
    firstName: patientDetails.first_name,
    lastName: patientDetails.last_name,
    dob: patientDetails.dob,
    insurance_number: patientDetails.insurance.insurance_number,
    phone: patientDetails?.phone || '',
    city: patientDetails.address.city,
    province: patientDetails.address.province,
    extra_line_1: patientDetails.address.extra_line_1,
    postal_code: patientDetails.address.postal_code,
    street_address: patientDetails.address.street_address,
    RepresentativeId: guardianDetails?.RepresentativeId || '',
    representativeRelationship: guardianDetails?.representativeRelationship || ''
  };

  return !deepEqual(originalClient, updatedClient);
};
