import { useContext, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Auth User Context
import { AuthContext } from '../../contexts/auth-context';

// Request Hooks
import { useFetchLogo } from '../../request-hooks/shared/useSharedRequest';

// Dot Components
import Skeleton from './Skeleton';
import InstitutionLogo, { InstitutionLogoSizes } from './InstitutionLogo';

const useStyles = makeStyles(() => ({
  skeleton: {
    borderRadius: '50%',
    '&--large': {
      height: '50px',
      width: '50px'
    },
    '&--regular': {
      height: '35px',
      width: '35px'
    },
    '&--small': {
      height: '30px',
      width: '30px'
    },
    '&--x-small': {
      height: '25px',
      width: '25px'
    }
  }
}));

const AuthInstitutionLogo = ({ size = 'regular' }: { size?: InstitutionLogoSizes }) => {
  const classes = useStyles();
  const { userInfo } = useContext(AuthContext);
  const { fetchLogo, logo, isFetchingLogo } = useFetchLogo();

  // This use effect will fire fetchLogo hook if there's a file uploaded
  useEffect(() => {
    if (userInfo?.institution?.logo?.file_name) {
      fetchLogo();
    }
  }, [userInfo?.institution?.logo?.file_name, fetchLogo]);

  const logoBlob = useMemo(() => {
    if (!logo || !userInfo?.institution?.logo?.file_name) return;
    return new Blob([logo], {
      type: (logo as any).type
    });
  }, [logo, userInfo?.institution?.logo?.file_name]);

  const logoSrc = useMemo(() => {
    if (!logoBlob) return;
    return window.URL.createObjectURL(logoBlob);
  }, [logoBlob]);

  if (isFetchingLogo) return <Skeleton className={`${classes.skeleton} ${classes.skeleton}--${size}`} />;

  return <InstitutionLogo size={size} src={logoSrc || ''} />;
};

export default AuthInstitutionLogo;
