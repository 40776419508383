import { ClientUpload, PartnerClient } from '../../../types';

export const PatientFormInitialState = {
  internal_id: '',
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  insurance_number: '',
  dob: '',
  phone_number: '',
  street_address: '',
  city: '',
  province: '',
  postal_code: '',
  extra_line_1: '',
  RepresentativeId: '',
  guardian_first_name: '',
  guardian_last_name: '',
  guardian_email: '',
  guardian_phone_number: '',
  representativeRelationship: ''
};

export interface GuardianFormState {
  RepresentativeId: string;
  guardian_first_name: string;
  guardian_last_name: string;
  guardian_email: string;
  guardian_phone_number: string;
  representativeRelationship: string;
}
export interface ClientFormProps {
  onCreate?: (patient?: ClientUpload) => void;
  onSubmitSuccess?: (
    patient: PartnerClient,
    hasUserUpdates?: boolean,
    representative?: {
      RepresentativeId: string;
      guardian_first_name: string;
      guardian_last_name: string;
      guardian_email: string;
      guardian_phone_number: string;
      representativeRelationship: string;
    }
  ) => void;
  editMode?: boolean;
  patientDetails?: PartnerClient;
  submitText?: string;
  noInputBorder?: boolean;
  isMinorClient?: boolean;
  isRepresentative?: boolean;
  guardianDetails?: GuardianFormState;
}
