import merge from 'deepmerge';
import moment from 'moment';
import { InfiniteData, QueryClient } from 'react-query';
import { UseGetPartnerUsersQueryResponse } from '../../request-hooks/partners/usePartnerRequests';
import { DeepPartial, PartnerClient } from '../../types';
import { CLIENT_LIST_NEWEST_QUERY_KEY, CLIENT_TAB_STATISTICS_QUERY_KEY } from '../constants';

type Result = InfiniteData<UseGetPartnerUsersQueryResponse> | undefined;

export const addNewUsersToCache = (queryClient: QueryClient, newUsers: PartnerClient[]) => {
  queryClient.setQueryData<Result>(CLIENT_LIST_NEWEST_QUERY_KEY, (data: any) => {
    if (!data)
      return { pages: [{ information_requests: [...newUsers], meta: { total_pages: 1, count: 1 } }], pageParams: [0] };

    const newPagesArray = data.pages.map(({ users, meta }: any, index: number) => {
      if (index === 0) {
        const newUserArray = [...newUsers, ...users];
        return { users: newUserArray, meta };
      }
      return { users, meta };
    });

    return { pages: newPagesArray, pageParams: data.pageParams };
  });
};

export const updateUserInformationInCache = (
  queryClient: QueryClient,
  token: string,
  updatedUserInformation: DeepPartial<PartnerClient>
) => {
  queryClient.setQueryData<Result>(CLIENT_LIST_NEWEST_QUERY_KEY, (data: any) => {
    if (!token) return data;
    const newPagesArray = data.pages.map(({ users, meta }: any) => {
      const newUsers = users.map((user: any) => {
        if (user.token === token) {
          /**
           * Performing a deep merge on user to only update properties
           * coming from the response and keeping the rest of user object
           * the same.
           */
          return merge(user, updatedUserInformation);
        }
        return user;
      });
      return { users: newUsers, meta };
    });
    return {
      pages: newPagesArray,
      pageParams: data.pageParams
    };
  });
};

export const incrementUserStatisticsCache = (queryClient: QueryClient, incrementAmount?: number) => {
  queryClient.setQueriesData(CLIENT_TAB_STATISTICS_QUERY_KEY, (data: any) => {
    return {
      ...data,
      meta: {
        // eslint-disable-next-line
        count: data.meta.count + (incrementAmount ? incrementAmount : 1),
        // eslint-disable-next-line
        new_clients: data.meta.new_clients + (incrementAmount ? incrementAmount : 1)
      }
    };
  });
};

export const deleteUserInCache = (queryClient: QueryClient, token: string) => {
  const lastMonth = moment().subtract(1, 'month');
  let isNewClient = false; // Client added this month

  queryClient.setQueryData<Result>(CLIENT_LIST_NEWEST_QUERY_KEY, (data: any) => {
    if (!token) return data;
    const newPagesArray = data.pages.map(({ users, meta }: any) => {
      const newUsers = users.filter((user: any) => {
        if (user.token === token && user.added_at) {
          isNewClient = moment(user.added_at).isAfter(lastMonth);
        }
        return user.token !== token;
      });
      return { users: newUsers, meta };
    });
    return {
      pages: newPagesArray,
      pageParams: data.pageParams
    };
  });

  queryClient.setQueriesData(CLIENT_TAB_STATISTICS_QUERY_KEY, (data: any) => {
    if (!token) return data;
    return {
      ...data,
      meta: {
        count: data.meta.count ? data.meta.count - 1 : data.meta.count,
        new_clients: data.meta.new_clients && isNewClient ? data.meta.new_clients - 1 : data.meta.new_clients
      }
    };
  });
};
