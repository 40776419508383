import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface RecordsTabWrapperSearchInputContainerProps {
  children: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  searchContainer: {
    marginLeft: 'auto'
  },
  searchInputWrapper: {
    width: '210px'
  }
}));

const RecordsTabWrapperSearchInputContainer = (props: RecordsTabWrapperSearchInputContainerProps) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.searchContainer}>
      <div className={classes.searchInputWrapper}>{children}</div>
    </div>
  );
};

export default RecordsTabWrapperSearchInputContainer;
