/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface ProcedureIconProps extends SVGBaseProps {}

const ProcedureIcon = (props: ProcedureIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M21.4286 14.6429C21.902 14.1696 22.6695 14.1696 23.1429 14.6429C23.6163 15.1163 23.6163 15.8838 23.1429 16.3572L16.8754 22.6247C16.392 23.1082 15.6081 23.1082 15.1247 22.6247L11.7144 19.2144C11.241 18.741 11.241 17.9735 11.7144 17.5001C12.1877 17.0267 12.9553 17.0267 13.4286 17.5001L16.0001 20.0715L21.4286 14.6429Z'
        fill='inherit'
      />
      <path
        d='M18 7.5C18 6.67157 17.3284 6 16.5 6H1.5C0.671573 6 0 6.67157 0 7.5C0 8.32843 0.671573 9 1.5 9H16.5C17.3284 9 18 8.32843 18 7.5ZM18 1.5C18 0.671573 17.3284 0 16.5 0H1.5C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3H16.5C17.3284 3 18 2.32843 18 1.5ZM0 13.5C0 14.3284 0.671573 15 1.5 15H10.5C11.3284 15 12 14.3284 12 13.5C12 12.6716 11.3284 12 10.5 12H1.5C0.671573 12 0 12.6716 0 13.5Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default ProcedureIcon;
