import { InformationRequest } from './IInformationRequest';

export interface Notification {
  id: number;
  type: NOTIFICATION_TYPES;
  createdAt: string;
  updatedAt: string;
  isRead: boolean;
  information_request?: InformationRequest;
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum NOTIFICATION_TYPES {
  REQUEST_COMPLETE = 'REQUEST_COMPLETE',
  REQUEST_UNPROCESSABLE = 'REQUEST_UNPROCESSABLE',
  REQUEST_ACTION_REQUIRED = 'REQUEST_ACTION_REQUIRED',
  REQUEST_ARCHIVED_REMINDER = 'REQUEST_ARCHIVED_REMINDER',
  REQUEST_UNSUCCESSFUL_AUTHORIZATION = 'REQUEST_UNSUCCESSFUL_AUTHORIZATION',
  REQUEST_NEW_RESOURCE_ADMIN = 'REQUEST_NEW_RESOURCE_ADMIN',
  REQUEST_NEW_RESOURCE_PATIENT = 'REQUEST_NEW_RESOURCE_PATIENT',
  NEW_INCOMING_REQUEST = 'NEW_INCOMING_REQUEST'
}
