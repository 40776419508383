/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface PatientIconProps extends SVGBaseProps {}

const PatientIcon = (props: PatientIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M12.74 20.892C12.2662 21.3658 12.0001 22.0083 12.0001 22.6782V23.3685C12.0001 23.7173 12.2829 24 12.6316 24H13.3219C13.9919 24 14.6343 23.7339 15.1081 23.2602L20.906 17.4622C21.1526 17.2156 21.1526 16.8157 20.906 16.5691L19.4309 15.0941C19.1843 14.8475 18.7845 14.8475 18.5379 15.0941L12.74 20.892ZM23.8153 14.5528C24.0616 14.3065 24.0616 13.9087 23.8153 13.6624L22.3376 12.1847C22.0914 11.9384 21.6935 11.9384 21.4472 12.1847L20.7381 12.8938C20.4915 13.1404 20.4915 13.5403 20.7381 13.7869L22.2132 15.2619C22.4598 15.5085 22.8596 15.5085 23.1062 15.2619L23.8153 14.5528Z'
        fill='inherit'
      />
      <path
        d='M18 7.5C18 6.67157 17.3284 6 16.5 6H1.5C0.671573 6 0 6.67157 0 7.5C0 8.32843 0.671573 9 1.5 9H16.5C17.3284 9 18 8.32843 18 7.5ZM18 1.5C18 0.671573 17.3284 0 16.5 0H1.5C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3H16.5C17.3284 3 18 2.32843 18 1.5ZM0 13.5C0 14.3284 0.671573 15 1.5 15H10.5C11.3284 15 12 14.3284 12 13.5C12 12.6716 11.3284 12 10.5 12H1.5C0.671573 12 0 12.6716 0 13.5Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default PatientIcon;
