import decode from 'jwt-decode';

export interface JWTToken {
  sub: string;
  role: string;
  email: string;
  features: string[];
  iat: string;
  exp: number;
  jti: string;
}

export function getTokenExpirationDate(token: string) {
  const decoded = decode<JWTToken>(token);
  if (!decoded.exp) {
    return null;
  }

  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp);
  return date;
}
export function getEmailFromJWT(token: string) {
  const decoded = decode<JWTToken>(token);
  return decoded.email || '';
}

export function getRoleFromJWT(token: string) {
  const decoded = decode<JWTToken>(token);
  return decoded.role || '';
}

export function isTokenExpired(token?: string) {
  const date = getTokenExpirationDate(token || '');
  if (date === null) {
    return false;
  }
  return !(date.valueOf() > new Date().valueOf());
}
