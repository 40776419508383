/* eslint-disable jsx-a11y/heading-has-content */
import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { PartialLocation } from 'history';
import Icon from './Icon';
import { truncateStr, __prod__ } from '../../utils/utils';
import { IconNames, IconSizes } from '../../types/common/IconTypes';

export type TypographyColors =
  | 'white'
  | 'success'
  | 'pending'
  | 'purple'
  | 'grey'
  | 'red'
  | 'grey-40'
  | 'grey-30'
  | 'grey-60'
  | 'grey-80'
  | 'ship-cove'
  | 'error'
  | 'light--purple'
  | 'forest-green';

export interface TypographyProps extends HTMLAttributes<any> {
  a?: boolean;
  span?: boolean;
  paragraph?: boolean;
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  h5?: boolean;
  h6?: boolean;
  code?: boolean;
  tag?: boolean;
  link?: boolean;
  italic?: boolean;
  small?: boolean;
  dottedUnderline?: boolean;
  children?: any;
  align?: string; // left, right, center
  className?: string;
  hasSubtitle?: boolean;
  truncated?: number;
  icon?: IconNames; //see IconTypes.ts for options
  iconClassName?: string;
  href?: string | PartialLocation;
  chartValues?: string;
  chartLabel?: string;
  chartTitle?: string;
  uppercase?: boolean;
  bold?: boolean;
  iconSize?: IconSizes; //see IconTypes.ts for options
  color?: TypographyColors; // grey, grey-40, light, light--purple, default color is dark,
  style?: React.CSSProperties;
  underlined?: boolean;
  openInNewTab?: boolean;
  fontWeight?: 300 | 350 | 400 | 500 | 600;
}

const Text = ({
  a,
  align,
  className,
  href,
  hasSubtitle,
  icon,
  iconSize,
  iconClassName,
  truncated,
  span,
  tag,
  link,
  italic,
  dottedUnderline,
  paragraph,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  children,
  uppercase,
  small,
  chartValues,
  chartLabel,
  color,
  bold,
  chartTitle,
  code,
  style,
  underlined,
  openInNewTab,
  fontWeight,
  ...others
}: TypographyProps) => {
  // all the modifiers
  const classOptions = clsx(
    align ? `is-${align}` : 'is-left',
    color ? `is-${color}` : '',
    italic ? 'is-italic' : '',
    dottedUnderline ? 'is-dotted-underline' : '',
    bold ? 'is-bold' : '',
    hasSubtitle ? 'has-subtitle' : '',
    icon ? 'has-icon' : '',
    uppercase ? 'is-uppercase' : '',
    truncated ? 'is-truncated' : '',
    chartValues ? 'type-chart__values' : '',
    chartLabel ? 'type-chart__label' : '',
    link ? 'type-link' : '',
    tag ? 'type-tag is-uppercase' : '',
    underlined ? 'is-underlined' : '',
    fontWeight && `font-weight-${fontWeight}`,
    className
  );

  let renderItems = children;

  if (truncated) {
    if (typeof children === 'string') {
      renderItems = truncateStr(children, truncated);
    } else if (!__prod__) {
      // eslint-disable-next-line quotes
      console.error("'truncated' prop only works on strings and not JSX elements");
    }
  }

  // options for the icon
  const IconComponent = <Icon name={icon} size={iconSize} className={iconClassName} />;

  const CommonProps = {
    children: (
      <>
        {icon && IconComponent}
        {renderItems}
      </>
    ),
    style,
    ...others
  };

  return (
    <>
      {paragraph && <p {...CommonProps} className={`type-paragraph ${classOptions}`} />}
      {h1 && <h1 {...CommonProps} className={`type-h1 ${classOptions}`} />}
      {h2 && <h2 {...CommonProps} className={`type-h2 ${classOptions}`} />}
      {h3 && <h3 {...CommonProps} className={`type-h3 ${classOptions}`} />}
      {h4 && <h4 {...CommonProps} className={`type-h4 ${classOptions}`} />}
      {h5 && <h5 {...CommonProps} className={`type-h5 ${classOptions}`} />}
      {h6 && <h6 {...CommonProps} className={`type-h6 ${classOptions}`} />}
      {code && <code {...CommonProps} className={`code ${classOptions}`} />}
      {span && <span {...CommonProps} className={`${classOptions}`} />}
      {link && (
        <Link
          {...CommonProps}
          to={href}
          className={`type-link ${classOptions}`}
          {...(openInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
        />
      )}
      {a && (
        // eslint-disable-next-line
        <a
          {...CommonProps}
          href={href as string}
          className={`type-link ${classOptions}`}
          target='_blank'
          rel='noopener noreferrer'
        />
      )}
      {tag && <span {...CommonProps} className={`type-tag ${classOptions}`} />}
      {chartTitle && <span {...CommonProps} className={`type-chart__title is-uppercase ${classOptions}`} />}
      {small && <div {...CommonProps} className={`type-small ${classOptions}`} />}
      {chartValues && <span {...CommonProps} className={`type-chart__values ${classOptions}`} />}
      {chartLabel && <span {...CommonProps} className={`type-chart__label is-uppercase ${classOptions}`} />}
    </>
  );
};

export default Text;
