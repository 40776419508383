/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { SnackbarCloseReason } from '@material-ui/core/';
import { createContext, useState, Context, useMemo, useCallback, ReactNode } from 'react';
import Snackbar, { SnackbarProps } from '../components/shared/Snackbar';

type SnackbarContextType = {
  addNotification: (
    message: React.ReactNode,
    type?: 'success' | 'progress' | 'error' | 'none',
    action?: ReactNode,
    props?: SnackbarProps
  ) => void;
  removeNotification: () => void;
};

interface Notification {
  message: React.ReactNode;
  type?: 'success' | 'progress' | 'error' | 'none';
  action?: ReactNode;
  props?: SnackbarProps;
}

export const SnackbarContext: Context<SnackbarContextType> = createContext({
  addNotification: (
    message: React.ReactNode,
    type?: 'success' | 'progress' | 'error' | 'none',
    action?: ReactNode,
    props?: SnackbarProps
  ) => {},
  removeNotification: () => {}
});
const AUTO_HIDE_DURATION = 1000 * 5;

export type SnackbarProviderProps = React.PropsWithChildren<Record<string, unknown>>;

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [notification, setNotification] = useState<Notification>();
  const [isOpen, setOpen] = useState(false);

  const addNotification = useCallback(
    (
      message: React.ReactNode,
      type?: 'success' | 'progress' | 'error' | 'none',
      action?: ReactNode,
      props?: SnackbarProps
    ) => {
      setNotification({ message, type, action, props });
      setOpen(true);
    },
    []
  );

  const removeNotification = useCallback(() => {
    setOpen(false);
  }, []);

  const value = useMemo(() => ({ addNotification, removeNotification }), [addNotification, removeNotification]);

  const handleClose = (event: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {notification && (
        <Snackbar
          open={isOpen}
          autoHideDuration={AUTO_HIDE_DURATION}
          onClose={handleClose}
          message={notification.message}
          type={notification.type}
          action={notification.action}
          {...notification.props}
        />
      )}
    </SnackbarContext.Provider>
  );
};
