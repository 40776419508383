import React from 'react';

export interface AlertContainerProps {
  children: React.ReactElement;
  show?: boolean;
  className?: string;
}

const AlertContainer = (props: AlertContainerProps) => {
  const { children, show = true, className } = props;

  if (!show) return null;

  return (
    <div role='alert' className={className}>
      {children}
    </div>
  );
};

export default AlertContainer;
