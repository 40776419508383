import { CSSProperties, ReactNode } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core';

// Dot Components
import CustomTooltip from '.';
import CircleQuestionIcon from '../Icons/CircleQuestionIcon';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.shipCove.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}));

interface QuestionIconTooltipProps {
  title: NonNullable<ReactNode>;
  style?: CSSProperties;
}

const QuestionIconTooltip = ({ title, ...others }: QuestionIconTooltipProps) => {
  const classes = useStyles();

  return (
    <>
      <CustomTooltip title={title} placement='bottom' {...others}>
        <span>
          <CircleQuestionIcon className={classes.icon} />
        </span>
      </CustomTooltip>
    </>
  );
};

export default QuestionIconTooltip;
