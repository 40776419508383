import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TFunction } from 'react-i18next';
import apiService from '../services/api-service';
import { isNotUndefined } from '../utils/utils';

type FetchMethods = 'GET';
type ApiMethods = 'PUT' | 'POST' | 'FILE' | 'DELETE' | 'FORM_DATA' | 'PATCH';
type Method = FetchMethods | ApiMethods;

export interface QueryOptions {
  initialValues?: {
    loading?: boolean;
  };
  fallbackErrorMessage?: (t: TFunction<'errors'>) => string;
}

export function useQuery<TRequest = any, TResponse = any>(url: string, method: Method = 'GET', options?: QueryOptions) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(
    options && options.initialValues && isNotUndefined(options.initialValues.loading)
      ? options.initialValues.loading
      : false
  );
  const [response, setResponse] = useState<TResponse>();

  const mounted = useRef(true);

  useEffect(() => {
    if (!apiService.endpoint) throw new Error('apiService endpoint is not defined');
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const addResponse = (res: TResponse) => {
    if (mounted.current) {
      setResponse(res);
      setLoading(false);
    }
  };

  const addError = (err: string) => {
    if (mounted.current) {
      setError(err);
      setLoading(false);
    }
  };

  const makeRequest = useCallback(
    async (data?: TRequest) => {
      try {
        setLoading(true);
        setError('');
        let res;
        if (method === 'FILE') {
          res = await apiService.file(url, data, options?.fallbackErrorMessage);
        }
        if (method === 'GET') {
          res = await apiService.get(url, data, options?.fallbackErrorMessage);
        }
        if (method === 'POST') {
          res = await apiService.post(url, data, undefined, options?.fallbackErrorMessage);
        }
        if (method === 'PUT') {
          res = await apiService.put(url, data, options?.fallbackErrorMessage);
        }
        if (method === 'DELETE') {
          res = await apiService.delete(url, data, options?.fallbackErrorMessage);
        }
        if (method === 'FORM_DATA') {
          const { file, fileField, formdata } = data as any;
          res = await apiService.upload(url, file, fileField, formdata, 'POST', options?.fallbackErrorMessage);
        }
        if (res) {
          addResponse(res);
          return res as TResponse;
        }
        throw new Error('Something went wrong');
      } catch (err) {
        addError(err);
        return Promise.reject(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [method, url]
  );

  return {
    response,
    loading,
    error,
    makeRequest
  };
}

/**
 * A cache object which uses key-value pair to cache API requests by memoizing promises
 * to make the app feel a lot smoother. The key for this cache would be the URL of the API request
 * and the value would be the Promise object that is result of the API call.
 * If the same API is called with the same key again, we can return the promise from the cache
 * without fetching it again from the server.
 */
const cache = new Map<string, Promise<any>>();

// Exporting a function to clear cache rather than the cache itself
// to make sure the cache isn't updated elsewhere in the app directly
export const clearCache = () => {
  Object.keys(cache).forEach((cacheKey) => {
    cache.delete(cacheKey);
  });
};

export const clearCacheByKey = (cacheKey: string) => {
  if (cache.has(cacheKey)) {
    cache.delete(cacheKey); // Delete cache by key
  }
};

export interface GetQueryOptions extends Omit<QueryOptions, 'initialValues'> {
  shouldFetch?: boolean;
  method?: 'GET' | 'FILE';
  shouldClearCache?: boolean;
}

export function useGetQuery<TRes = any>(url: string, options?: GetQueryOptions) {
  const shouldFetch = useMemo(
    () => (isNotUndefined(options) && isNotUndefined(options.shouldFetch) ? options.shouldFetch : true),
    [options]
  );
  const shouldClearCache = useMemo(
    () => (isNotUndefined(options) && isNotUndefined(options.shouldClearCache) ? options.shouldClearCache : true),
    [options]
  );
  const { makeRequest, response, error, loading } = useQuery<never, TRes>(url, options?.method || 'GET', {
    ...options,
    initialValues: { loading: shouldFetch }
  });

  const [res, setRes] = useState<TRes | undefined>();

  const clearCache = useCallback(() => clearCacheByKey(url), [url]);

  const makeRequestOverriden = useCallback(
    (data?: undefined, options?: { resetCache?: boolean }) => {
      if (cache.has(url) && !options?.resetCache) {
        cache.get(url)?.then((res) => setRes(res)); // Handle cached promise and update the response state
        return cache.get(url) as Promise<TRes>; // Return cached promise
      }

      cache.set(
        url,
        makeRequest(data).catch((error) => {
          cache.delete(url); // Self-destroy cached promise if error cought
          return Promise.reject(error);
        })
      );

      return cache.get(url) as Promise<TRes>; // Return created promise from the cache
    },
    [makeRequest, url]
  );

  // Delete cache on unmount
  useEffect(() => {
    return () => {
      if (shouldClearCache) {
        clearCache();
      }
    };
  }, [shouldClearCache, clearCache]);

  useEffect(() => {
    if (cache.get(url)) {
      cache.get(url)?.then((res) => setRes(res)); // Handle cached promise and updated the response state
    }
  }, [url]);

  useEffect(() => {
    if (response) {
      setRes(response);
    }
  }, [response]);

  useEffect(() => {
    if (!shouldFetch || cache.get(url)) return;

    cache.set(
      url,
      makeRequest().catch((error) => {
        cache.delete(url); // Self-destroy cached promise if error cought
        return Promise.reject(error);
      })
    );
  }, [shouldFetch, makeRequest, url]);

  useEffect(() => {
    if (!loading && !response && shouldClearCache) {
      cache.delete(url);
      setRes(undefined);
    }
  }, [loading, response, shouldClearCache, url]);

  return {
    response: res,
    error,
    loading: isNotUndefined(res) ? false : loading, // If res is cached then loading should be false,
    makeRequest: makeRequestOverriden,
    clearCache
  };
}

/**
 * A hook to manage mutations(Any non GET requests made to the backend)
 * The reason for this to be separate from `useQuery` is mutation URL of the request wouldn't always
 * be known before hand for ex updating a subscription id where subsription is not known till another request is made
 * @param method takes in the type of request to make i.e.'PUT' | 'POST' | 'FILE' | 'DELETE' | 'FORM_DATA' | 'PATCH';
 * look more into makeRequest function to know what those methods would perform
 */
export function useMutation<TRequest = any, TResponse = any>(method: ApiMethods = 'POST') {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<TResponse>();

  const mounted = useRef(true);

  useEffect(() => {
    if (!apiService.endpoint) throw new Error('apiService endpoint is not defined');
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const addResponse = (res: TResponse) => {
    if (mounted.current) {
      setResponse(res);
      setLoading(false);
    }
  };

  const addError = (err: string) => {
    if (mounted.current) {
      setError(err);
      setLoading(false);
    }
  };

  const makeRequest = useCallback(
    async (url: string, data?: TRequest) => {
      try {
        setLoading(true);
        setError('');
        let res;
        if (method === 'FILE') {
          res = await apiService.file(url, data);
        }
        if (method === 'POST') {
          res = await apiService.post(url, data);
        }
        if (method === 'PUT') {
          res = await apiService.put(url, data);
        }
        if (method === 'DELETE') {
          res = await apiService.delete(url, data);
        }
        if (method === 'FORM_DATA') {
          const { file, fileField, formdata } = data as any;
          res = await apiService.upload(url, file, fileField, formdata);
        }
        if (method === 'PATCH') {
          res = await apiService.patch(url, data);
        }
        if (res) {
          addResponse(res);
          return res as TResponse;
        }
        throw new Error('Something went wrong');
      } catch (error) {
        addError(error);
        return Promise.reject(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [method]
  );

  return {
    response,
    loading,
    error,
    makeRequest
  };
}

export default useQuery;
