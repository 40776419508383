/* eslint-disable max-len */
import {
  DIRECTORY_MIME_TYPE,
  IMAGE_MIME_TYPE,
  PDF_MIME_TYPE,
  TXT_MIME_TYPE,
  XML_MIME_TYPE,
  ZIP_MIME_TYPE
} from '../../utils/constants';
import {
  ImageFileIcon,
  SVGBaseProps,
  XMLFileIcon,
  GenericFileIcon,
  PDFIcon,
  TextFileIcon,
  ZIPFileIcon,
  DirectoryFileIcon
} from './Icons';

export interface FileIconProps extends SVGBaseProps {
  mimeType?: string;
}

export const getFileIconFromMimeType = (mimeType?: string) => {
  if (!mimeType) {
    return GenericFileIcon;
  }
  if (PDF_MIME_TYPE.includes(mimeType)) {
    return PDFIcon;
  }
  if (IMAGE_MIME_TYPE.includes(mimeType)) {
    return ImageFileIcon;
  }
  if (XML_MIME_TYPE.includes(mimeType)) {
    return XMLFileIcon;
  }
  if (TXT_MIME_TYPE.includes(mimeType)) {
    return TextFileIcon;
  }
  if (ZIP_MIME_TYPE.includes(mimeType)) {
    return ZIPFileIcon;
  }
  if (DIRECTORY_MIME_TYPE.includes(mimeType)) {
    return DirectoryFileIcon;
  }

  return GenericFileIcon;
};

const FileIcon = (props: FileIconProps) => {
  const { mimeType, ...others } = props;

  const Icon = getFileIconFromMimeType(mimeType) as typeof GenericFileIcon;

  return <Icon {...others} />;
};

export default FileIcon;
