import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '../../shared/Button';
import Icon from '../../shared/Icon';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    borderRadius: '300rem',
    padding: '2px 3.2rem 0 3.2rem ',
    height: '4.6rem',
    margin: '2rem 0'
  },
  icon: {
    marginLeft: '1.2rem'
  },
  buttonText: {
    textTransform: 'none',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '2rem'
  }
}));

interface SubmitButtonContainerProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  label: string;
  showEndIcon?: boolean;
  type?: 'button' | 'submit' | 'reset';
  style?: React.CSSProperties;
  isLoading?: boolean;
}

const SubmitButtonContainer = ({
  onClick,
  disabled,
  label,
  showEndIcon,
  type,
  style,
  isLoading
}: SubmitButtonContainerProps) => {
  const classes = useStyles();

  return (
    <div className='center-horizontal'>
      <Button
        isLoading={isLoading}
        type={type || 'button'}
        onClick={onClick}
        isDisabled={isLoading || disabled}
        iconRight={showEndIcon}
        iconComponent={showEndIcon && <Icon className={classes.icon} name='arrow-forward-green' />}
        label={label}
        style={style}
      />
    </div>
  );
};

export default SubmitButtonContainer;
