import React from 'react';
import Button from './Button';
import Text from './Typography';

export interface PageHeaderProps {
  illustrationClass?: string;
  title: string;
  subtitle?: string[]; // each element goes on a new line
  text?: string;
  h3?: string;
  children?: any;
  className?: string;
  ctaText?: string;
  lightText?: boolean;
  onClickCTA?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  showMessage?: () => void;
}

const PageHeader = (props: PageHeaderProps) => {
  return (
    <div
      className={`
      page-header
      ${!props.title && !props.subtitle && !props.text && !props.children && 'no-header-content'}
      ${props.className}
    `}
    >
      <div className='container'>
        {props.showMessage && props.showMessage()}
        {props.illustrationClass && <div className={`illustration ${props.illustrationClass}`} />}
        {props.title && (
          <Text h1 color='grey-40' align='center'>
            {props.title}
          </Text>
        )}
        {props.subtitle && typeof props.subtitle === 'object' && props.subtitle.length && (
          <div>
            {props.subtitle.map((subtitle, i) => (
              <Text color='ship-cove' key={`subtitle-${i}`} h4 align='center' className='is-subtitle'>
                {subtitle}
              </Text>
            ))}
          </div>
        )}
        {props.h3 && (
          <Text h3 align='center' className='is-header-text'>
            {props.h3}
          </Text>
        )}
        {props.text && (
          <Text paragraph align='center' className='is-header-text'>
            {props.text}
          </Text>
        )}
        {props.children && <div className='form'>{props.children}</div>}
        {props.ctaText && props.onClickCTA && (
          <div className='is-centered'>
            <Button label={props.ctaText} onClick={props.onClickCTA} className='is-primary' />
          </div>
        )}
      </div>
    </div>
  );
};
export default PageHeader;
