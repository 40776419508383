import { lazy, Suspense, useContext } from 'react';
import useTitle from '../../../hooks/useTitle';

// Layout
import RequestsTabListView from '../../layouts/RequestsTabListView';

// Dot Components
import TabsContent from '../../shared/TabsContent';
import Spinner from '../../shared/Spinner';

// Tabs
import { AuthContext } from '../../../contexts/auth-context';
import Container from '../../shared/Container';

// Components to be loaded only when tabs are visited
const IncomingRequestsTab = lazy(() => import('./IncomingRequestsTab'));
const MyRequestsTab = lazy(() => import('./MyRequestsTab'));
const AllRequestsTab = lazy(() => import('./AllRequestsTab'));

const SuspenseWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Suspense
      fallback={
        <Container isFlex justifyContent='center' style={{ minHeight: '50vh' }}>
          <Spinner />
        </Container>
      }
    >
      {children}
    </Suspense>
  );
};

const RequestsOverview = () => {
  useTitle((t) => t('requestsOverview'));
  const { isOwner, isManager } = useContext(AuthContext);

  return (
    <RequestsTabListView active='myRequests'>
      <TabsContent value='myRequests'>
        <SuspenseWrapper>
          <MyRequestsTab />
        </SuspenseWrapper>
      </TabsContent>
      {/* Hide All Request Table from members */}
      {(isManager || isOwner) && (
        <TabsContent value='allRequests'>
          <SuspenseWrapper>
            <AllRequestsTab />
          </SuspenseWrapper>
        </TabsContent>
      )}

      <TabsContent value='incomingRequests'>
        <SuspenseWrapper>
          <IncomingRequestsTab />
        </SuspenseWrapper>
      </TabsContent>
    </RequestsTabListView>
  );
};

export default RequestsOverview;
