import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from '../../hooks/useTranslate';
import { hexToRGB } from '../../utils/utils';
import Container from './Container';
import Text from './Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    background: hexToRGB(theme.palette.mint.main, 0.07),
    width: '100%'
  }
}));

const ProcessingUploadsMessageContainer = () => {
  const classes = useStyles();
  const { t, ready } = useTranslate('processingUploadsMessageContainer');
  if (!ready) return null;
  return (
    <Container className={classes.container} padding='3rem 0px' margin='2rem 0px 0px' justifyContent='center'>
      <Text h4 align='center' color='grey-60'>
        {t('message')}
      </Text>
    </Container>
  );
};

export default ProcessingUploadsMessageContainer;
