/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface MedicalConditionIconProps extends SVGBaseProps {}

const MedicalConditionIcon = (props: MedicalConditionIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M13.74 21.8392C12.7528 22.7263 11.2439 22.7181 10.26 21.8272C4.08 16.2531 0 12.5767 0 8.06487C0 4.38854 2.904 1.5 6.6 1.5C8.688 1.5 10.692 2.46683 12 3.99465C13.308 2.46683 15.312 1.5 17.4 1.5C21.096 1.5 24 4.38854 24 8.06487C24 12.5767 19.92 16.2531 13.74 21.8392Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default MedicalConditionIcon;
