import { makeStyles } from '@material-ui/core/styles';
import { TabsListProps, TabsList as PrimitiveTabsList } from '@radix-ui/react-tabs';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() => ({
  tabListRoot: {
    borderBottom: '1px solid #E6E4EB',
    display: 'flex',
    marginBottom: '32px'
  }
}));

const TabsList = React.forwardRef<HTMLDivElement, TabsListProps>((props, ref) => {
  const classes = useStyles();
  return <PrimitiveTabsList ref={ref} className={clsx(classes.tabListRoot, props.className)} {...props} />;
});

export default TabsList;
