import { makeStyles } from '@material-ui/core';
import { useState, useContext, useEffect, Dispatch, SetStateAction } from 'react';
import { useTranslate } from '../../../../hooks/useTranslate';
import Text from '../../../shared/Typography';
import { GetFieldPropsFunction, SetValueFunction } from '../../../../hooks/useForm';
import { PartnerClient } from '../../../../types';
import CreateClientForm from '../../../shared/CreateClientForm';
import { SnackbarContext } from '../../../../contexts/snackbar-context';
import ButtonText from '../../../shared/ButtonText';
import AlertContainer from '../../../shared/AlertContainer';
import Checkbox from '../../../shared/Checkbox';
import { isDependant } from '../../../../utils/utils';
import { GuardianFormState } from '../../../shared/CreateClientForm/ICreateClientForm';
import { createRepresentativeFormState } from '../../../shared/CreateClientForm/ClientFormUtil';
import { ClientInformationInitialState } from '..';
import ClientSelect from '../../../shared/ClientSelect';
import SubmitButtonContainer from '../SubmitButtonContainer';
import { AuthContext } from '../../../../contexts/auth-context';
import { SCOPES } from '../../../../utils/scopes';

interface ClientInformationProps {
  setRefetchConsentForm: (boolean: boolean) => void;
  getFieldPropsFunction: GetFieldPropsFunction<ClientInformationInitialState>;
  allowNext: boolean;
  handleNext: () => void;
  clientInformation: ClientInformationInitialState;
  setClientInformationValuesFunction: SetValueFunction<{
    email: string;
    isMinorClient: boolean;
    user: PartnerClient;
  }>;
  setGuardianDetails: Dispatch<
    SetStateAction<
      | {
          RepresentativeId: string;
          guardian_first_name: string;
          guardian_last_name: string;
          guardian_email: string;
          guardian_phone_number: string;
          representativeRelationship: string;
        }
      | undefined
    >
  >;
  guardianDetails?: GuardianFormState;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '70%',
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    }
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    margin: '0 1rem'
  },
  searchInput: {
    padding: '13px 31px'
  },
  alertContainer: {
    height: '24px',
    marginTop: '40px'
  },
  clientSearchButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const ClientInformation = ({
  setRefetchConsentForm,
  getFieldPropsFunction,
  handleNext,
  clientInformation,
  setClientInformationValuesFunction,
  guardianDetails,
  setGuardianDetails
}: ClientInformationProps) => {
  const classes = useStyles();
  const { addNotification } = useContext(SnackbarContext);
  const { t } = useTranslate('createNewRequest');
  const [patientDetails, setPatientDetails] = useState<PartnerClient>();
  const [showClientManualForm, setShowClientManualForm] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { isScopeEnabled } = useContext(AuthContext);
  const hasMinorFlowScope = isScopeEnabled(SCOPES.MINOR_FLOW);

  const onSubmitSuccess = (
    user?: PartnerClient,
    hasUserUpdates?: boolean,
    representative?: {
      RepresentativeId: string;
      guardian_first_name: string;
      guardian_last_name: string;
      guardian_email: string;
      guardian_phone_number: string;
      representativeRelationship: string;
    }
  ) => {
    if (!user) {
      setErrorMessage(t('physicians.clientInformationStep.errors.noClientInformation'));
      return;
    }
    setClientInformationValuesFunction('email', user.email);
    setClientInformationValuesFunction('user', user);

    if (hasUserUpdates) {
      const userFullName = `${user.first_name} ${user.last_name}`;
      if (isEditMode && user) {
        addNotification(
          t('physicians.clientInformationStep.editSuccess', {
            name: userFullName
          }),
          'success'
        );
      } else {
        addNotification(
          t('physicians.clientInformationStep.createSuccess', {
            name: userFullName
          }),
          'success'
        );
      }
      setRefetchConsentForm(true);
    }

    if (clientInformation.isMinorClient && representative) {
      setClientInformationValuesFunction('isMinorClient', true);

      setGuardianDetails({
        RepresentativeId: representative.RepresentativeId,
        guardian_first_name: representative.guardian_first_name,
        guardian_last_name: representative.guardian_last_name,
        guardian_email: representative.guardian_email,
        guardian_phone_number: representative.guardian_phone_number,
        representativeRelationship: representative.representativeRelationship
      });
    }

    handleNext();
    setIsEditMode(false);
  };

  const openManualClientForm = () => {
    setPatientDetails(undefined);
    setShowClientManualForm(true);
    setIsEditMode(false);
  };

  const selectUserClient = (user: PartnerClient) => {
    !showClientManualForm && setShowClientManualForm(true);
    setIsEditMode(true);
    setPatientDetails(user);
    setRefetchConsentForm(true);

    if (user.dob && isDependant(user.dob)) {
      setClientInformationValuesFunction('isMinorClient', true);
      if (user?.designated_users && user.designated_users.length) {
        setGuardianDetails(createRepresentativeFormState(user.designated_users[0]));
      }
    } else {
      setClientInformationValuesFunction('isMinorClient', false);
      setGuardianDetails(undefined);
    }
  };

  useEffect(() => {
    const patientFormDetails = getFieldPropsFunction('user').value;
    if (patientFormDetails?.id) {
      setIsEditMode(true);
      setShowClientManualForm(true);
      setPatientDetails(patientFormDetails);
    }
    // eslint-disable-next-line
  }, []);

  const renderFormContent = () => {
    return (
      <>
        <ClientSelect placeholder={t('physicians.clientInformationStep.seachInputLabel')} onSelect={selectUserClient} />
        <div className={classes.clientSearchButtonContainer}>
          <ButtonText
            isPrimary
            style={{ margin: '1.5rem 1rem 0 1rem' }}
            label={t('physicians.clientInformationStep.manualButtonLabel')}
            onClick={openManualClientForm}
          />
          {!patientDetails?.email && hasMinorFlowScope && (
            <Checkbox
              label={t('physicians.clientInformationStep.minorClient')}
              checked={clientInformation.isMinorClient}
              onChange={(event) => setClientInformationValuesFunction('isMinorClient', event.target.checked)}
            />
          )}
        </div>
        {showClientManualForm ? (
          <CreateClientForm
            onSubmitSuccess={onSubmitSuccess}
            guardianDetails={guardianDetails}
            patientDetails={patientDetails}
            editMode={isEditMode}
            isMinorClient={clientInformation.isMinorClient}
            submitText={
              isEditMode
                ? t('physicians.clientInformationStep.continueButtonText')
                : t('physicians.clientInformationStep.createClientButtonText')
            }
          />
        ) : (
          <>
            <AlertContainer className={classes.alertContainer}>
              <Text paragraph align='center' className='is-error'>
                {!!errorMessage && errorMessage}
              </Text>
            </AlertContainer>
            <SubmitButtonContainer
              style={{ padding: '14px 76px', marginTop: '20px' }}
              onClick={() => onSubmitSuccess()}
              label={t('physicians.clientInformationStep.continueButtonText')}
            />
          </>
        )}
      </>
    );
  };

  const getHeading = () => {
    return t('physicians.clientInformationStep.heading');
  };

  const getDescription = () => {
    return t('physicians.clientInformationStep.description');
  };

  return (
    <div className={classes.root}>
      <div className='flex center-horizontal'>
        <div className={classes.container}>
          <div>
            <Text h2 fontWeight={400} style={{ marginBottom: '20px' }}>
              {getHeading()}
            </Text>
            <Text paragraph>{getDescription()}</Text>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '35px' }}>{renderFormContent()}</div>
    </div>
  );
};

export default ClientInformation;
