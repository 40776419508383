import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import PageHeader from './PageHeader';
import Text from './Typography';
import { AuthContext } from '../../contexts/auth-context';
import MailToLink from './MailToLink';
import Spinner from './Spinner';
import { blockStates } from '../../utils/constants';
import { UserInstitution } from '../../types';
import { useTranslate } from '../../hooks/useTranslate';
import { useRoutes } from '../../hooks/useRoutes';

export interface AuthorizationControllerProps {
  blockedStates?: string[];
  children: React.ReactNode;
  notAuthorizedMessage?: React.ReactNode;
}

export const useAuthorizationController = (statesToBlock: string[]) => {
  const { isEmailVerified, hasInstitution, hasSubscription, hasPaymentPlan } = useContext(AuthContext);
  const [userInstitution] = useState<UserInstitution>();
  const [isAuthorized, setAuthorized] = useState(false);

  useEffect(() => {
    if (
      [isEmailVerified, hasInstitution, hasSubscription, statesToBlock].some(
        // prevent state updates / re-renderings until all of these are properly set
        (stateVar) => stateVar === null || stateVar === undefined
      )
    ) {
      return;
    }
    let authorized = true;
    if (!isEmailVerified && statesToBlock.includes(blockStates.EMAIL_UNVERIFIED)) {
      authorized = false;
    } else if (!hasInstitution && statesToBlock.includes(blockStates.NO_INSTITUTION)) {
      authorized = false;
    } else if (!hasPaymentPlan && !hasSubscription && statesToBlock.includes(blockStates.NO_ACTIVE_SUBSCRIPTION)) {
      authorized = false;
    }
    setAuthorized(authorized);
  }, [hasPaymentPlan, isEmailVerified, hasInstitution, hasSubscription, statesToBlock, userInstitution]);

  return { isAuthorized };
};

const AuthorizationController = ({ blockedStates, children, notAuthorizedMessage }: AuthorizationControllerProps) => {
  const history = useHistory();
  const {
    isEmailVerified,
    hasInstitution,
    hasSubscription,
    requestVerificationEmail,
    userInfo,
    isLoggedIn,
    hasPaymentPlan
  } = useContext(AuthContext);
  const [userInstitution, setUserInstitution] = useState<UserInstitution>();
  const [isAuthorized, setAuthorized] = useState(false);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState<string[]>();
  const [showCTA, setShowCTA] = useState(false);
  const [illustration, setIllustration] = useState('');
  const [statesToBlock] = useState<string[]>(
    !blockedStates || !blockedStates.length ? Object.keys(blockStates) : blockedStates
  );
  const { t, ready } = useTranslate('authorizationController');
  const { routesTFunction } = useRoutes();

  const sendNewVerificationEmail = () => {
    requestVerificationEmail().then(() => {
      setSubtitle([t('subtitles.newVerificationLinkSent')]);
      setShowCTA(false);
    });
  };

  useEffect(() => {
    if (userInfo) {
      const userInst = userInfo?.institution?.user_institution;
      if (userInst) setUserInstitution(userInst);
    }
  }, [userInfo]);

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push(routesTFunction('redirectPaths./login'));
    }
    if (
      [isEmailVerified, hasInstitution, hasSubscription, statesToBlock].some(
        (stateVar) => stateVar === null || stateVar === undefined
      ) ||
      !ready
    ) {
      return;
    }
    let authorized = true;
    setIllustration('illo-onboarding-needed');
    if (!isEmailVerified && statesToBlock.includes(blockStates.EMAIL_UNVERIFIED)) {
      setTitle(t('titles.thanksForRegistering'));
      setSubtitle([
        t('subtitles.verificationRequired.verifyToContinue'),
        t('subtitles.verificationRequired.linkSentToEmail')
      ]);
      setShowCTA(true);
      authorized = false;
    } else if (!hasInstitution && statesToBlock.includes(blockStates.NO_INSTITUTION)) {
      setTitle(t('titles.institutionRequired'));
      const subtitle = t('subtitles.institutionRequired');
      setSubtitle([subtitle]);
      authorized = false;
    } else if (!hasPaymentPlan && !hasSubscription && statesToBlock.includes(blockStates.NO_ACTIVE_SUBSCRIPTION)) {
      setTitle(t('titles.paymentRequired'));
      if (userInstitution && (userInstitution.role === 'owner' || userInstitution.isBilledSeparately)) {
        setSubtitle([t('subtitles.paymentRequired.oneMoreStep'), t('subtitles.paymentRequired.billedIndividually')]);
        setShowCTA(true);
      } else {
        setSubtitle([
          t('subtitles.paymentRequired.oneMoreStep'),
          t('subtitles.paymentRequired.noInstitutionBillingSetup')
        ]);
        setIllustration('illo-onboarding-final');
      }
      authorized = false;
    }
    setAuthorized(authorized);
  }, [
    history,
    routesTFunction,
    isLoggedIn,
    isEmailVerified,
    hasInstitution,
    hasSubscription,
    statesToBlock,
    userInstitution,
    t,
    ready,
    hasPaymentPlan
  ]);

  let ctaText;
  let ctaOnClick;
  if (!isEmailVerified) {
    ctaText = t('ctaTexts.sendNewLink');
    ctaOnClick = sendNewVerificationEmail;
  } else if (hasInstitution && !hasSubscription) {
    ctaText = t('ctaTexts.choosePlan');
    ctaOnClick = () => history.push(routesTFunction('redirectPaths./settings/billing'));
  }

  if (isEmailVerified === null || hasInstitution === null || hasSubscription === null || !ready) {
    return (
      <div style={{ height: '80vh' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {!isAuthorized && typeof notAuthorizedMessage !== 'undefined'
        ? notAuthorizedMessage
        : (title || subtitle) && (
            <PageHeader
              className='verification'
              illustrationClass={illustration}
              title={title}
              subtitle={subtitle}
              ctaText={showCTA ? ctaText : undefined}
              onClickCTA={showCTA ? ctaOnClick : undefined}
            >
              {isEmailVerified && !hasInstitution && (
                <Text paragraph fontWeight={400} align='center'>
                  <Trans t={t} i18nKey='contact' components={{ MailToLink: <MailToLink /> }} />
                </Text>
              )}
            </PageHeader>
          )}
      {isAuthorized && children}
    </>
  );
};

export function withAuthorizationController<T>(
  Component: React.FunctionComponent<T>,
  blockedStates?: string[],
  notAuthorizedMessage?: React.ReactNode
) {
  const HOC: React.FC<T> = (props) => {
    return (
      <AuthorizationController blockedStates={blockedStates} notAuthorizedMessage={notAuthorizedMessage}>
        <Component {...props} />
      </AuthorizationController>
    );
  };
  return HOC;
}

export default AuthorizationController;
