import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Fragment } from 'react';
import { PatientTimelineItemSkeleton } from '../pages/ClientDetail/PatientTimelineItem';
import Skeleton from '../shared/Skeleton';
import { generateKey } from '../../utils/utils';
import Text from '../shared/Typography';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: '1rem'
  },
  headingSectionBase: {
    marginLeft: '-1rem'
  },
  heading: {
    marginLeft: '-1.1rem',
    padding: '1rem 0px',
    fontWeight: 350,
    fontSize: '16px'
  }
}));

export const RecentActivitiesTabSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, 'full-height')}>
      <Fragment key={generateKey('recent-activities')}>
        {[...new Array(3)].map((_, idx) => {
          return (
            <>
              <div className={classes.headingSectionBase}>
                <Text paragraph color='ship-cove' className={classes.heading}>
                  <Skeleton width='12rem' />
                </Text>
              </div>
              {[...new Array(idx % 2 === 0 ? 3 : 3)].map(() => {
                return <PatientTimelineItemSkeleton />;
              })}
            </>
          );
        })}
      </Fragment>
    </div>
  );
};
