import { makeStyles } from '@material-ui/core/';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { USER_ACTIONS } from '../../../utils/rbac';
import { useCheckPermission } from '../../shared/Can';
import Skeleton from '../../shared/Skeleton';
import WalkthroughModal from './WalkthroughModal';
import { useRoutes } from '../../../hooks/useRoutes';

interface WalkthroughProps {}

export const TUTORIAL_LIST = [
  {
    displayNameTranslationKey: 'createNewRequest',
    redirectPathTranslationKey: 'redirectPaths./tutorial/create-new-request',
    permission: USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.CREATE_REQUEST_FLOW,
    description: React.lazy(() => import('./CreateNewRequestTutorial'))
  },
  {
    displayNameTranslationKey: 'requestAndClientTabs',
    redirectPathTranslationKey: 'redirectPaths./tutorial/request-and-client-tab',
    permission: USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.REQUEST_VS_CLIENT_TAB,
    description: React.lazy(() => import('./RequestAndClientTabTutorial'))
  },
  {
    displayNameTranslationKey: 'dashboardStatistics',
    redirectPathTranslationKey: 'redirectPaths./tutorial/dashboard-stats',
    permission: USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.DASHBOARD_STATS,
    description: React.lazy(() => import('./DashboardStatsTutorial'))
  },
  {
    displayNameTranslationKey: 'createNewPatient',
    redirectPathTranslationKey: 'redirectPaths./tutorial/create-new-patient',
    permission: USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.CREATE_PATIENT_FLOW,
    description: React.lazy(() => import('./CreateNewPatientTutorial'))
  },
  {
    displayNameTranslationKey: 'institutionRoles',
    redirectPathTranslationKey: 'redirectPaths./tutorial/institution-roles',
    permission: USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.INSTITUTION_ROLE,
    description: React.lazy(() => import('./InstitutionRolesTutorial'))
  },
  {
    displayNameTranslationKey: 'addClinicalNote',
    redirectPathTranslationKey: 'redirectPaths./tutorial/add-clinical-note',
    permission: USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.CREATE_CLINICAL_NOTE,
    description: React.lazy(() => import('./CreatingClinicalNoteTutorial'))
  },
  {
    displayNameTranslationKey: 'understandingRequestStatuses',
    redirectPathTranslationKey: 'redirectPaths./tutorial/understanding-request-statuses',
    permission: USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.ALL_REQUEST_STATUSES,
    description: React.lazy(() => import('./RequestStatusTutorial'))
  },
  {
    displayNameTranslationKey: 'readVsUnReadRows',
    redirectPathTranslationKey: 'redirectPaths./tutorial/read-and-unread-row',
    permission: USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.READ_VS_UNREAD,
    description: React.lazy(() => import('./ReadVsUnReadTutorial'))
  }
];

const useStyles = makeStyles((theme) => ({
  modal: {
    width: 'auto',
    padding: '1rem 2.5rem',
    '& p': {
      margin: '0px !important'
    }
  },
  imageContainer: {
    marginTop: '1rem',
    position: 'relative',
    backgroundColor: theme.palette.catSkillWhite.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '5px',
    padding: '1rem 0px 0px'
  },
  image: {
    margin: '1rem 0px',
    width: '90%',
    height: 'auto',
    borderRadius: '5px'
  },
  li: {
    display: 'flex',
    alignItems: 'center'
  },
  tag: {
    marginRight: '5px'
  },
  tagColumn: {
    minWidth: '20rem'
  },
  table: {
    overflow: 'auto',
    maxHeight: '58vh'
  },
  margins: {
    margin: '1rem 0px'
  }
}));

const FallbackScreen = () => {
  const classes = useStyles();
  return (
    <>
      <article>
        <Skeleton className={classes.margins} height='12vh' width='100%' />
        <Skeleton className={classes.margins} height='12vh' width='100%' />
        <Skeleton className={classes.margins} height='12vh' width='100%' />
      </article>
    </>
  );
};

const getUserTutorials = () => {
  return TUTORIAL_LIST.map((list) => {
    const Component = () => {
      const canViewList = useCheckPermission(list.permission);

      if (!canViewList) return null;

      const Description = list.description;

      return (
        <React.Suspense fallback={<FallbackScreen />}>
          <Description />
        </React.Suspense>
      );
    };

    if (!Component()) return null;

    return {
      ...list,
      description: (
        <>
          <Component />
        </>
      )
    };
  }).filter((item) => !!item) as {
    description: JSX.Element;
    displayNameTranslationKey: string;
    redirectPathTranslationKey: string;
    permission: string;
  }[]; // casting type manually so null array values are not inferred
};

const Walkthrough: React.FC<WalkthroughProps> = () => {
  const location = useLocation();
  const history = useHistory();
  const list = getUserTutorials();
  const { routesTFunction } = useRoutes();

  const handleCloseModal = () => {
    if (location.state?.background) {
      history.push(location.state.background);
    } else {
      history.push('/');
    }
  };

  const index = useMemo(() => {
    const idx = list.findIndex(
      (listItem) => routesTFunction(listItem.redirectPathTranslationKey as any) === location.pathname
    );
    return idx;
  }, [location.pathname, list, routesTFunction]);

  if (index === -1) {
    return null;
  }

  return <WalkthroughModal index={index} open onClose={handleCloseModal} steps={list} />;
};

export default Walkthrough;
