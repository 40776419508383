import { ReactNode } from 'react';
import clsx from 'clsx';

import { makeStyles, Checkbox as MuiCheckbox, CheckboxProps, FormControlLabel } from '@material-ui/core';
import useId from '../../hooks/useId';

const useStyles = makeStyles((theme) => ({
  label: {
    '&.MuiFormControlLabel-label': {
      fontSize: theme.typography.body1.fontSize
    }
  },
  checkBoxRoot: {
    color: theme.palette.shipCove.main,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      fontSize: '2.5rem'
    },
    '&:hover': {
      borderRadius: '1.5rem'
    },
    '&.Mui-checked': {
      color: theme.palette.primary.main
    }
  }
}));

export interface ExtendedCheckboxProps extends CheckboxProps {
  label: string | ReactNode;
  checked: boolean;
  containerClassName?: string;
  labelClassName?: string;
}

const Checkbox = ({
  label,
  className,
  containerClassName,
  checked,
  onChange,
  labelClassName,
  ...other
}: ExtendedCheckboxProps) => {
  const classes = useStyles();

  const id = useId({ defaultValue: other.id, prefix: 'checkbox' });

  return (
    <FormControlLabel
      label={label}
      className={containerClassName}
      classes={{
        label: clsx(classes.label, labelClassName)
      }}
      control={
        <MuiCheckbox
          checked={checked}
          id={id}
          className={className}
          onChange={onChange}
          classes={{
            root: classes.checkBoxRoot
          }}
          {...other}
        />
      }
    />
  );
};

export default Checkbox;
