import { makeStyles } from '@material-ui/core/styles';
import { ServerFile } from '../../types';
import { generateKey } from '../../utils/utils';
import Card from './Card';
import FileIcon from './FileIcon';
import { DownloadIcon, EyeIcon } from './Icons';
import Spinner from './Spinner';
import Text from './Typography';

interface FilePreviewProps {
  file: ServerFile;
  downloadOptions: {
    downloadFile: (file: ServerFile) => void;
    isDownloading: boolean;
  };
  viewOptions: {
    viewFile: (file: ServerFile) => void;
    isFetchingFile: boolean;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem !important',
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[90]}`,
    borderRadius: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.25rem 0px'
  },
  text: {
    marginLeft: '1rem'
  },
  buttonGroup: {
    display: 'flex',
    marginLeft: '2rem',
    alignItems: 'center'
  },
  button: {
    background: 'none',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: theme.palette.shipCove.main,
      fontSize: '2rem',
      marginLeft: '0.75rem'
    },
    '&:hover,&:focus': {
      '& svg': {
        color: theme.palette.primary.hover
      }
    }
  }
}));

const FilePreview = ({ file, downloadOptions, viewOptions }: FilePreviewProps) => {
  const classes = useStyles();

  const { downloadFile, isDownloading } = downloadOptions;
  const { viewFile, isFetchingFile } = viewOptions;

  return (
    <Card key={generateKey('file', file.id, file.file_name)} noShadow noPadding className={classes.root}>
      <div className={classes.item}>
        <FileIcon mimeType={file.mime_type} />
        <Text paragraph className={classes.text}>
          {file.file_name}
        </Text>
      </div>
      <div className={classes.buttonGroup}>
        <button className={classes.button} type='button' onClick={() => viewFile(file)}>
          {isFetchingFile ? <Spinner size='small' /> : <EyeIcon />}
        </button>
        <button className={classes.button} type='button' onClick={() => downloadFile(file)}>
          {isDownloading ? <Spinner size='small' /> : <DownloadIcon />}
        </button>
      </div>
    </Card>
  );
};
export default FilePreview;
