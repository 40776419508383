import { generateKey } from '../../utils/utils';
import Grid from '../shared/Grid';
import { RecordsTabWrapper } from '../shared/RecordsTabWrapper';
import { ResourceCardSkeleton } from '../shared/ResourceCard';
import Skeleton from '../shared/Skeleton';

export const RecordsTabSkeleton = () => {
  return (
    <RecordsTabWrapper.Container>
      <RecordsTabWrapper.HeadingContainer noDivider>
        <RecordsTabWrapper.Title>
          <Skeleton height='100%' width='12ch' />
        </RecordsTabWrapper.Title>
        <RecordsTabWrapper.SearchInputContainer>
          <Skeleton height='3.2rem' width='20rem' borderRadius='2rem' />
        </RecordsTabWrapper.SearchInputContainer>
      </RecordsTabWrapper.HeadingContainer>
      <RecordsTabWrapper.Content>
        <Grid container isMultiline style={{ marginTop: '2rem' }}>
          {[...new Array(10)].map((_, idx) => {
            return (
              <Grid key={generateKey('resource-card-skeleton', idx)} item size='is-half'>
                <ResourceCardSkeleton />
              </Grid>
            );
          })}
        </Grid>
      </RecordsTabWrapper.Content>
    </RecordsTabWrapper.Container>
  );
};
