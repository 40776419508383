/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface AllergyIconProps extends SVGBaseProps {}

const AllergyIcon = (props: AllergyIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.97762 2.71885C7.47168 6.67307 4 12.6839 4 15.6359C4 20.255 7.80558 23.9995 12.5 23.9995C17.1944 23.9995 21 20.255 21 15.6359C21 12.6839 17.5283 6.67307 15.0224 2.71885C14.7316 2.25994 14.4537 1.82874 14.1959 1.43322C13.3932 0.202185 11.6068 0.202185 10.8041 1.43322C10.5463 1.82874 10.2684 2.25994 9.97762 2.71885Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default AllergyIcon;
