import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: (props: RootClassProps) => ({
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    height: props.height || 'auto',
    justifyContent: props.justifyContent,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      height: props.tablet?.height,
      justifyContent: props.tablet?.justifyContent
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      height: props.mobile?.height,
      justifyContent: props.mobile?.justifyContent
    }
  }),
  children: (props: RootClassProps) => ({
    display: props.isFlex ? 'flex' : 'block',
    height: props.height || 'auto',
    width: props.width || 'auto',
    padding: props.padding,
    margin: props.margin,
    flexWrap: props.isFlex && !!props.wrap ? 'wrap' : 'initial',
    ...(props.style as any),
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      height: props.tablet?.height,
      width: props.tablet?.width,
      justifyContent: props.justifyContent,
      margin: props.tablet?.margin,
      padding: props.tablet?.height,
      flexWrap: props.tablet?.flexWrap,
      ...props.tablet
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      height: props.mobile?.height,
      width: props.mobile?.width,
      justifyContent: props.justifyContent,
      margin: props.mobile?.margin,
      padding: props.mobile?.height,
      flexWrap: props.mobile?.flexWrap,
      ...props.mobile
    }
  })
}));

type JustifyContentTypes =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'start'
  | 'end'
  | 'left'
  | 'right'
  | string;

interface CommonStyles {
  height?: number | string;
  width?: number | string;
  padding?: number | string;
  margin?: number | string;
  justifyContent?: JustifyContentTypes;
}

interface RootClassProps extends CommonStyles {
  mobile?: React.CSSProperties;
  tablet?: React.CSSProperties;
  style?: React.CSSProperties;
  isFlex?: boolean;
  wrap?: boolean;
}

export interface ContainerProps {
  width?: number | string;
  height?: number | string;
  justifyContent?: string;
  margin?: number | string;
  padding?: number | string;
  className?: string;
  style?: React.CSSProperties;
  wrap?: boolean;
  tablet?: React.CSSProperties;
  mobile?: React.CSSProperties;
  isFlex?: boolean;
  inLineStyle?: React.CSSProperties;
  children: React.ReactNode;
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      children,
      height,
      width,
      padding,
      margin,
      justifyContent,
      className,
      style,
      mobile,
      tablet,
      isFlex,
      wrap,
      inLineStyle
    },
    ref
  ) => {
    const classes = useStyles({
      height,
      width,
      padding,
      margin,
      justifyContent,
      mobile,
      tablet,
      isFlex,
      wrap,
      style
    });

    return (
      <div className={classes.root} ref={ref}>
        <div className={clsx(classes.children, className)} style={inLineStyle}>
          {children}
        </div>
      </div>
    );
  }
);

export default Container;
