import { ReactNode } from 'react';
import clsx from 'clsx';

// Material UI
import { makeStyles } from '@material-ui/core';

// Dot Component
import Divider from './Divider';

const useStyles = makeStyles(() => ({
  popper: {
    position: 'absolute',
    zIndex: 1,
    right: 0,
    borderRadius: '20px',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
    backgroundColor: '#fff',
    minWidth: '250px',

    '&__header': {
      padding: '16px 20px 11.5px 20px'
    },

    '&__content': {
      padding: '18px 20px 20px 20px'
    }
  }
}));

export interface PopperProps extends React.HTMLAttributes<HTMLDivElement> {
  open: boolean;
  children: ReactNode;
  header?: ReactNode;
  showHeaderDivider?: boolean;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
}

/**
 * @param open if `true`, the popper is visible.
 * @param children a popper render node inside the popper body (popper_content class).
 * @param header an optional popper render node inside the popper body (popper_header class).
 * @param showHeaderDivider optional and if `true` and passed with the @param header,
 * the header will have a separator from the @param children node.
 * @param className an optional class modifier to apply.
 * @param headerClassName an optional style modifier.
 * @param contentClassName an optional style modifier.
 * @returns a popper component with content.
 */
const Popper = (props: PopperProps) => {
  const { open, children, header, showHeaderDivider, className, headerClassName, contentClassName, ...others } = props;
  const classes = useStyles();

  return (
    <div style={{ position: 'relative' }}>
      {open && (
        <div className={clsx(classes.popper, className)} {...others}>
          <>
            {header && (
              <>
                <div className={clsx(headerClassName || `${classes.popper}__header`)}>{header}</div>
                {showHeaderDivider && <Divider />}
              </>
            )}
            <div className={clsx(contentClassName || `${classes.popper}__content`)}>{children}</div>
          </>
        </div>
      )}
    </div>
  );
};

export default Popper;
