import { makeStyles } from '@material-ui/core/styles';
import { TabsTrigger, TabsTriggerProps } from '@radix-ui/react-tabs';
import clsx from 'clsx';
import React from 'react';

export interface TabProps extends TabsTriggerProps {
  label: string | React.ReactElement;
  className?: string;
  activeClass?: string;
}

const useStyles = makeStyles((theme) => ({
  tab: {
    '&[data-state="active"]': {
      borderBottom: `3px solid ${theme.palette.primary.hover}`,
      color: theme.palette.text.main,
      '&:hover,&:focus': {
        color: theme.palette.text.main
      }
    }
  }
}));

const Tab = React.forwardRef<HTMLButtonElement, TabProps>(({ label, onClick, className, ...others }, ref) => {
  const classes = useStyles();
  return (
    <TabsTrigger ref={ref} className={clsx('auth-tabs__tab', classes.tab, className)} onClick={onClick} {...others}>
      {label}
    </TabsTrigger>
  );
});

export default Tab;
