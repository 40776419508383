/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface ImmunizationIconProps extends SVGBaseProps {}

const ImmunizationIcon = (props: ImmunizationIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M4.102 18.0417L0.386573 21.7447C0.139097 21.9913 0 22.3276 0 22.677C0 23.8488 1.41761 24.4427 2.24543 23.6132L5.95579 19.8952L7.81385 21.753C8.3271 22.2662 9.15919 22.2664 9.67271 21.7535L20.8284 10.6106C21.3423 10.0973 21.3425 9.26462 20.8289 8.75108L18.9586 6.88107L20.8255 5.04069L21.7689 5.97068C22.2806 6.47503 23.1029 6.47358 23.6128 5.96742C24.1278 5.45614 24.1293 4.62376 23.6161 4.11065L19.8888 0.383868C19.3756 -0.129244 18.5431 -0.127763 18.0317 0.387172C17.5255 0.89695 17.5241 1.71919 18.0285 2.23075L18.9586 3.17402L17.118 5.04069L15.2477 3.17069C14.7341 2.65714 13.9013 2.65735 13.3879 3.17116L2.24347 14.3253C1.73047 14.8387 1.73068 15.6707 2.24394 16.1839L4.102 18.0417Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default ImmunizationIcon;
