import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import apiService from '../../../services/api-service';
import { useTranslate } from '../../../hooks/useTranslate';
import Skeleton from '../../shared/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1.2px solid',
    background: 'none',
    borderRadius: '16px',
    minHeight: '25rem',
    width: '29.5rem',
    padding: '2.5rem 2.8rem 2.5rem 3.1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    color: theme.palette.text.main,
    lineHeight: '2.5rem',
    fontSize: '1.5rem',
    fontWeight: 500,
    margin: '1rem 0',
    borderColor: theme.palette.text.secondary
  },
  formTitle: {
    fontSize: '1.8rem'
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontWeight: 300
  },
  active: {
    borderWidth: '3px',
    borderColor: theme.palette.success.main,
    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.1)'
  },
  link: {
    color: theme.palette.link.main,
    textDecorationLine: 'underline',
    cursor: 'pointer',
    padding: '1px 0px',
    background: 'none',
    '&:hover': {
      color: theme.palette.link.main
    }
  },
  skeletonRoot: {
    border: '1.2px solid',
    background: 'none',
    borderRadius: '16px',
    minHeight: '25rem',
    width: '29.5rem',
    padding: '2.5rem 2.8rem 2.5rem 3.1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    borderColor: theme.palette.text.secondary,
    gap: '1rem'
  },
  skeletonValues: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  }
}));

export interface FormCardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  displayName: string;
  updatedAt: Date | string | number;
  pageCount: number;
  active: boolean;
  formId: any;
}

export const FormCardSkeleton = () => {
  const styles = useStyles();
  return (
    <div className={styles.skeletonRoot}>
      <div className={styles.formTitle}>
        <Skeleton width='20ch' />
      </div>
      <div className={styles.skeletonValues}>
        <div className={styles.subtitle}>
          <Skeleton width='12ch' />
        </div>
        <div>
          <Skeleton width='12ch' />
        </div>
      </div>
      <div className={styles.skeletonValues}>
        <div className={styles.subtitle}>
          <Skeleton width='15ch' />
        </div>
        <div>
          <Skeleton width='4ch' />
        </div>
      </div>
      <Skeleton width='15ch' />
    </div>
  );
};

const FormCard = React.forwardRef<HTMLDivElement, FormCardProps>(
  ({ displayName, pageCount, active, formId, ...others }, ref) => {
    const styles = useStyles();
    const { t } = useTranslate('createNewRequest');

    const viewForm = () => {
      apiService.file(`/institution_forms/${formId}/view`).then((blob) => {
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    };

    return (
      <div {...others} ref={ref} className={clsx(styles.root, active && styles.active, others.className)}>
        <div className={styles.formTitle}>{displayName}</div>
        <div>
          <div className={styles.subtitle}>{t('institutionFormCard.numbPages')}</div>
          <div>{pageCount}</div>
        </div>
        <button
          type='button'
          className={styles.link}
          onClick={(e) => {
            // Stopping event propogation so the event doesn't interfere with the root div's onClick handler
            e.stopPropagation();
            viewForm();
          }}
          aria-label={t('institutionFormCard.viewFormLinkAriaLabel', { formName: displayName })}
        >
          {t('institutionFormCard.viewFormLinkText')}
        </button>
      </div>
    );
  }
);

export default FormCard;
