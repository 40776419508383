import { useCallback, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'react-i18next';
import { useTranslate } from './useTranslate';

const DEFAULT_DOCUMENT_TITLE = 'Dot Connect';

type StringOrTfunction = string | ((t: TFunction<'titles'>) => string);

const getTitleFromStringOrTFunction = (arg: StringOrTfunction, t: TFunction<'titles'>) => {
  if (typeof arg === 'function') {
    return arg(t);
  }
  return arg;
};

const useTitle = (initialValue?: StringOrTfunction) => {
  const { t, ready } = useTranslate('titles');
  const [title, setTitle] = useState(DEFAULT_DOCUMENT_TITLE);
  const previousTitle = useMemo(() => document.title, []);

  useEffect(() => {
    if (!initialValue || !ready) return;
    setTitle(getTitleFromStringOrTFunction(initialValue, t));
  }, [t, ready, initialValue]);

  useEffect(() => {
    return () => {
      document.title = previousTitle;
    };
  }, [previousTitle]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const setValue = useCallback(
    (value: StringOrTfunction) => {
      setTitle(getTitleFromStringOrTFunction(value, t));
    },
    [t]
  );

  return { setTitle: setValue };
};

export default useTitle;
