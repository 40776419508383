import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import IconButton, { IconButtonProps } from '../IconButton';
import { ArrowLeftIcon } from '../Icons';

interface RecordsTabWrapperBackButtonProps extends Omit<IconButtonProps, 'label' | 'children'> {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const useStyles = makeStyles(() => ({
  button: {
    marginRight: '3px'
  }
}));

const RecordsTabWrapperBackButton = (props: RecordsTabWrapperBackButtonProps) => {
  const { onClick } = props;
  const classes = useStyles();

  return (
    <IconButton {...props} className={clsx(props.className, classes.button)} onClick={onClick} label='Go back'>
      <ArrowLeftIcon color={(theme) => theme.palette.primary.main} />
    </IconButton>
  );
};

export default RecordsTabWrapperBackButton;
