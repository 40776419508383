/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface DownloadIconProps extends SVGBaseProps {}

const DownloadIcon = (props: DownloadIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.35 9.60842C18.67 5.83374 15.64 3 12 3C9.11 3 6.6 4.79434 5.35 7.4202C2.34 7.77031 0 10.5603 0 13.9411C0 17.5626 2.69 20.5057 6 20.5057H19C21.76 20.5057 24 18.0549 24 15.0352C24 12.1467 21.95 9.80536 19.35 9.60842ZM17 12.847L12.35 17.9346C12.15 18.1534 11.84 18.1534 11.64 17.9346L7 12.847H10V8.47054H14V12.847H17Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default DownloadIcon;
