import clsx from 'clsx';
import React from 'react';
import AppNav from '../AppNav';

interface AppViewProps {
  children?: React.ReactNode;
  fullWidth?: boolean;
  navClassName?: string;
  pageClassName?: string;
  className?: string;
}

const AppView = (props: AppViewProps) => {
  return (
    <div className={clsx('app-container', props.className)}>
      <div>
        <AppNav className={props.navClassName} />
        <div
          className={clsx('page-container', props.fullWidth ? 'fullwidth-container' : 'container', props.pageClassName)}
        >
          <div>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default AppView;
