/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface XMLFileIconProps extends Omit<SVGBaseProps, 'color'> {}

const XMLFileIcon = (props: XMLFileIconProps) => {
  return (
    <SVGBase viewBox='0 0 24 24' {...props}>
      <path
        d='M0.772461 3.27767C0.772461 1.86361 1.91878 0.717285 3.33285 0.717285H18.2121C19.6261 0.717285 20.7725 1.86361 20.7725 3.27767V18.1569C20.7725 19.571 19.6261 20.7173 18.2121 20.7173H3.33285C1.91879 20.7173 0.772461 19.571 0.772461 18.1569V3.27767Z'
        fill='#F043AB'
      />
      <path
        d='M14.9754 13.4226V7.92743C14.9754 7.41383 15.3917 6.99748 15.9053 6.99748C16.4189 6.99748 16.8353 7.41383 16.8353 7.92743V12.6009C16.8353 12.6678 16.8896 12.7221 16.9565 12.7221H17.9826C18.4428 12.7221 18.8159 13.0952 18.8159 13.5554C18.8159 14.0157 18.4428 14.3888 17.9826 14.3888H15.9415C15.4079 14.3888 14.9754 13.9562 14.9754 13.4226Z'
        fill='white'
      />
      <path
        d='M3.6049 7.04579H3.28279C2.83662 7.04579 2.5127 7.47021 2.63043 7.90057L3.33734 10.4848C3.37029 10.6052 3.36925 10.7324 3.33433 10.8523L2.57043 13.475C2.44437 13.9078 2.769 14.3405 3.21978 14.3405H3.70342C4.00953 14.3405 4.27747 14.1349 4.3567 13.8392L5.0172 11.3745C5.04269 11.2794 5.17748 11.2789 5.20366 11.3738L5.8851 13.844C5.96601 14.1373 6.23282 14.3405 6.53707 14.3405H6.91595C7.38316 14.3405 7.70965 13.878 7.55327 13.4378L6.64334 10.876C6.59554 10.7415 6.59156 10.5952 6.63199 10.4582L7.38304 7.91357C7.51093 7.48026 7.18617 7.04579 6.73438 7.04579H6.52529C6.22661 7.04579 5.96329 7.24171 5.87748 7.52781L5.19674 9.79733C5.16965 9.88766 5.04245 9.88964 5.01256 9.8002L4.24635 7.50773C4.15414 7.23182 3.89581 7.04579 3.6049 7.04579Z'
        fill='white'
      />
      <path
        d='M8.8884 7.86704V13.5675C8.8884 14.0211 9.25609 14.3888 9.70966 14.3888H9.80628C10.2598 14.3888 10.6275 14.0211 10.6275 13.5675V10.7585C10.6275 10.7105 10.69 10.6919 10.7163 10.7321L11.4572 11.8641C11.4762 11.893 11.5184 11.8933 11.5377 11.8646L12.3024 10.7279C12.329 10.6883 12.3908 10.7072 12.3908 10.7549V13.6158C12.3908 14.0427 12.7369 14.3888 13.1638 14.3888C13.5907 14.3888 13.9367 14.0427 13.9367 13.6158V7.86704C13.9367 7.41348 13.569 7.04579 13.1155 7.04579H13.0014C12.6389 7.04579 12.3193 7.28339 12.2149 7.63047L11.5404 9.87269C11.5269 9.91767 11.4636 9.91884 11.4485 9.87439L10.6724 7.60166C10.5589 7.26922 10.2465 7.04579 9.89522 7.04579H9.70966C9.25609 7.04579 8.8884 7.41348 8.8884 7.86704Z'
        fill='white'
      />
    </SVGBase>
  );
};

export default XMLFileIcon;
