import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment';

// Localization
import { TFunction, Trans } from 'react-i18next';

// Routing
import { useHistory } from 'react-router-dom';

// Helper Hooks
import useOutsideClick from '../../hooks/useOutsideClick';
import { useTranslate } from '../../hooks/useTranslate';
import { useRoutes } from '../../hooks/useRoutes';

// Dot API
import apiService from '../../services/api-service';

// Dot Socket
import { SocketContext } from '../../contexts/socket-context';
import { SOCKET_EVENT } from '../../utils/constants';

// Dot Types
import { Notification } from '../../types/common/INotification';

// Dot Components
import ButtonText from './ButtonText';
import Divider from './Divider';
import Icon from './Icon';
import IconButton from './IconButton';
import Popper from './Popper';
import Text from './Typography';

const useStyles = makeStyles((theme) => ({
  openButton: {
    padding: '6.5px',
    borderRadius: '50%',
    backgroundColor: `${theme.palette.lavendar.main}1A` /* 10% transparency */,
    '&:hover, &:focus': {
      boxShadow: `0 0 0 2.5px ${theme.palette.lavendar.main}33` /* 20% transparency */
    }
  },
  notificationPopper: {
    width: '378px',
    marginTop: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',

    '&__header': {
      padding: '18.5px 22px 10px 32px'
    },

    '&__content': {
      paddingBottom: '18.5px'
    }
  },
  unreadNotificationCounter: {
    position: 'absolute',
    right: '11px',
    top: '12px',
    backgroundColor: theme.palette.mandy.main,
    color: 'white',
    height: '16px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    padding: '0 4px',
    textAlign: 'center',
    borderRadius: '9px',
    cursor: 'pointer'
  },
  notification: {
    cursor: 'pointer',
    position: 'relative',
    padding: '8px 22px 8px 32px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[100]
    },

    '&>p:first-of-type': {
      color: theme.palette.grey[80],
      width: '71%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box !important',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      whiteSpace: 'normal'
    },

    '&--unread': {
      '&>p:first-of-type': {
        color: theme.palette.grey[40]
      },

      '&::before': {
        content: '""',
        display: 'block',
        height: '7px',
        width: '7px',
        background: theme.palette.mandy.main,
        borderRadius: '50%',
        position: 'absolute',
        top: '19px', // line height (24px) / 2 + size of bullet (7px)
        left: '16px',
        transform: 'translateY(-50%)' // vertical alignment
      }
    }
  },
  messageDivider: {
    margin: '0 15px',
    color: theme.palette.grey[90]
  }
}));

interface NotificationMessageProps {
  notification: Notification;
  t: TFunction<'navbar'>;
}

const NotificationTranslation = ({ notification, t }: NotificationMessageProps) => {
  const { type, information_request: informationRequest } = notification;
  const { institution_name: institutionName, user, internal_id: internalId } = informationRequest || {};
  const { first_name: firstName, last_name: lastName, email } = user || {};

  const i18nKeys = {
    REQUEST_COMPLETE: 'notificationPopper.requestCompletedMessage',
    REQUEST_ACTION_REQUIRED: 'notificationPopper.additionalInfoNeededMessage',
    REQUEST_UNPROCESSABLE: 'notificationPopper.unprocessableMessage',
    REQUEST_ARCHIVED_REMINDER: 'notificationPopper.requestArchivedReminderMessage',
    REQUEST_UNSUCCESSFUL_AUTHORIZATION: 'notificationPopper.requestUnsuccessfulAuthorization',
    REQUEST_NEW_RESOURCE_ADMIN: 'notificationPopper.requestNewResourceAdmin',
    REQUEST_NEW_RESOURCE_PATIENT: 'notificationPopper.requestNewResourcePatient',
    NEW_INCOMING_REQUEST: 'notificationPopper.newIncomingRequest'
  };

  if (!i18nKeys[type]) return null;

  return (
    <Trans
      t={t}
      i18nKey={i18nKeys[type] as any}
      components={{
        linkText: <Text span color='purple' fontWeight={500} />
      }}
      values={{
        clientName: firstName ? `${firstName} ${lastName}` : email,
        institutionName: institutionName,
        requestInternalId: internalId
      }}
    />
  );
};

const NotificationPopper = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  const { routesTFunction } = useRoutes();
  const { t } = useTranslate('navbar');
  const history = useHistory();
  const { socket } = useContext(SocketContext);

  const { ref: divRef } = useOutsideClick<HTMLDivElement>(() => {
    setOpen(false);
  });

  useEffect(() => {
    async function fetchNotifications() {
      try {
        const { notifications: fetchedNotifications } = await apiService.get('/notifications?limit=4');
        const unreadNotificationsCount = (await apiService.get('/notifications?is_read=0&count_only=true')).meta.count;
        setNotifications(fetchedNotifications);
        setUnreadNotificationsCount(unreadNotificationsCount);
      } catch (err) {
        console.log(err);
      }
    }
    fetchNotifications();
  }, []);

  useEffect(() => {
    return () => {
      if (!socket) return;
      socket.off(SOCKET_EVENT.NEW_NOTIFICATION);
      socket.off(SOCKET_EVENT.READ_NOTIFICATIONS);
      socket.off(SOCKET_EVENT.DELETED_NOTIFICATION);
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) return;
    // Add new notification
    socket.on(SOCKET_EVENT.NEW_NOTIFICATION, async (data: string) => {
      const { notification } = JSON.parse(data);
      if (notification) {
        setNotifications((prevState) => {
          if (prevState.length === 4) prevState.pop();
          return [notification, ...prevState];
        });
        setUnreadNotificationsCount((prevState) => prevState + 1);
      }
    });
    // Update notifications (mark as read)
    socket.on(SOCKET_EVENT.READ_NOTIFICATIONS, async (data: string) => {
      const { notificationIds } = JSON.parse(data);
      if (!notificationIds || !notificationIds.length) return;
      setNotifications((prevState) => {
        const updatedNotifications = prevState.map((notification) => {
          if (notificationIds.some((notificationId: number) => notificationId === notification.id)) {
            return { ...notification, isRead: true };
          }
          return notification;
        });
        return updatedNotifications;
      });
      setUnreadNotificationsCount((prev) => prev - notificationIds.length);
    });
    // Delete notification
    socket.on(SOCKET_EVENT.DELETED_NOTIFICATION, async (data: string) => {
      const { notification: deletedNotification } = JSON.parse(data);
      if (!deletedNotification) return;
      setNotifications((prevState) => prevState.filter((notification) => notification.id !== deletedNotification.id));
      // Decrement unread notifications counter if deleted notification was unread
      if (!deletedNotification.isRead) {
        setUnreadNotificationsCount((prev) => prev - 1);
      }
    });
  }, [socket]);

  useEffect(() => {
    // Whenever the location changes we should close the popper
    setOpen(false);
  }, [history.location]);

  const readAllNotifications = async () => {
    try {
      if (unreadNotificationsCount > 0) {
        const res = await apiService.put('/notifications', {});
        if (res) {
          setUnreadNotificationsCount(0);
          if (notifications && notifications.length > 0) {
            setNotifications([...notifications].map((notification) => ({ ...notification, isRead: true })));
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div ref={divRef}>
      <IconButton onClick={() => setOpen((open) => !open)} label='' className={classes.openButton}>
        <Icon name='bell-purple' style={{ width: '22px', height: '22px' }} />
        {unreadNotificationsCount > 0 && (
          <div className={classes.unreadNotificationCounter}>
            <Text span>{unreadNotificationsCount}</Text>
          </div>
        )}
      </IconButton>
      <Popper
        open={open}
        className={classes.notificationPopper}
        headerClassName={`${classes.notificationPopper}__header`}
        contentClassName={`${classes.notificationPopper}__content`}
        header={
          <div className='flex space-between'>
            <div className='flex gap-10'>
              <Text paragraph color='grey-30' fontWeight={500}>
                {t('notificationPopper.header')}
              </Text>
            </div>

            <ButtonText
              label={t('notificationPopper.markAllAsReadButtonText')}
              isPrimary
              onClick={() => readAllNotifications()}
            />
          </div>
        }
      >
        <>
          {notifications.length > 0 &&
            notifications.map((notification) => {
              const { id, createdAt, isRead, information_request: informationRequest } = notification;
              const { internal_id: internalId } = informationRequest || {};
              return (
                <div
                  key={`notification-widget-item-${id}`}
                  onClick={() =>
                    internalId && history.push(routesTFunction('redirectPaths./requests/:internalId', { internalId }))
                  }
                >
                  <div className={clsx(`${classes.notification}`, !isRead && `${classes.notification}--unread`)}>
                    <Text paragraph fontWeight={500}>
                      <NotificationTranslation notification={notification} t={t} />
                    </Text>
                    <Text paragraph color='grey-80' align='right' style={{ fontSize: '12px', width: '25%' }}>
                      {moment(createdAt).fromNow()}
                    </Text>
                  </div>
                  <Divider className={classes.messageDivider} />
                </div>
              );
            })}
          <div className='center' style={{ marginTop: '15px' }}>
            <ButtonText
              isPrimary
              label={t('notificationPopper.viewAllNotificationsButtonText')}
              onClick={() => history.push(routesTFunction('redirectPaths./notifications'))}
            />
          </div>
        </>
      </Popper>
    </div>
  );
};

export default NotificationPopper;
