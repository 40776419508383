import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRoutes } from '../../../../hooks/useRoutes';
import { useTranslate } from '../../../../hooks/useTranslate';
import {
  useDownloadBusinessUpload,
  useDownloadFile,
  useViewBusinessUpload,
  useViewFile
} from '../../../../request-hooks/shared/useSharedRequest';
import {
  BusinessUpload,
  GenericHealthInformationResource,
  InstitutionAddress,
  PartnerClient,
  ServerFile
} from '../../../../types';
import {
  aggregateAndSortFiles,
  generateKey,
  getStyleInformationFromResourceType,
  hexToRGB,
  isNotEmptyArray,
  useFormatDate
} from '../../../../utils/utils';
import FilePreview from '../../../shared/FilePreview';
import { GenericFileIcon } from '../../../shared/Icons';
import Skeleton from '../../../shared/Skeleton';
import Tag, { TagProps } from '../../../shared/Tag';
import TimelineItemBase from './TimelineItemBase';
import Text from '../../../shared/Typography';

type BusinessUploadActivity = {
  business_upload: BusinessUpload;
};

type ResourceActivity = {
  resource: GenericHealthInformationResource;
};

type TimelineActivity = ResourceActivity | BusinessUploadActivity;

const isResourceActivity = (activity: TimelineActivity): activity is ResourceActivity => {
  return !!(activity as ResourceActivity).resource;
};

interface PatientTimelineItemProps {
  activity: TimelineActivity;
  client: PartnerClient;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  iconBase: {
    zIndex: 1,
    height: '22px',
    width: '22px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '-1rem',
    marginTop: '3px',
    minWidth: '22px',
    backgroundColor: (props: { color: string }) => props.color,
    '& svg': {
      color: 'white'
    }
  },
  childrenBase: {
    paddingLeft: '1.7rem'
  },
  resourceTypeLink: (props: { color: string }) => ({
    color: props.color,
    '&:hover,&:focus': {
      color: hexToRGB(props.color, 0.5),
      borderColor: hexToRGB(props.color, 0.5)
    }
  }),
  secondaryDetailsSection: {
    display: 'flex',
    alignItems: 'baseline'
  },
  dateText: {
    color: `${theme.palette.grey[60]} !important`,
    padding: '4px 0px 6px',
    fontSize: '12px',
    fontWeight: 350
  }
}));

export const useGetStyleInformationAboutActivity = (
  activity: TimelineActivity
): { color: string; iconComponent: React.ReactNode } => {
  const theme = useTheme();
  const IconProps = {
    fontSize: '1.2rem',
    color: 'white'
  };

  if (isResourceActivity(activity)) {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      resource: { resource_type }
    } = activity;
    const { Icon, color } = getStyleInformationFromResourceType(resource_type, theme);
    return { color, iconComponent: <Icon {...IconProps} /> };
  }

  return { color: theme.palette.forestGreen.main, iconComponent: <GenericFileIcon {...IconProps} /> };
};

export const useParseDetailsFromActvity = (activity: TimelineActivity, client: PartnerClient) => {
  const { t: resourceTypesTFunction } = useTranslate('resourceTypes');
  const { t } = useTranslate('patientTimelineItem');
  const { color } = useGetStyleInformationAboutActivity(activity);
  const classes = useStyles({ color });
  const formatDate = useFormatDate();
  const { internalId } = useParams<{ internalId: string }>();
  const { routesTFunction } = useRoutes();

  const description = useMemo(() => {
    if (isResourceActivity(activity)) {
      const { resource } = activity;
      const path = routesTFunction('redirectPaths./clients/:internalId/records', { internalId });
      const isCreatedByPhysician = resource.source === 'physician';
      const institutionName = (
        resource as GenericHealthInformationResource<{
          information_request: { institution_address: InstitutionAddress };
        }>
      ).information_request?.institution_address?.institution?.institution_name;

      const key = isCreatedByPhysician
        ? 'description.recordAddedThroughDotConnect'
        : institutionName
        ? 'description.recordsReceived'
        : 'description.recordsReceivedWithoutInstitution';

      const TextDescription = () => (
        <Trans
          t={t}
          i18nKey={key}
          components={{
            Link: (
              <Text
                link
                href={{
                  state: { resource },
                  pathname: path
                }}
                className={classes.resourceTypeLink}
              />
            )
          }}
          values={{
            patientName: clsx(client.first_name, client.last_name),
            resourceType: resourceTypesTFunction(`sentence.${resource.resource_type}` as any),
            institutionName
          }}
        />
      );

      return <TextDescription />;
    }

    return <>{t('description.recordFileUploaded', { patientName: clsx(client.first_name, client.last_name) })}</>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity, resourceTypesTFunction, t]);

  const date = useMemo(() => {
    const dateToFormat = isResourceActivity(activity)
      ? activity.resource.createdAt
      : activity.business_upload.created_at;
    return formatDate(dateToFormat, 'MMM DD, YYYY h:mm A');
  }, [activity, formatDate]);

  const files = useMemo(() => {
    const allFiles = isResourceActivity(activity)
      ? aggregateAndSortFiles(activity.resource)
      : [activity.business_upload as any as ServerFile];

    return allFiles;
  }, [activity]);

  const tagProps = useMemo<TagProps | undefined>(() => {
    if (!isResourceActivity(activity)) return;
    const { resource } = activity;

    const isRead = resource.access_permissions[0].is_read;

    if (isRead) {
      return { color: 'success', text: 'read' };
    }
    return { color: 'error', text: 'unread' };
  }, [activity]);

  return { description, date, files, tagProps };
};

export const PatientTimelineItemSkeleton = () => {
  const classes = useStyles({ color: '#ffffff' });
  return (
    <TimelineItemBase>
      <div className={classes.root}>
        <div className={classes.iconBase}>
          <Skeleton borderRadius='50%' width='2rem' height='2rem' />
        </div>
        <div className={classes.childrenBase}>
          <div>
            <Text h6 fontWeight={350} className='type-h8'>
              <Skeleton width='30ch' />
            </Text>
            <section className={classes.secondaryDetailsSection}>
              <Text paragraph fontWeight={400} className={clsx('type-small', classes.dateText)}>
                <Skeleton width='12ch' />
              </Text>
            </section>
          </div>
        </div>
      </div>
    </TimelineItemBase>
  );
};

const PatientTimelineItem = ({ activity, client }: PatientTimelineItemProps) => {
  const { color, iconComponent } = useGetStyleInformationAboutActivity(activity);
  const classes = useStyles({ color });
  const { description, date, files, tagProps } = useParseDetailsFromActvity(activity, client);

  const FilePreviewHOC = ({ file }: { file: ServerFile }) => {
    const { loading: isDownloading, downloadFile } = useDownloadFile();
    const { loading: isFetchingFile, viewFile } = useViewFile();
    const { isDownloadingBusinessUpload, downloadBusinessUpload } = useDownloadBusinessUpload();
    const { viewBusinessUpload, isFetchingBusinessUpload } = useViewBusinessUpload();

    const downloadOptions = useMemo(() => {
      if (isResourceActivity(activity)) {
        return { isDownloading, downloadFile: () => downloadFile(file) };
      }

      return {
        isDownloading: isDownloadingBusinessUpload,
        downloadFile: () => {
          downloadBusinessUpload(client.id, activity.business_upload);
        }
      };
    }, [downloadBusinessUpload, downloadFile, file, isDownloading, isDownloadingBusinessUpload]);

    const viewOptions = useMemo(() => {
      if (isResourceActivity(activity)) {
        return { isFetchingFile, viewFile };
      }

      return {
        isFetchingFile: isFetchingBusinessUpload,
        viewFile: () => {
          viewBusinessUpload(client.id, activity.business_upload);
        }
      };
    }, [isFetchingBusinessUpload, isFetchingFile, viewBusinessUpload, viewFile]);

    return (
      <FilePreview
        viewOptions={viewOptions}
        downloadOptions={downloadOptions}
        key={generateKey(file.id, file.file_name)}
        file={file}
      />
    );
  };

  return (
    <TimelineItemBase>
      <div className={classes.root}>
        <div className={classes.iconBase}>{iconComponent}</div>
        <div className={classes.childrenBase}>
          <div>
            <Text h6 fontWeight={350} className='type-h8'>
              {description}
            </Text>
            <section className={classes.secondaryDetailsSection}>
              <Text paragraph fontWeight={400} className={clsx('type-small', classes.dateText)}>
                {date}
              </Text>
              {tagProps && <Tag color={tagProps.color} text={tagProps.text} />}
            </section>
            {isNotEmptyArray(files) &&
              files.map((file) => <FilePreviewHOC file={file} key={generateKey(file.id, file.file_name)} />)}
          </div>
        </div>
      </div>
    </TimelineItemBase>
  );
};

export default PatientTimelineItem;
