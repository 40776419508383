import React, { useContext, useEffect, useRef, useState, MouseEvent, useCallback } from 'react';

// Material UI
import { makeStyles, Switch, FormControlLabel } from '@material-ui/core';

// Routing
import { useHistory } from 'react-router-dom';

// Localization
import { Trans } from 'react-i18next';
import { useTranslate } from '../../../../hooks/useTranslate';

// Auth User Context
import { AuthContext } from '../../../../contexts/auth-context';

// Dot API
import apiService from '../../../../services/api-service';

// Dot Hooks
import { useRoutes } from '../../../../hooks/useRoutes';
import {
  useFetchLogo,
  useFetchAvatar,
  useAvatarUpload,
  useLogoUpload
} from '../../../../request-hooks/shared/useSharedRequest';

// Constants
import {
  DOT_CONNECT_KNOWLEDGE_BASE_URL,
  DOT_SUPPORT_MAIL,
  DOT_SUPPORT_MAIL_LINK,
  DOT_SUPPORT_PHONE,
  DOT_SUPPORT_PHONE_LINK
} from '../../../../utils/constants';

// Notification Snackbars
import { SnackbarContext } from '../../../../contexts/snackbar-context';

// Dot Components
import Button from '../../../shared/Button';
import Text from '../../../shared/Typography';
import ButtonText from '../../../shared/ButtonText';
import { CloseIcon } from '../../../shared/Icons';
import IconButton from '../../../shared/IconButton';
import OverviewSkeleton from './OverviewSkeleton';
import AuthUserAvatar from '../../../shared/AuthUserAvatar';
import ImageCropModal from './ImageCropModal';
import AuthInstitutionLogo from '../../../shared/AuthInstitutionLogo';
import Divider from '../../../shared/Divider';
import Skeleton from '../../../shared/Skeleton';
import { useMutation } from '../../../../hooks/useQuery';
import Container from '../../../shared/Container';
import Can from '../../../shared/Can';
import { USER_ACTIONS } from '../../../../utils/rbac';
import QuestionIconTooltip from '../../../shared/CustomTooltip/QuestionIconTooltip';

const useStyles = makeStyles(() => ({
  root: {
    width: '65%',
    margin: '0 auto',
    padding: '6.5rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  sectionContainer: {
    width: '80%',
    margin: '0 auto'
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  divider: {
    marginBottom: '3rem',
    marginTop: '4rem',
    width: '100%'
  }
}));
export interface ToggleUserEmailNotifRequest {
  userId: number;
  data: {
    user_institution: {
      email_notifications: boolean;
    };
  };
}

const useToggleUserEmailNotif = () => {
  const { loading, makeRequest, error, response } = useMutation('PUT');
  return {
    isUserEmailNotifLoading: loading,
    errorUpdatingUserEmailNotif: error,
    userEmailNotifResponse: response,
    toggleUserEmailNotif: ({ userId, data }: ToggleUserEmailNotifRequest) =>
      makeRequest(`/institutions/users/${userId}`, data)
  };
};

const Overview = () => {
  const classes = useStyles();
  const { userInfo, setUserInformation, resetPassword, updateUserEmailNotif } = useContext(AuthContext);
  const [pwResetError, setPwResetError] = useState('');
  const [pwResetMessage, setPwResetMessage] = useState('');
  const [institution] = useState(userInfo?.institution);
  const { t, language, i18n, ready } = useTranslate('settingsOverviewTab');
  const { routesTFunction } = useRoutes();
  const history = useHistory();

  const [isAvatarLoading, setIsAvatarLoading] = useState(false);
  const { clearAvatarCache, fetchAvatar } = useFetchAvatar(userInfo!.id);
  const { uploadAvatar } = useAvatarUpload();

  const [isLogoLoading, setIsLogoLoading] = useState(false);
  const { fetchLogo, clearLogoCache } = useFetchLogo();
  const { uploadLogo } = useLogoUpload();

  const [resetLoading, setIsResetLoading] = useState(false);
  const [isAvatarCropModalOpen, setIsAvatarCropModalOpen] = useState(false);
  const [isLogoCropModalOpen, setIsLogoCropModalOpen] = useState(false);

  const { isUserEmailNotifLoading, toggleUserEmailNotif, errorUpdatingUserEmailNotif, userEmailNotifResponse } =
    useToggleUserEmailNotif();
  const [emailNotificationStatus, setEmailNotificationStatus] = useState(
    userInfo?.institution?.user_institution?.email_notifications
  );

  const hiddenAvatarFileInput = useRef<HTMLInputElement>(null);
  const hiddenLogoFileInput = useRef<HTMLInputElement>(null);

  const { addNotification, removeNotification } = useContext(SnackbarContext);

  const [isRemoveLogoLoading, setRemoveLogoLoading] = useState(false);
  const [isRemoveAvatarLoading, setRemoveAvatarLoading] = useState(false);

  const RemoveNotificationButton = useCallback(() => {
    return (
      <IconButton label='' onClick={removeNotification}>
        <CloseIcon color='white' size='small' />
      </IconButton>
    );
  }, [removeNotification]);

  const handleUploadOrChangeAvatarClick = (event: MouseEvent) => {
    event.preventDefault();
    hiddenAvatarFileInput.current && hiddenAvatarFileInput.current.click();
  };

  const handleUploadOrChangeLogoClick = (event: MouseEvent) => {
    event.preventDefault();
    hiddenLogoFileInput.current && hiddenLogoFileInput.current.click();
  };

  const onRemoveAvatar = async () => {
    setRemoveAvatarLoading(true);
    try {
      setIsAvatarLoading(true);
      await apiService.delete('/user/avatar');
      clearAvatarCache();
      userInfo?.avatar && setUserInformation({ ...userInfo, avatar: { ...userInfo?.avatar, file: null } });
      addNotification(t('userDetailsCard.successfullUserAvatarUpdateMessage'), 'success', <RemoveNotificationButton />);
      setRemoveAvatarLoading(false);
    } catch (error) {
      addNotification(t('userDetailsCard.failedUserAvatarUpdateMessage'), 'error', <RemoveNotificationButton />);
      setRemoveAvatarLoading(false);
    } finally {
      setIsAvatarLoading(false);
    }
  };

  const onRemoveLogo = async () => {
    setRemoveLogoLoading(true);

    try {
      setIsLogoLoading(true);
      await apiService.delete('/institutions/logo');
      clearLogoCache();
      userInfo?.institution &&
        setUserInformation({
          ...userInfo,
          institution: { ...userInfo?.institution, logo: null }
        });

      addNotification(
        t('userDetailsCard.successfullOrganizationLogoUpdateMessage'),
        'success',
        <RemoveNotificationButton />
      );
      setRemoveLogoLoading(false);
    } catch (error) {
      setRemoveLogoLoading(false);
      addNotification(t('userDetailsCard.failedOrganizationLogoUpdateMessage'), 'error', <RemoveNotificationButton />);
    } finally {
      setIsLogoLoading(false);
    }
  };

  const uploadAvatarFile = async (avatarFile: File) => {
    try {
      setIsAvatarLoading(true);
      const { avatar } = await uploadAvatar({
        file: avatarFile,
        fileField: 'image',
        formdata: { upload_type: 'avatar' }
      });

      clearAvatarCache();
      await fetchAvatar();

      userInfo?.avatar && setUserInformation({ ...userInfo, avatar });

      addNotification(t('userDetailsCard.successfullUserAvatarUpdateMessage'), 'success', <RemoveNotificationButton />);
    } catch (error) {
      addNotification(t('userDetailsCard.failedUserAvatarUpdateMessage'), 'error', <RemoveNotificationButton />);
    } finally {
      setIsAvatarLoading(false);
    }
  };

  const uploadLogoFile = async (logoFile: File) => {
    try {
      setIsLogoLoading(true);
      const { logo } = await uploadLogo({
        file: logoFile,
        fileField: 'image',
        formdata: { upload_type: 'institution_logo' }
      });

      // Fetch resized version of organization logo
      await fetchLogo(undefined, { resetCache: true });

      userInfo?.institution &&
        setUserInformation({
          ...userInfo,
          institution: { ...userInfo?.institution, logo }
        });

      addNotification(
        t('userDetailsCard.successfullOrganizationLogoUpdateMessage'),
        'success',
        <RemoveNotificationButton />
      );
    } catch (error) {
      addNotification(t('userDetailsCard.failedOrganizationLogoUpdateMessage'), 'error', <RemoveNotificationButton />);
    } finally {
      setIsLogoLoading(false);
    }
  };

  const onPasswordResset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsResetLoading(true);
    setPwResetError('');
    resetPassword(userInfo!.email)
      .then(() => {
        setPwResetMessage(t('securityCard.successfullPasswordResetText', { email: userInfo?.email }));
        setIsResetLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsResetLoading(false);
        setPwResetError(t('securityCard.unSuccessfullPasswordResetText'));
      });
  };

  const handleEmailNotifSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleUserEmailNotif({
      userId: userInfo?.id as number,
      data: {
        user_institution: {
          email_notifications: event.target.checked
        }
      }
    });

    if (!errorUpdatingUserEmailNotif) {
      setEmailNotificationStatus(event.target.checked);
    }
  };

  useEffect(() => {
    if (userEmailNotifResponse) {
      updateUserEmailNotif(userEmailNotifResponse?.user_institution?.email_notifications);
    }
    // eslint-disable-next-line
  }, [userEmailNotifResponse]);

  useEffect(() => {
    i18n.on('languageChanged', () => {
      const redirectionPath = routesTFunction('redirectPaths./settings/overview');
      /**
       * This makes the page to do a full refresh which refetches
       * all the information with the backend messages being localized
       */
      window.location.href = redirectionPath;
    });
  }, [language, routesTFunction, history, i18n]);

  if (!ready) return <OverviewSkeleton />;

  return (
    <div>
      {hiddenAvatarFileInput.current?.files && hiddenAvatarFileInput.current?.files[0] && (
        <ImageCropModal
          isOpen={isAvatarCropModalOpen}
          setIsModalOpen={setIsAvatarCropModalOpen}
          header={t('avatarUploadModalHeader')}
          file={hiddenAvatarFileInput.current?.files[0]}
          onSuccess={(avatarFile: File) => uploadAvatarFile(avatarFile)}
          onFail={() => {
            addNotification(t('userDetailsCard.failedUserAvatarUpdateMessage'), 'error', <RemoveNotificationButton />);
          }}
        />
      )}
      {hiddenLogoFileInput.current?.files && hiddenLogoFileInput.current?.files[0] && (
        <ImageCropModal
          isOpen={isLogoCropModalOpen}
          setIsModalOpen={setIsLogoCropModalOpen}
          header={t('logoUploadModalHeader')}
          file={hiddenLogoFileInput.current?.files[0]}
          onSuccess={(logoFile: File) => uploadLogoFile(logoFile)}
          onFail={() => {
            addNotification(
              t('userDetailsCard.failedOrganizationLogoUpdateMessage'),
              'error',
              <RemoveNotificationButton />
            );
          }}
        />
      )}
      <div className={classes.root}>
        <div className={classes.sectionContainer}>
          <Text h5 color='grey-60' className='mb20'>
            {t('userDetailsCard.heading')}
          </Text>
          <div className='flex gap-35'>
            <div style={{ width: '120px' }}>
              <Text paragraph color='grey-60'>
                {t('userDetailsCard.firstName')}
              </Text>
              <Text paragraph fontWeight={400}>
                {userInfo?.first_name}
              </Text>
            </div>
            <div style={{ width: '120px' }}>
              <Text paragraph color='grey-60'>
                {t('userDetailsCard.lastName')}
              </Text>
              <Text paragraph fontWeight={400}>
                {userInfo?.last_name}
              </Text>
            </div>
            <div style={{ width: '160px' }}>
              <Text paragraph color='grey-60'>
                {t('userDetailsCard.email')}
              </Text>
              <Text paragraph fontWeight={400}>
                {userInfo?.email}
              </Text>
            </div>
          </div>
          <div className='flex gap-50'>
            <div>
              <Text paragraph color='grey-60' className='mt30 mb15'>
                {t('userDetailsCard.avatar')}
              </Text>
              <div className='flex gap-30'>
                {isAvatarLoading ? (
                  <Skeleton borderRadius='50%' width='50px' height='50px' />
                ) : (
                  <AuthUserAvatar size='large' />
                )}
                <Button
                  isSecondary
                  label={
                    userInfo?.avatar?.file?.file_name
                      ? t('buttons.changeAvatarButtonText')
                      : t('buttons.uploadAvatarButtonText')
                  }
                  onClick={(e) => handleUploadOrChangeAvatarClick(e)}
                />
                <input
                  type='file'
                  ref={hiddenAvatarFileInput}
                  onChange={() => setIsAvatarCropModalOpen(true)}
                  onClick={(e: MouseEvent<HTMLInputElement>) => {
                    (e.target as HTMLInputElement).value = '';
                  }}
                  accept='image/jpeg, image/png'
                  style={{ display: 'none' }}
                />
                {Boolean(userInfo?.avatar?.file?.file_name) && (
                  <ButtonText
                    onClick={onRemoveAvatar}
                    label={t('buttons.removeButtonText')}
                    isDanger
                    isDisabled={isRemoveAvatarLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.sectionContainer}>
          <Text h5 color='grey-60' className='mb20'>
            {t('userOrganizationCard.heading')}
          </Text>
          <div className='flex gap-35'>
            <div style={{ width: '120px' }}>
              <Text paragraph color='grey-60'>
                {t('userOrganizationCard.organization')}
              </Text>
              <Text paragraph fontWeight={400}>
                {institution?.institution_name ?? ''}
              </Text>
            </div>
            <div style={{ width: '120px' }}>
              <Text paragraph color='grey-60'>
                {t('userOrganizationCard.role')}
              </Text>
              {institution?.user_institution?.role && (
                <Text paragraph fontWeight={400} className={classes.capitalize}>
                  <Trans t={t} i18nKey={`roles.${institution.user_institution.role}` as any} />
                </Text>
              )}
            </div>
            <Can perform={USER_ACTIONS.VIEW.SETTINGS_OVERVIEW_ARCHIVE_INFO}>
              <div>
                <div className='flex'>
                  <Text paragraph color='grey-60'>
                    {t('userOrganizationCard.requestArchivePeriod')}
                    <QuestionIconTooltip
                      style={{ marginLeft: '5px' }}
                      title={
                        <Trans
                          t={t}
                          i18nKey='userOrganizationCard.requestArchivePeriodMessage'
                          components={{
                            br: (
                              <>
                                <br />
                                <br />
                              </>
                            )
                          }}
                        />
                      }
                    />
                  </Text>
                </div>
                <Text paragraph fontWeight={400}>
                  {t('userOrganizationCard.requestArchivePeriodValue')}
                </Text>
              </div>
            </Can>
          </div>
          {/* If there's no instituion logo, this section will not be shown for non-owner role users */}
          {(institution?.logo?.file_name || institution?.user_institution?.role === 'owner') && (
            <div className='flex gap-50'>
              <div>
                <Text paragraph color='grey-60' className='mt30 mb15'>
                  {t('userDetailsCard.icon')}
                </Text>
                <div className='flex gap-30'>
                  {isLogoLoading ? (
                    <Skeleton borderRadius='50%' width='50px' height='50px' />
                  ) : (
                    <AuthInstitutionLogo size='large' />
                  )}

                  {institution?.user_institution?.role === 'owner' && (
                    <>
                      <Button
                        isSecondary
                        label={
                          userInfo?.institution?.logo?.file_name
                            ? t('buttons.changeIconButtonText')
                            : t('buttons.uploadIconButtonText')
                        }
                        onClick={(e) => handleUploadOrChangeLogoClick(e)}
                      />
                      <input
                        type='file'
                        ref={hiddenLogoFileInput}
                        onChange={() => setIsLogoCropModalOpen(true)}
                        onClick={(e: MouseEvent<HTMLInputElement>) => {
                          (e.target as HTMLInputElement).value = '';
                        }}
                        accept='image/jpeg, image/png'
                        style={{ display: 'none' }}
                      />
                      {Boolean(userInfo?.institution?.logo?.file_name) && (
                        <ButtonText
                          onClick={onRemoveLogo}
                          label={t('buttons.removeButtonText')}
                          isDanger
                          isDisabled={isRemoveLogoLoading}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <Divider className={classes.divider} />

        <div className={classes.sectionContainer}>
          <Text h5 color='grey-60' className='mb20'>
            {t('notificationsCard.heading')}
          </Text>
          <div>
            <FormControlLabel
              control={
                <Switch
                  disabled={isUserEmailNotifLoading}
                  checked={emailNotificationStatus}
                  onChange={handleEmailNotifSwitch}
                />
              }
              label={
                <Text paragraph fontWeight={500}>
                  {t('notificationsCard.optIntext')}
                </Text>
              }
              labelPlacement='start'
            />
            <div style={{ marginBottom: '1rem' }}>
              <Text paragraph className='is-error'>
                {errorUpdatingUserEmailNotif}
              </Text>
            </div>
            <Text paragraph>{t('notificationsCard.notificationsDescription')}</Text>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.sectionContainer}>
          <Text h5 color='grey-60' className='mb20'>
            {t('securityCard.heading')}
          </Text>
          <div>
            <Text paragraph>{t('securityCard.forgotPasswordText')}</Text>
            <form onSubmit={onPasswordResset}>
              <div style={{ marginBottom: '1rem' }}>
                <Text paragraph className='is-error'>
                  {pwResetError}
                </Text>
              </div>
              <div style={{ marginBottom: '1rem' }}>
                <Text paragraph className=''>
                  {pwResetMessage}
                </Text>
              </div>
              <Container style={{ display: 'flex' }}>
                <Button
                  isSecondary
                  style={{
                    border: 'none',
                    background: 'transparent',
                    color: resetLoading ? '#fff' : '#672bc2',
                    padding: 0
                  }}
                  type='submit'
                  label={t('securityCard.resetButtonText')}
                  isLoading={resetLoading}
                  disabled={resetLoading}
                />
              </Container>
            </form>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.sectionContainer}>
          <Text h5 color='grey-60' className='mb20'>
            {t('helpSection.heading')}
          </Text>
          <div>
            <Text paragraph>
              <Trans
                t={t}
                i18nKey='helpSection.FAQParagraph'
                components={{
                  linkText: <Text openInNewTab a href={DOT_CONNECT_KNOWLEDGE_BASE_URL} fontWeight={500} />
                }}
              />
            </Text>
            <Text paragraph style={{ marginTop: '20px' }}>
              <Trans
                t={t}
                i18nKey='helpSection.contactsParagraph'
                components={{
                  phoneLink: <Text openInNewTab a href={DOT_SUPPORT_PHONE_LINK} fontWeight={500} />,
                  emailLink: <Text openInNewTab a href={DOT_SUPPORT_MAIL_LINK} fontWeight={500} />
                }}
                values={{ dotPhone: DOT_SUPPORT_PHONE, dotEmail: DOT_SUPPORT_MAIL }}
              />
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
