import React, { RefObject, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Icon from '../Icon';
import { conditionalProps, getTitleCase, hexToRGB, isNotUndefined } from '../../../utils/utils';
import Button from '../Button';
import { IconNames } from '../../../types/common/IconTypes';
import IconButton from '../IconButton';
import { useTranslate } from '../../../hooks/useTranslate';
import useId from '../../../hooks/useId';

interface CommonProps {
  label?: string;
  hideLabelAbove?: boolean;
  children?: React.ReactNode;
  toggleVisibilityButton?: boolean;
  hideLabel?: boolean;
  error?: string;
  focusOnMount?: boolean;
  maxLength?: number;
  noPlaceholders?: boolean;
  iconName?: IconNames;
  pattern?: string;
  maxWidth?: boolean;
  inputClassName?: string;
  buttonRight?: string;
  iconPosition?: 'start' | 'end';
  iconClassName?: string;
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  labelProps?: Omit<React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>, 'htmlFor'>;
  wrapperProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  errorClassName?: string;
  toggleInputError?: boolean;
  upperCase?: boolean;
  titleCase?: boolean;
  inputType?: string;
  numeric?: boolean;
  showCharsCount?: boolean;
  format?: (value: any) => any | undefined;
}

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & CommonProps;
export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & CommonProps;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '1rem'
  },
  label: {
    fontSize: '1.5rem',
    color: theme.palette.grayscale['40'],
    fontWeight: 300
  },
  hidelabel: {
    opacity: 0
  },
  input: {
    outline: 'none',
    borderRadius: '3.6rem',
    backgroundColor: 'white',
    width: '100%',
    padding: '10px 16px',
    fontSize: '1.5rem',
    color: theme.palette.grayscale['30'],
    border: `2px solid ${theme.palette.grey['80']}`,
    height: '100%',
    '&:focus': {
      color: theme.palette.text.main,
      border: `2px solid ${theme.palette.primary.main}`
    },
    '&:disabled': {
      color: '#40374F',
      borderColor: '#C4C5D2'
    },
    '&::placeholder': {
      color: hexToRGB(theme.palette.text.main, 0.3)
    }
  },
  inputIcon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0px'
  },
  withToggleButton: {
    paddingRight: '3.2rem'
  },
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },
  maxWidth: {
    width: '100%'
  },
  visibilityButton: {
    right: 10,
    position: 'absolute',
    background: 'none',
    padding: 0
  },
  clippedFieldWidth: {
    paddingRight: '110px'
  },
  optionalLabel: {
    fontSize: '1.2rem',
    marginLeft: '0.75rem',
    color: theme.palette.text.main,
    fontStyle: 'italic'
  },
  withIconLeft: {
    paddingLeft: '3rem'
  },
  withIconRight: {
    paddingRight: '3rem'
  },
  iconStyles: {
    position: 'absolute',
    '&.start': {
      left: 10
    },
    '&.end': {
      right: 10
    }
  },
  textArea: {
    borderRadius: '16px'
  }
}));

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const classes = useStyles();
  const { label, className, error, focusOnMount, noPlaceholders, maxWidth, placeholder, hideLabel, ...others } = props;

  const textAreaId = useId({ prefix: 'textarea', defaultValue: others.id });

  return (
    <div className={classes.root}>
      <label
        htmlFor={textAreaId}
        className={clsx(
          classes.label,
          hideLabel && classes.hidelabel,
          others.maxLength && others.showCharsCount && classes.maxWidth
        )}
      >
        <div className='flex space-between'>
          {label}
          {others.maxLength && others.showCharsCount && (
            <span>
              {others.value ? others.value.toString().length : 0}/{others.maxLength}
            </span>
          )}
        </div>
      </label>
      <textarea
        {...others}
        id={textAreaId}
        ref={ref as RefObject<HTMLTextAreaElement>}
        className={clsx(
          classes.input,
          classes.textArea,
          error && classes.error,
          classes.inputIcon,
          maxWidth && classes.maxWidth,
          className
        )}
        autoFocus={focusOnMount}
        placeholder={placeholder || (!noPlaceholders ? label : undefined)}
      />
    </div>
  );
});

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const containerRef = useRef<HTMLDivElement>();
  const {
    value,
    children,
    name,
    label,
    hideLabelAbove,
    className,
    toggleVisibilityButton,
    type,
    error,
    focusOnMount,
    maxLength,
    noPlaceholders,
    iconName,
    maxWidth,
    inputClassName,
    buttonRight,
    iconPosition,
    placeholder,
    buttonProps,
    labelProps,
    wrapperProps,
    errorClassName,
    toggleInputError,
    hideLabel,
    upperCase,
    titleCase,
    numeric,
    format,
    ...others
  } = props;

  const icon = showPassword ? 'close' : 'open';
  const { t } = useTranslate('passwordToggle');

  const inputId = useId({ defaultValue: others.id, prefix: `input-${name}` });
  const helperTextId = useId({ prefix: 'input-helper-text' });

  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };

  const InputIcon = () => {
    return <Icon name={iconName} className={clsx(classes.iconStyles, iconPosition)} />;
  };

  const [inputType, setInputType] = useState(type);

  useEffect(() => {
    if (!isNotUndefined(toggleVisibilityButton) || type) return;
    return setInputType(showPassword ? 'text' : 'password');
  }, [toggleVisibilityButton, showPassword, type]);

  return (
    <div className={clsx(classes.root, wrapperProps?.className)}>
      {!hideLabelAbove && (
        <label
          {...labelProps}
          className={clsx(classes.label, hideLabel && classes.hidelabel, labelProps && labelProps?.className)}
          htmlFor={inputId}
        >
          {label}
        </label>
      )}
      <div
        {...wrapperProps}
        className={clsx(classes.inputIcon, maxWidth && classes.maxWidth, inputClassName, wrapperProps?.className)}
        ref={containerRef as RefObject<HTMLDivElement>}
      >
        {iconName && iconPosition && <InputIcon />}
        <input
          title={label}
          name={name}
          id={inputId}
          max={maxLength}
          ref={ref}
          className={clsx(
            classes.input,
            className,
            (error || toggleInputError) && classes.error,
            toggleVisibilityButton && classes.withToggleButton,
            buttonRight && classes.clippedFieldWidth,
            iconName && iconPosition === 'start' && classes.withIconLeft,
            iconName && iconPosition === 'end' && classes.withIconRight,
            maxWidth && classes.maxWidth
          )}
          value={value}
          type={inputType}
          autoFocus={focusOnMount}
          placeholder={placeholder || (!noPlaceholders ? label : undefined)}
          maxLength={maxLength}
          {...conditionalProps({ 'aria-describedby': helperTextId, 'aria-invalid': true }, !!error)}
          {...others}
          onChange={(e) => {
            const { value } = e.currentTarget;
            if (upperCase) e.currentTarget.value = value.toUpperCase();
            if (titleCase) e.currentTarget.value = getTitleCase(value);
            if (maxLength && value.length > maxLength) return;
            if (numeric && isNaN(Number(value))) return;
            if (isNotUndefined(format)) e.currentTarget.value = format(e.currentTarget.value);
            others && others.onChange && others.onChange(e);
          }}
          onFocus={(e) => {
            if (isNotUndefined(others.onFocus)) others.onFocus(e);
          }}
          onBlur={(e) => {
            if (isNotUndefined(others.onBlur)) others.onBlur(e);
          }}
        />
        {toggleVisibilityButton && (
          <IconButton
            className={classes.visibilityButton}
            onClick={handleClick}
            label={showPassword ? t('hidePassword') : t('showPassword')}
          >
            <Icon size='medium' name={`eye-${icon}` as IconNames} />
          </IconButton>
        )}
        {buttonRight && (
          <Button
            onClick={handleClick}
            className={clsx(classes.visibilityButton, 'is-primary')}
            label={buttonRight}
            {...buttonProps}
          />
        )}
      </div>
      {!!children && children}
      {error && (
        <p
          className={clsx('error-text', errorClassName)}
          id={helperTextId}
          style={{ maxWidth: containerRef.current?.offsetWidth }}
        >
          {error}
        </p>
      )}
    </div>
  );
});

export default Input;
