import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

type IconButtonColors = 'primary';

export interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  label: string;
  color?: IconButtonColors;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  primary: {
    color: theme.palette.primary.main,
    '&:hover, &:focus': {
      color: theme.palette.primary.hover,
      '& svg': {
        color: theme.palette.primary.hover
      }
    },
    '& svg': {
      color: theme.palette.primary.main
    }
  }
}));

const IconButton = (props: IconButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
  const { children, label, className, color, ...others } = props;
  const classes = useStyles();
  return (
    <button
      ref={ref}
      aria-label={label}
      type='button'
      className={clsx(classes.root, color && classes[color], className)}
      {...others}
    >
      {children}
    </button>
  );
};

export default React.forwardRef(IconButton);
