/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface CloseIconProps extends SVGBaseProps {}

const CloseIcon = (props: CloseIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M23.455 0.574519C22.7552 -0.144879 21.6154 -0.144879 20.8956 0.574519L11.9974 9.44708L3.09934 0.554535C2.39949 -0.184845 1.23974 -0.184845 0.539885 0.554535C-0.179962 1.25395 -0.179962 2.39299 0.539885 3.11239L9.43799 12.0049L0.539885 20.8975C-0.179962 21.6169 -0.179962 22.7559 0.539885 23.4553C1.23974 24.1747 2.39949 24.1747 3.09934 23.4553L11.9974 14.5628L20.8956 23.4553C21.6154 24.1747 22.7552 24.1747 23.495 23.4553C24.1749 22.7559 24.1749 21.6169 23.455 20.8975L14.5569 12.0049L23.455 3.11239C24.1549 2.41298 24.1549 1.25395 23.455 0.574519V0.574519Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default CloseIcon;
