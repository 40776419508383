import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { IconSizes } from '../../../types';

export interface SVGBaseProps extends Omit<React.SVGAttributes<SVGSVGElement>, 'color'> {
  className?: string;
  size?: IconSizes;
  color?: string | ((theme: Theme) => string);
}

const useStyles = makeStyles((theme) => ({
  root: {
    userSelect: 'none',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fill: 'currentColor',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(24)
  }
}));

const SVGBase = (props: SVGBaseProps) => {
  const { color: propColor, fontSize: propFontSize, ...other } = props;
  const theme = useTheme();
  const classes = useStyles();

  const color = useMemo(() => {
    if (typeof propColor === 'function') {
      return propColor(theme);
    }
    return propColor;
  }, [propColor, theme]);

  const fontSize = useMemo(() => {
    if (typeof propFontSize === 'number') {
      return theme.typography.pxToRem(propFontSize);
    }
    return propFontSize;
  }, [propFontSize, theme]);

  return (
    <svg
      viewBox='0 0 24 24'
      {...other}
      style={{ ...other.style, fontSize }}
      xmlns='http://www.w3.org/2000/svg'
      className={clsx(classes.root, other.className)}
      color={color || 'currentColor'}
    />
  );
};

export default SVGBase;
