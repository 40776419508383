import { ClientInformationInitialState } from '../components/pages/CreateRequest';
import { ValidationErrors } from '../hooks/useForm';
import { useTranslate } from '../hooks/useTranslate';
import { isValidEmail } from '../utils/utils';

export default function useClientInformationValidator() {
  const { t } = useTranslate('createNewRequest');
  return (fields: ClientInformationInitialState) => {
    const errors: ValidationErrors<ClientInformationInitialState> = {};

    if (!isValidEmail(fields.email)) {
      errors.email = t('clientInformationStep.errors.email');
    }

    return errors;
  };
}
