import { useState, WheelEvent } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core';

// Localization
import { useTranslate } from '../../../../hooks/useTranslate';

// Icons
import SliderIcon from '../../../../assets/icons/ic-slider-polygon.svg';

// Image processing util
import { cropImage } from '../../../../utils/imageProcessing';

// Dot Components
import Button from '../../../shared/Button';
import Container from '../../../shared/Container';
import Icon from '../../../shared/Icon';
import Modal from '../../../shared/Modal';
import Spinner from '../../../shared/Spinner';

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: '1rem 0px !important',
    width: '30%',
    overflowY: 'auto',
    margin: 'auto',
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      width: '98%'
    }
  },
  imageWrapper: {
    margin: '0 auto',
    overflow: 'hidden',
    width: '200px',
    height: '200px',
    border: `2px solid ${theme.palette.catSkillWhite.main}`,
    borderRadius: '50%'
  },
  modalImagePreview: {
    verticalAlign: 'middle',
    width: '200px',
    height: '200px',
    objectFit: 'cover',
    transform: (props: { scaleIndex: number }) => `scale(${props.scaleIndex})`
  },
  slider: {
    '-webkit-appearance': 'none',
    width: '70%',
    height: '1px',
    background: theme.palette.grey[60],
    outline: 'none',
    opacity: 0.7,
    '-webkit-transition': '.2s',
    transition: 'opacity .2s',
    '&::-webkit-slider-thumb': {
      '-webkit-appearance': 'none',
      appearance: 'none',
      width: '16px',
      height: '22px',
      border: 0,
      background: `url(${SliderIcon})`,
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer'
    },
    '&::-moz-range-thumb': {
      width: '16px',
      height: '22px',
      border: 0,
      background: `url(${SliderIcon})`,
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer'
    }
  }
}));

interface ImageCropModalProps {
  file: File;
  isOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  onSuccess: (avatarFile: File) => void;
  onFail: () => void;
  header: string;
}

const ImageCropModal = ({ file, isOpen, setIsModalOpen, onSuccess, onFail, header }: ImageCropModalProps) => {
  const [modalLoading, setModalLoading] = useState(false);
  const [scaleIndex, setScaleIndex] = useState(1);
  const classes = useStyles({ scaleIndex });

  const { t, ready } = useTranslate('settingsOverviewTab');

  const onWheel = (e: WheelEvent<HTMLDivElement>) => {
    if (e.deltaY < 0 && scaleIndex < 1.96) {
      setScaleIndex(scaleIndex + 0.05);
    }

    if (e.deltaY > 0 && scaleIndex > 1.04) {
      setScaleIndex(scaleIndex - 0.05);
    }
  };

  const handleIncrementClick = () => {
    if (scaleIndex <= 1.9) {
      setScaleIndex(scaleIndex + 0.1);
    } else {
      setScaleIndex(2);
    }
  };

  const handleDecrementClick = () => {
    if (scaleIndex > 1.1) {
      setScaleIndex(scaleIndex - 0.1);
    } else {
      setScaleIndex(1);
    }
  };

  const onClose = () => {
    setIsModalOpen(false);
    setScaleIndex(1);
  };

  const onSave = async () => {
    try {
      setModalLoading(true);
      let updatedFile = file;

      if (scaleIndex > 1) {
        updatedFile = (await cropImage({ file, scaleIndex })) as File;
      }

      onSuccess(updatedFile);
    } catch (error) {
      onFail();
    } finally {
      onClose();
      setModalLoading(false);
    }
  };

  if (!ready) return <Spinner />;

  return (
    <Modal open={isOpen} onClose={onClose} className={classes.modal} header={header} showHeaderDivider>
      <Container justifyContent='center' width='700px' margin='2rem 7rem'>
        <div className={classes.imageWrapper} onWheelCapture={onWheel}>
          <img src={URL.createObjectURL(file)} alt='Avatar' className={classes.modalImagePreview} />
        </div>
        <div className='flex space-around center mt35'>
          <Icon name='slider-minus' onClick={handleDecrementClick} />
          <input
            type='range'
            min='1'
            max='2'
            step='0.05'
            value={scaleIndex}
            onChange={(e) => setScaleIndex(Number(e.target.value))}
            className={classes.slider}
          />
          <Icon name='slider-plus' onClick={handleIncrementClick} />
        </div>
        <div className='center-horizontal gap-30 mt30'>
          <Button isSecondary label={t('buttons.cancelButtonText')} onClick={() => onClose()} />
          <Button label={t('buttons.saveButtonText')} isLoading={modalLoading} onClick={onSave} />
        </div>
      </Container>
    </Modal>
  );
};

export default ImageCropModal;
