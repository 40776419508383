import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface RecordsTabWrapperContentWrapperProps {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem 0px',
    '& .half-grid-item': {
      [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
        paddingRight: '2rem'
      }
    }
  }
}));

const RecordsTabWrapperContentWrapper = (props: RecordsTabWrapperContentWrapperProps) => {
  const { children } = props;

  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};

export default RecordsTabWrapperContentWrapper;
