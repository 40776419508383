/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface CommunicationRecordsIconProps extends SVGBaseProps {}

const CommunicationRecordsIcon = (props: CommunicationRecordsIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M21.6 21.6H6C5.36348 21.6 4.75303 21.3471 4.30294 20.8971C3.85286 20.447 3.6 19.8365 3.6 19.2V8.32799L0.276 4.36799C0.108 4.16399 0 3.88799 0 3.59999C0 3.28173 0.126428 2.97651 0.351472 2.75147C0.576515 2.52642 0.88174 2.39999 1.2 2.39999H21.6C22.2365 2.39999 22.847 2.65285 23.2971 3.10294C23.7471 3.55302 24 4.16347 24 4.79999V19.2C24 19.8365 23.7471 20.447 23.2971 20.8971C22.847 21.3471 22.2365 21.6 21.6 21.6ZM7.8 5.99999C7.64087 5.99999 7.48826 6.06321 7.37574 6.17573C7.26321 6.28825 7.2 6.44086 7.2 6.59999V7.79999C7.2 7.95912 7.26321 8.11174 7.37574 8.22426C7.48826 8.33678 7.64087 8.39999 7.8 8.39999H19.8C19.9591 8.39999 20.1117 8.33678 20.2243 8.22426C20.3368 8.11174 20.4 7.95912 20.4 7.79999V6.59999C20.4 6.44086 20.3368 6.28825 20.2243 6.17573C20.1117 6.06321 19.9591 5.99999 19.8 5.99999H7.8ZM7.8 10.8C7.64087 10.8 7.48826 10.8632 7.37574 10.9757C7.26321 11.0883 7.2 11.2409 7.2 11.4V12.6C7.2 12.7591 7.26321 12.9117 7.37574 13.0243C7.48826 13.1368 7.64087 13.2 7.8 13.2H19.8C19.9591 13.2 20.1117 13.1368 20.2243 13.0243C20.3368 12.9117 20.4 12.7591 20.4 12.6V11.4C20.4 11.2409 20.3368 11.0883 20.2243 10.9757C20.1117 10.8632 19.9591 10.8 19.8 10.8H7.8ZM7.8 15.6C7.64087 15.6 7.48826 15.6632 7.37574 15.7757C7.26321 15.8883 7.2 16.0409 7.2 16.2V17.4C7.2 17.5591 7.26321 17.7117 7.37574 17.8243C7.48826 17.9368 7.64087 18 7.8 18H13.8C13.9591 18 14.1117 17.9368 14.2243 17.8243C14.3368 17.7117 14.4 17.5591 14.4 17.4V16.2C14.4 16.0409 14.3368 15.8883 14.2243 15.7757C14.1117 15.6632 13.9591 15.6 13.8 15.6H7.8Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default CommunicationRecordsIcon;
