import { DOT_SUPPORT_MAIL, DOT_SUPPORT_MAIL_LINK } from '../../utils/constants';

const MailToLink = () => {
  return (
    <a className='type-link' target='_blank' rel='noopener noreferrer' href={DOT_SUPPORT_MAIL_LINK}>
      {DOT_SUPPORT_MAIL}
    </a>
  );
};

export default MailToLink;
