//@flow
import React, { useMemo } from 'react';
import { useTranslate } from '../../hooks/useTranslate';
import { isNotUndefined } from '../../utils/utils';
import Button from './Button';
import Container from './Container';
import Spinner from './Spinner';
import Text, { TypographyProps } from './Typography';

export interface GenericErrorScreenProps {
  label?: string;
  buttonProps?: { urlTo: string; label: string };
  showActionButton?: boolean;
  labelProps?: TypographyProps;
}

const GenericErrorScreen: React.FC<GenericErrorScreenProps> = ({
  label,
  buttonProps,
  labelProps = { paragraph: true },
  showActionButton = true
}) => {
  const { t, ready } = useTranslate('genericErrorScreen');
  const buttonProperties = useMemo(() => {
    if (isNotUndefined(buttonProps)) {
      return { label: buttonProps.label, urlTo: buttonProps.urlTo };
    }
    return { label: t('buttonText'), urlTo: '/' };
  }, [buttonProps, t]);

  return (
    <Container height='35rem' isFlex style={{ alignItems: 'center' }} justifyContent='center'>
      {ready ? (
        <div>
          <Container margin='2rem'>
            <Text {...labelProps}>{label || t('message')}</Text>
          </Container>
          {showActionButton && (
            <Container justifyContent='center'>
              <Button label={buttonProperties.label} urlTo={buttonProperties.urlTo} />
            </Container>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </Container>
  );
};

export default GenericErrorScreen;
