import { useContext } from 'react';
import { makeStyles } from '@material-ui/core';

// Routing
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../hooks/useRoutes';

// Localization
import { useTranslate } from '../../hooks/useTranslate';

// Auth user info
import { AuthContext } from '../../contexts/auth-context';

// Helpers
import useTitle from '../../hooks/useTitle';

// Page Layout
import PublicLayout from '../layouts/PublicLayout';
import AppView from '../layouts/AppView';

// Dot Components
import Button from '../shared/Button';
import Text from '../shared/Typography';
import Spinner from '../shared/Spinner';

// Media
import illoDots from '../../assets/illustrations/illo-dots.webp';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '70px',
    textAlign: 'center'
  }
}));

const GenericNotFound = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t, ready } = useTranslate('notFoundPage');
  useTitle((t) => t('notFoundPage'));
  const { routesTFunction } = useRoutes();
  const { isLoggedIn } = useContext(AuthContext);

  const Wrapper = isLoggedIn ? AppView : PublicLayout;

  if (!ready) {
    return (
      <div style={{ height: '80vh', width: '100%' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <Wrapper>
      <div className={classes.container}>
        <img src={illoDots} width='368px' height='252px' alt='General not found page illustration' />
        <div>
          <Text h1 color='grey-40' align='center'>
            {t('titleText')}
          </Text>
          <Text h4 color='ship-cove' align='center' style={{ marginTop: '1.3rem' }}>
            {t('messageText')}
          </Text>
          <Button
            onClick={() => history.push(routesTFunction('routerPaths./'))}
            style={{ marginTop: '4rem' }}
            label={t('dotHealthHomeButtonText')}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default GenericNotFound;
