import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { socketservice } from '../services/websocket-service';
import { AuthContext } from './auth-context';

type SocketContextType = {
  socket?: SocketIOClient.Socket;
};

export const SocketContext = createContext<SocketContextType>({
  socket: undefined
});

type SocketProviderProps = React.PropsWithChildren<Record<string, unknown>>;

const SocketProvider = ({ children }: SocketProviderProps) => {
  const { isLoggedIn } = useContext(AuthContext);
  const [socket, setSocket] = useState<SocketIOClient.Socket | undefined>();

  useEffect(() => {
    if (isLoggedIn) {
      const s = socketservice.connect();
      if (s) setSocket(s);
    } else {
      setSocket(undefined);
      socketservice.disconnect();
    }
  }, [isLoggedIn]);

  const value = useMemo(() => ({ socket }), [socket]);

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export default SocketProvider;
