const dataURItoBlob = (dataURI: string) => {
  const bytes =
    dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
};

interface ICropImageOptions {
  file: File;
  scaleIndex: number;
}

export const cropImage = ({ file, scaleIndex }: ICropImageOptions) => {
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas') as HTMLCanvasElement;
  return new Promise<Blob>((resolve, reject) => {
    if (!file.type.match(/image.*/)) {
      reject(new Error('Wrong file format provided. Required format: png/jpg'));
    }

    if (scaleIndex < 1) {
      reject(new Error('Invalid scale index value provided. Scale index must be >= 1'));
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => {
        const { width } = image;
        const { height } = image;

        // Get desired image crop rectangle size based on the scale index provided
        const cropWidth = width / scaleIndex;
        const cropHeight = height / scaleIndex;

        canvas.width = cropWidth;
        canvas.height = cropHeight;

        canvas.getContext('2d')?.drawImage(
          image, // Original image
          (width - cropWidth) / 2, // x-coordinate from which to start cropping the image
          (height - cropHeight) / 2, // y-coordinate from which to start cropping the image
          cropWidth,
          cropHeight,
          0, // no horizontal image margins
          0, // no vertical image margins
          cropWidth, // cropped image version width (same as original)
          cropHeight // cropped image version height (same as original)
        );
        const dataUrl = canvas.toDataURL('image/jpeg');
        resolve(dataURItoBlob(dataUrl));
      };
      // Load the image with the src of the image file provided
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
};
