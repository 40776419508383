/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface ImageFileIconProps extends Omit<SVGBaseProps, 'color'> {}

const ImageFileIcon = (props: ImageFileIconProps) => {
  return (
    <SVGBase viewBox='0 0 24 24' {...props}>
      <path
        d='M0.350586 3.27767C0.350586 1.86361 1.49691 0.717285 2.91097 0.717285H17.7902C19.2043 0.717285 20.3506 1.86361 20.3506 3.27767V18.1569C20.3506 19.571 19.2043 20.7173 17.7902 20.7173H2.91097C1.49691 20.7173 0.350586 19.571 0.350586 18.1569V3.27767Z'
        fill='#5B53FF'
      />
      <path
        d='M6.7274 7.77042V13.4709C6.7274 13.9245 7.09509 14.2922 7.54865 14.2922H7.64527C8.09884 14.2922 8.46653 13.9245 8.46653 13.4709V10.6619C8.46653 10.6139 8.52896 10.5953 8.55526 10.6355L9.29622 11.7675C9.31515 11.7964 9.35744 11.7967 9.37673 11.768L10.1414 10.6313C10.168 10.5917 10.2298 10.6106 10.2298 10.6583V13.5192C10.2298 13.9461 10.5759 14.2922 11.0028 14.2922C11.4296 14.2922 11.7757 13.9461 11.7757 13.5192V7.77042C11.7757 7.31686 11.408 6.94917 10.9545 6.94917H10.8404C10.4779 6.94917 10.1583 7.18677 10.0539 7.53385L9.37944 9.77607C9.36591 9.82106 9.30264 9.82222 9.28746 9.77777L8.51141 7.50504C8.39789 7.1726 8.0855 6.94917 7.73421 6.94917H7.54865C7.09509 6.94917 6.7274 7.31686 6.7274 7.77042Z'
        fill='white'
      />
      <path
        d='M5.03658 13.4951V7.8912C5.03658 7.37093 4.61482 6.94917 4.09455 6.94917C3.57428 6.94917 3.15252 7.37093 3.15252 7.8912V13.4951C3.15252 14.0153 3.57428 14.4371 4.09455 14.4371C4.61482 14.4371 5.03658 14.0153 5.03658 13.4951Z'
        fill='white'
      />
      <path
        d='M14.2153 6.99748H16.7274C17.341 6.99748 17.8385 7.49494 17.8385 8.10859V8.66238C17.8385 9.0769 17.5025 9.41294 17.0879 9.41294H16.7601C16.5266 9.41294 16.3374 9.22369 16.3374 8.99023C16.3374 8.75678 16.1481 8.56753 15.9147 8.56753H14.8825V12.6255H16.3374V11.7559H16.0558C15.6756 11.7559 15.3674 11.4477 15.3674 11.0675C15.3674 10.6873 15.6756 10.3791 16.0558 10.3791H16.7274C17.341 10.3791 17.8385 10.8766 17.8385 11.4902V13.326C17.8385 13.9396 17.341 14.4371 16.7274 14.4371H14.2153C13.6017 14.4371 13.1042 13.9396 13.1042 13.326V8.10859C13.1042 7.49494 13.6017 6.99748 14.2153 6.99748Z'
        fill='white'
      />
    </SVGBase>
  );
};

export default ImageFileIcon;
