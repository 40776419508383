import React from 'react';
import clsx from 'clsx';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  noShadow?: boolean;
  className?: string;
  noRadius?: boolean;
  noPadding?: boolean;
  style?: React.CSSProperties;
  noBorder?: boolean;
  selected?: boolean;
  addCheckboxLabel?: boolean;
  children?: React.ReactNode;
  imageRight?: React.ReactNode;
}

const Card = ({
  noShadow,
  noRadius,
  noPadding,
  noBorder,
  selected,
  className,
  children,
  style,
  id,
  addCheckboxLabel,
  imageRight,
  ...other
}: CardProps) => {
  const card = (
    <div
      className={clsx(
        'card',
        noShadow && 'no-shadow',
        noRadius && 'no-radius',
        noPadding && 'no-padding',
        noBorder && !selected && 'no-border',
        selected && 'is-selected',
        className
      )}
      {...other}
      style={style}
    >
      {imageRight && (
        <div className='flex' style={{ float: 'right' }}>
          {imageRight}
        </div>
      )}
      {children}
    </div>
  );
  if (addCheckboxLabel) {
    return <label htmlFor={`card__checkbox-item-${id}`}>{card}</label>;
  }
  return card;
};

export default Card;
