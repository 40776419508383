// @flow

const config = {
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || '',
  AUTH_ENDPOINT: process.env.REACT_APP_AUTH_ENDPOINT || '',
  ORIGIN: process.env.REACT_APP_ORIGIN || '',
  RESET_PW_REDIRECT: process.env.REACT_APP_RESET_PW_REDIRECT || ''
};

export default config;
