import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth-context';
import { ROLES } from '../../utils/roles';
import { useRoutes } from '../../hooks/useRoutes';
import Container from '../shared/Container';
import Spinner from '../shared/Spinner';

const Home = () => {
  const { role } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setLoading] = useState(true);
  const { routesTFunction } = useRoutes();

  useEffect(() => {
    if (role === ROLES.PARTNER) {
      if (location.state?.forwardTo) {
        return history.push(location.state.forwardTo);
      }
      return history.push(routesTFunction('redirectPaths./requests'));
    }
    setLoading(false);
  }, [role, history, location, routesTFunction]);

  if (isLoading) return null;

  return (
    <Container justifyContent='center' height='80vh'>
      <Spinner />
    </Container>
  );
};

export default Home;
