/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface EyeIconProps extends SVGBaseProps {}

const EyeIcon = (props: EyeIconProps) => {
  return (
    <SVGBase viewBox='0 0 24 18' {...props}>
      <g clipPath='url(#clip0)'>
        <path
          d='M11.9999 1.5668C7.73091 1.5668 3.97695 3.76073 1.79293 7.08194C0.902428 8.43612 0.902428 10.1975 1.79293 11.5517C3.97695 14.8729 7.73091 17.0667 11.9999 17.0667C16.2688 17.0667 20.0229 14.8729 22.2069 11.5517C23.0973 10.1975 23.0973 8.43612 22.2069 7.08194C20.0229 3.76073 16.2688 1.5668 11.9999 1.5668ZM11.9999 14.4835C9.14787 14.4835 6.8332 12.1688 6.8332 9.31681C6.8332 6.46481 9.14787 4.15013 11.9999 4.15013C14.8519 4.15013 17.1666 6.46481 17.1666 9.31681C17.1666 12.1688 14.8519 14.4835 11.9999 14.4835ZM11.9999 6.21681C10.2845 6.21681 8.89987 7.60148 8.89987 9.31681C8.89987 11.0321 10.2845 12.4168 11.9999 12.4168C13.7152 12.4168 15.0999 11.0321 15.0999 9.31681C15.0999 7.60148 13.7152 6.21681 11.9999 6.21681Z'
          fill='inherit'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='24' height='17.6' fill='white' />
        </clipPath>
      </defs>
    </SVGBase>
  );
};

export default EyeIcon;
