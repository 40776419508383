import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment';
import { useTranslate } from '../../hooks/useTranslate';
import { ResourceType } from '../../types';
import { getStyleInformationFromResourceType, isNotUndefined } from '../../utils/utils';
import Card from './Card';
import IconButton from './IconButton';
import { ArrowRightIcon } from './Icons';
import Skeleton from './Skeleton';
import Text from './Typography';

export interface ResourceCardProps {
  resourceType: ResourceType;
  lastUpdatedAt: moment.MomentInput;
  onClick?: (resourceType: ResourceType) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '2.5rem 3rem 2.5rem 2.5rem'
  },
  iconContainer: {
    padding: '0.5rem 0px'
  },
  resourceTypeSection: {
    padding: '0.5rem 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  isClickable: {
    cursor: 'pointer'
  },
  button: {
    background: 'none'
  }
}));

export const ResourceCardSkeleton = () => {
  const classes = useStyles();
  return (
    <Card noShadow className={clsx(classes.root)}>
      <div>
        <div className={classes.iconContainer}>
          <Skeleton height='3rem' width='3rem' />
        </div>
        <div className={classes.resourceTypeSection}>
          <Text h6 fontWeight={300} color='grey-60'>
            <Skeleton height='100%' width='15ch' />
          </Text>
          <Skeleton width='2rem' height='2rem' />
        </div>
        <div style={{ marginTop: '5px' }}>
          <Text small color='grey-60'>
            <Skeleton height='14px' width='15ch' />
          </Text>
        </div>
      </div>
    </Card>
  );
};

const ResourceCard = ({ resourceType, lastUpdatedAt, onClick }: ResourceCardProps) => {
  const theme = useTheme();
  const { Icon, color } = getStyleInformationFromResourceType(resourceType, theme);
  const { ready } = useTranslate(['resourceCard', 'resourceTypes']);
  const { t: resourceTypeTFunction } = useTranslate('resourceTypes');
  const { t } = useTranslate('resourceCard');

  const classes = useStyles();

  const handleClick = () => {
    if (isNotUndefined(onClick)) onClick(resourceType);
  };

  if (!ready) {
    return <ResourceCardSkeleton />;
  }

  return (
    <Card noShadow className={clsx(classes.root, isNotUndefined(onClick) && classes.isClickable)} onClick={handleClick}>
      <div>
        <div className={classes.iconContainer}>
          <Icon color={color} fontSize='2.2rem' />
        </div>
        <div className={classes.resourceTypeSection}>
          <Text h6 fontWeight={300} color='grey-60'>
            {resourceTypeTFunction(`sentence.${resourceType}` as any)}
          </Text>
          <IconButton
            className={classes.button}
            label={t('buttonLabel', { resourceType: resourceTypeTFunction(`lower.${resourceType}` as any) })}
          >
            <ArrowRightIcon color={(theme) => theme.palette.primary.main} />
          </IconButton>
        </div>
        <div>
          <Text small color='grey-60'>
            {t('lastUpdatedLabel')} {moment(lastUpdatedAt).fromNow()}
          </Text>
        </div>
      </div>
    </Card>
  );
};

export default ResourceCard;
