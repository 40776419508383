import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import MUIStep from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import MUIStepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Divider from './Divider';
import Icon from './Icon';
import { conditionalProps, generateKey, hexToRGB, isNotUndefined } from '../../utils/utils';

const StepConnector = withStyles(({ palette }) => ({
  alternativeLabel: {
    top: 19
  },
  line: {
    borderColor: palette.grey[90],
    margin: '0 5px',
    borderWidth: '2px'
  }
}))(MUIStepConnector);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  },
  iconStyles: {
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    cursor: 'not-allowed',
    '&[role="button"]': {
      cursor: 'pointer',
      '&:hover': {
        boxShadow: `0px 0px 4px 2px ${hexToRGB(theme.palette.primary.hover!, 0.47)};`
      }
    },
    alignItems: 'center',
    '&>i, &>svg': {
      height: 40,
      width: 40
    }
  },
  stepTitle: {
    color: theme.palette.text.main,
    width: '100%'
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

interface StepIconProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  active?: boolean;
  completed?: boolean;
}

function StepIcon(props: StepIconProps) {
  const { active, completed, role, ...others } = props;

  const classes = useStyles();
  return (
    <div className={clsx(classes.iconStyles)} role={role} {...others}>
      {active ? (
        <Icon name='radio-check-primary' />
      ) : completed ? (
        <Icon name='check-circle-green' />
      ) : (
        <Icon name='circle-grey' />
      )}
    </div>
  );
}

interface Step {
  content: React.ReactNode;
  label: string | React.ReactNode;
  disableGoingForward?: boolean;
  ariaLabel?: string;
  completed?: boolean;
}

export interface StepperProps {
  steps: Step[];
  activeStep: number;
  setActiveStep: (index: number) => void;
}

export default function CustomStepper({ steps, activeStep, setActiveStep }: StepperProps) {
  const classes = useStyles();

  const getStepContent = (step: number) => {
    if (!steps[step]) return 'Undefined step';
    return steps[step].content;
  };

  const canStepMoveToGivenIndex = (index: number) => {
    if (index < activeStep) return true;
    let canMoveForward = true;
    for (let i = 0; i < index; i++) {
      const { disableGoingForward } = steps[i];
      if (isNotUndefined(disableGoingForward) && !disableGoingForward) {
        canMoveForward = false;
        break;
      }
    }
    return canMoveForward;
  };

  const handleSetActiveStep = (index: number) => {
    if (canStepMoveToGivenIndex(index)) {
      setActiveStep(index);
    }
  };

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnector />}>
        {steps.map((step, index) => {
          return (
            <MUIStep key={generateKey('stepper-step', index)}>
              <StepLabel
                StepIconComponent={(props) => {
                  const updatedProps = {
                    ...props,
                    completed: step?.completed ? step.completed : props.completed
                  };
                  return (
                    <StepIcon
                      {...updatedProps}
                      {...conditionalProps(
                        {
                          onClick: () => {
                            handleSetActiveStep(index);
                          },
                          tabIndex: 0,
                          role: 'button',
                          'aria-disabled': !canStepMoveToGivenIndex(index),
                          'aria-label': step.ariaLabel,
                          onKeyPress: (e) => {
                            const { code } = e;
                            if (code === 'Space' || code === 'Enter') {
                              handleSetActiveStep(index);
                            }
                          }
                        },
                        canStepMoveToGivenIndex(index)
                      )}
                    />
                  );
                }}
              >
                <div className={classes.stepTitle}>{step.label}</div>
              </StepLabel>
            </MUIStep>
          );
        })}
      </Stepper>
      <Divider />

      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed - you&apos;re finished</Typography>
          </div>
        ) : (
          <div>
            <div className={classes.instructions}>{getStepContent(activeStep)}</div>
          </div>
        )}
      </div>
    </div>
  );
}
