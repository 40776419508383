import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { TFunction } from 'react-i18next';
import { isArray } from 'util';
import config from '../config';
import i18n from '../i18n';
import { isNotUndefined } from '../utils/utils';
import { LARGE_DOWNLOAD_FILE_SIZE } from '../utils/constants';

const CUSTOM_ERR_CODES = [
  // if any of these codes are present in an error response, return the whole error object
  // as it will have custom handling
  'NOT_INSTITUTION_USER',
  'EMAIL_ALREADY_INVITED',
  'NOT_EMAIL_VERIFIED',
  'NO_ACTIVE_SUBSCRIPTION'
];

type FallbackErrorMessageFunction = (t: TFunction<'errors'>) => string;

export class ApiService {
  _endpoint: string | undefined;

  _language: string | undefined;

  constructor(endpoint?: string) {
    this._endpoint = endpoint;
  }

  get endpoint() {
    return this._endpoint;
  }

  get token() {
    return window.localStorage.access_token || window.localStorage.public_access_token;
  }

  get headers() {
    const headers: any = {
      'Content-Type': 'application/json'
    };

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    if (this._language) {
      headers['Accept-Language'] = this._language;
    }

    return headers;
  }

  setLanguage(language: string) {
    this._language = language;
  }

  setEndpoint(endpoint: string) {
    this._endpoint = endpoint;
  }

  resetToken() {
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('public_access_token');
  }

  handleError(error: AxiosError, fallbackErrorMessage?: FallbackErrorMessageFunction) {
    console.log(error.response);

    const checkErrorCodeExistence = (code: string) => i18n.exists(`errors:errorCode.${code}`);
    const errorsTFunction: TFunction<'errors'> = i18n.getFixedT(i18n.language, 'errors');

    if (error.response && error.response.data) {
      if (error.response.data.error && error.response.data.error.code) {
        if (CUSTOM_ERR_CODES.includes(error.response.data.error.code)) {
          return Promise.reject(error.response.data.error);
        }

        const { code } = error.response.data.error;
        const translationKey = `errors:errorCode.${code}`;

        const doesErrorCodeTranslationExist = checkErrorCodeExistence(code);

        if (doesErrorCodeTranslationExist) {
          return Promise.reject(errorsTFunction(translationKey as any));
        }
        if (isNotUndefined(fallbackErrorMessage)) {
          return Promise.reject(fallbackErrorMessage(errorsTFunction));
        }
        return Promise.reject(errorsTFunction('errorMessages.api.generic'));
      }

      /**
       * This condition handles errors returned by auth service endpoints
       * as the error structure is different in auth service than the regular api
       */
      if (error.response.data.errors) {
        const { errors } = error.response.data;

        const translatedErrors = errors.map((err: any) => {
          const translationKey = `errors:errorCode.${err.code}`;

          if (err.message_code) {
            const translationKey = `passwordInput:messages.${err.message_code}`;
            const doesMessageCodeTransaltionExist = i18n.exists(translationKey);

            if (doesMessageCodeTransaltionExist) {
              const translatedMessage = i18n.t(translationKey);
              return { ...err, translatedMessage };
            }
          }

          const doesTranslationExist = checkErrorCodeExistence(err.code);

          if (doesTranslationExist) {
            return { ...err, translatedMessage: errorsTFunction(translationKey as any) };
          }
          return { ...err, translatedMessage: '' };
        });

        return Promise.reject(translatedErrors);
      }

      return Promise.reject(errorsTFunction('errorMessages.api.generic'));
    }
    if (error.response && error.response.status >= 500) {
      return Promise.reject(errorsTFunction('errorMessages.api.generic'));
    }
    if (isNotUndefined(fallbackErrorMessage)) {
      return Promise.reject(fallbackErrorMessage(errorsTFunction));
    }
    return Promise.reject(error.message || error);
  }

  get<T = any>(
    url: string,
    options: Omit<AxiosRequestConfig, 'method' | 'url' | 'headers'> = {},
    fallbackErrorMessage?: FallbackErrorMessageFunction
  ): Promise<T> {
    const opts: AxiosRequestConfig = {
      ...options,
      method: 'get',
      url: `${this.endpoint}${url}`,
      headers: this.headers
    };
    return axios(opts)
      .then((response) => response.data)
      .catch((err) => this.handleError(err, fallbackErrorMessage));
  }

  post<T = any>(
    url: string,
    body: any,
    headers?: AxiosRequestConfig['headers'],
    fallbackErrorMessage?: FallbackErrorMessageFunction,
    responseType?: 'json' | 'blob' | 'arraybuffer' | 'document' | 'stream'
  ) {
    return axios({
      url: `${this.endpoint}${url}`,
      method: 'post',
      data: JSON.stringify(body),
      headers: headers || this.headers,
      responseType: responseType || 'json'
    })
      .then((response: AxiosResponse<T>) => response.data)
      .catch((err) => this.handleError(err, fallbackErrorMessage));
  }

  put(url: string, body: any, fallbackErrorMessage?: FallbackErrorMessageFunction) {
    return axios({
      url: `${this.endpoint}${url}`,
      method: 'put',
      data: JSON.stringify(body),
      headers: this.headers
    })
      .then((response) => response.data)
      .catch((err) => this.handleError(err, fallbackErrorMessage));
  }

  patch<T = any>(url: string, body: any, fallbackErrorMessage?: FallbackErrorMessageFunction) {
    return axios({
      url: `${this.endpoint}${url}`,
      method: 'PATCH',
      headers: this.headers,
      data: JSON.stringify(body)
    })
      .then((response) => response.data as T)
      .catch((err) => this.handleError(err, fallbackErrorMessage));
  }

  delete(url: string, body = {}, fallbackErrorMessage?: FallbackErrorMessageFunction) {
    return axios({
      url: `${this.endpoint}${url}`,
      method: 'delete',
      data: JSON.stringify(body),
      headers: this.headers
    })
      .then((response) => response.data)
      .catch((err) => this.handleError(err, fallbackErrorMessage));
  }

  async file(url: string, data?: any, fallbackErrorMessage?: FallbackErrorMessageFunction) {
    if (data && data.file_size && data.file_size > LARGE_DOWNLOAD_FILE_SIZE) {
      const fileBuffers = [];
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < data.file_size; i += LARGE_DOWNLOAD_FILE_SIZE) {
        try {
          const res = await fetch(`${this.endpoint}${url}?start=${i}&end=${i + LARGE_DOWNLOAD_FILE_SIZE}`, {
            method: 'GET',
            headers: this.headers
          });
          const buff = await res.arrayBuffer();

          fileBuffers.push(buff);
        } catch (err) {
          return this.handleError(err as AxiosError<any>, fallbackErrorMessage);
        }
      }
      /* eslint-enable no-await-in-loop */

      return new Blob(fileBuffers);
    }

    return axios({
      url: `${this.endpoint}${url}`,
      method: 'get',
      headers: this.headers,
      responseType: 'blob'
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => this.handleError(err, fallbackErrorMessage));
  }

  upload(
    url: string,
    file?: File,
    fileField?: string,
    data = {} as any,
    method = 'POST',
    fallbackErrorMessage?: FallbackErrorMessageFunction
  ) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      const xhr = new XMLHttpRequest();

      const xhrHandleError = (err: any) => {
        const errorsTFunction: TFunction<'errors'> = i18n.getFixedT(i18n.language, 'errors');
        if (err.code) {
          const checkErrorCodeExistence = (code: string) => i18n.exists(`errors:errorCode.${code}`);
          if (checkErrorCodeExistence(err.code)) {
            reject(errorsTFunction(`errorCode.${err.code}` as any));
          }
        }
        if (isNotUndefined(fallbackErrorMessage)) {
          reject(fallbackErrorMessage(errorsTFunction));
        }
        reject(errorsTFunction('errorMessages.api.generic'));
      };

      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      if (isArray(file)) {
        file.forEach((f) => {
          formData.append(fileField!, f, f.name);
        });
      } else if (fileField && file) {
        formData.append(fileField, file, file.name);
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 300) {
            let response;
            try {
              response = JSON.parse(xhr.response);
              resolve(response);
            } catch (err) {
              resolve(xhr.response);
            }
          } else {
            const error = JSON.parse(xhr.response);
            xhrHandleError(error.error);
          }
        }
      };

      xhr.open(method, `${this.endpoint}${url}`, true);
      xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
      xhr.send(formData);
    });
  }
}

const apiService = new ApiService();

const apiEndpoint = config.API_ENDPOINT;
export const publicApiService = new ApiService(`${apiEndpoint}/public`);
export const baseApiService = new ApiService(apiEndpoint);
export default apiService;
