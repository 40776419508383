import clsx from 'clsx';
import React from 'react';
import { IconNames, IconSizes } from '../../types/common/IconTypes';

export interface IconProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  className?: string;
  size?: IconSizes;
  name?: IconNames;
  onClick?: () => void;
}

/* eslint-disable */
const Icon = ({ className, size, name, onClick, ...other }: IconProps) => (
  <i
    role={onClick ? 'button' : ''}
    className={clsx('icon', `is-${size || 'small'}`, name && `ic-${name}`, className)}
    onClick={onClick}
    onKeyPress={onClick}
    {...other}
  />
);

export default Icon;
