/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface AddIconProps extends SVGBaseProps {}

const AddIcon = (props: AddIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M14.0001 2C14.0001 0.895431 13.1047 0 12.0001 0C10.8956 0 10.0001 0.89543 10.0001 2V10H2.00011C0.895546 10 0.00012207 10.8955 0.00012207 12C0.00012207 13.1046 0.895545 14 2.00011 14H10.0001V22C10.0001 23.1046 10.8956 24 12.0001 24C13.1047 24 14.0001 23.1046 14.0001 22V14H22C23.1045 14 23.9999 13.1046 23.9999 12C23.9999 10.8955 23.1045 10 22 10H14.0001V2Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default AddIcon;
