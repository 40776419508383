/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface DownloadFileIconProps extends SVGBaseProps {}

const DownloadFileIcon = (props: DownloadFileIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M22 14.7778V19.0371C22 19.6019 21.7659 20.1435 21.3491 20.5429C20.9324 20.9423 20.3671 21.1667 19.7778 21.1667H4.22222C3.63285 21.1667 3.06762 20.9423 2.65087 20.5429C2.23413 20.1435 2 19.6019 2 19.0371V14.7778'
        stroke='#95919C'
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.44507 9.45371L12.0006 14.7778L17.5562 9.45371'
        stroke='#95919C'
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12.0012 14.7778V2' stroke='#95919C' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
    </SVGBase>
  );
};

export default DownloadFileIcon;
