import { useMemo } from 'react';
import { Grow, makeStyles } from '@material-ui/core';
import {
  Item as PrimitiveRadioItem,
  RadioGroupItemProps as PrimitiveRadioGroupItemProps,
  Indicator
} from '@radix-ui/react-radio-group';
import clsx from 'clsx';
import Icon from './Icon';
import { generateRandomId } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '1rem',
    '&:hover': {
      '&>span:first-of-type': {
        border: `1px solid ${theme.palette.forestGreen.main}`,
        boxShadow: '0px 0px 4px 2px rgba(26, 205, 162, 0.47);'
      }
    }
  },
  input: {
    opacity: 0,
    position: 'absolute',
    cursor: 'pointer'
  },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    width: 26,
    height: 26,
    justifyContent: 'center',
    border: `1px solid #E6E4EB`,
    borderRadius: '50%',
    '&:focus,&:hover,&:focus-within': {
      border: `1px solid ${theme.palette.forestGreen.main}`,
      boxShadow: '0px 0px 4px 2px rgba(26, 205, 162, 0.47);'
    },
    '&>i': {
      width: 26,
      height: 26
    }
  },
  label: {
    color: theme.palette.text.main,
    marginLeft: '1rem'
  }
}));

export interface CustomRadioProps extends PrimitiveRadioGroupItemProps {
  label: string;
}

const CustomRadio = ({ checked, disabled, className, label, ...other }: CustomRadioProps) => {
  const classes = useStyles();
  const id = useMemo(() => generateRandomId('radio'), []);

  return (
    <div className={classes.root}>
      <PrimitiveRadioItem id={id} disabled={disabled} className={clsx(classes.iconButton, className)} {...other}>
        {checked && (
          <Indicator className={classes.iconButton}>
            <Grow in={checked} timeout={200}>
              <Icon name='check-filled' />
            </Grow>
          </Indicator>
        )}
      </PrimitiveRadioItem>
      <label className={clsx(classes.label)} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default CustomRadio;
