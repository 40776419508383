import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryClient, useQuery } from 'react-query';
import { getTokenExpirationDate, getEmailFromJWT, getRoleFromJWT } from '../utils/jwt';
import apiService, { ApiService } from '../services/api-service';
import Modal from '../components/shared/Modal';
import config from '../config';
import Button from '../components/shared/Button';
import { ROLES } from '../utils/roles';
import { getRole, loggedIn, isNotUndefined } from '../utils/utils';
import { AuthContextType, User, UserBasedAccessControlCondition, InstitutionPaymentPlan } from '../types';
import { INSTITUTION_USER_QUERY_KEY, SUBSCRIPTION_STATUSES, USER_INSTITUTION_ROLES } from '../utils/constants';
import Container from '../components/shared/Container';
import Text from '../components/shared/Typography';
import DropdownInput from '../components/shared/DropdownInput';
import Spinner from '../components/shared/Spinner';
import { TranslationResourceNameSpace, useTranslate, useLoadTranslation } from '../hooks/useTranslate';
import { useRoutes } from '../hooks/useRoutes';
import { USER_ACTIONS } from '../utils/rbac';
import { clearCache } from '../hooks/useQuery';

const AuthContext = React.createContext<AuthContextType>({
  login: () => Promise.reject('The component is not wrapped inside AuthProvider'),
  isLoggedIn: false,
  signup: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  },
  logout: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  },
  enable2fa: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  },
  validate2fa: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  },
  resetPassword: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  },
  validatePasswordReset: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  },
  updatePassword: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  },
  verifyEmail: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  },
  requestVerificationEmail: () => {
    return Promise.reject('The component is not wrapped inside AuthProvider');
  },
  isEmailVerified: false,
  hasInstitution: false,
  hasSubscription: false,
  hasPaymentPlan: false,
  setHasSubscription: () => {},
  setUserInformation: () => {},
  setIsVerified: () => {},
  updateUserEmailNotif: () => {},
  isManager: false,
  isMember: false,
  isOwner: false,
  isScopeEnabled: () => false
});

type AuthProviderProps = React.PropsWithChildren<Record<string, unknown>>;

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(loggedIn());
  const [isEmailVerified, setEmailVerified] = useState(false);
  const [hasInstitution, setHasInstitution] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [userInfo, setUserInfo] = useState<User>();
  const [paymentPlan, setPaymentPlan] = useState<InstitutionPaymentPlan>();
  const [userBasedAccessControlConditions, setUserBasedAccessControlConditions] = useState<
    UserBasedAccessControlCondition[]
  >([]);
  /**
   * userRole is the role we want the user to have while using the application
   * Only makes a difference when ADMIN role user is logged in which case,
   * the ADMIN user can toggle which type of role they want to continue the app as
   * For regular users this would be equal to the roleFromJWT value.
   */
  const [userRole, setUserRole] = useState<keyof typeof ROLES>();
  const [scopes, setScopes] = useState<string[]>();
  const [showRoleSelectionScreen, setshowRoleSelectionScreen] = useState(false);
  const { ready, isInitialized } = useTranslate(['navbar', 'login', 'signup']);
  const { loadTranslationsAsync } = useLoadTranslation();

  const { routesTFunction } = useRoutes();

  const queryClient = useQueryClient();

  const history = useHistory();

  const tokenRenewalTimeout = useRef<number>();
  const activityTimeout = useRef<number>();
  const continueTimeout = useRef<number>();
  const active = useRef(true);

  const authApiEndpoint = config.AUTH_ENDPOINT;
  const apiEndpoint = config.API_ENDPOINT;
  const authApiService = new ApiService(`${authApiEndpoint}/api`);
  const roleFromJWT = getRole(); //This is the role from the JWT
  const onboardingService = new ApiService(`${apiEndpoint}/onboarding`);
  const baseApiService = new ApiService(apiEndpoint);

  const UNBLOCKED_SUBSCRIPTION_STATUSES = SUBSCRIPTION_STATUSES.ACTIVE.concat(SUBSCRIPTION_STATUSES.ALERT);

  const setEndpointFromRole = () => {
    const apiEndpoint = config.API_ENDPOINT;
    const apiServiceEndpoint = apiEndpoint + '/partner';
    apiService.setEndpoint(apiServiceEndpoint);
  };

  useEffect(() => {
    if (userInfo) {
      const allConditions: UserBasedAccessControlCondition[] = [];
      allConditions.push({
        isBlocked: !isOwner,
        actions: [USER_ACTIONS.VIEW.REQUEST_DASHBOARD_PAGE_TABS.INCOMING_REQUESTS]
      });
      allConditions.push({
        isBlocked: !isOwner && !isManager,
        actions: [USER_ACTIONS.VIEW.REQUEST_DASHBOARD_PAGE_TABS.ALL_REQUESTS]
      });
      allConditions.push({
        isBlocked: false,
        actions: [USER_ACTIONS.CLICK.SUBMIT_NEW_REQUEST_BUTTON]
      });
      setUserBasedAccessControlConditions(allConditions);
    }
  }, [userInfo, isOwner, isManager]);

  useEffect(() => {
    const status = loggedIn();
    setLoggedIn(status);
  }, []);

  useEffect(() => {
    if (roleFromJWT === ROLES.ADMIN) {
      setUserRole(ROLES.PARTNER as keyof typeof ROLES);
    } else {
      setUserRole(roleFromJWT as keyof typeof ROLES);
    }
  }, [roleFromJWT]);

  useEffect(() => {
    if (!userRole) return;
    setEndpointFromRole();
  }, [userRole]);

  const setUserInformation = (user: User) => {
    if (user) {
      setUserInfo(user);
      setEmailVerified(!!user.email_verified);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      const namespacesToLoad: TranslationResourceNameSpace = ['settingsOverviewTab', 'settingsTabs'];
      namespacesToLoad.push('createNewRequest', 'requestStatistics', 'requestTable', 'tutorialList');
      if (userRole === 'PARTNER') {
        const physicianNameSpaces: TranslationResourceNameSpace = [
          'clientAndRequestTabs',
          'clientStatistics',
          'clientTable'
        ];
        namespacesToLoad.push(...physicianNameSpaces);
      }

      loadTranslationsAsync(namespacesToLoad);
    }
  }, [isLoggedIn, loadTranslationsAsync, userRole]);

  const fetchInstitutionUser = async () => {
    try {
      setEndpointFromRole();
      const res = await apiService.get('/user?include=institutions,subscriptions,avatar').then((response) => {
        if (response.user) {
          const { user } = response;
          setEmailVerified(!!user.email_verified);
          const newScopes = user?.institution?.scopes?.map((scope: { scope: any }) => scope.scope);
          setScopes(newScopes);
          if (user.institution && user.institution.id) {
            setHasInstitution(true);
          } else {
            setHasInstitution(false);
          }
          if (
            user.email.includes('dothealth.ca') ||
            user.institution?.demo ||
            (user.customers &&
              user.customers.length &&
              user.customers[0].subscription &&
              UNBLOCKED_SUBSCRIPTION_STATUSES.includes(user.customers[0].subscription.status))
          ) {
            setHasSubscription(true);
          } else {
            setHasSubscription(false);
          }
          if (user?.institution?.user_institution?.role === USER_INSTITUTION_ROLES.manager) {
            setIsManager(true);
          }

          if (user?.institution?.user_institution?.role === USER_INSTITUTION_ROLES.owner) {
            setIsOwner(true);
          }
          if (user?.institution?.user_institution?.role === USER_INSTITUTION_ROLES.member) {
            setIsMember(true);
          }
          setUserInfo(user);

          const paymentPlan = user?.institution?.payment_plan;

          if (paymentPlan) {
            setPaymentPlan(paymentPlan);
          }
        }
      });

      return res;
    } catch (error) {
      console.error(error);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      logout();
    }
  };

  const { refetch } = useQuery(INSTITUTION_USER_QUERY_KEY, fetchInstitutionUser, {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: isLoggedIn
  });

  useEffect(() => {
    if (isLoggedIn) {
      refetch();
    } else {
      setUserInfo(undefined);
      setEmailVerified(false);
      setHasInstitution(false);
      setHasSubscription(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    scheduleRenewal();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    scheduleActivityCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  interface AuthResult {
    access_token: string;
    refresh_token: string;
  }

  const setSession = (authResult: AuthResult) => {
    if (authResult.access_token) {
      localStorage.setItem('email', getEmailFromJWT(authResult.access_token));
      localStorage.setItem('access_token', authResult.access_token);
      localStorage.setItem('refresh_token', authResult.refresh_token);
      localStorage.setItem('expires_at', getTokenExpirationDate(authResult.access_token)!.valueOf().toString());
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      scheduleRenewal();
      setLoggedIn(true);
    }
  };

  const clearSession = () => {
    localStorage.removeItem('email');
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user_email');
    if (tokenRenewalTimeout.current) {
      clearTimeout(tokenRenewalTimeout.current);
    }
    setShowModal(false);
    setLoggedIn(false);
    queryClient.clear();
    clearCache();
  };

  const activityCheck = () => {
    active.current = false;
  };

  const startActivityTimer = () => {
    activityTimeout.current = window.setTimeout(activityCheck, 60 * 1000);
  };

  const resetTimer = () => {
    if (!active.current) {
      active.current = true;
    }
    window.clearTimeout(activityTimeout.current);
    startActivityTimer();
  };

  const scheduleActivityCheck = () => {
    document.addEventListener('mousemove', resetTimer, false);
    document.addEventListener('mousedown', resetTimer, false);
    document.addEventListener('keypress', resetTimer, false);
    document.addEventListener('scroll', resetTimer, false);
    window.addEventListener('storage', (event) => {
      if (event.key === 'access_token' && event.newValue && event.newValue !== event.oldValue) {
        resetTimer();
      }
    });
    startActivityTimer();
  };

  const scheduleRenewal = () => {
    if (tokenRenewalTimeout.current) {
      clearTimeout(tokenRenewalTimeout.current);
    }
    const expiresAt = localStorage.getItem('expires_at');
    if (expiresAt) {
      try {
        const delay = JSON.parse(expiresAt) - Date.now() - 40 * 1000;
        if (delay > 0) {
          tokenRenewalTimeout.current = window.setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            renewToken();
          }, delay);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const login = (username: string, password: string, tfa_token: string): Promise<any> => {
    const data = {
      user: {
        email: username,
        password
      },
      refresh_token: true,
      tfa_token: ''
    };
    if (tfa_token && tfa_token.length > 0) {
      data.tfa_token = tfa_token;
    }
    return authApiService.post('/authenticate', data).then((authResult) => {
      const role = getRoleFromJWT(authResult.access_token);
      if (!ROLES[role as keyof typeof ROLES])
        return Promise.reject([
          {
            message:
              // eslint-disable-next-line quotes,max-len
              "Sorry, you aren't authorized to use this application. If this is a mistake, please speak to your administrator."
          }
        ]);
      setSession(authResult);
      return authResult;
    });
  };

  const signup = (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    invitationToken: string
  ): Promise<any> => {
    const data = {
      user: {
        email,
        password,
        partner_type: 'partner'
      }
    };
    // Verify the invitation token before sending registration request
    return baseApiService.get(`/invitation/${invitationToken}`).then((res) => {
      if (res.invitation) {
        return authApiService
          .post('/signup', data)
          .then((authResult) => {
            return authResult;
          })
          .then((createAuthUserResult) => {
            // Now create the API user
            if (createAuthUserResult.access_token) {
              // Use custom headers because we haven't stored access token in our session yet
              // (we must wait for the user to be fully created before the app tries to use the access token)
              const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${createAuthUserResult.access_token}`
              };
              return onboardingService
                .post(
                  '/create_partner_user',
                  {
                    user: { email, first_name: firstName, last_name: lastName },
                    invitationToken
                  },
                  headers
                )
                .then(() => authApiService.post('/verify-link', {}, headers))
                .then(() => {
                  setSession(createAuthUserResult);
                  return createAuthUserResult;
                });
            }
            return {};
          });
      }
      return {};
    });
  };

  const resetPassword = (email: string) => {
    return authApiService.post(
      '/reset-password',
      {
        user: { email },
        redirect_url: config.RESET_PW_REDIRECT
      },
      undefined,
      (t) => t('errorMessages.auth.resetPassword')
    );
  };

  const validatePasswordReset = (token: string, query: string) => {
    return authApiService.get(`/update-password/${token}${query}`);
  };

  const updatePassword = (password: string, token: string, query: string) => {
    const [, email] = query.split('=');
    return authApiService
      .put(`/update-password/${token}`, {
        user: { password, email }
      })
      .then(() => {
        history.push(routesTFunction('redirectPaths./login'));
      });
  };

  const verifyEmail = (token: string) => {
    return baseApiService.get(`/verify/${token}`);
  };

  const requestVerificationEmail = () => {
    return authApiService.post('/verify-link', {});
  };

  const logout = () => {
    return baseApiService
      .get('/logout')
      .catch((err) => console.log(err))
      .finally(() => clearSession());
  };

  const enable2fa = (email: string, password: string) => {
    return authApiService.post('/enable-2fa', { user: { email, password } });
  };

  const validate2fa = (token: string, email: string, password: string) => {
    return authApiService.post('/2fa', {
      tfa_token: token,
      user: {
        email,
        password
      }
    });
  };

  const renewToken = () => {
    if (tokenRenewalTimeout.current) {
      clearTimeout(tokenRenewalTimeout.current);
    }
    if (continueTimeout.current) clearTimeout(continueTimeout.current);
    if (active.current) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getNewToken();
    } else {
      continueTimeout.current = window.setTimeout(() => {
        history.push(`${routesTFunction('redirectPaths./logout')}?prev=${window.location.pathname}`);
      }, 60 * 1000);
      setShowModal(true);
    }
  };

  const onModalSubmit = () => {
    if (continueTimeout.current) {
      window.clearTimeout(continueTimeout.current);
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getNewToken();
    setShowModal(false);
  };

  const getNewToken = () => {
    return authApiService
      .post('/token', {
        refresh_token: localStorage.getItem('refresh_token')
      })
      .then((authResult) => {
        setSession(authResult);
      })
      .catch(() => {
        logout();
      });
  };

  const isScopeEnabled = (scope: string) => {
    if (scopes && scopes.length > 0) {
      return scopes.includes(scope);
    }

    return false;
  };

  if ((isLoggedIn && !userInfo) || !ready || !isInitialized) {
    return (
      <Container height='100vh' width='100vw' isFlex style={{ alignItems: 'center' }} justifyContent='center'>
        <Spinner />
      </Container>
    );
  }

  if (showRoleSelectionScreen && isLoggedIn) {
    return (
      <Container height='100vh' width='100vw' justifyContent='center' isFlex style={{ alignItems: 'center' }}>
        <Container justifyContent='center'>
          <Text h1 align='center'>
            Please select a role you want to view the application as
          </Text>
          <Container justifyContent='center' margin='4rem'>
            <DropdownInput
              name='role'
              options={[
                ...Object.keys(ROLES)
                  .map((key) => ({
                    value: key,
                    label: ROLES[key as keyof typeof ROLES]
                  }))
                  .filter((values) => values.label !== ROLES.ADMIN)
              ]}
              onChange={(e) => {
                setUserRole(e.target.value as keyof typeof ROLES);
              }}
              value={userRole!}
            />
            {userRole && (
              <Button label={`Continue the app as ${userRole}`} onClick={() => setshowRoleSelectionScreen(false)} />
            )}
          </Container>
        </Container>
      </Container>
    );
  }

  const updateUserEmailNotif = (updatedEmailNotif: boolean) => {
    const updatedUserInfo = { ...userInfo };

    if (updatedUserInfo && updatedUserInfo.institution && updatedUserInfo.institution.user_institution) {
      updatedUserInfo.institution.user_institution.email_notifications = updatedEmailNotif;
    }

    setUserInfo(updatedUserInfo as User);
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        isLoggedIn,
        signup,
        role: userRole,
        scopes,
        logout,
        userInfo,
        paymentPlan,
        hasPaymentPlan: isNotUndefined(paymentPlan),
        enable2fa,
        validate2fa,
        resetPassword,
        validatePasswordReset,
        updatePassword,
        verifyEmail,
        requestVerificationEmail,
        isEmailVerified,
        hasInstitution,
        hasSubscription,
        setHasSubscription,
        setUserInformation,
        setIsVerified: setEmailVerified,
        userBasedAccessControlConditions,
        updateUserEmailNotif: updateUserEmailNotif,
        isManager,
        isOwner,
        isMember,
        isScopeEnabled
      }}
    >
      <Modal open={showModal} onClose={onModalSubmit}>
        <div className='flex space-around flex-column'>
          <h2>Click continue to prevent auto-logout</h2>
          <br />
          <Button onClick={onModalSubmit} label='Continue' />
        </div>
      </Modal>

      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
