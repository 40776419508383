export const SCOPES = {
  TREATMENT: 'treatment',
  PROVIDER: 'provider',
  SERVICE: 'service',
  OPERATION: 'operation',
  PORTAL: 'portal',
  ARCHIVE: 'archive',
  MINOR_FLOW: 'minor_flow',
  FRENCH_REQUEST: 'french_request',
  ASSIGN_INCOMING: 'assign_incoming'
};
