import moment from 'moment';
import { Collapse } from '@material-ui/core/';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from '../../hooks/useTranslate';
import Container from './Container';
import Text from './Typography';
import { AuthContext } from '../../contexts/auth-context';
import { NUM_DAYS_AFTER_NOTIFICATION_TO_DISABLE_PLAN } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '12px',
    padding: '16px 21px',
    width: '100%'
  },
  errorBanner: {
    background: theme.palette.error.main
  },
  warningBanner: {
    background: '#FF5722'
  }
}));

const FundErrorBanner = () => {
  const classes = useStyles();
  const { t: tDates } = useTranslate('dates');
  const { t, ready } = useTranslate('billing');
  const { paymentPlan } = useContext(AuthContext);
  const [message, setMessage] = useState('');
  const [showBanner, setShowBanner] = useState(false);
  const [style, setStyle] = useState<'error' | 'warning'>('error');

  useEffect(() => {
    if (paymentPlan && ready) {
      const balance = parseFloat(paymentPlan?.balance);
      const minBalance = parseFloat(paymentPlan?.minimum_balance);
      const amountPending = parseFloat(paymentPlan?.amount_pending);
      const pendingRequestCharge = parseFloat(paymentPlan?.pending_request_charge);
      const triggerBalance = parseFloat(paymentPlan?.notification_trigger_balance);

      const availableBalance = balance - amountPending || 0;
      const availableBalanceBelowTrigger = triggerBalance > availableBalance;
      const estimatedRequestLeft = Math.round((availableBalance - minBalance) / pendingRequestCharge);

      if (paymentPlan.disabled) {
        setShowBanner(true);
        setMessage(t('insufficientFundMessage'));
        return;
      }

      // Show a warning banner when available balance is below our treshold
      if (availableBalanceBelowTrigger) {
        setStyle('warning');
        setShowBanner(true);

        // If the estimated request left is above 0.
        // We show how many request the institution can make before getting locked
        if (estimatedRequestLeft > 0) {
          setMessage(t('fundsWarningRequestLeft', { estimated_request_left: estimatedRequestLeft }));
          return;
        }

        // If the estimated request left is < 0 and we have a first notification date
        // we show the date where they are locked out instead.
        if (estimatedRequestLeft <= 0 && paymentPlan.first_notification_sent_at) {
          let daysTillDisabled = NUM_DAYS_AFTER_NOTIFICATION_TO_DISABLE_PLAN;
          if (moment(paymentPlan.first_notification_sent_at).isoWeekday() === 5) {
            daysTillDisabled += 1;
          }
          const localizedDateFormat = tDates(`${'MMM DD, YYYY'}` as any, { nsSeparator: '|' });
          const lockedDate = moment(paymentPlan.first_notification_sent_at)
            .add(daysTillDisabled, 'days')
            .format(localizedDateFormat);

          setMessage(t('fundsWarningLockedDate', { date: lockedDate }));
          return;
        }

        // We have a default warning message in case both are unavailable
        setMessage(t('fundsWarning'));
      }
    }
  }, [paymentPlan, t, tDates, ready]);

  if (!ready) return null;

  return (
    <Collapse in={showBanner}>
      <Container
        className={clsx(classes.container, style === 'warning' ? classes.warningBanner : classes.errorBanner)}
        padding='3rem 0px'
        margin='2rem 0px 0px'
        justifyContent='center'
      >
        <Text paragraph align='center' color='white' icon='warning-circle-white'>
          {message}
        </Text>
      </Container>
    </Collapse>
  );
};

export default FundErrorBanner;
