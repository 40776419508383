import clsx from 'clsx';

// Material UI
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  logoImage: {
    verticalAlign: 'middle',
    objectFit: 'cover',
    borderRadius: '50%',

    '&.is-large': {
      width: '50px',
      height: '50px'
    },
    '&.is-regular': {
      width: '33px',
      height: '33px'
    }
  }
}));

export type InstitutionLogoSizes = 'regular' | 'large';

interface InstitutionLogoProps {
  src?: string;
  size?: InstitutionLogoSizes;
}

const InstitutionLogo = ({ src, size = 'regular' }: InstitutionLogoProps) => {
  const classes = useStyles();

  return <>{src && <img src={src} alt='Institution Logo' className={clsx(classes.logoImage, `is-${size}`)} />}</>;
};

export default InstitutionLogo;
