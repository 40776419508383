export const AllIconNames = [
  'check-filled',
  'check-unfilled',
  'followup-flag',
  'help',
  'allergies',
  'appt',
  'history',
  'intake',
  'er',
  'patient',
  'refer',
  'check--green',
  'rx',
  'record',
  'clinical',
  'imaging',
  'lab',
  'img',
  'pdf',
  'rotate is-purple',
  'rotate is-white',
  'txt',
  'steth',
  'canada',
  'lock-open',
  'lock-mid',
  'lock-closed',
  'lock-success',
  'cal',
  'file',
  'pin',
  'pending',
  'face',
  'noun-face',
  'rosetta-neutral',
  'camera-grey',
  'camera--purple',
  'id',
  'warning',
  'alert',
  'error',
  'location',
  'check',
  'check-green',
  'close-filled',
  'close-gray',
  'close-red',
  'ellipse',
  'eye-open',
  'eye-open-white',
  'eye-open-orange',
  'eye-close',
  'eye-closed',
  'mobile',
  'desktop',
  'camera',
  'id-shield',
  'shopping-cart',
  'download is-purple',
  'download is-white',
  'arrow-forward is-purple',
  'arrow-forward is-white',
  'arrow-back is-purple',
  'arrow-back is-white',
  'arrow-down',
  'zoom-in is-purple',
  'zoom-in is-white',
  'zoom-out is-purple',
  'zoom-out is-white',
  'search',
  'hello',
  'profile-illo',
  'star',
  'add',
  'profile-add',
  'building',
  'green-lock',
  'membership-star',
  'green-arrow-share',
  'checkbox-outline-grey',
  'checkbox-primary',
  'download-cloud',
  'arrow-forward-green',
  'check-circle-green',
  'circle-grey',
  'radio-check-primary',
  'sent-white',
  'processing-white',
  'check-circle-white',
  'dot-health-icon-circle',
  'sun-life-financial ',
  'star-green',
  'lock-green',
  'sync',
  'copy',
  'union',
  'edit-purple',
  'caret-down',
  'caret-right',
  'search-grey',
  'search-primary',
  'processing-purple',
  'sent-blue',
  'check-purple',
  'slider-plus',
  'slider-minus',
  'success-snackbar',
  'progress-snackbar',
  'error-snackbar',
  'enterprise',
  'warning-circle-white',
  'close-circle-white',
  'warning-yellow',
  'warning-circle-red',
  'warning-white',
  'close-white',
  'check-white',
  'log-out',
  'profile-add-mint',
  'add-mint-circle-purple',
  'bell-purple',
  'gear-grey',
  'archive-white',
  'archive-blue',
  'archive-circle-white',
  'archive-stroke-blue',
  'empty-avatar',
  'file-purple',
  'clock-white',
  'clock-stroke',
  'checkmark-green-circle',
  'primary-triangle',
  'resource-green',
  'broken-image'
] as const;

export type IconNames = typeof AllIconNames[number];

export type IconSizes = 'x-small' | 'small' | 'regular' | 'medium' | 'large' | 'x-large' | 'xx-large';
