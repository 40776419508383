/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface PatientVisitIconProps extends SVGBaseProps {}

const PatientVisitIcon = (props: PatientVisitIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6287 0C6.87057 0 3 3.87057 3 8.62866C3 13.7689 8.44838 20.8567 10.6918 23.5562C11.1849 24.1479 12.0847 24.1479 12.5778 23.5562C14.8089 20.8567 20.2573 13.7689 20.2573 8.62866C20.2573 3.87057 16.3867 0 11.6287 0ZM11.6287 2.46533C12.9846 2.46533 14.094 3.57473 14.094 4.93066C14.094 6.29892 12.9846 7.39599 11.6287 7.39599C10.2727 7.39599 9.16333 6.29892 9.16333 4.93066C9.16333 3.57473 10.2727 2.46533 11.6287 2.46533ZM11.6287 14.792C9.57011 14.792 7.75809 13.7442 6.698 12.1418C6.72265 10.5146 9.98921 9.61479 11.6287 9.61479C13.2681 9.61479 16.5347 10.5146 16.5593 12.1418C15.4992 13.7442 13.6872 14.792 11.6287 14.792Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default PatientVisitIcon;
