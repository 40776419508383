import { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/';

import { SubmitFunction, useForm, ValidationErrors } from '../../../hooks/useForm';
import { useCreatePatient, UseCreatePatientRequest } from '../../../request-hooks/partners/usePartnerRequests';

import { useTranslate } from '../../../hooks/useTranslate';
import useQuery, { useMutation } from '../../../hooks/useQuery';
import { useUpdatePatientInformation } from '../../../request-hooks/shared/useSharedRequest';

import Container from '../Container';
import Button from '../Button';
import Input from '../Input';
import Spinner from '../Spinner';
import Text from '../Typography';
import DropdownInput from '../DropdownInput';
import ButtonText from '../ButtonText';
import Divider from '../Divider';

import {
  isValidHCN,
  isValidEmail,
  isValidPhoneNumber,
  isNotUndefined,
  deepEqual,
  hexToRGB,
  isValidPostalCode,
  generateKey,
  provinces,
  Provinces,
  EXAMPLE_HCNS,
  getTitleCase,
  removeNullUndefined,
  isDependant,
  formatPhone
} from '../../../utils/utils';
import { ClientUpload, PartnerClient } from '../../../types';
import { MAX_CLIENT_BIRTH_DATE, MIN_CLIENT_BIRTH_DATE } from '../../../utils/constants';
import { ClientFormProps, PatientFormInitialState } from './ICreateClientForm';
import {
  createPatientFormState,
  createPartnerClient,
  createRepresentativeFormState,
  getFormattedClientFromForm,
  getGuardianFromData,
  getRepresentativeFromForm,
  resetGuardianFields,
  shouldUpdateClient
} from './ClientFormUtil';
import { SnackbarContext } from '../../../contexts/snackbar-context';
import DateInputGroup from '../Input/DateInputGroup';
import ClientSelect from '../ClientSelect';

const provincesForDropDown = ['', ...provinces];

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    margin: '3rem 0px',
    '&>label': {
      [theme.breakpoints.up('tablet')]: {
        width: '80%',
        margin: '1.2rem auto'
      }
    },
    [theme.breakpoints.up('widescreen')]: {
      maxWidth: '782px'
    }
  },
  width100: {
    width: '100%'
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  dateInputRow: {
    marginBottom: '24px',
    [theme.breakpoints.down('md')]: {
      gap: '30px',
      flexDirection: 'column'
    }
  },
  inputWrapper: {
    width: '46%'
  },
  addressInputWrapper: {
    width: '50%'
  },
  input: {
    padding: '13px 24px'
  },
  noInputBorder: {
    borderBottom: `2px solid ${theme.palette.grey['80']}`,
    '&:focus': {
      color: theme.palette.text.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    '&:disabled': {
      borderRadius: '1.6rem',
      fontWeight: 300,
      borderWidth: '1px'
    },
    '&::placeholder': {
      color: hexToRGB(theme.palette.text.main, 0.3)
    }
  },
  editModeDisabled: {
    border: 'none'
  },
  guardianDivider: {
    margin: '40px 0'
  }
}));

const ClientForm: React.FC<ClientFormProps> = ({
  noInputBorder,
  onCreate,
  onSubmitSuccess,
  editMode,
  patientDetails,
  submitText,
  isMinorClient,
  guardianDetails
}) => {
  const classes = useStyles();
  const { t, ready } = useTranslate('manualCreatePatient');
  const { t: errorsTFunction } = useTranslate('errors');
  const [editGuardian, setEditGuardian] = useState(false);
  const [isGuardianFormOpen, setIsGuardianFormOpen] = useState(false);
  const { isLoading, createPatients, errorWhileCreatingPatients, response } = useCreatePatient();
  const { updatePatientInformation, isUpdatingPatientInformation } = useUpdatePatientInformation(
    Number(patientDetails?.id)
  );

  const {
    loading: isLoadingCreateGuardian,
    error: errorWhileCreatingGuardian,
    makeRequest: createGuardian
  } = useQuery<UseCreatePatientRequest>('/user', 'POST');

  const { makeRequest: updateGuardian, loading: isUpdatingGuardianInformation } = useMutation('PUT');

  const { t: provinceTFunction, ready: isProvinceTranslationReady } = useTranslate('provinces');

  const mappedProvinces = useMemo(() => {
    if (isProvinceTranslationReady) {
      return provincesForDropDown.map((province) => {
        if (!province) return { label: '', value: '' };
        return {
          value: province,
          label: provinceTFunction(`${province}` as any)
        };
      });
    }
    return [{ label: '', value: '' }];
  }, [provinceTFunction, isProvinceTranslationReady]);

  const submitClient = (values: typeof PatientFormInitialState, guardianId?: string) => {
    const representative = getRepresentativeFromForm(values, guardianId);

    if (editMode && patientDetails) {
      const updatedClientData = getFormattedClientFromForm({
        values: values,
        isMinorClient: isMinorClient,
        guardianId: guardianId
      });

      if (shouldUpdateClient(values, patientDetails, guardianDetails)) {
        updatePatientInformation(updatedClientData).then((res) => {
          if (isNotUndefined(onSubmitSuccess)) {
            const PartnerClient = createPartnerClient(res, values);
            if (isMinorClient && guardianId) {
              onSubmitSuccess(PartnerClient, true, representative);
            } else {
              onSubmitSuccess(PartnerClient, true);
            }
          }
        });
      } else {
        // eslint-disable-next-line no-lonely-if
        if (isNotUndefined(onSubmitSuccess)) {
          const PartnerClient = createPartnerClient(updatedClientData, values);
          if (isMinorClient && guardianId) {
            onSubmitSuccess(PartnerClient, false, representative);
          } else {
            onSubmitSuccess(PartnerClient, false);
          }
        }
      }
    } else {
      createPatients(
        removeNullUndefined({
          user: {
            ...values,
            email: isMinorClient ? undefined : values.email,
            phone: isMinorClient ? undefined : values.phone_number,
            RepresentativeId: isMinorClient ? guardianId : null,
            representativeRelationship: isMinorClient ? values.representativeRelationship : null
          }
        })
      ).then((res) => {
        if (isNotUndefined(onSubmitSuccess)) {
          const PartnerClient = createPartnerClient(res, values);
          if (isMinorClient && guardianId) {
            onSubmitSuccess(PartnerClient, true, representative);
          } else {
            onSubmitSuccess(PartnerClient, true);
          }
        }
      });
    }
  };

  const { t: createNewRequestT } = useTranslate('createNewRequest');
  const { addNotification } = useContext(SnackbarContext);

  const onSubmit: SubmitFunction<typeof PatientFormInitialState> = async (values: typeof PatientFormInitialState) => {
    const {
      guardian_email: guardianEmail,
      guardian_first_name: guardianFirstName,
      guardian_last_name: guardianLastName,
      guardian_phone_number: guardianPhoneNumber,
      RepresentativeId: guardianId
    } = values;

    const updatedGuardianClient = {
      id: guardianId,
      first_name: getTitleCase(guardianFirstName),
      last_name: getTitleCase(guardianLastName),
      email: guardianEmail,
      phone_number: guardianPhoneNumber
    };

    const originalGuardianClient = {
      id: guardianId,
      first_name: guardianDetails?.guardian_first_name,
      last_name: guardianDetails?.guardian_last_name,
      email: guardianDetails?.guardian_email,
      phone_number: guardianDetails?.guardian_phone_number
    };

    const guardianFullName = `${updatedGuardianClient.first_name} ${updatedGuardianClient.last_name}`;

    if (isMinorClient) {
      if (editGuardian) {
        if (deepEqual(originalGuardianClient, updatedGuardianClient)) {
          submitClient(values, guardianId);
        } else {
          updateGuardian(`/users/${guardianId}`, { user: updatedGuardianClient }).then(() => {
            addNotification(
              createNewRequestT('physicians.clientInformationStep.editSuccess', { name: guardianFullName }),
              'success'
            );
            submitClient(values, guardianId);
          });
        }
      } else {
        createGuardian({
          user: updatedGuardianClient as unknown as ClientUpload
        }).then((response) => {
          addNotification(
            createNewRequestT('physicians.clientInformationStep.createSuccess', { name: guardianFullName }),
            'success'
          );
          if (response && response.user && response.user.id) {
            submitClient(values, response.user && response.user.id);
            setValue('RepresentativeId', response.user.id);
            setEditGuardian(true);
          }
        });
      }
    } else {
      submitClient(values, guardianId);
    }
  };

  const { getFieldProps, handleSubmit, setValue, setValues, values, validateField, resetErrors, resetForm } = useForm(
    PatientFormInitialState,
    {
      validate: (values) => {
        const errors: ValidationErrors<typeof PatientFormInitialState> = {};

        if (isMinorClient) {
          if (!isGuardianFormOpen) {
            setIsGuardianFormOpen(true);
          }
          if (!isValidEmail(values.guardian_email)) {
            errors.guardian_email = t('errors.invalidEmail');
          }
          if (!values.guardian_phone_number || !isValidPhoneNumber(values.guardian_phone_number)) {
            errors.guardian_phone_number = t('errors.invalidPhoneNumber');
          }
          if (!values.guardian_first_name) {
            errors.guardian_first_name = t('errors.invalidFirstName');
          }
          if (!values.guardian_last_name) {
            errors.guardian_last_name = t('errors.invalidLastName');
          }
          if (!values.representativeRelationship) {
            errors.representativeRelationship = t('errors.invalidRelationship');
          }
          if (values.dob) {
            const currentDate = moment();
            const selectedDate = moment(values.dob, 'YYYY-M-D', true);
            const age = currentDate.diff(selectedDate, 'years');

            if (selectedDate.isValid() && (age < 0 || age > 16)) {
              errors.dob = t('errors.invalidMinorDOB');
            }
          }
        } else {
          if (!isValidEmail(values.email)) {
            errors.email = t('errors.invalidEmail');
          }
          if (!values.phone_number || !isValidPhoneNumber(values.phone_number)) {
            errors.phone_number = t('errors.invalidPhoneNumber');
          }
          if (values.dob && moment(values.dob).isAfter(MIN_CLIENT_BIRTH_DATE)) {
            errors.dob = t('errors.invalidDOB_MIN');
          }
          if (values.dob && moment(values.dob).isBefore(MAX_CLIENT_BIRTH_DATE)) {
            errors.dob = t('errors.invalidDOB');
          }
          if (!values.phone_number || !isValidPhoneNumber(values.phone_number)) {
            errors.phone_number = t('errors.invalidPhoneNumber');
          }
        }

        if (!values.first_name) {
          errors.first_name = t('errors.invalidFirstName');
        }
        if (!values.last_name) {
          errors.last_name = t('errors.invalidLastName');
        }
        if (!values.insurance_number || !isValidHCN(values.insurance_number, values.province as Provinces)) {
          if (values.province) {
            errors.insurance_number = t('errors.invalidHCNWithExample', { example: EXAMPLE_HCNS[values.province] });
          } else {
            errors.insurance_number = t('errors.invalidHCN');
          }
        }
        if (!values.dob) {
          errors.dob = t('errors.invalidDOB');
        }
        if (!values.street_address) {
          errors.street_address = t('errors.invalidStreetAddress');
        }
        if (!values.postal_code || !isValidPostalCode(values.postal_code)) {
          errors.postal_code = t('errors.invalidPostalCode');
        }
        if (!values.province) {
          errors.province = t('errors.invalidProvince');
        }
        if (!values.city) {
          errors.city = t('errors.invalidCity');
        }
        return errors;
      },
      shouldFocusError: false
    }
  );

  const getSubmitError = useMemo(() => {
    if (errorWhileCreatingPatients === errorsTFunction('errorCode.US_ALREADY_EXISTS')) {
      const client = isMinorClient
        ? `${values?.first_name} ${values?.last_name} (${values.insurance_number})`
        : values.email;
      return t('errors.clientExist', {
        client: client
      });
    }

    if (errorWhileCreatingGuardian === errorsTFunction('errorCode.US_ALREADY_EXISTS')) {
      return t('errors.clientExist', {
        client: values.guardian_email
      });
    }
    return t('errors.generic');
    // eslint-disable-next-line
  }, [errorWhileCreatingPatients, errorWhileCreatingGuardian, isMinorClient, t, errorsTFunction]);

  const openGuardianForm = () => {
    setEditGuardian(false);
    setIsGuardianFormOpen(true);
    resetGuardianFields(setValues);
  };

  function getDependantUserRelationship(dependantId: string, userData: PartnerClient) {
    const dependantUsers = userData.designated_users;
    if (!dependantUsers) return undefined;

    for (const dependantUser of dependantUsers) {
      if (String(dependantUser.id) === String(dependantId)) {
        return dependantUser.relationship;
      }
    }

    return undefined;
  }

  const selectGuardian = (guardianUser: PartnerClient) => {
    resetErrors();
    setEditGuardian(true);
    setIsGuardianFormOpen(true);

    const relationship = getDependantUserRelationship(values.id, guardianUser);
    setValues(getGuardianFromData(guardianUser, relationship));
  };

  useEffect(() => {
    if (response && isNotUndefined(onCreate)) {
      onCreate(response);
    }
  }, [onCreate, response, t]);

  useEffect(() => {
    if (!isMinorClient) {
      resetGuardianFields(setValues);
      setIsGuardianFormOpen(false);
      setEditGuardian(false);
    }
    // eslint-disable-next-line
  }, [isMinorClient]);

  useEffect(() => {
    resetErrors();
    resetForm();
    setEditGuardian(false);
    if (patientDetails && editMode) {
      const { designated_users: representativeUsers } = patientDetails;
      const patientFormState = createPatientFormState(patientDetails);
      const isMinor = isDependant(patientDetails?.dob);

      if (isMinor && !guardianDetails && representativeUsers && representativeUsers.length > 0) {
        setEditGuardian(true);
        setIsGuardianFormOpen(true);
        setValues({
          ...createRepresentativeFormState(representativeUsers[0]),
          ...patientFormState
        });
      } else if (isMinor && guardianDetails) {
        setEditGuardian(true);
        setIsGuardianFormOpen(true);
        setValues({
          ...guardianDetails,
          ...patientFormState
        });
      } else {
        setValues(patientFormState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetails, guardianDetails, editMode]);

  return (
    <div key={generateKey(patientDetails?.id, editMode)}>
      {ready && (patientDetails || !editMode) ? (
        <form
          key={generateKey(patientDetails?.id, editMode)}
          onSubmit={handleSubmit(onSubmit)}
          className={classes.form}
        >
          <Container isFlex className={`${classes.width100} ${classes.inputRow}`}>
            <div className={classes.inputWrapper}>
              <Input
                className={classes.input}
                {...getFieldProps('first_name')}
                maxWidth
                label={t('inputsLabels.firstName')}
                noPlaceholders
              />
            </div>
            <div className={classes.inputWrapper}>
              <Input
                className={classes.input}
                {...getFieldProps('last_name')}
                maxWidth
                label={t('inputsLabels.lastName')}
                noPlaceholders
              />
            </div>
          </Container>
          <Container isFlex className={`${classes.width100} ${classes.inputRow} ${classes.dateInputRow}`}>
            <div className={classes.inputWrapper}>
              <DateInputGroup
                isMinor={isMinorClient}
                label={t('inputsLabels.dob')}
                setDateValue={(value: Date | null, shouldValidate: boolean) =>
                  setValue('dob', value ? moment(value).format('YYYY-MM-DD') : '', { shouldValidate })
                }
                validateDate={() => validateField('dob', values)}
                dateError={getFieldProps('dob').error}
                minDate={MIN_CLIENT_BIRTH_DATE}
                maxDate={MAX_CLIENT_BIRTH_DATE}
                initialValue={moment(patientDetails?.dob || '').toDate()}
              />
            </div>
            <div className={classes.inputWrapper}>
              <Input
                className={classes.input}
                {...getFieldProps<HTMLInputElement>('insurance_number', {
                  onChange: (e) => {
                    e.persist();
                    const { value } = e.target;
                    validateField('insurance_number', { ...values, insurance_number: value });
                  }
                })}
                maxWidth
                label={t('inputsLabels.hcn')}
                noPlaceholders
              />
            </div>
          </Container>
          {!isMinorClient && (
            <Container isFlex className={`${classes.width100} ${classes.inputRow}`}>
              <div className={classes.inputWrapper}>
                <Input
                  className={clsx(
                    classes.input,
                    noInputBorder && classes.noInputBorder,
                    !!patientDetails?.email && editMode && classes.editModeDisabled
                  )}
                  {...getFieldProps('email')}
                  maxWidth
                  label={t('inputsLabels.email')}
                  disabled={!!patientDetails?.email && editMode}
                  noPlaceholders
                />
              </div>
              <div className={classes.inputWrapper}>
                <Input
                  className={classes.input}
                  {...getFieldProps('phone_number')}
                  value={formatPhone(getFieldProps('phone_number').value)}
                  format={formatPhone}
                  maxWidth
                  label={t('inputsLabels.phoneNumber')}
                  noPlaceholders
                />
              </div>
            </Container>
          )}
          <div style={{ width: '100%' }}>
            <Input
              className={classes.input}
              {...getFieldProps('street_address')}
              maxWidth
              label={t('inputsLabels.address')}
            />
          </div>
          <Container isFlex className={`${classes.inputRow}`} style={{ width: '80%' }}>
            <div className={`${classes.addressInputWrapper}`}>
              <Input
                className={classes.input}
                {...getFieldProps('extra_line_1')}
                maxWidth
                placeholder={t('inputPlaceholders.addressLine2')}
              />
            </div>
            <div className={`${classes.addressInputWrapper}`}>
              <Input
                className={classes.input}
                {...getFieldProps('city')}
                maxWidth
                placeholder={t('inputPlaceholders.city')}
              />
            </div>
          </Container>
          <Container isFlex style={{ width: '80%', display: 'flex' }}>
            <div className={`${classes.addressInputWrapper}`}>
              <DropdownInput
                className={classes.input}
                {...getFieldProps('province', {
                  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                    e.persist();
                    const { value } = e.target;
                    validateField('province', { ...values, province: value });
                  }
                })}
                maxWidth
                options={mappedProvinces}
                placeholderText={t('inputPlaceholders.province')}
              />
            </div>
            <div style={{ width: '35%', maxWidth: '210px' }}>
              <Input
                className={classes.input}
                {...getFieldProps('postal_code')}
                maxWidth
                placeholder={t('inputPlaceholders.postalCode')}
              />
            </div>
          </Container>
          {isMinorClient && (
            <>
              <Divider className={classes.guardianDivider} />
              <ClientSelect placeholder={t('searchGuardians')} onSelect={selectGuardian} legalAgeOnly />
              <ButtonText
                isPrimary
                style={{ margin: '1.5rem 1rem' }}
                label={t('enterGuardianInfo')}
                onClick={openGuardianForm}
              />
              {isGuardianFormOpen && (
                <>
                  <Container isFlex className={`${classes.width100} ${classes.inputRow}`}>
                    <div className={classes.inputWrapper}>
                      <Input
                        className={classes.input}
                        {...getFieldProps('guardian_first_name')}
                        maxWidth
                        label={t('inputsLabels.guardianFirstName')}
                        noPlaceholders
                      />
                    </div>
                    <div className={classes.inputWrapper}>
                      <Input
                        className={classes.input}
                        {...getFieldProps('guardian_last_name')}
                        maxWidth
                        label={t('inputsLabels.guardianLastName')}
                        noPlaceholders
                      />
                    </div>
                  </Container>
                  <Container isFlex className={`${classes.width100} ${classes.inputRow}`}>
                    <div className={classes.inputWrapper}>
                      <Input
                        className={clsx(
                          classes.input,
                          noInputBorder && classes.noInputBorder,
                          editGuardian && classes.editModeDisabled
                        )}
                        {...getFieldProps('guardian_email')}
                        maxWidth
                        label={t('inputsLabels.guardianEmail')}
                        disabled={editGuardian}
                        noPlaceholders
                      />
                    </div>
                    <div className={classes.inputWrapper}>
                      <Input
                        className={classes.input}
                        {...getFieldProps('guardian_phone_number')}
                        maxWidth
                        label={t('inputsLabels.guardianPhone')}
                        noPlaceholders
                      />
                    </div>
                  </Container>
                  <Container isFlex className={`${classes.width100} ${classes.inputRow} `}>
                    <div className={classes.inputWrapper}>
                      <Input
                        className={classes.input}
                        {...getFieldProps('representativeRelationship')}
                        maxWidth
                        label={t('inputsLabels.guardianRelationship')}
                        noPlaceholders
                      />
                    </div>
                  </Container>
                </>
              )}
            </>
          )}
          <Container justifyContent='center' padding='1rem 0px' margin='70px 0 0 0'>
            <Button
              type='submit'
              label={submitText || t('addClient')}
              isDisabled={
                isLoading || isUpdatingPatientInformation || isLoadingCreateGuardian || isUpdatingGuardianInformation
              }
              isLoading={
                isLoading || isUpdatingPatientInformation || isLoadingCreateGuardian || isUpdatingGuardianInformation
              }
              style={{ padding: '9px 45px' }}
            />
          </Container>
          {(errorWhileCreatingPatients || errorWhileCreatingGuardian) && (
            <Text paragraph bold align='center' color='error' style={{ marginTop: '16px' }}>
              {getSubmitError}
            </Text>
          )}
        </form>
      ) : (
        <Container justifyContent='center'>
          <Spinner />
        </Container>
      )}
    </div>
  );
};

export default ClientForm;
