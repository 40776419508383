import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Root as RadioRoot, Item as RadioItem } from '@radix-ui/react-radio-group';
import FormCard, { FormCardSkeleton } from './InstitutionFormCard';
import SubmitButtonContainer from './SubmitButtonContainer';
import Divider from '../../shared/Divider';
import { InstitutionForm } from '../../../types';
import { useTranslate } from '../../../hooks/useTranslate';
import RadioGroup from '../../shared/RadioGroup';
import { generateKey } from '../../../utils/utils';
import Skeleton from '../../shared/Skeleton';

const useStyles = makeStyles(() => ({
  root: {
    width: '60%',
    margin: '9rem 0'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '1.5rem'
  },
  radioGroupRoot: {
    width: '40%'
  },
  radioIcon: {
    height: '24px'
  },
  formCard: {
    cursor: 'pointer'
  },
  radioSkeletonWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem'
  }
}));

interface FormSelectionInputProps {
  forms: InstitutionForm[];
  onSelect: (form: InstitutionForm) => void;
  active?: InstitutionForm;
  handleNext: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onRequestTypeChange: (requestType: string) => void;
  requestType: string;
  requestTypeOptions: string[];
  allowNext: boolean;
}

export const FormSelectionSkeleton = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <FormCardSkeleton />
        <FormCardSkeleton />
      </div>
      <Divider style={{ margin: '2rem' }} />
      <div className={classes.radioSkeletonWrapper}>
        <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center', alignItems: 'center' }}>
          <Skeleton borderRadius='50%' height='2.2rem' width='2.2rem' />
          <Skeleton height='2.2rem' width='25ch' />
        </div>
        <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center', alignItems: 'center' }}>
          <Skeleton borderRadius='50%' height='2.2rem' width='2.2rem' />
          <Skeleton height='2.2rem' width='25ch' />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '2.5rem' }}>
        <Skeleton width='15rem' height='4.5rem' borderRadius='7rem' />
      </div>
    </div>
  );
};

const FormSelection = ({
  forms,
  onSelect,
  active,
  handleNext,
  allowNext,
  onRequestTypeChange,
  requestType,
  requestTypeOptions
}: FormSelectionInputProps) => {
  const classes = useStyles({
    classes: { radioGroupRoot: 'rgroot-1', container: 'ct123' }
  });
  const { t } = useTranslate('createNewRequest');

  return (
    <div className={classes.root}>
      <div>
        <RadioRoot
          className={classes.container}
          name='request_form'
          aria-label={t('formSelectionPage.selectFormRadioGroupLabel')}
          onValueChange={(value) => {
            const form = forms.find(({ display_name }) => display_name === value);
            if (form) onSelect(form);
          }}
        >
          {forms &&
            forms.map((form) => {
              return (
                <RadioItem
                  /**
                   * Using the `asChild` prop to the radio item
                   * would add the necessary props to make the child component behave as a radio button
                   * and use the child component in the DOM and woudln't create a new DOM node
                   */
                  asChild
                  key={generateKey('request-form', form.id)}
                  value={form.display_name}
                  aria-label={t('formSelectionPage.requestFormAriaLabel', { formName: form.display_name })}
                >
                  <FormCard
                    className={classes.formCard}
                    active={active?.id === form.id}
                    onClick={() => onSelect(form)}
                    key={form.id}
                    displayName={form.display_name}
                    updatedAt={form.updated_at}
                    pageCount={form.page_count}
                    formId={form.id}
                  />
                </RadioItem>
              );
            })}
        </RadioRoot>
      </div>
      <Divider style={{ margin: '2rem' }} />
      <div className={classes.container}>
        <RadioGroup
          question={t('formSelectionPage.radioGroup.question')}
          options={requestTypeOptions.map((option) => ({ label: option, value: option }))}
          selectedOption={{
            value: requestType,
            label: requestType
          }}
          name={t('formSelectionPage.radioGroup.name')}
          onChange={(option) => onRequestTypeChange(option.value)}
        />
      </div>
      <SubmitButtonContainer
        label={t('formSelectionPage.continueButtonText')}
        disabled={!allowNext}
        onClick={handleNext}
        showEndIcon
      />
    </div>
  );
};

export default FormSelection;
