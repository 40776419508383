/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface FamilyHistoryIconProps extends SVGBaseProps {}

const FamilyHistoryIcon = (props: FamilyHistoryIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M13.7143 2C8.03428 2 3.42857 6.47778 3.42857 12H1.23157C0.78271 12 0.561198 12.5456 0.883025 12.8585L3.42857 15.3333L3.82967 15.7517C4.21343 16.152 4.84965 16.1636 5.24773 15.7776L8.25719 12.8589C8.57962 12.5462 8.35825 12 7.90909 12H5.71428C5.71428 7.7 9.29142 4.22222 13.7143 4.22222C18.1371 4.22222 21.7143 7.7 21.7143 12C21.7143 16.3 18.1371 19.7778 13.7143 19.7778C11.9093 19.7778 10.2421 19.19 8.90673 18.2031C8.41381 17.8388 7.71785 17.8299 7.27839 18.2571V18.2571C6.82236 18.7005 6.81922 19.4383 7.32205 19.8278C9.07659 21.1868 11.2919 22 13.7143 22C19.3943 22 24 17.5222 24 12C24 6.47778 19.3943 2 13.7143 2ZM13.4286 7.55556C12.9552 7.55556 12.5714 7.93931 12.5714 8.4127V12.6026C12.5714 12.9173 12.7393 13.208 13.0118 13.3652L16.7977 15.5495C17.1678 15.7631 17.6407 15.6428 17.8637 15.2783V15.2783C18.095 14.9004 17.9695 14.4062 17.5858 14.1845L14.2857 12.2778V8.4127C14.2857 7.93931 13.9019 7.55556 13.4286 7.55556V7.55556Z'
        fill='inherit'
      />
      <path
        d='M13.7143 2C8.03431 2 3.4286 6.47778 3.4286 12H1.37958C0.870493 12 0.61554 12.5984 0.97552 12.9484L3.4286 15.3333L3.71964 15.6369C4.16034 16.0966 4.90517 16.1098 5.36279 15.666L8.16447 12.9489C8.52514 12.5991 8.27034 12 7.76091 12H5.71431C5.71431 7.7 9.29146 4.22222 13.7143 4.22222C18.1372 4.22222 21.7143 7.7 21.7143 12C21.7143 16.3 18.1372 19.7778 13.7143 19.7778C11.9217 19.7778 10.2649 19.198 8.93418 18.2233C8.42759 17.8522 7.70407 17.8433 7.25557 18.2793C6.80831 18.7142 6.80475 19.4247 7.29829 19.8093C9.05657 21.1795 11.281 22 13.7143 22C19.3943 22 24 17.5222 24 12C24 6.47778 19.3943 2 13.7143 2ZM13.4286 7.55556C12.9552 7.55556 12.5715 7.92865 12.5715 8.38889V12.6306C12.5715 12.9285 12.733 13.2043 12.9965 13.3564L16.7716 15.5345C17.1535 15.7548 17.6468 15.6328 17.874 15.2617C18.1013 14.8902 17.9757 14.4098 17.5935 14.1889L14.2857 12.2778V8.38889C14.2857 7.92865 13.902 7.55556 13.4286 7.55556Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default FamilyHistoryIcon;
