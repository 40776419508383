/* eslint-disable @typescript-eslint/naming-convention */
// For rendering auth event logs in app
export enum AUTH_EVENTS {
  CREATION = 'CREATION',
  AUTHENTICATION = 'AUTHENTICATION',
  AUTHENTICATION_FAILURE_PASSWORD_INVALID = 'AUTHENTICATION_FAILURE_PASSWORD_INVALID',
  AUTHENTICATION_FAILURE_RATE_LIMIT = 'AUTHENTICATION_FAILURE_RATE_LIMIT',
  AUTHENTICATION_FAILURE_2FA_INVALID = 'AUTHENTICATION_FAILURE_2FA_INVALID',
  PASSWORD_UPDATE = 'PASSWORD_UPDATE',
  '2FA_ENABLE' = '2FA_ENABLE',
  REQUEST_ACCESS = 'REQUEST_ACCESS',
  REQUEST_ACCESS_FAILURE = 'REQUEST_ACCESS_FAILURE',
  RESOURCE_ACCESS = 'RESOURCE_ACCESS',
  RESOURCE_ACCESS_FAILURE = 'RESOURCE_ACCESS_FAILURE',
  FILE_ACCESS = 'FILE_ACCESS',
  FILE_ACCESS_FAILURE = 'FILE_ACCESS_FAILURE'

  // Note: Auth Events not rendered in-app:
  // '2FA_VALIDATION_FAILURE_INVALID'
  // '2FA_VALIDATION'
  // 'ADMIN_PASSWORD_RESET_REQUEST'
  // 'ADMIN_UPDATE_USER'
  // 'ADMIN_BLOCK_USER'
  // 'ADMIN_EMAIL_VERIFICATION_REQUEST'
  // 'ADMIN_UNBLOCK_USER'
  // 'ADMIN_UPDATE_USER_ROLE'
  // 'TOKEN_REFRESH_FAILURE_INVALID'
  // 'TOKEN_REFRESH_FAILURE_EXPIRED'
  // 'TOKEN_REFRESH_FAILURE_REVOKED'
  // 'TOKEN_REFRESH'
  // 'LOGOUT'
  // 'PASSWORD_RESET_REQUEST'
  // 'PASSWORD_UPDATE_FAILURE_EXPIRED'
  // 'PASSWORD_UDPATE_FAILURE_INVALID'
  // 'EMAIL_VERIFICATION'
  // 'EMAIL_VERIFICATION_FAILURE_EXPIRED'
  // 'EMAIL_VERIFICATION_FAILURE_INVALID'
  // 'EMAIL_VERIFICATION_REQUEST'
  // 'CREATION_FAILURE_DUPLICATE'
  // 'CREATION_FAILURE_RATE_LIMIT'
}
