/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface MedicationDispenseIconProps extends SVGBaseProps {}

const MedicationDispenseIcon = (props: MedicationDispenseIconProps) => {
  return (
    <SVGBase viewBox='0 0 21 24' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.74558 0H0.766538C0.343191 0 0 0.343192 0 0.766539V17.2236C0 18.295 0.868509 19.1635 1.93987 19.1635V19.1635C3.01123 19.1635 3.87973 18.295 3.87973 17.2236V10.8468H5.28343L9.70736 16.5596L9.83635 16.7274L5.50417 22.3926C5.00099 23.0506 5.47017 24 6.29853 24H8.37769C8.69931 24 9.0013 23.8453 9.18923 23.5843L12.1955 19.4089L15.1757 23.5812C15.3634 23.844 15.6665 24 15.9895 24H18.172C19.0015 24 19.4704 23.0483 18.9649 22.3906L14.6113 16.7265L18.3136 11.8832C18.8167 11.2252 18.3475 10.2759 17.5192 10.2759H15.5499C15.2278 10.2759 14.9255 10.431 14.7376 10.6926L12.2697 14.1293L9.89175 10.7376C11.0448 10.4211 11.9372 9.91879 12.6565 9.11592C13.4817 8.19698 13.8851 7.02217 13.8851 5.61637C13.8851 4.59897 13.6851 3.71639 13.2705 2.98135C12.8634 2.25963 12.3157 1.67419 11.6293 1.22868C10.967 0.787396 10.2076 0.477425 9.35511 0.295722C8.51107 0.0984159 7.64105 0 6.74558 0ZM3.87973 8.00951V3.35452H6.58801C6.99228 3.35452 7.40611 3.38821 7.82969 3.45598L7.83138 3.45624C8.25851 3.52195 8.62844 3.64391 8.94522 3.81814C9.25582 3.98897 9.51117 4.22103 9.71373 4.51709L9.71798 4.52298C9.90107 4.77648 10.0053 5.12411 10.0053 5.5901C10.0053 6.10753 9.8927 6.51161 9.68741 6.8209C9.47002 7.13055 9.1921 7.36963 8.85006 7.54065L8.84529 7.54315C8.50991 7.71883 8.12115 7.84152 7.67539 7.90755C7.2164 7.97555 6.75768 8.00951 6.29914 8.00951H3.87973Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default MedicationDispenseIcon;
