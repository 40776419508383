import RecordsTabWrapperBackButton from './BackButton';
import RecordsTabWrapperContainer from './Container';
import RecordsTabWrapperContentWrapper from './Content';
import RecordsTabWrapperHeading from './Heading';
import RecordsTabWrapperResourceIconWrapper from './ResourceIcon';
import RecordsTabWrapperSearchInputContainer from './SearchInputContainer';
import RecordsTabWrapperSearchResults from './SearchResult';
import RecordsTabWrapperTitle from './Title';

export const RecordsTabWrapper = {
  HeadingContainer: RecordsTabWrapperHeading,
  Container: RecordsTabWrapperContainer,
  BackButton: RecordsTabWrapperBackButton,
  ResourceIconWrapper: RecordsTabWrapperResourceIconWrapper,
  Title: RecordsTabWrapperTitle,
  Content: RecordsTabWrapperContentWrapper,
  SearchInputContainer: RecordsTabWrapperSearchInputContainer,
  SearchResult: RecordsTabWrapperSearchResults
};
