import { Suspense, useEffect, lazy } from 'react';

import { matchPath, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { useRoutes } from '../../../hooks/useRoutes';
import ClientDetailView from '../../layouts/ClientDetailView';
import PrivateRoute from '../../shared/PrivateRoute';
import GenericErrorScreen from '../../shared/GenericErrorScreen';
import { useTranslate } from '../../../hooks/useTranslate';
import { USER_ACTIONS } from '../../../utils/rbac';

import Spinner from '../../shared/Spinner';
import Container from '../../shared/Container';
import { RecentActivitiesTabSkeleton } from '../../Skeletons/RecentActivitiesTabSkeleton';
import { RecordsTabSkeleton } from '../../Skeletons/RecordsTabSkeleton';

const RecentActivitiesTab = lazy(() => import('./RecentActivitiesTab'));
const RecordsTab = lazy(() => import('./RecordsTab'));

interface Params {
  internalId: string;
}

const ClientDetailPage = () => {
  const { routesTFunction, ready } = useRoutes();
  const history = useHistory();
  const { internalId } = useParams<Params>();
  const { pathname } = useLocation();
  const { t } = useTranslate('clientDetails');

  useEffect(() => {
    if (matchPath(pathname, { path: routesTFunction('routerPaths./clients/:internalId/records'), exact: true })) return;
    if (matchPath(pathname, { path: routesTFunction('routerPaths./clients/:internalId'), exact: true })) {
      history.push(routesTFunction('redirectPaths./clients/:internalId/recent-activities', { internalId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routesTFunction, ready, internalId]);

  if (!ready) {
    <Container justifyContent='center' height='80vh'>
      <Spinner />
    </Container>;
  }

  return (
    <ClientDetailView>
      <Switch>
        <PrivateRoute
          perform={USER_ACTIONS.VIEW.CLIENT_DETAIL_PAGE_TABS.RECENT_ACTIVITIES}
          noWrap
          exact
          path={routesTFunction('routerPaths./clients/:internalId/recent-activities')}
          component={() => {
            return (
              <Suspense fallback={<RecentActivitiesTabSkeleton />}>
                <RecentActivitiesTab />
              </Suspense>
            );
          }}
        />
        <PrivateRoute
          perform={USER_ACTIONS.VIEW.CLIENT_DETAIL_PAGE_TABS.RECORDS}
          noWrap
          exact
          path={routesTFunction('routerPaths./clients/:internalId/records')}
          component={() => {
            return (
              <Suspense fallback={<RecordsTabSkeleton />}>
                <RecordsTab />
              </Suspense>
            );
          }}
        />
        <PrivateRoute
          noWrap
          path={`${routesTFunction('routerPaths./clients/:internalId')}`}
          component={() => {
            return (
              <GenericErrorScreen
                label={t('errorState.message')}
                buttonProps={{
                  urlTo: routesTFunction('redirectPaths./clients/:internalId/recent-activities', { internalId }),
                  label: t('errorState.viewUserActivityButtonText')
                }}
              />
            );
          }}
        />
      </Switch>
    </ClientDetailView>
  );
};

export default ClientDetailPage;
