import { useCallback, useContext, useMemo } from 'react';
import moment from 'moment';

// Material UI
import { makeStyles } from '@material-ui/core';

// Localization
import { Trans, TFunction } from 'react-i18next';

// Dot Types
import { Invitation, User } from '../../../../types';

// Dot Hooks
import { useFetchAvatar } from '../../../../request-hooks/shared/useSharedRequest';

// Notification Snackbar
import { SnackbarContext } from '../../../../contexts/snackbar-context';

// Utils
import { useFormatDate, generateKey } from '../../../../utils/utils';

// Dot Component
import Table from '../../../shared/Table';
import Text from '../../../shared/Typography';
import Skeleton from '../../../shared/Skeleton';
import UserAvatar from '../../../shared/UserAvatar';
import IconButton from '../../../shared/IconButton';
import { CloseIcon } from '../../../shared/Icons';

// Users table component
import UsersCancelInvitationAction from './UsersCancelInvitationAction';
import DataTable from '../../../shared/DataTable/DataTable';

const useStyles = makeStyles((theme) => ({
  table: {
    margin: '0'
  },
  skeletonRow: {
    '& td, th': {
      padding: '1.85rem 2rem'
    },
    '& td:first-child, th:first-child': {
      paddingLeft: '4.4rem'
    },
    '& td:last-child, th:last-child': {
      paddingRight: '4.4rem'
    },
    '&:last-child &:not(:first-child)': {
      borderBottom: `1px solid ${theme.palette.catSkillWhite.main}`
    }
  },
  '@keyframes changeFromRecentlyCreated': {
    from: { background: `${theme.palette.newRowGreen.main}` },
    to: { background: theme.palette.catSkillWhite.main }
  },
  recentlyCreated: {
    animation: '$changeFromRecentlyCreated',
    animationDuration: '10s'
  },
  avatarColumn: {
    paddingTop: '1.45rem !important',
    paddingBottom: '1.45rem !important',
    width: '10ch',
    maxWidth: '10ch'
  },
  nameColumn: {
    width: '26ch',
    maxWidth: '26ch'
  },
  emailColumn: {
    width: '34ch',
    maxWidth: '34ch'
  },
  dateCreatedColumn: {
    width: '20ch',
    maxWidth: '20ch'
  },
  roleColumn: {
    width: '16ch',
    maxWidth: '16ch'
  },
  statusColumn: {
    width: '16ch',
    maxWidth: '16ch'
  },
  cancelActionColumn: {
    width: '12ch',
    maxWidth: '12ch'
  },
  capitalize: {
    textTransform: 'capitalize'
  }
}));

type UserOrInvitation = User | Invitation;

export const TableSkeleton = () => {
  const classes = useStyles();

  return (
    <Table
      alternateRowColours
      renderHead={() => {
        return (
          <tr className={classes.skeletonRow}>
            <th className={classes.avatarColumn}>{}</th>
            <th className={classes.nameColumn}>
              <Skeleton borderRadius='7px' height='2.3rem' width='6ch' />
            </th>
            <th className={classes.emailColumn}>
              <Skeleton borderRadius='7px' height='2.3rem' width='15ch' />
            </th>
            <th className={classes.dateCreatedColumn}>
              <Skeleton borderRadius='7px' height='2.3rem' width='12ch' />
            </th>
            <th className={classes.roleColumn}>
              <Skeleton borderRadius='7px' height='2.3rem' width='6ch' />
            </th>
            <th className={classes.statusColumn}>
              <Skeleton borderRadius='7px' height='2.3rem' width='7ch' />
            </th>
            <th className={classes.cancelActionColumn}>{}</th>
          </tr>
        );
      }}
      renderRows={() => {
        return [...new Array(5)].map((_, idx) => {
          return (
            <tr className={classes.skeletonRow} key={generateKey('users-table-body-row-skeleton', idx)}>
              <td className={classes.avatarColumn}>
                <Skeleton borderRadius='50%' height='3rem' width='3rem' />
              </td>
              <td className={classes.nameColumn}>
                <Skeleton borderRadius='7px' height='2rem' width='20ch' />
              </td>
              <td className={classes.emailColumn}>
                <Skeleton borderRadius='7px' height='2rem' width='30ch' />
              </td>
              <td className={classes.dateCreatedColumn}>
                <Skeleton borderRadius='7px' height='2rem' width='11ch' />
              </td>
              <td className={classes.roleColumn}>
                <Skeleton borderRadius='7px' height='2rem' width='8ch' />
              </td>
              <td className={classes.statusColumn}>
                <Skeleton borderRadius='7px' height='2rem' width='6ch' />
              </td>
              <td className={classes.cancelActionColumn}>{}</td>
            </tr>
          );
        });
      }}
    />
  );
};

const UserAvatarCell = ({ user }: { user: User }) => {
  const shouldFetchAvatar = useMemo(() => Boolean(user.avatar?.file?.file_name), [user.avatar]);

  const { avatar, isFetchingAvatar } = useFetchAvatar(user.id, shouldFetchAvatar);

  if (isFetchingAvatar) return <Skeleton borderRadius='50%' height='3rem' width='3rem' />;

  return <UserAvatar disableToolTip user={user} src={avatar || ''} size='small' />;
};

interface UsersTableProps {
  users: User[];
  invitations: Invitation[];
  deleteInvitation: (invitationId: number) => void;
  t: TFunction<'settingsMyInstitutionTab'>;
}

const UsersTable = ({ users, invitations, deleteInvitation, t }: UsersTableProps) => {
  const classes = useStyles();
  const formatDate = useFormatDate();
  const { addNotification, removeNotification } = useContext(SnackbarContext);

  const RemoveNotificationButton = useCallback(() => {
    return (
      <IconButton label='' onClick={removeNotification}>
        <CloseIcon color='white' size='small' />
      </IconButton>
    );
  }, [removeNotification]);

  const columns = [
    {
      props: { className: classes.avatarColumn },
      value: (data: UserOrInvitation) => {
        if (isTypeUser(data)) {
          return <UserAvatarCell user={data} />;
        }
      }
    },
    {
      name: t('institutionMemberTable.name'),
      props: { className: classes.nameColumn },
      value: (data: UserOrInvitation) => {
        if (isTypeUser(data)) {
          return (
            <Text paragraph color='purple'>
              {data.first_name} {data.last_name}
            </Text>
          );
        }
        return (
          <Text paragraph italic>
            {t('institutionMemberTable.invitedUsers.name')}
          </Text>
        );
      }
    },
    {
      name: t('institutionMemberTable.email'),
      props: { className: classes.emailColumn },
      value: (data: UserOrInvitation) => {
        if (isTypeUser(data)) {
          return data.email;
        }
        return data.email_address;
      }
    },
    {
      name: t('institutionMemberTable.dateAdded'),
      props: { className: classes.dateCreatedColumn },
      value: (data: UserOrInvitation) => {
        if (isTypeUser(data)) {
          return data.user_institution?.created_at
            ? formatDate(data.user_institution.created_at, 'MMM DD, YYYY')
            : 'N/A';
        }
        return formatDate(data.created_at, 'MMM DD, YYYY');
      }
    },
    {
      name: t('institutionMemberTable.role'),
      props: { className: classes.roleColumn },
      value: (data: UserOrInvitation) => {
        if (isTypeUser(data)) {
          return (
            <Text paragraph className={classes.capitalize}>
              <Trans t={t} i18nKey={`roles.${data.user_institution?.role}` as any} />
            </Text>
          );
        }
        return (
          <Text paragraph className={classes.capitalize}>
            <Trans t={t} i18nKey={`roles.${data?.role}` as any} />
          </Text>
        );
      }
    },
    {
      name: t('institutionMemberTable.status'),
      props: { className: classes.statusColumn },
      value: (data: UserOrInvitation) => {
        if (isTypeUser(data)) {
          return <Text paragraph>{t('institutionMemberTable.activeUsers.status')}</Text>;
        }
        return <Text paragraph>{t('institutionMemberTable.invitedUsers.status')}</Text>;
      }
    },
    {
      props: { className: classes.cancelActionColumn },
      value: (data: UserOrInvitation) => {
        if (!isTypeUser(data)) {
          return (
            <UsersCancelInvitationAction
              invite={data}
              t={t}
              onSuccess={() => {
                deleteInvitation(data.id);
                addNotification(
                  t('cancelInvitationModal.successfullInvitationCancelMessage'),
                  'success',
                  <RemoveNotificationButton />
                );
              }}
              onFailure={() => {
                addNotification(
                  t('cancelInvitationModal.failedInvitationCancelMessage'),
                  'error',
                  <RemoveNotificationButton />
                );
              }}
            />
          );
        }
      }
    }
  ];

  const isRecentlyCreated = (date: string) => {
    const diff = moment().utc().diff(date, 'seconds');
    return diff < 10;
  };

  const isTypeUser = (arg: UserOrInvitation): arg is User => {
    return !!(arg as User).first_name;
  };

  return (
    <>
      <DataTable
        className={classes.table}
        animateRows
        alternateRowColours
        alternateRowBorder
        data={[...users, ...invitations]}
        rowId={(userOrInvite) => generateKey('users-table-row-user-or-invite-id', userOrInvite.id)}
        columns={columns}
        rowProps={(userOrInvite) => {
          if (!isTypeUser(userOrInvite) && isRecentlyCreated(userOrInvite.created_at)) {
            return { className: classes.recentlyCreated };
          }
        }}
      />
    </>
  );
};

export default UsersTable;
