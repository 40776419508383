import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  page: {
    boxShadow: '0px 0px 16px rgba(42, 10, 90, 0.12)',
    borderRadius: '20px',
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: '2.5rem',
    padding: '1rem 0',
    margin: '3rem 0',
    backgroundColor: 'white'
  }
}));

export interface PageContainerProps {
  children: React.ReactNode;
  className?: string;
}

const PageContainer = ({ children, className }: PageContainerProps) => {
  const classes = useStyles();
  return <div className={clsx(classes.page, className)}>{children}</div>;
};

export default PageContainer;
