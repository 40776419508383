/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface ArrowLeftIconProps extends SVGBaseProps {}

const ArrowLeftIcon = (props: ArrowLeftIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M10.5272 20.8934C11.117 21.3938 12.0123 21.3938 12.6021 20.8934C13.2866 20.3126 13.2866 19.3006 12.6021 18.7198L5.66256 12.8315L22.4193 12.8315C23.2923 12.8315 24 12.1642 24 11.3411C24 10.5181 23.2923 9.85082 22.4193 9.85082L5.66333 9.85082L12.6021 3.96317C13.2866 3.38234 13.2866 2.37037 12.6021 1.78954C12.0123 1.28909 11.117 1.28909 10.5272 1.78954L0.488422 10.3076C-0.162776 10.8601 -0.162776 11.8228 0.488422 12.3754L10.5272 20.8934Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default ArrowLeftIcon;
