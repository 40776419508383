import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Divider from '../Divider';

interface RecordsTabWrapperHeadingProps {
  children: React.ReactNode;
  noDivider?: boolean;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: '1rem 0px',
    alignItems: 'center'
  }
}));

const RecordsTabWrapperHeading = (props: RecordsTabWrapperHeadingProps) => {
  const { children, noDivider } = props;

  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>{children}</div>
      {!noDivider && <Divider />}
    </>
  );
};

export default RecordsTabWrapperHeading;
