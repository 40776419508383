import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export interface SpinnerProps {
  withSpacing?: boolean;
  size?: 'small' | 'medium';
  noMargin?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addMargin: {
    marginTop: '10px'
  },
  noMargin: {
    marginRight: 0
  },
  sizeSmall: {
    height: '1.5rem',
    width: '1.5rem'
  }
}));

const Spinner = ({ withSpacing, size = 'medium', noMargin }: SpinnerProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, withSpacing ? classes.addMargin : '')}>
      <i className={clsx('spin', noMargin && classes.noMargin, size === 'small' && classes.sizeSmall)} />
    </div>
  );
};

export default Spinner;
