import { lazy, Suspense } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';

import ScrollToTop from './hooks/scrollToTop';
import { useRoutes } from './hooks/useRoutes';
import { useTranslate } from './hooks/useTranslate';

import { __prod__ } from './utils/utils';
import { USER_ACTIONS } from './utils/rbac';

import { AuthProvider } from './contexts/auth-context';
import SocketProvider from './contexts/socket-context';

import { SnackbarProvider } from './contexts/snackbar-context';

import theme from './theme/primaryTheme';

import Spinner from './components/shared/Spinner';
import PrivateRoute from './components/shared/PrivateRoute';
import UpdateCacheWrapper from './components/UpdateCacheWrapper';
import ClientDetailPage from './components/pages/ClientDetail';
import RequestsOverview from './components/pages/RequestsOverview';
import Settings from './components/pages/Settings';
import GenericNotFound from './components/pages/GenericNotFound';
import Home from './components/pages/Home';
import CreateRequest from './components/pages/CreateRequest';
import Container from './components/shared/Container';

// Avoid lazy loading parent components here with tabs or steps that are also lazy loaded
// This causes flickering and rerendering of the entire component

// Public Routes we want to only be loaded when visited
const ForgotPassword = lazy(() => import('./components/pages/ForgotPassword'));
const UpdatePassword = lazy(() => import('./components/pages/UpdatePassword'));
const Login = lazy(() => import('./components/pages/Login'));
const Logout = lazy(() => import('./components/pages/Logout'));
const PatternLibrary = lazy(() => import('./components/pages/PatternLibrary'));
const VerifyEmail = lazy(() => import('./components/pages/VerifyEmail'));
const AcceptInvitation = lazy(() => import('./components/pages/AcceptInvitation'));

// Private Routes we want to  only be loaded when  visited
const ClientsOverview = lazy(() => import('./components/pages/ClientsOverview'));
const CreateClients = lazy(() => import('./components/pages/CreateClients'));
const ClientProfile = lazy(() => import('./components/pages/ClientProfile'));
const ManualCreateClient = lazy(() => import('./components/pages/CreateClients/ManualCreateClients'));
const Walkthrough = lazy(() => import('./components/pages/Walkthrough'));
const UploadMultipleRecords = lazy(() => import('./components/pages/UploadMultipleRecords'));
const UploadSinglePatientRecordModal = lazy(() => import('./components/pages/UploadSinglePatientRecordModal'));
const NotificationList = lazy(() => import('./components/pages/NotificationList'));
const RequestDetail = lazy(() => import('./components/pages/RequestDetail'));
const AddNotesPage = lazy(() => import('./components/pages/AddNotes'));

const SuspenseWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Suspense
      fallback={
        <Container justifyContent='center' height='80vh'>
          <Spinner />
        </Container>
      }
    >
      {children}
    </Suspense>
  );
};

const App = () => {
  const location = useLocation();
  const { routesTFunction } = useRoutes();

  return (
    <div>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <AuthProvider>
            <SocketProvider>
              <div className='main'>
                <UpdateCacheWrapper>
                  <ScrollToTop />
                  <Switch location={location.state?.background || location}>
                    {!__prod__ && (
                      <Route
                        path='/pattern-library'
                        component={() => {
                          return (
                            <SuspenseWrapper>
                              <PatternLibrary />
                            </SuspenseWrapper>
                          );
                        }}
                      />
                    )}
                    <Route
                      path={routesTFunction('routerPaths./logout')}
                      component={() => {
                        return (
                          <SuspenseWrapper>
                            <Logout />
                          </SuspenseWrapper>
                        );
                      }}
                    />
                    <Route
                      exact
                      path={routesTFunction('routerPaths./login')}
                      component={() => {
                        return (
                          <SuspenseWrapper>
                            <Login />
                          </SuspenseWrapper>
                        );
                      }}
                    />
                    <Route
                      exact
                      path={routesTFunction('routerPaths./forgot-password')}
                      component={() => {
                        return (
                          <SuspenseWrapper>
                            <ForgotPassword />
                          </SuspenseWrapper>
                        );
                      }}
                    />
                    <Route
                      exact
                      path={routesTFunction('routerPaths./update-password/:token')}
                      component={() => {
                        return (
                          <SuspenseWrapper>
                            <UpdatePassword />
                          </SuspenseWrapper>
                        );
                      }}
                    />
                    <Route
                      exact
                      path={routesTFunction('routerPaths./verify/:token')}
                      component={() => {
                        return (
                          <SuspenseWrapper>
                            <VerifyEmail />
                          </SuspenseWrapper>
                        );
                      }}
                    />
                    <Route
                      exact
                      path={routesTFunction('routerPaths./invitation/:token')}
                      component={() => {
                        return (
                          <SuspenseWrapper>
                            <AcceptInvitation />
                          </SuspenseWrapper>
                        );
                      }}
                    />
                    <PrivateRoute
                      perform={USER_ACTIONS.VIEW.REQUEST_TABLE}
                      exact
                      path={[routesTFunction('routerPaths./requests'), routesTFunction('routerPaths./requests/all')]}
                      component={RequestsOverview}
                    />
                    <PrivateRoute
                      perform={[
                        USER_ACTIONS.VIEW.REQUEST_TABLE,
                        USER_ACTIONS.VIEW.REQUEST_DASHBOARD_PAGE_TABS.INCOMING_REQUESTS
                      ]}
                      exact
                      path={routesTFunction('routerPaths./requests/incoming')}
                      component={RequestsOverview}
                    />
                    <PrivateRoute
                      perform={USER_ACTIONS.VIEW.CLIENT_DASHBOARD_PAGE}
                      exact
                      path={routesTFunction('routerPaths./clients')}
                      component={() => {
                        return (
                          <Suspense
                            fallback={
                              <SuspenseWrapper>
                                <Spinner />
                              </SuspenseWrapper>
                            }
                          >
                            <ClientsOverview />
                          </Suspense>
                        );
                      }}
                    />
                    <PrivateRoute
                      perform={USER_ACTIONS.VIEW.CLIENT_DETAIL_PAGE}
                      path={routesTFunction('routerPaths./clients/:internalId')}
                      component={ClientDetailPage}
                    />
                    <PrivateRoute perform={USER_ACTIONS.VIEW.DASHBOARD_PAGE} exact path='/' component={Home} />
                    <PrivateRoute
                      exact
                      path={routesTFunction('routerPaths./settings/:selectedTab')}
                      component={Settings}
                    />
                    <PrivateRoute
                      perform={USER_ACTIONS.VIEW.CREATE_REQUEST_PAGE}
                      exact
                      path={routesTFunction('routerPaths./requests/new')}
                      component={CreateRequest}
                    />
                    <PrivateRoute
                      perform={USER_ACTIONS.VIEW.REQUEST_TIMELINE_PAGE}
                      exact
                      path={routesTFunction('routerPaths./requests/:internalId')}
                      component={() => {
                        return (
                          <Suspense
                            fallback={
                              <SuspenseWrapper>
                                <Spinner />
                              </SuspenseWrapper>
                            }
                          >
                            <RequestDetail />
                          </Suspense>
                        );
                      }}
                    />
                    {/* <PrivateRoute exact path='/change-plan' component={ChangePlan} />  */}
                    <PrivateRoute
                      perform={USER_ACTIONS.VIEW.NOTIFICATION_LIST}
                      exact
                      path={routesTFunction('routerPaths./notifications')}
                      component={() => {
                        return (
                          <Suspense
                            fallback={
                              <SuspenseWrapper>
                                <Spinner />
                              </SuspenseWrapper>
                            }
                          >
                            <NotificationList />
                          </Suspense>
                        );
                      }}
                    />
                    <PrivateRoute
                      perform={USER_ACTIONS.VIEW.CREATE_NEW_PATIENTS}
                      exact
                      path={routesTFunction('routerPaths./create-patients')}
                      component={() => {
                        return (
                          <Suspense
                            fallback={
                              <SuspenseWrapper>
                                <Spinner />
                              </SuspenseWrapper>
                            }
                          >
                            <CreateClients />
                          </Suspense>
                        );
                      }}
                    />
                    <PrivateRoute
                      perform={USER_ACTIONS.VIEW.CREATE_NEW_PATIENTS}
                      exact
                      path={routesTFunction('routerPaths./create-patient')}
                      component={() => {
                        return (
                          <Suspense
                            fallback={
                              <SuspenseWrapper>
                                <Spinner />
                              </SuspenseWrapper>
                            }
                          >
                            <ManualCreateClient />
                          </Suspense>
                        );
                      }}
                    />
                    <Route component={GenericNotFound} />
                  </Switch>
                  <PrivateRoute
                    perform={USER_ACTIONS.VIEW.UPLOAD_RECORDS}
                    exact
                    path={routesTFunction('routerPaths./upload')}
                    noWrap={!!location.state?.background}
                    component={() => {
                      return (
                        <Suspense
                          fallback={
                            <SuspenseWrapper>
                              <Spinner />
                            </SuspenseWrapper>
                          }
                        >
                          <UploadMultipleRecords />
                        </Suspense>
                      );
                    }}
                  />
                  <PrivateRoute
                    perform={USER_ACTIONS.VIEW.ADD_CLINICAL_NOTE_MODAL}
                    exact
                    path={routesTFunction('routerPaths./add-note/:internalId')}
                    noWrap={!!location.state?.background}
                    component={() => {
                      return (
                        <Suspense
                          fallback={
                            <SuspenseWrapper>
                              <Spinner />
                            </SuspenseWrapper>
                          }
                        >
                          <AddNotesPage />
                        </Suspense>
                      );
                    }}
                  />
                  <PrivateRoute
                    perform={USER_ACTIONS.VIEW.WALKTHROUGH_MODAL}
                    path={routesTFunction('routerPaths./tutorial/:slug')}
                    noWrap={!!location.state?.background}
                    component={() => {
                      return (
                        <Suspense
                          fallback={
                            <SuspenseWrapper>
                              <Spinner />
                            </SuspenseWrapper>
                          }
                        >
                          <Walkthrough />
                        </Suspense>
                      );
                    }}
                  />
                  <PrivateRoute
                    perform={USER_ACTIONS.VIEW.UPLOAD_SINGLE_RECORD_FOR_PATIENT}
                    exact
                    path={routesTFunction('routerPaths./upload/:internalId')}
                    noWrap={!!location.state?.background}
                    component={() => {
                      return (
                        <Suspense
                          fallback={
                            <SuspenseWrapper>
                              <Spinner />
                            </SuspenseWrapper>
                          }
                        >
                          <UploadSinglePatientRecordModal />
                        </Suspense>
                      );
                    }}
                  />
                  <PrivateRoute
                    perform={USER_ACTIONS.VIEW.CLIENT_PROFILE_MODAL}
                    exact
                    path={routesTFunction('routerPaths./clients/:internalId/profile')}
                    noWrap={!!location.state?.background}
                    component={() => {
                      return (
                        <Suspense
                          fallback={
                            <SuspenseWrapper>
                              <Spinner />
                            </SuspenseWrapper>
                          }
                        >
                          <ClientProfile />
                        </Suspense>
                      );
                    }}
                  />
                </UpdateCacheWrapper>
              </div>
            </SocketProvider>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
};

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: 1, refetchOnWindowFocus: false } } });

const DefaultApp = () => {
  const { ready, isInitialized } = useTranslate(['routes', 'errors']);

  if (!ready || !isInitialized) {
    return (
      <div style={{ width: '100vw', height: '100vh' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default DefaultApp;
