/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface DatePickerIconProps extends SVGBaseProps {}

const DatePickerIcon = (props: DatePickerIconProps) => {
  return (
    <SVGBase viewBox='0 0 24 24' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.6709 2.18182H19.58V1.09091C19.58 0.490909 19.0891 0 18.4891 0C17.8891 0 17.3982 0.490909 17.3982 1.09091V2.18182H6.48909V1.09091C6.48909 0.490909 5.99818 0 5.39818 0C4.79818 0 4.30727 0.490909 4.30727 1.09091V2.18182H3.21636C2.01636 2.18182 1.03455 3.16364 1.03455 4.36364V21.8182C1.03455 23.0182 2.01636 24 3.21636 24H20.6709C21.8709 24 22.8527 23.0182 22.8527 21.8182V4.36364C22.8527 3.16364 21.8709 2.18182 20.6709 2.18182ZM19.58 21.8182H4.30727C3.70727 21.8182 3.21636 21.3273 3.21636 20.7273V7.63636H20.6709V20.7273C20.6709 21.3273 20.18 21.8182 19.58 21.8182Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default DatePickerIcon;
