import { ROLES } from './roles';
import { SCOPES } from './scopes';

export const USER_ACTIONS = {
  VIEW: {
    CLIENT_DASHBOARD_PAGE: 'CLIENT_DASHBOARD_PAGE:VIEW',
    REQUEST_DASHBOARD_PAGE: 'REQUEST_DASHBOARD_PAGE:VIEW',
    REQUEST_DASHBOARD_PAGE_TABS: {
      ALL_REQUESTS: 'ALL_REQUESTS:VIEW',
      INCOMING_REQUESTS: 'INCOMING_REQUESTS:VIEW'
    },
    CREATE_REQUEST_PAGE: 'CREATE_REQUEST_PAGE:VIEW',
    CLIENT_DETAIL_PAGE: 'CLIENT_DETAIL_PAGE:VIEW',
    REQUEST_TIMELINE_PAGE: 'REQUEST_TIMELINE_PAGE:VIEW',
    DASHBOARD_PAGE: 'DASHBOARD_PAGE:VIEW',
    REQUEST_TABLE: 'REQUEST_TABLE:VIEW',
    SETTINGS_OVERVIEW_CARD: 'SETTINGS_OVERVIEW_CARD:VIEW',
    SETTINGS_OVERVIEW_ARCHIVE_INFO: 'SETTINGS_OVERVIEW_ARCHIVE_INFO:VIEW',
    SETTINGS_PAGE_BILLING_TAB: 'SETTINGS_PAGE_BILLING_TAB:VIEW',
    CREATE_NEW_REQUEST_BUTTON: 'CREATE_NEW_REQUEST_BUTTON:VIEW',
    ADD_CLINICAL_NOTE_MODAL: 'ADD_CLINICAL_NOTE_PAGE:VIEW',
    CREATE_NEW_PATIENTS: 'CREATE_NEW_PATIENTS:VIEW',
    WALKTHROUGH_MODAL: 'WALKTHROUGH_MODAL:VIEW',
    WALKTHROUGH_MODAL_ITEMS: {
      REQUEST_VS_CLIENT_TAB: 'REQUEST_VS_CLIENT_TAB:VIEW',
      DASHBOARD_STATS: 'DASHBOARD_STATS:VIEW',
      CREATE_REQUEST_FLOW: 'CREATE_REQUEST_FLOW:VIEW',
      CREATE_PATIENT_FLOW: 'CREATE_PATIENT_FLOW:VIEW',
      CREATE_CLINICAL_NOTE: 'CREATE_CLINICAL_NOTE:VIEW',
      ALL_REQUEST_STATUSES: 'ALL_REQUEST_STATUSES:VIEW',
      INSTITUTION_ROLE: 'INSTITUTION_ROLE:VIEW',
      READ_VS_UNREAD: 'READ_VS_UNREAD:VIEW'
    },
    CLIENT_DETAIL_PAGE_TABS: {
      RECENT_ACTIVITIES: 'RECENT_ACTIVITIES:VIEW',
      RECORDS: 'RECORDS:VIEW'
    },
    UPLOAD_RECORDS: 'UPLOAD_RECORDS:VIEW',
    UPLOAD_SINGLE_RECORD_FOR_PATIENT: 'UPLOAD_SINGLE_RECORD_FOR_PATIENT:VIEW',
    CLIENT_PROFILE_MODAL: 'CLIENT_PROFILE_MODAL:VIEW',
    NOTIFICATION_LIST: 'NOTIFICATION_LIST:VIEW'
  },
  CLICK: {
    SUBMIT_NEW_REQUEST_BUTTON: 'SUBMIT_NEW_REQUEST_BUTTON:CLICK'
  },
  FETCH: {
    BUSINESS_UPLOADS: 'BUSINESS_UPLOADS:FETCH'
  }
};

export const ROLE_PERMISSIONS = {
  [ROLES.PARTNER]: {
    STATIC: [
      USER_ACTIONS.VIEW.CLIENT_DASHBOARD_PAGE,
      USER_ACTIONS.VIEW.REQUEST_DASHBOARD_PAGE,
      USER_ACTIONS.VIEW.CREATE_REQUEST_PAGE,
      USER_ACTIONS.VIEW.CLIENT_DETAIL_PAGE,
      USER_ACTIONS.VIEW.REQUEST_TIMELINE_PAGE,
      USER_ACTIONS.VIEW.DASHBOARD_PAGE,
      USER_ACTIONS.VIEW.REQUEST_TABLE,
      USER_ACTIONS.VIEW.SETTINGS_OVERVIEW_CARD,
      USER_ACTIONS.VIEW.SETTINGS_PAGE_BILLING_TAB,
      USER_ACTIONS.VIEW.CREATE_NEW_REQUEST_BUTTON,
      USER_ACTIONS.VIEW.ADD_CLINICAL_NOTE_MODAL,
      USER_ACTIONS.VIEW.CREATE_NEW_PATIENTS,
      // USER_ACTIONS.VIEW.WALKTHROUGH_MODAL,
      // USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.CREATE_REQUEST_FLOW,
      // USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.DASHBOARD_STATS,
      // USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.CREATE_PATIENT_FLOW,
      // USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.REQUEST_VS_CLIENT_TAB,
      // USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.INSTITUTION_ROLE,
      // USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.CREATE_CLINICAL_NOTE,
      // USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.ALL_REQUEST_STATUSES,
      // USER_ACTIONS.VIEW.WALKTHROUGH_MODAL_ITEMS.READ_VS_UNREAD,
      USER_ACTIONS.VIEW.UPLOAD_RECORDS,
      USER_ACTIONS.VIEW.UPLOAD_SINGLE_RECORD_FOR_PATIENT,
      USER_ACTIONS.VIEW.CLIENT_DETAIL_PAGE_TABS.RECENT_ACTIVITIES,
      USER_ACTIONS.VIEW.CLIENT_DETAIL_PAGE_TABS.RECORDS,
      USER_ACTIONS.CLICK.SUBMIT_NEW_REQUEST_BUTTON,
      USER_ACTIONS.VIEW.CLIENT_PROFILE_MODAL,
      USER_ACTIONS.VIEW.NOTIFICATION_LIST,
      USER_ACTIONS.VIEW.REQUEST_DASHBOARD_PAGE_TABS.ALL_REQUESTS,
      USER_ACTIONS.VIEW.REQUEST_DASHBOARD_PAGE_TABS.INCOMING_REQUESTS,
      USER_ACTIONS.VIEW.SETTINGS_OVERVIEW_ARCHIVE_INFO
    ]
  }
};

export const SCOPE_PERMISSIONS = {
  [USER_ACTIONS.VIEW.CLIENT_PROFILE_MODAL]: {
    scopes: [SCOPES.TREATMENT],
    restrictions: [SCOPES.PROVIDER]
  },
  [USER_ACTIONS.VIEW.CLIENT_DETAIL_PAGE]: {
    scopes: [SCOPES.TREATMENT, SCOPES.PROVIDER],
    restrictions: []
  },
  [USER_ACTIONS.VIEW.REQUEST_DASHBOARD_PAGE_TABS.INCOMING_REQUESTS]: {
    scopes: [SCOPES.PORTAL],
    restrictions: []
  },
  [USER_ACTIONS.FETCH.BUSINESS_UPLOADS]: {
    scopes: [SCOPES.TREATMENT, SCOPES.PROVIDER],
    restrictions: []
  },
  [USER_ACTIONS.VIEW.SETTINGS_OVERVIEW_ARCHIVE_INFO]: {
    scopes: [SCOPES.ARCHIVE],
    restrictions: []
  }
};
