import { useEffect, useRef } from 'react';

export default function useOutsideClick<T extends HTMLElement>(handleClick: () => void): { ref: React.RefObject<T> } {
  const ref = useRef<T>();

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current?.contains(event.target as Node)) {
      handleClick();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref: ref as React.RefObject<T> };
}
