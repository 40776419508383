import { Divider, Theme, useMediaQuery } from '@material-ui/core/';
import clsx from 'clsx';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/auth-context';
import { useRoutes } from '../hooks/useRoutes';
import { useTranslate } from '../hooks/useTranslate';
import { blockStates } from '../utils/constants';
import { USER_ACTIONS } from '../utils/rbac';
import { ROLES } from '../utils/roles';
import { generateKey, getRole } from '../utils/utils';
import { TUTORIAL_LIST } from './pages/Walkthrough';
import { useAuthorizationController } from './shared/AuthorizationController';
import AuthUserAvatar from './shared/AuthUserAvatar';
import Can from './shared/Can';
import Icon from './shared/Icon';
import useOutsideClick from '../hooks/useOutsideClick';
import AuthInstitutionLogo from './shared/AuthInstitutionLogo';
import NotificationPopper from './shared/NotificationPopper';
import PublicInstitutionLogo from './shared/PublicInstitutionLogo';

export interface AppNavProps {
  logout?: () => void;
  logoutUser?: any;
  className?: string;
  public?: boolean;
  organizationName?: string;
}

const NavDropdownItems = ({ isAuthorized }: { isAuthorized: boolean }) => {
  const location = useLocation();
  const { t } = useTranslate('navbar');
  const { t: tutorialListTFunction, ready: isTutorialListTranslationReady } = useTranslate('tutorialList');
  const { routesTFunction } = useRoutes();
  const history = useHistory();

  if (!isAuthorized) {
    return (
      <li>
        <Link className='navbar-item' to={routesTFunction('redirectPaths./logout')}>
          <Icon name='log-out' style={{ marginRight: '23px' }} />
          {t('logOut')}
        </Link>
      </li>
    );
  }

  return (
    <>
      <li>
        <Link
          className={clsx('navbar-item', location.pathname.includes('settings') && 'is-active')}
          to={routesTFunction('redirectPaths./settings/overview')}
        >
          <Icon name='gear-grey' style={{ marginRight: '23px' }} />
          {t('settings')}
        </Link>
      </li>
      <Can perform={USER_ACTIONS.VIEW.WALKTHROUGH_MODAL}>
        {isTutorialListTranslationReady && (
          //  eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          <li tabIndex={0} className={clsx('navbar-item', 'navbar-item--no-icon')}>
            {t('help')}
            <div style={{ marginTop: '14px' }}>
              {TUTORIAL_LIST.map((list) => {
                const { displayNameTranslationKey } = list;
                return (
                  <Can
                    key={generateKey('tutorial-navigation-list', list.redirectPathTranslationKey)}
                    perform={list.permission}
                  >
                    <Link
                      onKeyPress={(event) => {
                        if (event.code === 'Space') {
                          history.push(
                            {
                              pathname: routesTFunction(list.redirectPathTranslationKey as any)
                            },
                            { background: location }
                          );
                        }
                      }}
                      to={{
                        pathname: routesTFunction(list.redirectPathTranslationKey as any),
                        state: { background: location }
                      }}
                      className={clsx('navbar-item', 'is-left', 'type-link', 'navbar-item--secondary')}
                      style={{ textAlign: 'left', display: 'inline-block', width: '100%' }}
                    >
                      {tutorialListTFunction(displayNameTranslationKey as any)}
                    </Link>
                  </Can>
                );
              })}
            </div>
          </li>
        )}
      </Can>
      <Divider style={{ marginBottom: '10px' }} />
      <li>
        <Link className='navbar-item' to={routesTFunction('redirectPaths./logout')}>
          <Icon name='log-out' style={{ marginRight: '23px' }} />
          {t('logOut')}
        </Link>
      </li>
    </>
  );
};

const AppNav = (props: AppNavProps) => {
  const [open, setOpen] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [institutionName, setInstitutionName] = useState('');
  const history = useHistory();
  const location = useLocation();
  const { userInfo, role } = useContext(AuthContext);
  // eslint-disable-next-line max-len
  // The desktop breakpoint is used because the styles from banner.css would make the dropdown transform below that screen width
  const isScreenWidthSmallerThanDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(theme.breakpoints.values.desktop)
  );
  const authorizationConditions = useMemo(() => {
    switch (role) {
      case 'PARTNER':
        return [blockStates.EMAIL_UNVERIFIED, blockStates.NO_INSTITUTION, blockStates.NO_ACTIVE_SUBSCRIPTION];
      default:
        return [];
    }
  }, [role]);
  const { t } = useTranslate('navbar');
  const { routesTFunction } = useRoutes();
  const { isAuthorized } = useAuthorizationController(authorizationConditions);

  const { ref: divRef } = useOutsideClick<HTMLDivElement>(() => {
    setOpen(false);
  });

  const { ref: buttonRef } = useOutsideClick<HTMLButtonElement>(() => {
    setOpen(false);
  });

  useEffect(() => {
    // Whenever the location changes we should close the dropdown
    setOpen(false);
  }, [history.location]);

  useEffect(() => {
    if (userInfo) {
      const role = getRole();
      if ([ROLES.PARTNER, ROLES.ADMIN].includes(role)) {
        if (userInfo.institution) {
          setInstitutionName(userInfo.institution.institution_name);
          setDisplayName(userInfo.institution.institution_name);
        } else if (userInfo.first_name && userInfo.last_name) {
          // Is a partner but they have not been added to an institution yet
          setDisplayName(`${userInfo.first_name} ${userInfo.last_name}`);
        }
      }
    }
  }, [userInfo]);

  if (props.public) {
    return (
      <nav className={clsx('navbar', props.className)}>
        <div className='container'>
          <div className='navbar-brand'>
            <Link className='navbar-item logo' to='/'>
              Dot Health
            </Link>
          </div>
          <div
            className={clsx('navbar-menu', open ? 'is-active' : '')}
            style={{ borderLeft: '1px solid #E6E4EB', marginRight: '10px' }}
          >
            <div className='navbar-left'>
              <div className='navbar-item inactive'>
                <div className='partner-logo'>
                  <PublicInstitutionLogo />
                  <span className='partner-logo-type'>{props.organizationName}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }

  // Tabs related to institution or institution role
  const userInstitution = userInfo?.institution?.user_institution;
  const userRole = userInstitution?.role;
  const showBillingLink = userRole === 'owner' || (userInstitution && userInstitution.isBilledSeparately);

  return (
    <nav className={clsx('navbar', props.className)}>
      <div className='container'>
        <div className='navbar-brand'>
          <Link className='navbar-item logo' to='/'>
            Dot Health
          </Link>
          {isScreenWidthSmallerThanDesktop && (
            <button
              type='button'
              className={`navbar-dropdown-button ${open ? 'is-active' : ''}`}
              aria-label='menu'
              aria-expanded='false'
              onClick={() => setOpen((prev) => !prev)}
              ref={buttonRef}
            >
              <AuthUserAvatar />
            </button>
          )}
        </div>
        <div
          className={clsx('navbar-menu', open ? 'is-active' : '')}
          style={{ borderLeft: '1px solid #E6E4EB', marginRight: '10px' }}
        >
          <div className='navbar-left'>
            {(institutionName || displayName) && (
              <div className='navbar-item inactive'>
                <div className='partner-logo'>
                  <AuthInstitutionLogo />
                  <span className='partner-logo-type'>{institutionName || displayName}</span>
                </div>
              </div>
            )}
          </div>
          <div className='navbar-right'>
            {isAuthorized && (
              <>
                <Can perform={USER_ACTIONS.VIEW.DASHBOARD_PAGE}>
                  <Link
                    className={clsx('navbar-link', location.pathname.includes('requests') && 'is-active')}
                    to={routesTFunction('redirectPaths./requests')}
                  >
                    {t('requestsTable')}
                  </Link>
                </Can>
                <Can perform={USER_ACTIONS.VIEW.CLIENT_DASHBOARD_PAGE}>
                  <Link
                    className={clsx('navbar-link', location.pathname.includes('clients') && 'is-active')}
                    to={routesTFunction('redirectPaths./clients')}
                  >
                    {t('clientTable')}
                  </Link>
                </Can>
                {showBillingLink && (
                  <Can perform={USER_ACTIONS.VIEW.SETTINGS_PAGE_BILLING_TAB}>
                    <Link
                      className={clsx('navbar-link', location.pathname.includes('billing') && 'is-active')}
                      to={routesTFunction('redirectPaths./settings/billing')}
                    >
                      {t('billing')}
                    </Link>
                  </Can>
                )}
                <div className='navbar-item'>
                  <NotificationPopper />
                </div>
              </>
            )}
            {isScreenWidthSmallerThanDesktop && <NavDropdownItems isAuthorized={isAuthorized} />}
          </div>
        </div>
        {!isScreenWidthSmallerThanDesktop && (
          <div className='navbar-item'>
            <div className='nav-dropdown' ref={divRef}>
              <button
                type='button'
                className={`navbar-dropdown-button nav-dropdown__dropdown-button ${open ? 'is-active' : ''}`}
                aria-label='menu'
                aria-expanded='false'
                onClick={() => setOpen((prev) => !prev)}
              >
                <AuthUserAvatar />
              </button>

              {open && (
                <>
                  <div className='nav-dropdown__container'>
                    <NavDropdownItems isAuthorized={isAuthorized} />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default AppNav;
