import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';

// Auth User Context
import { AuthContext } from '../../contexts/auth-context';
import { useFetchAvatar } from '../../request-hooks/shared/useSharedRequest';

// Dot Types
import { User } from '../../types';

// Dot Components
import Skeleton from './Skeleton';
import UserAvatar, { AvatarSizes } from './UserAvatar';

const useStyles = makeStyles(() => ({
  skeleton: {
    borderRadius: '50%',
    transform: 'none !important'
  },
  large: {
    height: '50px',
    width: '50px'
  },
  regular: {
    height: '35px',
    width: '35px'
  },
  small: {
    height: '30px',
    width: '30px'
  },
  'x-small': {
    height: '25px',
    width: '25px'
  }
}));

const AuthUserAvatar = ({ size = 'regular' }: { size?: AvatarSizes }) => {
  const classes = useStyles();
  const { userInfo } = useContext(AuthContext);
  const { avatar, isFetchingAvatar } = useFetchAvatar(userInfo!.id, !!userInfo?.avatar?.file?.file_name);

  if (isFetchingAvatar) return <Skeleton borderRadius='50%' className={clsx(classes.skeleton, classes[size])} />;

  return <UserAvatar disableToolTip size={size} src={avatar || ''} user={userInfo as User} />;
};

export default AuthUserAvatar;
