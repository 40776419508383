/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface DownPointingTriangleIconProps extends SVGBaseProps {}

const DownPointingTriangleIcon = (props: DownPointingTriangleIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M6.1438 6.98802C5.74356 7.4832 4.98862 7.4832 4.58838 6.98802L0.620309 2.07881C0.0917349 1.42487 0.557172 0.450194 1.39802 0.450194L9.33416 0.450195C10.175 0.450195 10.6404 1.42487 10.1119 2.07881L6.1438 6.98802Z'
        fill='#672BC2'
      />
    </SVGBase>
  );
};

export default DownPointingTriangleIcon;
