/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface ObservationIconProps extends SVGBaseProps {}

const ObservationIcon = (props: ObservationIconProps) => {
  return (
    <SVGBase {...props}>
      <path d='M6 1.5H18V18C18 21.3137 15.3137 24 12 24C8.68629 24 6 21.3137 6 18V1.5Z' fill='inherit' />
      <rect x='4.5' width='15' height='4.5' rx='2.25' fill='inherit' />
    </SVGBase>
  );
};

export default ObservationIcon;
