import io from 'socket.io-client';
import dev from '../utils/dev';
import apiService from './api-service';

// eslint-disable-next-line quotes
const isProduction = process.env.REACT_APP_NODE_ENV === "'production'";
// eslint-disable-next-line quotes
const isStaging = process.env.REACT_APP_NODE_ENV === "'staging'";
const isDevelopment = process.env.NODE_ENV === 'development';

const constants = {
  WEBSOCKET_ENDPOINT_PROD: process.env.REACT_APP_BACKEND_SOCKET || '',
  WEBSOCKET_ENDPOINT_STAGING: process.env.REACT_APP_BACKEND_STAGING_SOCKET || ''
};

export class WebsocketService {
  endpoint: string;

  socket: SocketIOClient.Socket | null;

  constructor() {
    this.endpoint = '';
    this.socket = null;
    if (isProduction || isDevelopment) {
      this.endpoint = constants.WEBSOCKET_ENDPOINT_PROD;
    } else if (isStaging) {
      this.endpoint = constants.WEBSOCKET_ENDPOINT_STAGING;
    }
  }

  connect() {
    if (this.endpoint && apiService.token) {
      this.socket = io(this.endpoint, {
        path: '/websocket',
        reconnectionAttempts: 5
      });
      this.socket.on('connect', () => {
        this.socket!.emit('authentication', { token: apiService.token });
        this.socket!.on('authenticated', () => {
          dev.log('Socket Connected!');
        });
      });
      this.socket.on('connect_error', (err: string) => dev.log('Socket connect error', err));
      this.socket.on('connect_timeout', (timeout: string) => dev.log('Socket connect timeout', timeout));
      this.socket.on('error', (err: string) => dev.log('Socket error', err));
      this.socket.on('disconnect', () => dev.log('Socket disconnected'));
      this.socket.on('unauthorized', (err: { message: string }) => {
        dev.log('There was an error with the socket authentication:', err.message);
      });
      return this.socket;
    }
    return null;
  }

  disconnect() {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
      dev.log('disconnected');
    }
  }
}

export const socketservice = new WebsocketService();
