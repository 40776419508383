const dev = {
  log(...optionalParams: unknown[]) {
    // eslint-disable-next-line quotes
    if (process.env.REACT_APP_NODE_ENV !== "'production'") {
      console.log(...optionalParams);
    }
  }
};

export default dev;
