import clsx from 'clsx';
import { Root, TabsProps as PrimitiveTabProps } from '@radix-ui/react-tabs';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export interface TabsProps extends PrimitiveTabProps {
  children: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}));

const Tabs = ({ children, className, ...props }: TabsProps) => {
  const classes = useStyles();
  return (
    <Root className={clsx(classes.root, className)} activationMode='manual' {...props}>
      {children}
    </Root>
  );
};

export default Tabs;
