import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useContext } from 'react';
import { AuthContext } from '../../../contexts/auth-context';
import { useTranslate } from '../../../hooks/useTranslate';
import { generateKey, removeFirstSignInFlag } from '../../../utils/utils';
import Button from '../../shared/Button';
import Container from '../../shared/Container';
import IconButton from '../../shared/IconButton';
import CloseIcon from '../../shared/Icons/CloseIcon';
import Modal from '../../shared/Modal';
import Skeleton from '../../shared/Skeleton';
import Text from '../../shared/Typography';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'relative',
    padding: '0 !important',
    overflowY: 'auto',
    margin: 'auto',
    maxWidth: '80rem',
    minHeight: '48.5rem',
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '21.6rem, 34.4rem',
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      width: '90%',
      backgroundSize: '17.2rem, 27.5rem'
    }
  },
  modalSectionContainer: {
    width: '100%',
    padding: '4.2rem 4.9rem',
    marginBottom: '30px',
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      padding: '2.4rem 2.9rem'
    }
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  messageText: {
    maxWidth: '44rem',
    [theme.breakpoints.down(theme.breakpoints.values.widescreen)]: {
      maxWidth: '30rem'
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      maxWidth: '20rem'
    }
  },
  messageSkeleton: {
    width: '43rem',
    height: '7.6rem',
    [theme.breakpoints.down(theme.breakpoints.values.widescreen)]: {
      width: '30rem',
      height: '10.2rem'
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      width: '20rem'
    }
  },
  getStartedContaienr: {
    marginTop: '4.7rem'
  },
  closeButton: {
    padding: '1rem',
    borderRadius: '50%',
    '&:focus,&:hover': {
      backgroundColor: theme.palette.lavendar.main,
      '& svg': {
        color: theme.palette.catSkillWhite.main
      }
    }
  },
  getStartedButtonSkeleton: {
    width: '18rem',
    height: '4.6rem'
  }
}));

export interface FirstSignInModalProps {
  onClose: () => void;
  isOpen: boolean;
  getStartedCallback: () => void;
}

const FirstSignInModal = (props: FirstSignInModalProps) => {
  const { onClose, isOpen, getStartedCallback } = props;

  const classes = useStyles();

  const { userInfo } = useContext(AuthContext);
  const { t, ready } = useTranslate('firstSignInModal');

  const handleClosingModal = () => {
    removeFirstSignInFlag();
    onClose();
  };

  const handleGetStarted = () => {
    getStartedCallback();
    handleClosingModal();
  };

  return (
    <Modal
      key={generateKey()}
      open={isOpen}
      onClose={() => onClose()}
      className={clsx(classes.modal, 'illo-bike-rider')}
    >
      <Container className={classes.modalSectionContainer}>
        <Container className={classes.headerContainer}>
          <Text h3 fontWeight={500}>
            {ready ? t('welcomeTitle', { name: userInfo?.first_name }) : <Skeleton width='20ch' />}
          </Text>
          <IconButton
            onClick={handleClosingModal}
            label='close button' // aria-label
            className={classes.closeButton}
          >
            <CloseIcon color={(theme) => theme.palette.shipCove.main} />
          </IconButton>
        </Container>
        <Text paragraph className={classes.messageText}>
          {ready ? t('welcomeMessage') : <Skeleton className={classes.messageSkeleton} />}
        </Text>
        <Container className={classes.getStartedContaienr}>
          {ready ? (
            <Button
              label={t('getStartedButton')}
              onClick={handleGetStarted} // TODO: Once tutorial feature is added initialize first step here
              style={{ padding: '14px 50px' }}
            />
          ) : (
            <Skeleton className={classes.getStartedButtonSkeleton} />
          )}
        </Container>
      </Container>
    </Modal>
  );
};

export default FirstSignInModal;
