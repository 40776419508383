import { useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Auth User Context
import { AuthContext } from '../../contexts/auth-context';

// Dot Components
import Skeleton from './Skeleton';
import InstitutionLogo, { InstitutionLogoSizes } from './InstitutionLogo';
import { publicApiService } from '../../services/api-service';

const useStyles = makeStyles(() => ({
  skeleton: {
    borderRadius: '50%',
    '&--large': {
      height: '50px',
      width: '50px'
    },
    '&--regular': {
      height: '35px',
      width: '35px'
    },
    '&--small': {
      height: '30px',
      width: '30px'
    },
    '&--x-small': {
      height: '25px',
      width: '25px'
    }
  }
}));

const PublicInstitutionLogo = ({ size = 'regular' }: { size?: InstitutionLogoSizes }) => {
  const classes = useStyles();
  const { isLoggedIn } = useContext(AuthContext);
  const [logo, setLogo] = useState<BlobPart>();
  const [isFetching, setIsFetching] = useState(false);

  // This use effect will fire fetchLogo hook if there's a file uploaded
  useEffect(() => {
    const fetchPublicLogo = async () => {
      setIsFetching(true);
      try {
        const logoResponse = await publicApiService.file(`/institutions/logo`);
        setLogo(logoResponse);
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    };

    if (!isLoggedIn) {
      fetchPublicLogo();
    } else {
    }
  }, [isLoggedIn]);

  const logoBlob = useMemo(() => {
    if (!logo) return;
    return new Blob([logo], {
      type: (logo as any).type
    });
  }, [logo]);

  const logoSrc = useMemo(() => {
    if (!logoBlob) return;
    return window.URL.createObjectURL(logoBlob);
  }, [logoBlob]);

  if (isFetching) return <Skeleton className={`${classes.skeleton} ${classes.skeleton}--${size}`} />;

  return <InstitutionLogo size={size} src={logoSrc || ''} />;
};

export default PublicInstitutionLogo;
