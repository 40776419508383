/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface UploadRecordIconProps extends SVGBaseProps {}

const UploadRecordIcon = (props: UploadRecordIconProps) => {
  return (
    <SVGBase viewBox='0 0 24 21' {...props}>
      <path
        d='M1.10148e-06 13.6373C-0.000670602 12.4325 0.305882 11.2474 0.890688 10.194C1.47549 9.14062 2.31924 8.25376 3.34219 7.61722C3.61217 5.51209 4.64001 3.57748 6.23335 2.17543C7.82669 0.773389 9.87633 0 11.9987 0C14.1211 0 16.1707 0.773389 17.7641 2.17543C19.3574 3.57748 20.3852 5.51209 20.6552 7.61722C21.9241 8.40661 22.911 9.57672 23.4752 10.9605C24.0394 12.3442 24.152 13.8709 23.7969 15.3224C23.4417 16.7739 22.637 18.0762 21.4977 19.0431C20.3584 20.0101 18.9426 20.5923 17.4527 20.7067L6.54475 20.7274C2.88405 20.4286 1.10148e-06 17.37 1.10148e-06 13.6373ZM17.2869 18.5317C18.3186 18.4524 19.2988 18.049 20.0876 17.3793C20.8764 16.7096 21.4333 15.8077 21.6789 14.8025C21.9245 13.7973 21.8461 12.7403 21.455 11.7823C21.0639 10.8243 20.3801 10.0144 19.5012 9.46829L18.6209 8.91962L18.49 7.8921C18.2861 6.31448 17.5147 4.86508 16.32 3.81482C15.1252 2.76455 13.5889 2.18528 11.9982 2.18528C10.4074 2.18528 8.8711 2.76455 7.67636 3.81482C6.48162 4.86508 5.71021 6.31448 5.50631 7.8921L5.37542 8.91962L4.49733 9.46829C3.61848 10.0143 2.93465 10.8241 2.5435 11.782C2.15236 12.7399 2.07391 13.7969 2.31936 14.8021C2.56481 15.8072 3.1216 16.7091 3.91021 17.3789C4.69883 18.0487 5.67893 18.4522 6.71055 18.5317L6.89926 18.5459H17.0982L17.2869 18.5317ZM13.0895 12.0011V16.3643H10.9079V12.0011H7.63554L11.9987 6.54715L16.3619 12.0011H13.0895Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default UploadRecordIcon;
