import clsx from 'clsx';
import React from 'react';

type SizeOptions =
  | 'is-1'
  | 'is-2'
  | 'is-3'
  | 'is-4'
  | 'is-5'
  | 'is-6'
  | 'is-7'
  | 'is-8'
  | 'is-9'
  | 'is-10'
  | 'is-11'
  | 'is-12'
  | 'is-full'
  | 'is-four-fifths'
  | 'is-three-quarters'
  | 'is-two-thirds'
  | 'is-three-fifths'
  | 'is-half'
  | 'is-two-fifths'
  | 'is-one-third'
  | 'is-one-quarter'
  | 'is-one-fifth';

export interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: SizeOptions;
  container?: boolean;
  children: React.ReactNode;
  isMultiline?: boolean;
  style?: React.CSSProperties;
  item?: boolean;
  className?: string;
  isDekstop?: boolean;
  mobile?: SizeOptions;
  tablet?: SizeOptions;
}

const Grid = ({
  size,
  container,
  item,
  children,
  isMultiline,
  style,
  className,
  isDekstop,
  mobile,
  tablet
}: GridProps) => {
  const classes = clsx(
    container ? 'columns' : 'column',
    item && size,
    isMultiline && 'is-multiline',
    isDekstop && 'is-desktop',
    tablet && `${tablet}-tablet`,
    mobile && `${mobile}-mobile`,
    className
  );
  return (
    <div className={clsx(classes)} style={style}>
      {children}
    </div>
  );
};

export default Grid;
