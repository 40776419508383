import { useState } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core';

// Localization
import { TFunction } from 'react-i18next';

// Dot API
import apiService from '../../../../services/api-service';

// Dot Types
import { Invitation } from '../../../../types';

// Dot Component
import Text from '../../../shared/Typography';
import Modal from '../../../shared/Modal';
import ButtonText from '../../../shared/ButtonText';
import Button from '../../../shared/Button';
import IconButton from '../../../shared/IconButton';
import { CloseIcon } from '../../../shared/Icons';
import Divider from '../../../shared/Divider';

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: 0,
    width: 'fit-content',
    maxWidth: '500px',
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      width: '98%'
    },
    '&__header': {
      padding: '2rem 2.5rem 1.7rem 2.5rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    '&__content': {
      padding: '2.3rem 2.5rem 3rem 2.5rem'
    }
  }
}));

interface CancelInvitationActionProps {
  invite: Invitation;
  onSuccess: () => void;
  onFailure: () => void;
  t: TFunction<'settingsMyInstitutionTab'>;
}

const UsersCancelInvitationAction = ({ invite, onSuccess, onFailure, t }: CancelInvitationActionProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const classes = useStyles();

  const cancelInvitation = async () => {
    try {
      setIsModalLoading(true);
      await apiService.delete(`/institutions/invitations/${invite.id}`);
      onSuccess();
    } catch (error) {
      console.log(error);
      onFailure();
    } finally {
      setIsModalOpen(false);
      setIsModalLoading(false);
    }
  };

  return (
    <>
      <ButtonText isDanger onClick={() => setIsModalOpen(true)} label={t('cancelInvitationModal.cancelButtonText')} />
      <Modal align='left' open={isModalOpen} onClose={() => setIsModalOpen(false)} className={classes.modal}>
        <div className={`${classes.modal}__header`}>
          <Text h5 color='grey-40'>
            {t('cancelInvitationModal.heading')}
          </Text>
          <IconButton label='' onClick={() => setIsModalOpen(false)}>
            <CloseIcon color={(theme) => theme.palette.shipCove.main} size='small' />
          </IconButton>
        </div>
        <Divider />
        <div className={`${classes.modal}__content`}>
          <Text span color='grey-40' className='mr12'>
            {t('cancelInvitationModal.paragraph1', { inviteEmail: invite.email_address })}
          </Text>
          <div className='flex gap-30 mt55'>
            <ButtonText
              style={{ margin: '14px 45px 13px 45px' }}
              label={t('cancelInvitationModal.keepInviteButtonText')}
              onClick={() => setIsModalOpen(false)}
            />
            <Button
              style={{ padding: '14px 45px 13px 45px' }}
              isDanger
              label={t('cancelInvitationModal.yesCancelButtonText')}
              onClick={() => cancelInvitation()}
              isLoading={isModalLoading}
              isDisabled={isModalLoading}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UsersCancelInvitationAction;
