/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface GoalIconProps extends SVGBaseProps {}

const GoalIcon = (props: GoalIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M21.84 2.4H21.6H18V0H6V2.4H3H2.16H0V10.8C0 12 1.2 13.2 2.4 13.2H6.24C6.72 15.48 7.92 17.52 10.8 17.88V20.4C7.2 20.64 7.2 21.96 7.2 23.52V24H16.8V23.64C16.8 22.08 16.8 20.76 13.2 20.52V18C16.2 17.64 17.4 15.6 17.76 13.32H21.6C22.8 13.32 24 12.12 24 10.92V2.4H21.84ZM2.4 10.8V4.8H6V7.2V10.8C4.32 10.8 2.88 10.8 2.4 10.8ZM21.6 10.8C21.12 10.8 19.68 10.8 18 10.8V4.8H19.2H21.6V10.8Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default GoalIcon;
