/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface ImagingIconProps extends SVGBaseProps {}

const ImagingIcon = (props: ImagingIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4ZM12.963 20.477V18.5818C13.8801 19.0339 14.8889 19.3052 15.8977 19.3052C17.6402 19.3052 19.1993 18.6722 20.3915 17.4967C20.7584 17.135 20.7584 16.5924 20.3915 16.2307C20.0247 15.869 19.4744 15.869 19.1076 16.2307C18.2822 17.0445 17.0899 17.5871 15.8977 17.5871C14.7972 17.5871 13.7884 17.2254 12.963 16.502V13.337C15.2557 14.4222 18.0071 14.0604 19.933 12.2519C20.2998 11.8902 20.2998 11.3476 19.933 10.9859C19.5661 10.6242 19.0159 10.6242 18.649 10.9859C17.0899 12.5232 14.6138 12.6136 12.963 11.1668V8.00185C14.7972 8.72526 16.9065 8.45398 18.3739 7.00715C18.7407 6.64545 18.7407 6.10289 18.3739 5.74118C18.0071 5.37947 17.4568 5.37947 17.0899 5.74118C15.9894 6.8263 14.2469 6.91673 12.963 5.92203V3.57093C12.963 3.02837 12.5961 2.66667 12.0459 2.66667C11.4956 2.66667 11.1287 3.02837 11.1287 3.57093V5.74118C9.93651 6.64545 8.194 6.64545 7.00176 5.56032C6.63492 5.19862 6.08466 5.19862 5.71781 5.56032C5.35097 5.92203 5.35097 6.46459 5.71781 6.8263C6.63492 7.73057 7.91887 8.27313 9.20282 8.27313C9.8448 8.27313 10.4868 8.09227 11.1287 7.91142V11.0764C9.47795 12.4328 7.00176 12.4328 5.44268 10.8955C5.07584 10.5338 4.52557 10.5338 4.15873 10.8955C3.79189 11.2572 3.79189 11.7998 4.15873 12.1615C5.35097 13.337 6.91005 13.8796 8.37743 13.8796C9.29453 13.8796 10.2116 13.6987 11.037 13.2466V16.4115C10.2116 17.135 9.20282 17.4967 8.10229 17.4967C6.91005 17.4967 5.71781 17.0445 4.89242 16.1403C4.52557 15.7786 3.97531 15.7786 3.60847 16.1403C3.24162 16.502 3.24162 17.0445 3.60847 17.4062C4.80071 18.5818 6.4515 19.2148 8.10229 19.2148C9.11111 19.2148 10.1199 18.9435 11.037 18.4914V20.477C11.037 21.0089 11.4682 21.44 12 21.44C12.5318 21.44 12.963 21.0089 12.963 20.477Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default ImagingIcon;
