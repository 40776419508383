// Material UI
import { makeStyles } from '@material-ui/core/';

// Dot Hooks
import { useTranslate } from '../../../../hooks/useTranslate';

// Dot Components
import Skeleton from '../../../shared/Skeleton';
import Text from '../../../shared/Typography';
import Button from '../../../shared/Button';
import Divider from '../../../shared/Divider';

const useStyles = makeStyles(() => ({
  root: {
    width: '65%',
    margin: '0 auto',
    padding: '6.5rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  sectionContainer: {
    width: '80%',
    margin: '0 auto'
  },
  divider: {
    marginBottom: '3rem',
    marginTop: '4rem',
    width: '100%'
  }
}));

const OverviewSkeleton = () => {
  const classes = useStyles();
  const { t } = useTranslate('settingsOverviewTab');

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.sectionContainer}>
          <Text h5 color='grey-60' className='mb20'>
            {t('userDetailsCard.heading')}
          </Text>
          <div className='flex gap-35'>
            <div className='flex gap-5' style={{ width: '120px' }}>
              <Skeleton borderRadius='7px' height='2rem' width='13rem' />
              <Skeleton borderRadius='7px' height='2rem' width='8rem' />
            </div>
            <div className='flex gap-5' style={{ width: '120px' }}>
              <Skeleton borderRadius='7px' height='2rem' width='12rem' />
              <Skeleton borderRadius='7px' height='2rem' width='6rem' />
            </div>
            <div className='flex gap-5' style={{ width: '120px' }}>
              <Skeleton borderRadius='7px' height='2rem' width='8rem' />
              <Skeleton borderRadius='7px' height='2rem' width='18rem' />
            </div>
          </div>
          <div className='flex gap-50'>
            <div>
              <Skeleton className='mt35 mb15' borderRadius='7px' height='2rem' width='7rem' />
              <div className='flex gap-30'>
                <Skeleton borderRadius='50%' height='5rem' width='5rem' />
                <Button isSecondary disabled label={t('buttons.uploadAvatarButtonText')} />
              </div>
            </div>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.sectionContainer}>
          <Text h5 color='grey-60' className='mb20'>
            {t('userOrganizationCard.heading')}
          </Text>
          <div className='flex gap-35'>
            <div className='flex gap-5' style={{ width: '120px' }}>
              <Skeleton borderRadius='7px' height='2rem' width='10rem' />
              <Skeleton borderRadius='7px' height='2rem' width='15rem' />
            </div>
            <div className='flex gap-5' style={{ width: '120px' }}>
              <Skeleton borderRadius='7px' height='2rem' width='5rem' />
              <Skeleton borderRadius='7px' height='2rem' width='8rem' />
            </div>
          </div>
          <div className='flex gap-50'>
            <div>
              <Skeleton className='mt35 mb15' borderRadius='7px' height='2rem' width='5rem' />
              <div className='flex gap-30'>
                <Skeleton borderRadius='50%' height='5rem' width='5rem' />
              </div>
            </div>
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.sectionContainer}>
          <Text h5 color='grey-60' className='mb20'>
            {t('securityCard.heading')}
          </Text>
          <div className='flex-column gap-5'>
            <Skeleton borderRadius='7px' height='2rem' width='13rem' />
            <Skeleton borderRadius='7px' height='2rem' width='14rem' />
            <Skeleton borderRadius='7px' height='2rem' width='20rem' />
          </div>
        </div>

        <Divider className={classes.divider} />

        <div className={classes.sectionContainer}>
          <Text h5 color='grey-60' className='mb20'>
            {t('helpSection.heading')}
          </Text>
          <div className='flex-column gap-5'>
            <Skeleton borderRadius='7px' height='2rem' width='13rem' />
            <Skeleton borderRadius='7px' height='2rem' width='14rem' />
            <Skeleton borderRadius='7px' height='2rem' width='20rem' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewSkeleton;
