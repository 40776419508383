import { createMuiTheme } from '@material-ui/core/styles';

/**
 * To update the theme with new properties it requires updates to
 * createMuiTheme.d.ts file in this folder to add new appropriate type values
 * More on how to do that here:
 * https://stackoverflow.com/questions/61120760/how-to-extend-material-ui-theme-with-typescript
 */
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Avenir Next , Sans-serif',
    h1: {
      fontSize: 'var(--font-size-h1)'
    },
    body1: { fontSize: 15 },
    body2: {
      fontSize: '15px',
      fontWeight: 300,
      lineHeight: '25px',
      color: '#574F63'
    },
    heading: {
      fontSize: '1.8rem'
    },
    h5: {
      fontSize: '2rem'
    },
    xLarge: { fontSize: '3.2rem' },
    large: { fontSize: '2.8rem' },
    medium: { fontSize: '2.2rem' },
    small: { fontSize: '2rem' },
    xSmall: { fontSize: '1.8rem' },
    xxSmall: { fontSize: '1.4rem' },
    xxxSmall: { fontSize: '1.2rem' }
  },
  palette: {
    primary: { main: '#672BC2', hover: '#9F69F0' },
    warning: { main: '#efc11e' },
    error: { main: '#EF1E2B' },
    highlightPink: { main: '#EF1EA4' },
    purpleHeart: { main: '#672BC2', secondary: '#9F69F0' },
    lavendar: { main: '#9F69F0' },
    success: { main: '#14E4B2' },
    link: { main: '#2EC8FF' },
    mint: { main: '#2EFFCD' },
    forestGreen: { main: '#0D9B7B' },
    neonPurple: { main: '#DF43F0' },
    discoBlue: { main: '#2EC8FF' },
    orange: { main: '#F09456' },
    turqBlue: { main: '#2effcd' },
    rose: { main: '#F043AB' },
    neonBlue: { main: '#5B53FF' },
    shamRock: { main: '#56F094' },
    mandy: { main: '#ef566c' },
    greenYellow: { main: '#b8eb2a' },
    catSkillWhite: { main: '#EBEEFB' },
    grey: { 40: '#574F63', 80: '#C4C5D2', 60: '#95919C', 100: '#FAFBFF', 90: '#E6E4EB' },
    shipCove: { main: '#8285A4' },
    newRowGreen: { main: 'rgba(83,230,215,0.2)' },
    border: { main: '#672BC2', secondary: '#E6E4EB' },
    text: { main: '#574F63', secondary: '#C4C5D2' },
    grayscale: { 30: '#40374F', 60: '#95919C', 90: '#E6E4EB' }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      //Values for the breakpoints below come from  variables.css
      fullhd: 1408,
      widescreen: 1217,
      desktop: 1025,
      tablet: 769,
      mobile: 420
    }
  },
  overrides: {
    MuiTypography: {
      body2: {
        fontSize: '15px'
      }
    },
    MuiButton: {
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#9F69F0'
        }
      }
    }
  }
});

export default theme;
