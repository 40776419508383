import { makeStyles } from '@material-ui/core/styles';
import { TabsContentProps, TabsContent as PrimitiveTabsContent } from '@radix-ui/react-tabs';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() => ({
  tabsContentRoot: {
    width: '100%'
  }
}));

const TabsContent = React.forwardRef<HTMLDivElement, TabsContentProps>((props, ref) => {
  const classes = useStyles();
  return <PrimitiveTabsContent ref={ref} className={clsx(classes.tabsContentRoot, props.className)} {...props} />;
});

export default TabsContent;
