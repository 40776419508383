import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface RecordsTabWrapperResourceIconWrapperProps {
  icon: React.ReactElement;
}

const useStyles = makeStyles(() => ({
  container: {
    marginRight: '3px',
    '& svg': {
      height: '20px',
      width: '20px'
    }
  }
}));

const RecordsTabWrapperResourceIconWrapper = (props: RecordsTabWrapperResourceIconWrapperProps) => {
  const { icon } = props;
  const classes = useStyles();

  return <span className={classes.container}>{icon}</span>;
};

export default RecordsTabWrapperResourceIconWrapper;
