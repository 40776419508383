import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useRoutes } from '../../../hooks/useRoutes';
import { useTranslate } from '../../../hooks/useTranslate';
import { useFetchPatientClinicalNotes } from '../../../request-hooks/partners/usePartnerRequests';
import { PartnerClient } from '../../../types';
import { generateKey, isEmptyArray, useFormatDate } from '../../../utils/utils';
import Card from '../../shared/Card';
import { AddIcon } from '../../shared/Icons';
import Skeleton from '../../shared/Skeleton';
import Spinner from '../../shared/Spinner';
import Text from '../../shared/Typography';

interface ClinicalNotesListCardProps {
  patient: PartnerClient;
}

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.4rem 0px 2.2rem',
    paddingLeft: '1rem',
    alignItems: 'center'
  },
  card: {
    width: '100%'
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem 0px',
    width: '100%'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem 0px'
  },
  link: {
    width: '75%',
    wordWrap: 'break-word'
  },
  dateText: {
    fontSize: '12px',
    color: `${theme.palette.grey[60]} !important`
  }
}));

interface Params {
  internalId: string;
}

export const ClinicalNotesListCardSkeleton = () => {
  const classes = useStyles();

  const ListSkeleton = () => {
    return (
      <div className={classes.item}>
        <Skeleton width='22ch' />
        <Skeleton width='7ch' />
      </div>
    );
  };
  return (
    <>
      <div className={classes.headerRoot}>
        <Text h5 style={{ fontWeight: 'normal' }}>
          <Skeleton height='100%' width='12ch' />
        </Text>
      </div>
      <Card noShadow className={classes.card}>
        {[...new Array(3)].map(() => {
          return <ListSkeleton key={generateKey()} />;
        })}
      </Card>
    </>
  );
};

const ClinicalNotesListCard = ({ patient }: ClinicalNotesListCardProps) => {
  const { patientResources, isFetchingPatientResources } = useFetchPatientClinicalNotes(patient.id);
  const formatDate = useFormatDate();
  const { routesTFunction } = useRoutes();
  const { internalId } = useParams<Params>();
  const location = useLocation();
  const classes = useStyles();
  const { t, ready } = useTranslate('clinicalNotesListCard');

  if (!ready || isFetchingPatientResources) {
    return <ClinicalNotesListCardSkeleton />;
  }

  return (
    <>
      <div className={classes.headerRoot}>
        <Text h5 style={{ fontWeight: 'normal' }}>
          {t('title')}
        </Text>
        <Link
          to={{
            pathname: routesTFunction('redirectPaths./add-note/:internalId', { internalId: internalId }),
            state: { user: patient, background: location }
          }}
          aria-label={t('addClinicalNoteLinkLabel', { patientName: `${patient.first_name} ${patient.last_name}` })}
        >
          <AddIcon color={(theme) => theme.palette.primary.main} />
        </Link>
      </div>
      <Card noShadow className={classes.card}>
        {isFetchingPatientResources || !ready ? (
          <Spinner />
        ) : !isEmptyArray(patientResources) ? (
          patientResources?.map(({ generated, updatedAt, id, ...other }) => {
            return (
              <div key={generateKey('clinical-note', id)} className={classes.item}>
                <Text
                  link
                  className={clsx('type-link', classes.link)}
                  href={{
                    pathname: `${routesTFunction('redirectPaths./clients/:internalId/records', { internalId })}`,
                    state: { resource: { generated, id, updatedAt, ...other } }
                  }}
                >
                  {generated.title}
                </Text>
                <Text small color='grey-60' className={classes.dateText}>
                  {formatDate(updatedAt)}
                </Text>
              </div>
            );
          })
        ) : (
          <>
            <Text paragraph align='center'>
              {t('emptyState.message', { patientName: clsx(patient.first_name, patient.last_name) })}
            </Text>
          </>
        )}
      </Card>
    </>
  );
};

export default ClinicalNotesListCard;
