/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface ClinicalNoteIconProps extends SVGBaseProps {}

const ClinicalNoteIcon = (props: ClinicalNoteIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.33333 0C2.38781 0 0 2.38781 0 5.33333V18.6667C0 21.6122 2.38781 24 5.33333 24H18.6667C21.6122 24 24 21.6122 24 18.6667V5.33333C24 2.38781 21.6122 0 18.6667 0H5.33333ZM13.3333 18.6667C14.0697 18.6667 14.6667 18.0697 14.6667 17.3333C14.6667 16.597 14.0697 16 13.3333 16H6.66667C5.93029 16 5.33333 16.597 5.33333 17.3333C5.33333 18.0697 5.93029 18.6667 6.66667 18.6667H13.3333ZM17.3333 13.3333C18.0697 13.3333 18.6667 12.7364 18.6667 12C18.6667 11.2636 18.0697 10.6667 17.3333 10.6667H6.66667C5.93029 10.6667 5.33333 11.2636 5.33333 12C5.33333 12.7364 5.93029 13.3333 6.66667 13.3333H17.3333ZM17.3333 8C18.0697 8 18.6667 7.40305 18.6667 6.66667C18.6667 5.93029 18.0697 5.33333 17.3333 5.33333H6.66667C5.93029 5.33333 5.33333 5.93029 5.33333 6.66667C5.33333 7.40305 5.93029 8 6.66667 8H17.3333Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default ClinicalNoteIcon;
