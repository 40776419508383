import { AccessPermission, CodeableConcept, PartnerClient } from '..';
import { InformationRequest } from '../common/IInformationRequest';

export interface Generated {
  title: string;
  date: string;
  text: string;
}

export interface Reference {
  reference: string;
  type: string;
  display: string;
  identifier: string;
}

export interface Performer {
  performerName: string;
  performerType: CodeableConcept;
  institution: string;
}

export interface Requester {
  name: string;
  institution: string;
}

export type GenericHealthInformationResource<T = unknown> = T & {
  id: string;
  createdAt: string;
  updatedAt: string;
  values: T;
  resource_type: ResourceType;
  generated: Generated;
  source: string;
  reviewed: boolean;
  draft: boolean;
  institution_address_id: number;
  UserId: number;
  phi_checked: boolean;
  InformationRequestId: number;
  user: PartnerClient;
  files?: ServerFile[];
  access_permissions: AccessPermission[];
  name?: string;
};

export type WithInformationRequest<T> = T & {
  information_request?: InformationRequest;
};

export interface ServerFile {
  id: number;
  file_name: string;
  mime_type: string;
  created_at: string;
  updated_at: string;
  resource_id: string;
  src?: string;
  page_count?: number;
  file_size?: number;
}

export interface ResourceIdentifier {
  value: string;
  source: string;
}

export enum ResourceType {
  ClinicalNoteResource = 'clinicalNote',
  CommunicationResource = 'communication',
  MedicalConditionResource = 'condition',
  DiagnosticReportResource = 'diagnosticReport',
  FormResource = 'form',
  ImagingStudyResource = 'imagingStudy',
  ImmunizationResource = 'immunization',
  MedicationRequestResource = 'medicationRequest',
  ResourceGroupResource = 'resourceGroup',
  AllergyResource = 'allergy',
  FamilyMemberHistoryResource = 'familyMemberHistory',
  PatientVisitResource = 'patientVisit',
  GoalResource = 'goal',
  ServiceRequestResource = 'serviceRequest',
  MedicationDispenseResource = 'medicationDispense',
  ObservationResource = 'observation',
  ProcedureResource = 'procedure'
}
