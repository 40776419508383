import moment from 'moment';
import { InfiniteData, QueryClient } from 'react-query';
import { IncomingRequest, UseGetPartnerRequestsQueryResponse } from '../../request-hooks/partners/usePartnerRequests';
import { InformationRequest } from '../../types';
import { INCOMING_REQUEST_QUERY_KEY } from '../constants';

/**
 * Finds the request with the matching token and returns it along with the remaining requests
 * @function
 * @param {InformationRequest[]} information_requests
 * @param {string} token
 * @returns {Object}
 * @property {InformationRequest | undefined} request
 * @property {InformationRequest[]} filteredRequests
 *
 */
export const findAndFilterInformationRequestsByToken = (information_requests: InformationRequest[], token?: string) => {
  if (!information_requests.length || !token) {
    return { request: undefined, filteredRequests: [] };
  }

  let matchedRequest: InformationRequest | undefined;
  const filteredRequests = information_requests.filter((request) => {
    if (request.token === token) {
      matchedRequest = request;
    }

    return request.token !== token;
  });

  return {
    request: matchedRequest,
    filteredRequests
  };
};

export type InfinitePhysicianRequestResult = InfiniteData<UseGetPartnerRequestsQueryResponse> | undefined;

/**
 * Updates the information request cache based on the query key
 * It finds the request with the matching token and updates the selected request
 * If the order query is 'LastUpdated', the updated request is placed on top of the list.
 * Otherwise, the updated request is placed in the same position.
  @function
  @param {string} queryClient 
  @param {string} queryKey 
  @param {InformationRequest} updatedInformationRequest
  @param {string} orderQuery
  @returns {void}
*/
export const updateInformationRequestCache = (
  queryClient: QueryClient,
  queryKey: string,
  updatedInformationRequest: InformationRequest,
  orderQuery?: string
) => {
  queryClient.setQueryData<InfinitePhysicianRequestResult>(queryKey, (data) => {
    if (!data) return;
    if (!updatedInformationRequest.token) return data;

    const newPagesArray = data.pages.map(({ information_requests, meta }) => {
      const { request, filteredRequests } = findAndFilterInformationRequestsByToken(
        information_requests,
        updatedInformationRequest.token
      );

      if (!request) {
        return { information_requests, meta };
      }

      const updatedRequest = {
        ...request,
        ...updatedInformationRequest
      };

      // place the updated request on top of the list
      if (orderQuery === 'LastUpdated') {
        return {
          information_requests: [updatedRequest, ...filteredRequests],
          meta
        };
      }

      // place the updated request  on the same position
      const updatedRequests = information_requests.map((request: InformationRequest) => {
        if (request.token === updatedInformationRequest.token) {
          return {
            ...request,
            ...updatedInformationRequest
          };
        }

        return request;
      });

      return {
        information_requests: updatedRequests,
        meta
      };
    });

    return {
      pages: newPagesArray,
      pageParams: data.pageParams
    };
  });
};

export const addNewRequestToCache = (queryClient: QueryClient, queryKey: string, request: InformationRequest) => {
  // Use the setQueryData method of the queryClient object to update the cache for the given queryKey
  queryClient.setQueryData<InfinitePhysicianRequestResult>(queryKey, (data) => {
    // If there is no data in the cache
    // return an object with a single page containing the new request
    if (!data) {
      const newData = {
        pages: [{ information_requests: [request], meta: { total_pages: 1, count: 1 } }],
        pageParams: [undefined]
      };
      return { ...newData };
    }

    // Initialize variables to keep track of where to insert the new request
    let firstReadInformationRequestIndex = -1;
    let informationRequestIndex = -1;

    // Iterate through each page of data
    for (let pageIndex = 0; pageIndex < data.pages.length; pageIndex++) {
      const { information_requests: informationRequests } = data.pages[pageIndex];

      // Iterate through each information_request within each page
      for (
        let informationRequestsIndex = 0;
        informationRequestsIndex < informationRequests.length;
        informationRequestsIndex++
      ) {
        const informationRequest = informationRequests[informationRequestsIndex];

        // If this is the first existing request whose last_updated_at property is before
        // the new request's last_updated_at property
        if (moment(informationRequest.last_updated_at).isBefore(request.last_updated_at)) {
          // Set variables to keep track of where to insert new request
          informationRequestIndex = informationRequestsIndex;
          firstReadInformationRequestIndex = pageIndex;
          break;
        }
      }

      // If we found where to insert new request, break out of loop
      if (firstReadInformationRequestIndex >= 0) break;
    }

    // If we didn't find any existing requests whose last_updated_at property is before
    // the new request's last_updated_at property,
    if (firstReadInformationRequestIndex < 0) {
      firstReadInformationRequestIndex = 0;
    }

    if (informationRequestIndex < 0) {
      // Insert new request at end of array on first page
      informationRequestIndex = data.pages[firstReadInformationRequestIndex].information_requests.length;
    }

    // Create a new array of pages with new request inserted at appropriate index and
    // sorted in descending order based on last_updated_at property.
    const newPagesArray = data.pages.map(({ information_requests, meta }, index) => {
      if (index === firstReadInformationRequestIndex) {
        const newInformationRequestsArray = [...information_requests];
        newInformationRequestsArray.splice(informationRequestIndex, 0, request);
        return {
          information_requests: newInformationRequestsArray.sort((a, b) =>
            moment(b.last_updated_at).diff(moment(a.last_updated_at))
          ),
          meta
        };
      }
      return { information_requests, meta };
    });

    return {
      pages: newPagesArray,
      pageParams: data.pageParams
    };
  });
};

export const deleteIncomingRequestFromCache = (queryClient: QueryClient, requestId: string) => {
  queryClient.setQueryData(INCOMING_REQUEST_QUERY_KEY, (data: any) => {
    const newPagesArray = data.pages.map(({ information_requests, meta }: any) => {
      const newInformationRequest = information_requests.filter(({ id }: { id: string }) => id !== requestId);
      return { information_requests: newInformationRequest, meta };
    });
    return {
      pages: newPagesArray,
      pageParams: data.pageParams
    };
  });
};

export const addIncomingRequestsToCache = (queryClient: QueryClient, requests: IncomingRequest[]) => {
  queryClient.setQueryData(INCOMING_REQUEST_QUERY_KEY, (data: any) => {
    // If there is no data in the cache
    // return an object with a the new meta data
    if (!data)
      return { pages: [{ information_requests: [...requests], meta: { total_pages: 1, count: 1 } }], pageParams: [0] };

    const newPagesArray = data.pages.map(({ information_requests, meta }: any, index: number) => {
      if (index === 0) {
        const newIncomingRequests = [...requests, ...information_requests];
        return { information_requests: newIncomingRequests, meta };
      }
      return { information_requests, meta };
    });

    return { pages: newPagesArray, pageParams: data.pageParams };
  });
};

export const incrementRequestCountMeta = (queryClient: QueryClient, queryKey: string, incrementAmount?: number) => {
  queryClient.setQueryData(queryKey, (data: any) => {
    // If there is no data in the cache
    // return an object with a the new meta data
    if (!data) {
      return {
        meta: {
          count: 1
        }
      };
    }
    return {
      ...data,
      meta: {
        // eslint-disable-next-line
        count: data.meta.count + (incrementAmount ? incrementAmount : 1)
      }
    };
  });
};

export const decrementRequestCountMeta = (queryClient: QueryClient, queryKey: string, decrementAount?: number) => {
  queryClient.setQueryData(queryKey, (data: any) => {
    // If there is no data in the cache
    // return an object with a the new meta data
    if (!data) {
      return {
        meta: {
          count: 0
        }
      };
    }

    return {
      ...data,
      meta: {
        // eslint-disable-next-line
        count: data.meta.count - (decrementAount ? decrementAount : 1)
      }
    };
  });
};
