import {
  makeStyles,
  Snackbar as MUISnackbar,
  SnackbarContent,
  SnackbarProps as MUISnackbarProps
} from '@material-ui/core';
import clsx from 'clsx';
import Icon from './Icon';
import Text from './Typography';

export interface SnackbarProps extends MUISnackbarProps {
  type?: 'success' | 'progress' | 'error' | 'none';
}
interface StyleProps {
  type?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.typography.body2,
    backgroundColor: '#fff1a8',
    width: '100%',
    fontWeight: 600,
    flexGrow: 'inherit',
    borderRadius: '1.2rem',
    color: '#fff',
    boxShadow: 'none'
  },
  snackbarBody: {
    display: 'flex',
    '& i': {
      marginRight: '2rem'
    }
  },
  success: {
    backgroundColor: theme.palette.forestGreen.main
  },
  error: {
    backgroundColor: theme.palette.mandy.main
  },
  progress: {
    backgroundColor: theme.palette.primary.main
  },
  errorText: {
    color: (props: StyleProps) => (!!props.type && props.type !== 'none' ? '#fff' : 'inherit'),
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '2.5rem'
  }
}));

const SnackbarIcon = ({ type }: SnackbarProps) => {
  switch (type) {
    case 'success':
      return <Icon name='success-snackbar' size='medium' />;
    case 'progress':
      return <Icon name='progress-snackbar' size='medium' className='spin' />;
    case 'error':
      return <Icon name='error-snackbar' size='medium' />;
    case 'none':
      return <Icon name='check-purple' size='medium' />;
    default:
      return null;
  }
};

const Snackbar = ({ open, onClose, type, message, autoHideDuration, action, ...other }: SnackbarProps) => {
  const classes = useStyles({ type });

  return (
    <MUISnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      {...other}
    >
      <SnackbarContent
        className={clsx(
          classes.container,
          type === 'success' && classes.success,
          type === 'error' && classes.error,
          type === 'progress' && classes.progress
        )}
        message={
          <div className={classes.snackbarBody}>
            <SnackbarIcon type={type} />
            <Text paragraph className={clsx(classes.errorText)}>
              {message}
            </Text>
          </div>
        }
        action={action}
      />
    </MUISnackbar>
  );
};

export default Snackbar;
