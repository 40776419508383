import moment from 'moment';
import { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery as useReactQuery } from 'react-query';
import { AuthContext } from '../../contexts/auth-context';
import { SocketContext } from '../../contexts/socket-context';
import useQuery, { GetQueryOptions, useGetQuery } from '../../hooks/useQuery';
import apiService from '../../services/api-service';
import {
  ClinicalNoteResource,
  GenericHealthInformationResource,
  Meta,
  PartnerClient,
  TimelineActivity,
  InformationRequest,
  ClientUpload,
  VersionedText,
  StatusHistory,
  BillingStatement
} from '../../types';
import {
  BLOCKED_RESOURCE_TYPES,
  CLIENT_TAB_STATISTICS_QUERY_KEY,
  INCOMING_REQUEST_QUERY_KEY,
  SOCKET_EVENT
} from '../../utils/constants';
import { isEmptyArray, isResourceActivity } from '../../utils/utils';
import { SCOPES } from '../../utils/scopes';

export interface UseCreateClinicalNoteRequest {
  user:
    | {
        first_name: string;
        last_name: string;
        email: string;
      }
    | { id: number };
  resource: {
    patientName: string;
    resource_type: 'clinicalNote';
    subject: string;
    recordedAt: string | Date;
    practitioner?: string;
    contents: string;
    diagnosis?: string;
    category?: string;
  };
}

export const useCreateClinicalNote = () => {
  const { makeRequest, loading, error, response } = useQuery<UseCreateClinicalNoteRequest>('/resources', 'POST');
  return {
    createClinicalNote: makeRequest,
    isCreatingClinicalNote: loading,
    errorWhileCreatingClinicalNote: error,
    createClinicalNoteResponse: response
  };
};

export interface UseCreatePatientsRequest {
  file: File | undefined;
  fileField: 'file';
  formdata?: {
    users: string;
  };
}

export const useCreatePatients = () => {
  const { loading, error, makeRequest, response } = useQuery<UseCreatePatientsRequest>('/users', 'FORM_DATA');
  return { isLoading: loading, errorWhileCreatingPatients: error, createPatients: makeRequest, response };
};

export interface UseCreatePatientRequest {
  user: ClientUpload;
}

export const useCreatePatient = () => {
  const { loading, error, makeRequest, response } = useQuery<UseCreatePatientRequest>('/user', 'POST');
  return { isLoading: loading, errorWhileCreatingPatients: error, createPatients: makeRequest, response };
};

export interface UseFetchPatientDetailsResponse {
  date_added: string;
  user: PartnerClient;
}

export const useFetchPatientDetails = (token: string, options?: GetQueryOptions, include?: string) => {
  const { makeRequest, loading, error, response } = useGetQuery<UseFetchPatientDetailsResponse>(
    `/users/institution/${token}?include=designated_users,appointed_users,address,insurance,gender${
      !!include && `,${include}`
    }`,
    options
  );
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (!socket) return;
    socket.on(SOCKET_EVENT.UPDATE_CLIENT, async () => {
      await makeRequest(undefined, { resetCache: true }); /* fetch new data and update cache */
    });
  }, [makeRequest, response, socket]);

  return {
    fetchPatientDetails: makeRequest,
    isFetchingPatientDetails: loading,
    errorWhileFetchingPatientDetails: error,
    patientDetails: response
  };
};

export interface UseGetPartnerRequestsQueryResponse {
  information_requests: InformationRequest[];
  meta: Meta;
}

export interface UseFetchPartnerRequestsResponse {
  meta: Meta;
}

export interface SocketInformationRequestResponse {
  information_request: InformationRequest;
}

interface FetchPartnerRequestsMetaProps {
  userId?: number;
  enabled?: boolean;
  daysThreshold?: number;
}

export const useFetchPartnerRequestsMeta = ({ userId, enabled, daysThreshold }: FetchPartnerRequestsMetaProps) => {
  const url = useMemo(
    () =>
      `/information_requests?${userId ? `creatorId=${userId}&` : ''}${
        daysThreshold ? `from=${moment().subtract(daysThreshold, 'day').format('MM-DD-YYYY')}&` : ''
      }count_only=1`,
    [userId, daysThreshold]
  );

  const queryKey = useMemo(
    () => `partner-request-meta${userId ? `-${userId}` : ''}${daysThreshold ? `-${daysThreshold}-days` : ''}`,
    [userId, daysThreshold]
  );

  const { data, isLoading, error, status, isStale } = useReactQuery<UseFetchPartnerRequestsResponse>(
    queryKey,
    () => {
      return apiService.get(url);
    },
    {
      // This data will be kept fresh for 30 minutes before becoming stale and forced to be refetched
      staleTime: 30 * 60 * 1000,
      // Time the Inactive Cache will live in memory for 35 mins before it is garbaged and have to be refetched
      cacheTime: 35 * 60 * 1000,
      enabled: enabled
    }
  );

  return {
    isFetchingPartnerRequestsMeta: isLoading,
    errorWhileFetchingPartnerRequestsMeta: error,
    partnerRequestsMeta: data?.meta,
    partnerRequestsMetaStatus: status,
    partnerRequestsMetaIsStale: isStale
  };
};

export const useGetPartnerRequests = (query: string, order: string, shouldFetchAuthUserRequests?: boolean) => {
  const [informationRequests, setInformationRequests] = useState<InformationRequest[]>([]);
  const { socket } = useContext(SocketContext);
  const { userInfo } = useContext(AuthContext);

  const [hasRequests, setHasRequests] = useState<boolean>();

  const { partnerRequestsMeta, isFetchingPartnerRequestsMeta, errorWhileFetchingPartnerRequestsMeta } =
    useFetchPartnerRequestsMeta({});

  useEffect(() => {
    if (partnerRequestsMeta && partnerRequestsMeta.count > 0) {
      setHasRequests(true);
    } else {
      setHasRequests(false);
    }
  }, [partnerRequestsMeta]);

  const searchQuery = useMemo(() => {
    return encodeURI(query);
  }, [query]);

  const orderQuery = useMemo(() => {
    return encodeURI(order);
  }, [order]);

  const queryKey = useMemo(
    () => `information_requests-search-${searchQuery}-order-${orderQuery}-${shouldFetchAuthUserRequests}`,
    [searchQuery, orderQuery, shouldFetchAuthUserRequests]
  );
  // More on useInfiniteQuery here: https://react-query.tanstack.com/reference/useInfiniteQuery
  const { isLoading, data, fetchNextPage, isFetchingNextPage, error, hasNextPage, status } =
    useInfiniteQuery<UseGetPartnerRequestsQueryResponse>(
      queryKey,
      ({ pageParam = 0 }) => {
        if (shouldFetchAuthUserRequests && userInfo) {
          return apiService.get(
            `/information_requests?creatorId=${userInfo.id}&include=physician,status_history,institution,user`,
            { params: { page: pageParam, query: searchQuery, order: orderQuery, limit: 12 } }
          );
        }

        return apiService.get(`/information_requests?include=physician,status_history,institution,user`, {
          params: { page: pageParam, query: searchQuery, order: orderQuery, limit: 12 }
        });
      },
      {
        getNextPageParam: ({ meta }, allPageData) => {
          const newPageNumber = allPageData.length;

          const hasMorePages = meta.total_pages > newPageNumber;

          if (hasMorePages) {
            return newPageNumber;
          }

          return undefined;
        },
        refetchOnWindowFocus: false,
        enabled: hasRequests,
        // Request list will be kept fresh for 30 minutes before becoming stale and forced to be refetched
        staleTime: 30 * 60 * 1000,
        // Inactive Request Cache will live in memory for 35 mins before it is garbaged and have to be refetched
        cacheTime: 35 * 60 * 1000
      }
    );

  useLayoutEffect(() => {
    if (!isEmptyArray(data?.pages)) {
      const allRequests: InformationRequest[] = [];
      data?.pages.forEach((page) => {
        const { information_requests: requests } = page;
        requests.forEach((request) => {
          allRequests.push(request);
        });
      });
      setInformationRequests(allRequests);
    }
  }, [data]);

  useEffect(() => {
    if (!socket) return;
    const tokens = informationRequests.map((r) => r.token);
    socket.emit(SOCKET_EVENT.JOIN_REQUESTS, {
      requestTokens: tokens
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, informationRequests.length]);

  const isLoadingInitial = useMemo(() => isEmptyArray(data?.pages) && isLoading && !error, [data, isLoading, error]);

  return {
    isLoadingInitial: isLoadingInitial || isFetchingPartnerRequestsMeta,
    isLoading: status === 'loading',
    informationRequests,
    fetchNextPage,
    isFetchingNextPage: isFetchingNextPage,
    error: error || errorWhileFetchingPartnerRequestsMeta,
    hasNextPage,
    status,
    hasRequests,
    meta: data?.pages[0].meta
  };
};

export interface UseFetchPartnerIncomingRequestsCountResponse {
  meta: Meta;
}

export const useFetchPartnerIncomingRequestsMeta = (options?: GetQueryOptions) => {
  const [incomingRequestsMeta, setIncomingRequestsMeta] = useState<Meta>();
  const { loading, error, makeRequest, response } = useGetQuery<UseFetchPartnerIncomingRequestsCountResponse>(
    '/incoming/information_requests?count_only=1',
    options
  );

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (response) {
      setIncomingRequestsMeta(response.meta);
    }
  }, [response]);

  useEffect(() => {
    return () => {
      if (!socket) return;
      socket.off(SOCKET_EVENT.NEW_INCOMING_REQUESTS);
      socket.off(SOCKET_EVENT.ACCEPT_REQUEST);
      socket.off(SOCKET_EVENT.REJECT_REQUEST);
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) return;
    socket.on(SOCKET_EVENT.NEW_INCOMING_REQUESTS, async (data: string) => {
      const { request_ids: requestIds } = JSON.parse(data);
      if (!requestIds) return;
      setIncomingRequestsMeta((prevMeta) => {
        if (prevMeta) return { ...prevMeta, count: prevMeta?.count + requestIds.length };
      });
    });
    socket.on(SOCKET_EVENT.ACCEPT_REQUEST, async () => {
      setIncomingRequestsMeta((prevMeta) => {
        if (prevMeta) return { ...prevMeta, count: prevMeta?.count - 1 };
      });
    });
    socket.on(SOCKET_EVENT.REJECT_REQUEST, async () => {
      setIncomingRequestsMeta((prevMeta) => {
        if (prevMeta) return { ...prevMeta, count: prevMeta?.count - 1 };
      });
    });
  }, [socket]);

  return {
    isFetchingPartnerIncomingRequestsMeta: loading,
    errorWhileFetchingPartnerIncomingRequests: error,
    incomingRequestsMeta,
    fetchPartnerIncomingRequestsMeta: makeRequest
  };
};

export interface UseGetPartnerIncomingRequestsResponse {
  information_requests: IncomingRequest[];
  meta: Meta;
}

export interface IncomingRequest extends InformationRequest {
  versionedText: VersionedText;
}

export const useGetPartnerIncomingRequests = ({ enabled }: { enabled: boolean }) => {
  const [incomingRequest, setIncomingRequest] = useState<IncomingRequest[]>([]);
  // More on useInfiniteQuery here: https://react-query.tanstack.com/reference/useInfiniteQuery
  const { isLoading, data, fetchNextPage, isFetchingNextPage, error, hasNextPage, status } =
    useInfiniteQuery<UseGetPartnerIncomingRequestsResponse>(
      INCOMING_REQUEST_QUERY_KEY,
      () => {
        const offset = incomingRequest.length;
        return apiService.get(INCOMING_REQUEST_QUERY_KEY, {
          params: { offset: offset, limit: 20 }
        });
      },
      {
        getNextPageParam: ({ meta }, allPageData) => {
          const newPageNumber = allPageData.length;

          const hasMorePages = meta.total_pages > newPageNumber;

          if (hasMorePages) {
            return newPageNumber;
          }

          return undefined;
        },
        refetchOnWindowFocus: false,
        enabled: enabled,
        // Request list will be kept fresh for 30 minutes before becoming stale and forced to be refetched
        staleTime: 30 * 60 * 1000,
        // Inactive Request Cache will live in memory for 35 mins before it is garbaged and have to be refetched
        cacheTime: 35 * 60 * 1000
      }
    );

  useLayoutEffect(() => {
    if (!isEmptyArray(data?.pages)) {
      const allRequests: IncomingRequest[] = [];
      data?.pages.forEach((page) => {
        const { information_requests: requests } = page;
        requests.forEach((request) => {
          allRequests.push(request);
        });
      });
      setIncomingRequest(allRequests);
    }
  }, [data]);

  const isLoadingInitial = useMemo(() => isEmptyArray(data?.pages) && isLoading && !error, [data, isLoading, error]);

  return {
    isLoadingInitial: isLoadingInitial,
    isLoading: status === 'loading',
    fetchNextPage,
    isFetchingNextPage: isFetchingNextPage,
    incomingRequest,
    error: error,
    hasNextPage,
    status,
    meta: data?.pages[0]?.meta
  };
};

export interface UseGetPartnerUsersQueryResponse {
  users: PartnerClient[];
  meta: Meta;
}

export interface UseFetchPartnerUserCountResponse {
  meta: Meta;
}

export const useFetchPartnerUsersMeta = () => {
  const url = '/users?include=latest_record,stats_only';

  const { data, isLoading, error, status, isStale } = useReactQuery<UseFetchPartnerUserCountResponse>(
    CLIENT_TAB_STATISTICS_QUERY_KEY,
    () => {
      return apiService.get(url);
    },
    {
      // This data will be kept fresh for 30 minutes before becoming stale and forced to be refetched
      staleTime: 30 * 60 * 1000,
      // Time the Inactive Cache will live in memory for 35 mins before it is garbaged and have to be refetched
      cacheTime: 35 * 60 * 1000
    }
  );

  return {
    isFetchingPartnerUsersMeta: isLoading,
    errorWhileFetchingPartnerUsersMeta: error,
    partnerUsersMeta: data?.meta,
    partnerUsersMetaStatus: status,
    partnerUsersMetaIsStale: isStale
  };
};

export const useGetPartnerUsers = (query: string, order: string) => {
  const searchQuery = useMemo(() => {
    return encodeURIComponent(query);
  }, [query]);
  const orderQuery = useMemo(() => {
    return encodeURIComponent(order);
  }, [order]);
  const [users, setUsers] = useState<PartnerClient[]>([]);
  const { socket } = useContext(SocketContext);
  const { isScopeEnabled } = useContext(AuthContext);
  const hasMinorFlowScope = isScopeEnabled(SCOPES.MINOR_FLOW);

  const [hasUsers, setHasUsers] = useState(false);

  const { partnerUsersMeta, isFetchingPartnerUsersMeta, errorWhileFetchingPartnerUsersMeta } =
    useFetchPartnerUsersMeta();

  useEffect(() => {
    if (partnerUsersMeta && partnerUsersMeta.count > 0) {
      setHasUsers(true);
    } else {
      setHasUsers(false);
    }
  }, [partnerUsersMeta]);

  const queryKey = useMemo(() => `users-search-${searchQuery}-order-${orderQuery}`, [searchQuery, orderQuery]);

  // More on useInfiniteQuery here: https://react-query.tanstack.com/reference/useInfiniteQuery
  const { isLoading, data, fetchNextPage, isFetchingNextPage, error, hasNextPage, status } =
    useInfiniteQuery<UseGetPartnerUsersQueryResponse>(
      queryKey,
      ({ pageParam = 0 }) => {
        if (hasMinorFlowScope) {
          return apiService.get(
            `/users?include=latest_record,insurance,address&search=${searchQuery}&order=${orderQuery}`,
            {
              params: { page: pageParam }
            }
          );
        }

        return apiService.get(
          `/users?include=latest_record,insurance,address&search=${searchQuery}&order=${orderQuery}&isLegalAge=true`,
          {
            params: { page: pageParam }
          }
        );
      },
      {
        getNextPageParam: ({ meta }, allPageData) => {
          const newPageNumber = allPageData.length;

          const hasMorePages = meta.total_pages > newPageNumber;

          if (hasMorePages) {
            return newPageNumber;
          }

          return undefined;
        },
        refetchOnWindowFocus: false,
        enabled: hasUsers,
        // cache will be kept fresh for 30 minutes before becoming stale and forced to be refetched
        staleTime: 30 * 60 * 1000,
        // Inactive Cache will live in memory for 35 mins before it is garbaged and have to be refetched
        cacheTime: 35 * 60 * 1000
      }
    );

  useLayoutEffect(() => {
    if (!isEmptyArray(data?.pages)) {
      const allUsers: PartnerClient[] = [];
      data?.pages.forEach((page) => {
        const { users } = page;
        users.forEach((user) => {
          allUsers.push(user);
        });
      });
      setUsers(allUsers);
    }
  }, [data]);

  useEffect(() => {
    if (!socket) return;
    const tokens = users.map((u) => u.token);
    socket.emit(SOCKET_EVENT.JOIN_CLIENTS, {
      clientTokens: tokens
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, users.length]);

  const isLoadingInitial = useMemo(() => isEmptyArray(data?.pages) && isLoading && !error, [data, isLoading, error]);

  return {
    isLoadingInitial: isLoadingInitial || isFetchingPartnerUsersMeta,
    isLoading: status === 'loading',
    users,
    fetchNextPage,
    isFetchingNextPage: isFetchingNextPage,
    error: error || errorWhileFetchingPartnerUsersMeta,
    hasNextPage,
    status,
    hasUsers,
    meta: data?.pages[0].meta
  };
};

export interface UseFetchPatientResourcesResponse {
  resources: GenericHealthInformationResource[];
}

const isNotBlockedResource = (resource: GenericHealthInformationResource) =>
  !BLOCKED_RESOURCE_TYPES.has(resource.resource_type);

export const useFetchPatientResources = (id: number, options?: GetQueryOptions) => {
  const { loading, error, response, makeRequest } = useGetQuery<UseFetchPatientResourcesResponse>(
    `/users/${id}/resources?include=files,institution`,
    options
  );

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (!socket) return;
    socket.on(SOCKET_EVENT.UPDATE_CLIENT, () => {
      makeRequest(undefined, { resetCache: true });
    });
  }, [socket, makeRequest]);

  return {
    isFetchingPatientResources: loading,
    errorWhileFetchingPatientResources: error,
    fetchPatientResources: makeRequest,
    patientResources: response?.resources.filter(isNotBlockedResource)
  };
};

export interface UseFetchRecentPatientActivityResponse {
  timeline: TimelineActivity[];
}

const isNotBlockedActivity = (timelineActivity: TimelineActivity) => {
  if (isResourceActivity(timelineActivity)) {
    return isNotBlockedResource(timelineActivity.resource);
  }
  return true;
};

export const useFetchRecentPatientActivity = (id: number, options?: GetQueryOptions) => {
  const { response, loading, error, makeRequest } = useGetQuery<UseFetchRecentPatientActivityResponse>(
    `/users/${id}/timeline`,
    options
  );

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (!socket) return;
    const listener = () => makeRequest(undefined, { resetCache: true });
    socket.on(SOCKET_EVENT.UPDATE_CLIENT, listener);
  }, [socket, makeRequest]);

  return {
    recentPatientActivity: response?.timeline.filter(isNotBlockedActivity),
    isFetchingRecentPatientActivity: loading,
    fetchRecentPatientActivity: makeRequest,
    errorWhileFetchingRecentPatientActivity: error
  };
};

export interface UseFetchPatientClinicalNotesResponse {
  resources: ClinicalNoteResource[];
}

export const useFetchPatientClinicalNotes = (id: number, options?: GetQueryOptions) => {
  const { loading, error, response, makeRequest } = useGetQuery<UseFetchPatientClinicalNotesResponse>(
    `/users/${id}/resources?exclude=nested&resource_type=clinicalNote&limit=3`,
    options
  );
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (!socket) return;
    const listener = () => makeRequest(undefined, { resetCache: true });
    socket.on(SOCKET_EVENT.UPDATE_CLIENT, listener);
  }, [socket, makeRequest]);

  return {
    isFetchingPatientResources: loading,
    errorWhileFetchingPatientResources: error,
    fetchPatientResources: makeRequest,
    patientResources: response?.resources
  };
};

export const useFetchConfirmationText = (token: string, userId: number) => {
  const queryKey = useMemo(
    () => `information-request-auth-confirmation-text-${userId ? `-${userId}` : ''}-${token ? `-${token}` : ''}`,
    [userId, token]
  );

  const { data, isLoading, error, status, isStale } = useReactQuery<UseFetchPartnerRequestsResponse>(
    queryKey,
    () => {
      return apiService.get('/information_requests/confirmation_text');
    },
    {
      // This data will be kept fresh for 30 minutes before becoming stale and forced to be refetched
      staleTime: 30 * 60 * 1000,
      // Time the Inactive Cache will live in memory for 35 mins before it is garbaged and have to be refetched
      cacheTime: 35 * 60 * 1000
    }
  );

  return {
    isFetchingConfirmationText: isLoading,
    errorWhileFetchingConfirmationTex: error,
    confirmationTextMeta: data?.meta,
    confirmationTextStatus: status,
    confirmationTextIsStale: isStale
  };
};

export interface UseRenewInformationRequest {
  status_history: StatusHistory[];
}

export const useRenewInformationRequest = (internalId: number) => {
  const { loading, error, response, makeRequest } = useQuery<UseRenewInformationRequest>(
    `/information_requests/institution/${internalId}/renew`,
    'PUT'
  );

  return {
    isRenewingInformationRequest: loading,
    renewInformationRequest: makeRequest,
    renewedInformationRequestStatusHistory: response,
    errorWhileRenewingInformationRequest: error
  };
};
export const useFetchStatements = () => {
  const url = '/statements';

  const { data, isLoading, error, status, isStale } = useReactQuery<BillingStatement[]>(
    url,
    () => {
      return apiService.get(url);
    },
    {
      // We don't expect this to change often so we keep cache time to infinity
      staleTime: Infinity,
      // We don't expect this to change often so we keep cache time to infinity
      cacheTime: Infinity
    }
  );

  return {
    isLoadingStatements: isLoading,
    errorWhileFetchingStatements: error,
    statements: data,
    statementsStatus: status,
    statementsIsStale: isStale
  };
};

export const useDownloadStatementFile = () => {
  const mutation = useMutation({
    mutationFn: (options: { statement_id: number }) => {
      const url = `/statements/${options.statement_id}/view`;
      return apiService.file(url, {
        responseType: 'blob'
      });
    }
  });

  return {
    isFetchingStatementFile: mutation.isLoading,
    errorWhileFetchingStatementFile: mutation.error,
    fetchStatemenFile: mutation.mutateAsync
  };
};
