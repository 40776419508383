/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface CircleQuestionIconProps extends SVGBaseProps {}

const CircleQuestionIcon = (props: CircleQuestionIconProps) => {
  return (
    <SVGBase viewBox='0 0 14 14' {...props}>
      <path
        d='M7.39347 8.696H6.55347V7.976C6.55347 7.752 6.57747 7.552 6.62547 7.376C6.68147 7.192 6.75347 7.028 6.84147 6.884C6.93747 6.74 7.04147 6.612 7.15347 6.5C7.27347 6.38 7.39347 6.264 7.51347 6.152L7.84947 5.852C8.04947 5.692 8.21347 5.508 8.34147 5.3C8.47747 5.092 8.54547 4.832 8.54547 4.52C8.54547 4.296 8.50547 4.096 8.42547 3.92C8.34547 3.744 8.23747 3.596 8.10147 3.476C7.96547 3.356 7.80547 3.264 7.62147 3.2C7.44547 3.136 7.25347 3.104 7.04547 3.104C6.61347 3.104 6.26147 3.236 5.98947 3.5C5.72547 3.764 5.56147 4.1 5.49747 4.508L4.68147 4.364C4.80147 3.74 5.07347 3.252 5.49747 2.9C5.92947 2.54 6.45747 2.36 7.08147 2.36C7.40147 2.36 7.70147 2.408 7.98147 2.504C8.26947 2.592 8.51747 2.728 8.72547 2.912C8.93347 3.088 9.09747 3.308 9.21747 3.572C9.34547 3.836 9.40947 4.136 9.40947 4.472C9.40947 4.728 9.38147 4.952 9.32547 5.144C9.26947 5.336 9.18947 5.508 9.08547 5.66C8.98947 5.812 8.87347 5.952 8.73747 6.08C8.60147 6.2 8.45747 6.324 8.30547 6.452C8.13747 6.588 7.99347 6.712 7.87347 6.824C7.76147 6.936 7.66947 7.056 7.59747 7.184C7.52547 7.304 7.47347 7.44 7.44147 7.592C7.40947 7.744 7.39347 7.928 7.39347 8.144V8.696ZM7.70547 10.424C7.70547 10.592 7.64547 10.744 7.52547 10.88C7.40547 11.008 7.24947 11.072 7.05747 11.072C6.86547 11.072 6.70947 11.008 6.58947 10.88C6.46947 10.744 6.40947 10.592 6.40947 10.424C6.40947 10.256 6.46947 10.108 6.58947 9.98C6.70947 9.844 6.86547 9.776 7.05747 9.776C7.24947 9.776 7.40547 9.844 7.52547 9.98C7.64547 10.108 7.70547 10.256 7.70547 10.424Z'
        fill='inherit'
        fillOpacity='0.75'
      />
      <rect
        x='0.4'
        y='0.4'
        width='13.2'
        height='13.2'
        rx='6.6'
        stroke='currentColor'
        strokeOpacity='0.75'
        strokeWidth='0.8'
        fill='transparent'
      />
    </SVGBase>
  );
};

export default CircleQuestionIcon;
