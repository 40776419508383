import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

export const defaultBaseColor = '#eee';

export const defaultHighlightColor = '#f5f5f5';

interface StyleProps {
  height?: string | number;
  width?: string | number;
  borderRadius?: string | number;
}

const useStyles = makeStyles(() => ({
  '@keyframes animateSkeleton': {
    '0%': {
      opacity: 1
    },
    '50%': {
      opacity: 0.4
    },
    '100%': {
      opacity: 1
    }
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1
    },
    '50%': {
      opacity: 0.4
    },
    '100%': {
      opacity: 1
    }
  },
  skeleton: {
    display: 'block',
    // Create a "on paper" color with sufficient contrast retaining the color
    backgroundColor: 'rgba(0, 0, 0, 0.11)',
    height: (props: StyleProps) => props.height || '1.5rem',
    width: (props: StyleProps) => props.width || '100%',
    borderRadius: (props: StyleProps) => props.borderRadius || '3px'
  },
  pulse: {
    animation: '$animateSkeleton 1.5s ease-in-out 0.5s infinite'
  },
  wave: {
    animation: '$pulse 1.5s ease-in-out 0.5s infinite'
  },
  circle: {
    borderRadius: '50%'
  }
}));

export interface SkeletonProps extends StyleProps {
  style?: React.CSSProperties;
  className?: string;
  animation?: 'wave' | 'pulse';
}

export default function Skeleton({ width, height, borderRadius, style, className, animation }: SkeletonProps) {
  const classes = useStyles({ width, height, borderRadius });
  const animationClass = animation ? classes[animation] : classes.wave;

  return (
    <span
      className={clsx(className, classes.skeleton, animationClass)}
      style={{
        ...style
      }}
    >
      &zwnj;
    </span>
  );
}
