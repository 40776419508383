import moment from 'moment';
import { ResourceType } from '../types';

export const DOT_SUPPORT_MAIL = 'care@dothealth.ca';
export const DOT_SUPPORT_PHONE = '1(800) 348-0368';
export const DOT_SUPPORT_MAIL_LINK = `mailto:${DOT_SUPPORT_MAIL}`;
export const DOT_SUPPORT_PHONE_LINK = `tel:${DOT_SUPPORT_PHONE}`;
export const DOT_CONNECT_KNOWLEDGE_BASE_URL = 'http://help.dothealth.ca/en/collections/2791941-using-dot-connect';

export const FIRST_SIGN_IN_KEY = 'DOT_CONNECT_FIRST_SIGN_IN';

export const SOCKET_EVENT = {
  NEW_REQUESTS: 'new_requests',
  JOIN_REQUESTS: 'join_requests',
  JOIN_CLIENTS: 'join_clients',
  NEW_CLIENTS: 'new_client',
  UPDATE_CLIENT: 'client_update',
  EXIT_REQUESTS: 'exit_requests',
  UPDATE_REQUEST: 'request_update',
  DISCONNECT: 'disconnect',
  NEW_NOTIFICATION: 'new_notification',
  READ_NOTIFICATIONS: 'read_notifications',
  DELETED_NOTIFICATION: 'deleted_notification',
  NEW_INCOMING_REQUESTS: 'new_incoming_requests',
  ACCEPT_REQUEST: 'accept_request',
  REJECT_REQUEST: 'reject_request',
  DELETED_CLIENT: 'deleted_client',
  NEW_INCOMING_REQUEST: 'new_incoming'
};

export const SUBSCRIPTION_STATUSES = {
  ACTIVE: ['active', 'trialing'],
  ALERT: ['past_due'],
  ERROR: ['unpaid', 'incomplete', 'canceled', 'incomplete_expired']
};

export const USER_INSTITUTION_ROLES = {
  patient: 'patient',
  member: 'member',
  owner: 'owner',
  manager: 'manager'
};

export const USER_ROLES = {
  patient: 'patient',
  admin: 'admin'
};

export const INSTITUTION_INVOICES_TYPE = {
  request_pending: 'request_pending',
  request_complete: 'request_complete',
  payment: 'payment',
  subscription_fee: 'subscription_fee'
};

export const blockStates = {
  EMAIL_UNVERIFIED: 'EMAIL_UNVERIFIED',
  NO_INSTITUTION: 'NO_INSTITUTION',
  NO_ACTIVE_SUBSCRIPTION: 'NO_ACTIVE_SUBSCRIPTION'
};

export const IMAGE_MIME_TYPE = ['image/jpeg', 'image/png'];
export const PDF_MIME_TYPE = ['application/pdf'];
export const XML_MIME_TYPE = ['application/xml', 'text/xml'];
export const FILE_MIME_TYPE = ['text/csv'];
export const TXT_MIME_TYPE = ['text/plain'];
export const ZIP_MIME_TYPE = ['application/zip'];
export const DIRECTORY_MIME_TYPE = ['directory'];
export const CSV_MIME_TYPE = ['text/csv'];
export const ALL_FILES_MIME_TYPE = [
  ...IMAGE_MIME_TYPE,
  ...PDF_MIME_TYPE,
  ...XML_MIME_TYPE,
  ...FILE_MIME_TYPE,
  ...TXT_MIME_TYPE,
  ...ZIP_MIME_TYPE,
  ...DIRECTORY_MIME_TYPE,
  ...CSV_MIME_TYPE
];

/**
 * Have a global constant for payment intent status for easy upgrades to newer stripe api version
 * These variables would be changed on api version older than 2019-02-11 according to this guide
 * https://stripe.com/docs/payments/payment-intents/migration#api-version
 */
export const STRIPE_PAYMENT_INTENT_STATUS = {
  /**
   * We need to add a new payment method
   */
  REQUIRES_SOURCE: 'requires_source',
  /**
   * We need to confirm existing payment method
   */
  REQUIRES_SOURCE_ACTION: 'requires_source_action'
};

export const MINIMUM_ACCEPTABLE_PASSWORD_STRENGTH_SCORE = 3;

export const BLOCKED_RESOURCE_TYPES = new Set([ResourceType.ResourceGroupResource]);

// REQUEST LIST QUERY KEYS
export const ALL_REQUEST_LAST_UPDATED_QUERY_KEY = 'information_requests-search--order-LastUpdated-false';
export const ALL_REQUEST_PATIENT_NAME_AZ_QUERY_KEY = 'information_requests-search--order-PatientNameAZ-false';
export const ALL_REQUEST_PATIENT_NAME_ZA_QUERY_KEY = 'information_requests-search--order-PatientNameZA-false';
export const ALL_REQUEST_REQUEST_STATUS_QUERY_KEY = 'information_requests-search--order-RequestStatus-false';

export const MY_REQUEST_LAST_UPDATED_QUERY_KEY = 'information_requests-search--order-LastUpdated-true';
export const MY_REQUEST_PATIENT_NAME_AZ_QUERY_KEY = 'information_requests-search--order-PatientNameAZ-true';
export const MY_REQUEST_PATIENT_NAME_ZA_QUERY_KEY = 'information_requests-search--order-PatientNameZA-true';
export const MY_REQUEST_REQUEST_STATUS_QUERY_KEY = 'information_requests-search--order-RequestStatus-true';

// INCOMING REQUEST LIST KEY
export const INCOMING_REQUEST_QUERY_KEY = '/incoming/information_requests?include=institution,physician';

// MY REQUEST TABLE STATISTICS QUERY KEYS
export const MY_ACTIVE_META_COUNT_QUERY_KEY = `information_requests-meta-active-true`;
export const MY_COMPLETE_META_COUNT_QUERY_KEY = `information_requests-meta-complete-true`;

// ALL REQUEST TABLE STATISTICS QUERY KEYS
export const ALL_ACTIVE_META_COUNT_QUERY_KEY = `information_requests-meta-active-false`;
export const ALL_COMPLETE_META_COUNT_QUERY_KEY = `information_requests-meta-complete-false`;

// CLIENT LIST QUERY KEY
export const CLIENT_LIST_NEWEST_QUERY_KEY = 'users-search--order-Newest';
export const CLIENT_LIST_NAME_AZ_QUERY_KEY = 'users-search--order-ClientNameAZ';
export const CLIENT_LIST_NAME_ZA_QUERY_KEY = 'users-search--order-ClientNameZA';

// CLIENT TABLE STATISTICS QUERY KEY
export const CLIENT_TAB_STATISTICS_QUERY_KEY = `client-tab-statistics`;

export const CLIENT_TABLE_ORDER_KEYS = {
  NEWEST: 'Newest',
  NAME_AZ: 'ClientNameAZ',
  NAME_ZA: 'ClientNameZA'
} as const;

// INSTITUTION USER QUERY KEY
export const INSTITUTION_USER_QUERY_KEY = 'institution-user';

export const LARGE_DOWNLOAD_FILE_SIZE = 20 * 1000 * 1000;

export const MIN_CLIENT_BIRTH_DATE = moment().subtract(18, 'years').toDate();
export const MAX_CLIENT_BIRTH_DATE = moment().subtract(120, 'years').toDate();

export const DEFAULT_RESOURCE_TYPES = {
  clinicalNote: 'Clinical Note',
  communication: 'Communication',
  condition: 'Condition',
  diagnosticReport: 'Diagnostic Report',
  form: 'Form',
  imagingStudy: 'Imaging Study',
  immunization: 'Immunization',
  medicationRequest: 'Medication Request',
  resourceGroup: 'Resource Group',
  allergy: 'Allergy',
  familyMemberHistory: 'Family Member History',
  carePlan: 'Care Plan',
  goal: 'Goal',
  medicationDispense: 'Medication Dispense',
  observation: 'Observation',
  patientVisit: 'Patient Visit',
  procedure: 'Procedure',
  serviceRequest: 'Service Request'
};

export const NUM_DAYS_AFTER_NOTIFICATION_TO_DISABLE_PLAN = 3;
