/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface BallPenOutlineIconProps extends SVGBaseProps {}

const BallPenOutlineIcon = (props: BallPenOutlineIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M19.0802 12.1875L18.1717 11.279L5.45075 24H0V18.548L14.5366 4.01005L21.8056 11.279C22.0465 11.52 22.1818 11.8467 22.1818 12.1875C22.1818 12.5282 22.0465 12.855 21.8056 13.0959L12.721 22.1818L10.9028 20.3649L19.0802 12.1875V12.1875ZM16.3548 9.46209L14.5366 7.64517L2.5699 19.6119V21.4301H4.38682L16.3548 9.46209V9.46209ZM19.9887 0.376215L23.6238 4.01005C23.8647 4.25101 24 4.57779 24 4.91851C24 5.25923 23.8647 5.586 23.6238 5.82697L21.8056 7.64517L16.3548 2.19313L18.1717 0.376215C18.4127 0.135325 18.7395 0 19.0802 0C19.4209 0 19.7477 0.135325 19.9887 0.376215V0.376215Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default BallPenOutlineIcon;
