/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface DiagnosticReportIconProps extends SVGBaseProps {}

const DiagnosticReportIcon = (props: DiagnosticReportIconProps) => {
  return (
    <SVGBase {...props}>
      <path
        d='M15.8272 10.5934C18.7927 10.5934 21.165 12.9475 21.165 15.8901C21.165 16.9259 20.8685 17.9029 20.3466 18.7151L23.1699 21.5349C23.6278 21.9921 23.6266 22.7345 23.1672 23.1903V23.1903C22.7145 23.6395 21.985 23.6425 21.5287 23.1969L18.6503 20.3865C17.8319 20.8926 16.8592 21.1869 15.8272 21.1869C12.8618 21.1869 10.4894 18.8328 10.4894 15.8901C10.4894 12.9475 12.8618 10.5934 15.8272 10.5934ZM15.8272 12.9475C15.0407 12.9475 14.2865 13.2575 13.7303 13.8094C13.1742 14.3612 12.8618 15.1097 12.8618 15.8901C12.8618 16.6706 13.1742 17.419 13.7303 17.9709C14.2865 18.5227 15.0407 18.8328 15.8272 18.8328C16.6137 18.8328 17.368 18.5227 17.9241 17.9709C18.4802 17.419 18.7927 16.6706 18.7927 15.8901C18.7927 15.1097 18.4802 14.3612 17.9241 13.8094C17.368 13.2575 16.6137 12.9475 15.8272 12.9475ZM6.92176 14.1246C6.2717 14.1246 5.74471 14.6516 5.74471 15.3016V15.3016C5.74471 15.9517 6.2717 16.4787 6.92176 16.4787H8.11707C8.28314 18.3031 9.06601 19.9392 10.2641 21.1869H3.37236C2.0557 21.1869 1 20.1275 1 18.8328V2.3541C1 1.04757 2.0557 0 3.37236 0H19.9789C20.608 0 21.2115 0.24802 21.6564 0.689498C22.1013 1.13098 22.3512 1.72975 22.3512 2.3541V11.8058C20.9871 9.66356 18.5792 8.23933 15.8272 8.23933C14.3208 8.23933 12.9092 8.67484 11.7231 9.41638H6.92176C6.2717 9.41638 5.74471 9.94336 5.74471 10.5934V10.5934C5.74471 11.2435 6.2717 11.7705 6.92176 11.7705H9.30325C8.87622 12.4767 8.52037 13.2771 8.31872 14.1246H6.92176ZM16.4294 7.06229C17.0795 7.06229 17.6065 6.5353 17.6065 5.88524V5.88524C17.6065 5.23517 17.0795 4.70819 16.4294 4.70819H6.92176C6.2717 4.70819 5.74471 5.23517 5.74471 5.88524V5.88524C5.74471 6.5353 6.2717 7.06229 6.92176 7.06229H16.4294Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default DiagnosticReportIcon;
