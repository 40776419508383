import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Text from '../Typography';

interface RecordsTabWrapperTitleProps {
  children: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  text: {
    marginLeft: '1rem',
    whiteSpace: 'nowrap'
  }
}));

const RecordsTabWrapperTitle = (props: RecordsTabWrapperTitleProps) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <Text h4 color='ship-cove' className={classes.text}>
      {children}
    </Text>
  );
};

export default RecordsTabWrapperTitle;
