import React, { useContext } from 'react';
import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth-context';
import AppView from '../layouts/AppView';
import GenericErrorScreen from './GenericErrorScreen';
import Can from './Can';
import PageContainer from './PageContainer';
import { useRoutes } from '../../hooks/useRoutes';
import AuthorizationController from './AuthorizationController';
import { blockStates } from '../../utils/constants';

export interface PrivateRouteProps extends RouteProps {
  perform?: string | string[];
  noWrap?: boolean;
}

const PrivateRoute = ({ component: Component, perform, noWrap, ...rest }: PrivateRouteProps) => {
  const { isLoggedIn } = useContext(AuthContext);
  const location = useLocation();
  const { routesTFunction } = useRoutes();
  const Wrapper = noWrap ? React.Fragment : AppView;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn) {
          return (
            <AuthorizationController
              blockedStates={[
                blockStates.EMAIL_UNVERIFIED,
                blockStates.NO_INSTITUTION,
                blockStates.NO_ACTIVE_SUBSCRIPTION
              ]}
            >
              <Wrapper>
                {perform ? (
                  <Can
                    perform={perform}
                    fallback={() => {
                      return (
                        <PageContainer>
                          <GenericErrorScreen />
                        </PageContainer>
                      );
                    }}
                  >
                    {React.createElement(Component!, props)}
                  </Can>
                ) : (
                  React.createElement(Component!, props)
                )}
              </Wrapper>
            </AuthorizationController>
          );
        }
        return (
          <Redirect
            to={{
              pathname: routesTFunction('redirectPaths./login'),
              search: `?prev=${location.pathname}`
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
