import { makeStyles } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.grey[40]
  },
  tooltip: {
    backgroundColor: theme.palette.shipCove.main,
    color: 'white',
    padding: '5px',
    fontSize: theme.typography.xxxSmall?.fontSize
  },
  tooltipPlacementBottom: {
    marginTop: '0.5rem'
  }
}));

export interface CustomTooltipProps extends TooltipProps {
  disabled?: boolean;
}

function CustomTooltip(props: CustomTooltipProps) {
  const classes = useStyles();

  if (props.disabled) return <>{props.children}</>;

  return <Tooltip classes={classes} {...props} />;
}

export default CustomTooltip;
