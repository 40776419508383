/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface ZIPFileIconProps extends Omit<SVGBaseProps, 'color'> {}

const ZIPFileIcon = (props: ZIPFileIconProps) => {
  return (
    <SVGBase color='#EF1E2B' {...props}>
      <path
        d='M0.766602 3.38998C0.766602 1.97591 1.91293 0.82959 3.32699 0.82959H18.2062C19.6203 0.82959 20.7666 1.97591 20.7666 3.38998V18.2692C20.7666 19.6833 19.6203 20.8296 18.2062 20.8296H3.32699C1.91293 20.8296 0.766602 19.6833 0.766602 18.2692V3.38998Z'
        fill='#8285A4'
      />
      <path
        d='M7.20918 7.06159L4.05968 7.0615C3.53869 7.06149 3.11634 7.48383 3.11634 8.00482C3.11634 8.5258 3.53868 8.94814 4.05966 8.94814H5.64605C5.72397 8.94814 5.76984 9.03564 5.72551 9.09972L3.41526 12.4395C3.24734 12.6823 3.15755 12.9705 3.15789 13.2657L3.15821 13.5472C3.15881 14.0804 3.59121 14.5123 4.12439 14.5123H7.21961C7.72188 14.5123 8.12906 14.1051 8.12906 13.6028C8.12906 13.1005 7.72188 12.6934 7.21961 12.6934H5.63882C5.56013 12.6934 5.51444 12.6043 5.56034 12.5404L7.90335 9.27686C8.08021 9.03051 8.17534 8.7349 8.17534 8.43164V8.02777C8.17534 7.49417 7.74278 7.0616 7.20918 7.06159Z'
        fill='white'
      />
      <path
        d='M11.7256 13.6074V8.0035C11.7256 7.48323 11.3038 7.06147 10.7836 7.06147C10.2633 7.06147 9.84153 7.48323 9.84153 8.0035V13.6074C9.84153 14.1276 10.2633 14.5494 10.7836 14.5494C11.3038 14.5494 11.7256 14.1276 11.7256 13.6074Z'
        fill='white'
      />
      <path
        d='M13.3918 13.5814V8.26972C13.3918 7.60278 13.9324 7.06209 14.5994 7.062L16.5326 7.06173C17.5732 7.06159 18.4169 7.90527 18.4169 8.94591V10.1564C18.4169 11.1969 17.5733 12.0406 16.5328 12.0406H15.3006V13.5814C15.3006 14.1086 14.8733 14.5359 14.3462 14.5359C13.8191 14.5359 13.3918 14.1086 13.3918 13.5814Z'
        fill='white'
      />
      <path d='M15.2847 8.90524H16.5611V10.1817H15.2847V8.90524Z' fill='#8285A4' />
    </SVGBase>
  );
};

export default ZIPFileIcon;
