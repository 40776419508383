import React from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Root, Content, Portal, Overlay, Title } from '@radix-ui/react-dialog';
import Text from './Typography';

const useStyles = makeStyles((theme) => ({
  narrow: {
    width: '40%',
    padding: '30px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  modalHeader: {
    margin: '1rem 2rem'
  }
}));

export interface ModalProps {
  children: React.ReactNode;
  open?: boolean;
  align?: 'left' | 'right' | 'center';
  narrow?: boolean;
  onClose: () => void;
  className?: string;
  header?: React.ReactNode;
  showHeaderDivider?: boolean;
  title?: string;
  description?: string;
  /**
   * The prop exists for accessibility reasons to show a title for modal which would be used by screenreaders
   * @default true
   */
  shouldUseHeaderAsModalTitle?: boolean;
}

export const ModalTitle = Title;

const Modal = (props: ModalProps) => {
  const {
    open,
    narrow,
    align,
    className,
    header,
    onClose,
    children,
    showHeaderDivider,
    shouldUseHeaderAsModalTitle = true
  } = props;
  const classes = useStyles();

  const HeaderWrapperComponent = shouldUseHeaderAsModalTitle ? Title : React.Fragment;

  return (
    <>
      <Root
        open={open}
        onOpenChange={(openState) => {
          if (!openState) {
            onClose();
          }
        }}
      >
        <Portal>
          <Overlay className='modal'>
            <Content
              className={clsx(
                'modal__content',
                narrow && classes.narrow,
                align ? `text-${align}` : 'text-center',
                className
              )}
            >
              {header && (
                <>
                  <HeaderWrapperComponent {...(shouldUseHeaderAsModalTitle ? { asChild: true } : {})}>
                    <Text h6 className={classes.modalHeader}>
                      {header}
                    </Text>
                  </HeaderWrapperComponent>
                  {showHeaderDivider && <Divider />}
                </>
              )}
              {children}
            </Content>
          </Overlay>
        </Portal>
      </Root>
    </>
  );
};

export default Modal;
