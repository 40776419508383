import { makeStyles } from '@material-ui/core/';
import Skeleton, { SkeletonProps } from '../../shared/Skeleton';

import CustomStepper from '../../shared/Stepper';
import { FormSelectionSkeleton } from './FormSelection';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '&>div': {
      width: '95%'
    }
  },
  labelSkeletonWrapper: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const StepperLabelSkeleton = (props: SkeletonProps) => {
  const classes = useStyles();
  return (
    <div className={classes.labelSkeletonWrapper}>
      <Skeleton {...props} />
    </div>
  );
};

const CreateRequestSkeleton = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <div>
          <CustomStepper
            steps={[
              {
                label: <StepperLabelSkeleton width='25ch' />,
                disableGoingForward: true,
                content: <FormSelectionSkeleton />
              },
              {
                label: <StepperLabelSkeleton width='18ch' />,
                disableGoingForward: true,
                content: <></>
              },
              {
                label: <StepperLabelSkeleton width='25ch' />,
                disableGoingForward: true,
                content: <></>
              },
              {
                label: <StepperLabelSkeleton width='17ch' />,
                content: <></>
              }
            ]}
            activeStep={0}
            setActiveStep={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateRequestSkeleton;
