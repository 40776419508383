import React from 'react';
import clsx from 'clsx';
import AppNav from '../AppNav';

interface PublicLayoutProps {
  children?: React.ReactNode;
  className?: string;
  organizationName?: string;
}

const PublicLayout = ({ children, className, organizationName }: PublicLayoutProps) => {
  return (
    <div className={clsx('app-container', className)}>
      <AppNav public organizationName={organizationName} />
      <div className='container is-fluid page-container'>
        <div className='auth-page'>{children}</div>
      </div>
    </div>
  );
};

export default PublicLayout;
