/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface ArrowRightIconProps extends SVGBaseProps {}

const ArrowRightIcon = (props: ArrowRightIconProps) => {
  return (
    <SVGBase viewBox='0 0 24 22' {...props}>
      <path
        d='M13.4728 20.661C12.883 21.1919 11.9877 21.1919 11.3979 20.661C10.7134 20.045 10.7134 18.9716 11.3979 18.3555L18.3375 12.1099H1.58074C0.707718 12.1099 -7.63214e-08 11.4022 0 10.5292C7.63217e-08 9.6562 0.707722 8.94847 1.58074 8.94847H18.3367L11.3979 2.70361C10.7134 2.08754 10.7134 1.01418 11.3979 0.398111C11.9877 -0.132705 12.883 -0.132703 13.4728 0.398113L23.5116 9.43293C24.1628 10.019 24.1628 11.0401 23.5116 11.6262L13.4728 20.661Z'
        fill='inherit'
      />
    </SVGBase>
  );
};

export default ArrowRightIcon;
