import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Text from '../Typography';

interface RecordsTabWrapperSearchResultsProps {
  children: React.ReactText;
}

const useStyles = makeStyles(() => ({
  searchResultsText: {
    margin: '0px 2rem'
  }
}));

const RecordsTabWrapperSearchResults = (props: RecordsTabWrapperSearchResultsProps) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <Text span color='grey-60' className={classes.searchResultsText}>
      {children}
    </Text>
  );
};

export default RecordsTabWrapperSearchResults;
