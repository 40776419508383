import { makeStyles } from '@material-ui/core';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import { useEffect, useReducer, useState } from 'react';
import loadable from '@loadable/component';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from '../../../hooks/useForm';
import { useRoutes } from '../../../hooks/useRoutes';
import useTitle from '../../../hooks/useTitle';
import { useTranslate } from '../../../hooks/useTranslate';
import apiService from '../../../services/api-service';
import { Institution, InstitutionAddress, Physician, PartnerClient } from '../../../types';
import { ReducerActionMap } from '../../../utils/ActionMap';
import useClientInformationValidator from '../../../validators/client-information-validator';
import PageContainer from '../../shared/PageContainer';
import CustomStepper from '../../shared/Stepper';
import Container from '../../shared/Container';
import Spinner from '../../shared/Spinner';
import { removeNullUndefined } from '../../../utils/utils';
import { GuardianFormState } from '../../shared/CreateClientForm/ICreateClientForm';
import CreateRequestSkeleton from './CreateRequestSkeleton';
import ClientInformation from './ClientInformation';

// These components are loaded after the initial render and after translation is loaded
const InstitutionPhysicianSelect = loadable(() => import('./InstitutionPhysicianSelect'));
const ConsentForm = loadable(() => import('./ConsentForm'));
const Review = loadable(() => import('./ReviewPage'));

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '&>div': {
      width: '95%'
    }
  },
  stepperContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '9rem 0'
  }
}));

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum PROVIDER_TYPE {
  PHYSICIAN = 'physician',
  INSTITUTION = 'institution'
}

interface InstitutionProvider extends Institution {
  providerType: PROVIDER_TYPE.INSTITUTION;
}

interface PhysicianProvider extends Physician {
  providerType: PROVIDER_TYPE.PHYSICIAN;
}

export type Provider = InstitutionProvider | PhysicianProvider;

export enum CreateRequestReducerActionTypes {
  ADD_HEALTH_CARE_PROVIDER = 'ADD_HEALTH_CARE_PROVIDER',
  REMOVE_HEALTHCARE_PROVIDER = 'REMOVE_HEALTHCARE_PROVIDER',
  ADD_PHYSICIAN_TO_INSTITUTION = 'ADD_PHYSICIAN_TO_INSTITUTION',
  REMOVE_PHYSICIAN_FROM_INSTITUTION = 'REMOVE_PHYSICIAN_FROM_INSTITUTION',
  ADD_NOTES_TO_REQUEST = 'ADD_NOTES_TO_REQUEST',
  REMOVE_NOTES_FROM_REQUEST = 'REMOVE_NOTES_FROM_REQUEST',
  RESET = 'RESET'
}

export type CreateRequestReducerPayload = {
  [CreateRequestReducerActionTypes.ADD_HEALTH_CARE_PROVIDER]: {
    institution: Institution;
    address: InstitutionAddress;
    physician?: Physician;
    callback?: () => void;
  };
  [CreateRequestReducerActionTypes.REMOVE_HEALTHCARE_PROVIDER]: {
    index: number;
  };
  [CreateRequestReducerActionTypes.ADD_PHYSICIAN_TO_INSTITUTION]: {
    index: number;
    physician: Physician;
  };
  [CreateRequestReducerActionTypes.REMOVE_PHYSICIAN_FROM_INSTITUTION]: {
    index: number;
  };
  [CreateRequestReducerActionTypes.ADD_NOTES_TO_REQUEST]: {
    index: number;
    notes: string;
  };
  [CreateRequestReducerActionTypes.REMOVE_NOTES_FROM_REQUEST]: {
    index: number;
  };
  [CreateRequestReducerActionTypes.RESET]: unknown;
};

export type CreateRequestReducerActions =
  ReducerActionMap<CreateRequestReducerPayload>[keyof ReducerActionMap<CreateRequestReducerPayload>];

export type HealthCareProvider = {
  institution: Institution;
  address: InstitutionAddress;
  physician?: Physician;
  notes?: string;
};

export type CreateRequestReducerState = {
  healthCareProviders: HealthCareProvider[];
};

export const CLIENT_INFORMATION_INITIAL_STATE = {
  email: '',
  user: {} as PartnerClient
};

function reducer(state: CreateRequestReducerState, action: CreateRequestReducerActions): CreateRequestReducerState {
  const { healthCareProviders } = state;
  let updatedHealthCareProviders;

  switch (action.type) {
    case CreateRequestReducerActionTypes.ADD_HEALTH_CARE_PROVIDER:
      updatedHealthCareProviders = [
        ...healthCareProviders,
        {
          institution: action.payload.institution,
          address: {
            ...action.payload.address,
            name: action.payload.address.name
              ? `${action.payload.institution.institution_name} – ${action.payload.address.name}`
              : action.payload.institution.institution_name
          },
          physician: action.payload.physician
        }
      ];
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.REMOVE_HEALTHCARE_PROVIDER:
      updatedHealthCareProviders = healthCareProviders.filter(
        (_, providerIndex) => providerIndex !== action.payload.index
      );
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.ADD_PHYSICIAN_TO_INSTITUTION:
      updatedHealthCareProviders = healthCareProviders.map((provider, index) => {
        if (action.payload.index === index) {
          return { ...provider, physician: action.payload.physician };
        }
        return { ...provider };
      });
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.REMOVE_PHYSICIAN_FROM_INSTITUTION:
      updatedHealthCareProviders = healthCareProviders.map((provider, index) => {
        if (action.payload.index === index) {
          return { ...provider, physician: undefined };
        }
        return { ...provider };
      });
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.ADD_NOTES_TO_REQUEST:
      updatedHealthCareProviders = healthCareProviders.map((provider, index) => {
        if (action.payload.index === index) {
          return { ...provider, notes: action.payload.notes };
        }
        return { ...provider };
      });
      return { healthCareProviders: updatedHealthCareProviders };
    case CreateRequestReducerActionTypes.REMOVE_NOTES_FROM_REQUEST:
      updatedHealthCareProviders = healthCareProviders.map((provider, index) => {
        if (action.payload.index === index) {
          return { ...provider, notes: undefined };
        }
        return { ...provider };
      });
      return { healthCareProviders: updatedHealthCareProviders };

    case CreateRequestReducerActionTypes.RESET:
      return { healthCareProviders: [] };
    default:
      return state;
  }
}

export type ClientInformationInitialState = {
  email: string;
  isMinorClient: boolean;
  user: PartnerClient;
};
export interface CreateRequestFormData {
  formName: string;
  updatedAt: string;
  formPageCount: number;
  id: number;
  requestType: string;
}

export interface CreateRequestUser {
  firstName?: string;
  lastName?: string;
  dob?: string;
  hcn?: string;
  fullAddress?: string;
  phone?: string;
  streetAddress?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  extraLine1?: string;
  email: string;
}

export interface CreateRequestInstitutionData {
  healthCareProviders: {
    institution: {
      institution_name: string;
      props?: {
        truncated: number;
      };
    };
    address: {
      address: string;
      phone?: string;
      phone_extension?: string;
      fax?: string;
      props?: {
        truncated: number;
      };
    };
    physician: {
      id?: number;
      first_name?: string;
      last_name?: string;
      created_at?: Date;
      institution_addresses?: InstitutionAddress[] | undefined;
      specializations?: any[];
    };
    notes?: string | undefined;
  }[];
}

interface CreateRequestLocationState {
  INITIAL_STATE: ClientInformationInitialState;
}

const CreateRequest = () => {
  useTitle((t) => t('createRequest'));

  const classes = useStyles();
  const history = useHistory();
  const { routesTFunction } = useRoutes();
  const { t, ready } = useTranslate('createNewRequest');
  const location = useLocation<CreateRequestLocationState>();

  const [error, setError] = useState<string | undefined>();
  const [activeStep, setActiveStep] = useState(0);
  const [confirmationTextId, setConfirmationTextId] = useState<number>();
  const [consentFormPages, setConsentFormPages] = useState<pdfjsLib.PDFPageProxy[]>([]);
  const [refetchConsentForm, setRefetchConsentForm] = useState(true);
  const [isConsentAuthorized, setIsConsentAuthorized] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [guardianDetails, setGuardianDetails] = useState<GuardianFormState>();

  const [healthCareProvidersState, dispatch] = useReducer(
    (state: any, action: any) => {
      setRefetchConsentForm(true);
      setIsConsentAuthorized(false);
      return reducer(state, action);
    },
    {
      healthCareProviders: []
    }
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const validate = useClientInformationValidator();

  const {
    getFieldProps: getClientInformationFormFieldProps,
    values: clientInformationValues,
    resetErrors: clearClientValidationErrors,
    setValue: setClientInformationValues,
    hasErrors
  } = useForm(location.state?.INITIAL_STATE || CLIENT_INFORMATION_INITIAL_STATE, { validate });

  /**
   * Fetches consent form data and updates state with the fetched data.
   */
  const getConsentForm = () => {
    // Check if refetching consent form is necessary
    if (refetchConsentForm) {
      // Reset consent form pages state
      setConsentFormPages([]);

      const userId = clientInformationValues?.user?.id;
      // Map health care providers to an array of institution addresses
      const institutionAddresses = healthCareProvidersState.healthCareProviders.map((hc) => ({
        institution_address_id: hc.address.id,
        notes: hc.notes
      }));

      // If user ID is present
      if (userId) {
        // Map institution addresses to an array of promises that fetch consent form data for each address
        const addressPromises = institutionAddresses.map((address) =>
          apiService
            .post(
              `/users/${userId}/consent_form`,
              { information_request: { ...address, representative_id: guardianDetails?.RepresentativeId } },
              undefined,
              undefined,
              'blob'
            )
            .then((data) => {
              // Create a new Blob object with the fetched data and type 'application/pdf'
              const file = new Blob([data], { type: 'application/pdf' });
              // Create a URL for the Blob object
              const fileURL = URL.createObjectURL(file);

              // Get PDF document from the created URL
              return pdfjsLib.getDocument(fileURL).promise.then((pdf) => {
                // If PDF document is present and has pages
                if (pdf && pdf.numPages > 0) {
                  // Return an array of promises that fetch each page of the PDF document
                  return Promise.all(Array.from({ length: pdf.numPages }, (_, i) => pdf.getPage(i + 1)));
                }
              });
            })
        );

        // Wait for all promises in the addressPromises array to resolve
        Promise.all(addressPromises).then((results) => {
          const pages = results.reduce((accumulator, pageArray) => {
            return (accumulator || []).concat(pageArray || []);
          }, []);
          // If pages array is not empty update consent form pages state with the fetched pages
          if (pages && pages.length > 0) {
            setConsentFormPages(pages);
            setRefetchConsentForm(false);
          }
        });
      }
    }
  };

  const getReviewCardData = () => {
    const { email } = clientInformationValues;

    const userData = {
      email,
      firstName: '',
      lastName: '',
      dob: '',
      hcn: '',
      phone: '',
      streetAddress: '',
      city: '',
      province: '',
      postalCode: '',
      extraLine1: ''
    };

    const {
      first_name: firstName,
      last_name: lastName,
      dob,
      insurance,
      address,
      phone
    } = clientInformationValues?.user;
    userData.firstName = firstName;
    userData.lastName = lastName;
    userData.dob = dob || '';
    userData.hcn = insurance?.insurance_number;
    userData.phone = phone || '';
    userData.streetAddress = address?.street_address;
    userData.city = address?.city;
    userData.province = address?.province;
    userData.postalCode = address?.postal_code;
    userData.extraLine1 = address?.extra_line_1 || '';

    const institutionData = {
      healthCareProviders: healthCareProvidersState.healthCareProviders.map((provider) => {
        return {
          institution: {
            institution_name: provider.institution.institution_name,
            props: { truncated: 47 }
          },
          address: {
            address: [provider.address.address, provider.address.city, provider.address.province].join(', '),
            phone: provider.address.phone,
            phone_extension: provider.address.phone_extension,
            fax: provider.address.fax,
            props: { truncated: 22 }
          },
          physician: {
            ...provider.physician
          },
          notes: provider.notes
        };
      })
    };

    return {
      userData,
      institutionData,
      guardianDetails: guardianDetails
    };
  };

  const getSubmissionSchema = () => {
    const { email, user, isMinorClient } = clientInformationValues;
    const request = removeNullUndefined({
      institutions: healthCareProvidersState.healthCareProviders.map((provider) => {
        return {
          institution_address_id: provider.address.id,
          physician_id: provider.physician?.id,
          notes: provider.notes
        };
      }),
      confirmation_text_id: confirmationTextId,
      userData: {
        fullName: `${user.first_name} ${user.last_name}`,
        HCN: `${user.insurance.insurance_number}`,
        dob: `${user.dob}`,
        phone: !isMinorClient ? user.phone : undefined,
        // eslint-disable-next-line
        fullAddress: `${user?.address?.street_address} ${user?.address?.extra_line_1}  ${user?.address?.city} ${user?.address?.province}, ${user?.address?.postal_code}`,
        email: email,
        representativeFullName: isMinorClient
          ? `${guardianDetails?.guardian_first_name} ${guardianDetails?.guardian_last_name}`
          : undefined,
        representativeEmail: isMinorClient ? guardianDetails?.guardian_email : undefined,
        representativePhone: isMinorClient ? guardianDetails?.guardian_phone_number : undefined,
        representativeRelationship: isMinorClient ? guardianDetails?.representativeRelationship : undefined
      }
    });

    if (isMinorClient) {
      return {
        representative: {
          representative_id: guardianDetails?.RepresentativeId
        },
        request
      };
    }

    return { request };
  };

  const handleSubmitRequest = () => {
    if (!consentFormPages.length) {
      setError(t('clientInformationStep.errors.noFormsSelected'));
      return;
    }
    setIsSubmitLoading(true);
    const { user } = clientInformationValues;
    const data = getSubmissionSchema();
    setError(undefined);
    apiService
      .post(`/users/${user.internal_id}/information_requests`, { ...data }, undefined, (t) =>
        t('errorMessages.api.creatingNewRequest')
      )
      .then(() => {
        setIsSubmitLoading(false);
        history.push('/', { forwardTo: routesTFunction('redirectPaths./requests') });
      })
      .catch((err) => {
        setIsSubmitLoading(false);
        setError(err);
      });
  };

  const getStepperSteps = () => {
    const physicianAndInstitution = {
      completed:
        !!healthCareProvidersState.healthCareProviders && healthCareProvidersState.healthCareProviders.length > 0,
      label: t('stepperLabels.physicianAndInstitution'),
      ariaLabel: t('stepperAccessibleTitle.physicianAndInstitution'),
      disableGoingForward:
        !!healthCareProvidersState.healthCareProviders && healthCareProvidersState.healthCareProviders.length > 0,
      content: (
        <div className={classes.stepperContainer}>
          <InstitutionPhysicianSelect
            fallback={
              <Container isFlex justifyContent='center' style={{ minHeight: '80vh' }}>
                <Spinner />
              </Container>
            }
            healthCareProviders={healthCareProvidersState.healthCareProviders}
            dispatch={dispatch}
            allowNext={
              !!healthCareProvidersState.healthCareProviders && healthCareProvidersState.healthCareProviders.length > 0
            }
            handleNext={handleNext}
          />
        </div>
      )
    };

    const clientInformation = {
      completed: hasErrors || !!clientInformationValues?.user?.email,
      label: t('stepperLabels.clientInformation'),
      ariaLabel: t('stepperAccessibleTitle.clientInformation'),
      disableGoingForward: hasErrors || !!clientInformationValues?.user?.email,
      content: (
        <div className={classes.stepperContainer}>
          <ClientInformation
            setRefetchConsentForm={setRefetchConsentForm}
            handleNext={handleNext}
            getFieldPropsFunction={getClientInformationFormFieldProps}
            setClientInformationValuesFunction={setClientInformationValues}
            setGuardianDetails={setGuardianDetails}
            guardianDetails={guardianDetails}
            clientInformation={clientInformationValues}
            allowNext
          />
        </div>
      )
    };

    const review = {
      label: t('stepperLabels.review'),
      ariaLabel: t('stepperAccessibleTitle.review'),
      content: (
        <div className={classes.stepperContainer}>
          <Review
            fallback={
              <Container isFlex justifyContent='center' style={{ minHeight: '80vh' }}>
                <Spinner />
              </Container>
            }
            consentFormPages={consentFormPages}
            cardData={getReviewCardData()}
            handleSubmit={handleSubmitRequest}
            isSubmitLoading={isSubmitLoading}
            error={error}
          />
        </div>
      )
    };

    const consentForm = {
      completed: isConsentAuthorized,
      label: t('stepperLabels.consentForm'),
      ariaLabel: t('stepperAccessibleTitle.consentForm'),
      disableGoingForward: isConsentAuthorized,
      content: (
        <div className={classes.stepperContainer}>
          <ConsentForm
            fallback={
              <Container isFlex justifyContent='center' style={{ minHeight: '80vh' }}>
                <Spinner />
              </Container>
            }
            isConsentAuthorized={isConsentAuthorized}
            setIsConsentAuthorized={setIsConsentAuthorized}
            consentFormPages={consentFormPages}
            getConsentForm={getConsentForm}
            handleNext={handleNext}
            clientInformation={clientInformationValues}
            setConfirmationTextId={saveConfirmationTextId}
            healthCareProviders={healthCareProvidersState.healthCareProviders}
          />
        </div>
      )
    };

    return [clientInformation, physicianAndInstitution, consentForm, review];
  };

  const saveConfirmationTextId = (id: number) => {
    setConfirmationTextId(id);
  };

  useEffect(() => {
    setError(undefined);
    clearClientValidationErrors();
  }, [activeStep, clearClientValidationErrors]);

  // reset steps when clientinformation is changed
  useEffect(() => {
    if (clientInformationValues?.user.email) {
      setIsConsentAuthorized(false);
      dispatch({ type: 'RESET' });
    }
  }, [clientInformationValues]);

  useEffect(() => {
    // Preload these components after the first render and when the translations are loaded with a delay of 1500ms
    if (ready) {
      setTimeout(() => {
        InstitutionPhysicianSelect.preload();
        ConsentForm.preload();
        Review.preload();
      }, 1500);
    }
  }, [ready]);

  return (
    <PageContainer>
      <div className={classes.root}>
        <div>
          {ready ? (
            <CustomStepper steps={getStepperSteps() as []} activeStep={activeStep} setActiveStep={setActiveStep} />
          ) : (
            <CreateRequestSkeleton />
          )}
        </div>
      </div>
    </PageContainer>
  );
};

export default CreateRequest;
