import { useMemo } from 'react';
import clsx from 'clsx';

// Material UI
import { makeStyles } from '@material-ui/core';

// Dot Types
import { Role, User } from '../../types';

// Dot Components
import Text from './Typography';
import CustomTooltip from './CustomTooltip';
import { USER_ROLES } from '../../utils/constants';
import Icon from './Icon';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: '#D9D9D9',
    borderRadius: '50%',
    '&.is-large': {
      width: '50px',
      height: '50px'
    },
    '&.is-regular': {
      width: '35px',
      height: '35px'
    },
    '&.is-small': {
      width: '30px',
      height: '30px'
    },
    '&.is-x-small': {
      width: '25px',
      height: '25px'
    }
  },
  emptyAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avatarImage: {
    verticalAlign: 'middle',
    borderRadius: '50%',
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  },
  avatarInitialsContainer: {
    backgroundColor: (props: { userColor: string }) => props.userColor,
    borderRadius: '50%',
    textAlign: 'center'
  },
  avatarInitials: {
    position: 'relative',
    textAlign: 'center',
    color: 'white',
    textTransform: 'uppercase',

    '&.is-large': {
      lineHeight: '50px'
    },
    '&.is-regular': {
      lineHeight: '35px',
      fontSize: '16px'
    },
    '&.is-small': {
      lineHeight: '30px',
      fontSize: '15px'
    },
    '&.is-x-small': {
      lineHeight: '25px',
      fontSize: '13px'
    }
  }
}));

export type AvatarSizes = 'x-small' | 'small' | 'regular' | 'large';
interface UserAvatarProps {
  src?: string;
  user: User;
  size?: AvatarSizes;
  disableToolTip?: boolean;
  role?: Role;
}

const UserAvatar = ({ src, user, size = 'regular', disableToolTip, role }: UserAvatarProps) => {
  const isPatientUser = role === USER_ROLES.patient;
  const userData = useMemo(() => {
    return {
      userInitials: `${user.first_name[0]}${user.last_name[0]}`,
      userColor: user?.avatar?.hexColor || '#9F69F0'
    };
  }, [user?.avatar?.hexColor, user.first_name, user.last_name]);

  const classes = useStyles({ userColor: userData.userColor });

  const renderAvatar = () => {
    if (isPatientUser) {
      return <Icon name='empty-avatar' style={{ width: '13px', height: '13px' }} />;
    }
    if (src) {
      return <img src={src} alt={`${user.first_name}'s avatar`} className={clsx(classes.avatarImage, `is-${size}`)} />;
    }

    return (
      <div className={classes.avatarInitialsContainer}>
        <Text h4 className={clsx(`${classes.avatarInitials}`, `is-${size}`)}>
          {userData.userInitials}
        </Text>
      </div>
    );
  };

  const getTooltipTitle = () => {
    if (isPatientUser) {
      return 'Member';
    }

    return `${user.first_name} ${user.last_name}`;
  };

  return (
    <>
      <CustomTooltip disabled={disableToolTip} title={getTooltipTitle()} placement='bottom'>
        <div className={clsx(classes.avatar, `is-${size}`, isPatientUser && classes.emptyAvatar)}>{renderAvatar()}</div>
      </CustomTooltip>
    </>
  );
};

export default UserAvatar;
