/* eslint-disable max-len */
import { useContext, useEffect, useMemo } from 'react';
import { AuthContext } from '../../contexts/auth-context';
import { ROLE_PERMISSIONS, SCOPE_PERMISSIONS, USER_ACTIONS } from '../../utils/rbac';
import { ROLES } from '../../utils/roles';
import { getRole, isNotEmptyArray } from '../../utils/utils';

/**
 * This Can component incorporates some parts of an auth0 blog post about RBAC.
 * Link to the post: https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/
 *
 * A general overview:
 *  - STATIC permissions are those permissions which don't need any data apart from the user role in order to determine access
 *  - DYNAMIC permissions (not added yet) would be those which need some data to check if the user has access for ex only creator of the comment can edit a comment
 */

interface CanComponentProps {
  perform: string | string[];
  fallback?: () => JSX.Element;
  children: React.ReactNode;
}

/**
 *
 * @param action The action(s) that the user want to perform
 * @param strict
 * #### NOTE:  This param would only be used when passing an array to the action param.
 *
 *
 * If `true` it would check that the user has permission to perform all the given actions
 *
 *
 * If `false` it would check if the user has permission to perform at least one of the given actions
 * @returns
 */
export const useCheckPermission = (action: string | string[], strict = true) => {
  const {
    role,
    userInfo,
    userBasedAccessControlConditions,
    scopes: userScopes,
    paymentPlan,
    hasPaymentPlan
  } = useContext(AuthContext);
  const isAdminUser = useMemo(() => {
    const role = getRole();
    return role === ROLES.ADMIN;
    // Disabling eslint next line as we'd want to update this value whenver the userInfo changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  if (!role) return false;

  if (role === ROLES.ADMIN) return true;

  if (!ROLE_PERMISSIONS[role!]) return false;

  if (hasPaymentPlan) {
    if (action === USER_ACTIONS.VIEW.CREATE_REQUEST_PAGE || action.includes(USER_ACTIONS.VIEW.CREATE_REQUEST_PAGE)) {
      if (paymentPlan?.disabled) {
        return false;
      }
    }
  }

  if (userInfo && !isAdminUser && isNotEmptyArray(userBasedAccessControlConditions)) {
    const blockedActions: string[] = [];
    for (let index = 0; index < userBasedAccessControlConditions.length; index++) {
      const { isBlocked, actions } = userBasedAccessControlConditions[index];
      if (isBlocked) {
        blockedActions.push(...actions);
      }
    }

    if (isNotEmptyArray(blockedActions)) {
      if (typeof action === 'string') {
        const isActionBlocked = blockedActions.includes(action);
        if (isActionBlocked) return false;
      } else {
        const isOneOfTheActionsBlocked = action.some((i) => blockedActions.includes(i));
        if (isOneOfTheActionsBlocked) return false;
      }
    }
  }

  const scopes: string[] = [];
  const restrictions: string[] = [];
  if (typeof action === 'string') {
    if (SCOPE_PERMISSIONS[action]) scopes.push(...SCOPE_PERMISSIONS[action].scopes);
    if (SCOPE_PERMISSIONS[action]) restrictions.push(...SCOPE_PERMISSIONS[action].restrictions);
  } else {
    action.forEach((act) => {
      if (SCOPE_PERMISSIONS[act]) scopes.push(...SCOPE_PERMISSIONS[act].scopes);
      if (SCOPE_PERMISSIONS[act]) restrictions.push(...SCOPE_PERMISSIONS[act].restrictions);
    });
  }

  if (!userScopes && (scopes.length || restrictions.length)) return false;
  if (userScopes && (scopes.length || restrictions.length)) {
    const invalidRestrictions = restrictions?.some((restriction) => userScopes.includes(restriction));

    if (restrictions && invalidRestrictions) {
      return false;
    }

    const valid = scopes.every((scopeRequired) => userScopes.includes(scopeRequired));

    if (!valid) {
      return false;
    }
  }

  const { STATIC: staticPermissions } = ROLE_PERMISSIONS[role];

  if (staticPermissions) {
    if (typeof action === 'string') {
      return staticPermissions.includes(action);
    }

    if (!strict) {
      return staticPermissions.some((i) => action.includes(i));
    }

    return action.every((i) => staticPermissions.includes(i));
  }

  return false;
};

const Can: React.FC<CanComponentProps> = ({ children, perform, fallback }: CanComponentProps) => {
  const isAuthorized = useCheckPermission(perform);

  useEffect(() => {
    if (!isAuthorized) {
      console.warn('Unable to perform', perform);
    }
  }, [isAuthorized, perform]);

  if (isAuthorized) return <>{children}</>;

  return typeof fallback !== 'undefined' ? fallback() : <></>;
};

export default Can;
