import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Root as PrimitiveRadioGroupRoot,
  RadioGroupProps as PrimitiveRadioGroupProps
} from '@radix-ui/react-radio-group';
import clsx from 'clsx';
import CustomRadio, { CustomRadioProps } from './Radio';
import Text, { TypographyProps } from './Typography';

const useStyles = makeStyles(() => ({
  root: {
    border: 0,
    margin: 0,
    display: 'inline-flex',
    padding: '1rem 1.5rem',
    position: 'relative',
    flexDirection: 'column',
    verticalAlign: 'top'
  },
  question: {
    fontSize: '18px'
  },
  options: {}
}));

type Option<T = any> = {
  label: any;
  value: T;
  disabled?: boolean;
};

export interface RadioGroupProps<T> extends Omit<PrimitiveRadioGroupProps, 'onChange'> {
  options: Option<T>[];
  selectedOption?: Option<T>;
  question: string;
  showQuestion?: boolean;
  icon?: JSX.Element;
  checkedIcon?: JSX.Element;
  name: string;
  error?: string;
  onChange: (option: Option<T>) => any;
  disabled?: boolean;
  children?: React.ReactNode;
  questionComponentProps?: TypographyProps;
  optionsContainerClassname?: string;
  radioComponentProps?: Omit<
    CustomRadioProps,
    'disabled' | 'key' | 'name' | 'label' | 'checked' | 'checkedIcon' | 'onChange' | 'value' | 'icon'
  >;
}

const RadioGroup = <T extends any>({
  options,
  selectedOption,
  question,
  error,
  onChange,
  radioComponentProps,
  questionComponentProps,
  optionsContainerClassname,
  showQuestion = false
}: RadioGroupProps<T>) => {
  const classes = useStyles();

  return (
    <PrimitiveRadioGroupRoot
      onValueChange={(inputLabel) => {
        const newSelectedOption = options.find(({ label }) => inputLabel === label);
        if (newSelectedOption) {
          onChange(newSelectedOption);
        }
      }}
      value={selectedOption?.label}
      className={classes.root}
      aria-label={question}
    >
      {showQuestion && (
        <Text h6 {...questionComponentProps} className={clsx(classes.question, questionComponentProps?.className)}>
          {question}
        </Text>
      )}
      <div className={optionsContainerClassname || classes.options}>
        {options.length > 0 &&
          options.map((option, index) => {
            return (
              <CustomRadio
                className={clsx(option.disabled && 'is-disabled-opacity')}
                checked={option.label === selectedOption?.label && !option.disabled}
                disabled={option.disabled}
                key={`radio-group-${option.label}-${index}`}
                label={option.label}
                value={option.label}
                {...radioComponentProps}
              />
            );
          })}
      </div>
      {error && (
        <Text paragraph color='error'>
          {error}
        </Text>
      )}
    </PrimitiveRadioGroupRoot>
  );
};

export default RadioGroup;
