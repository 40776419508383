import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { hexToRGB } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflow: 'auto',
    transform: 'rotateX(180deg)',
    // margin: '1rem 0 5rem 0',
    marginTop: '-1rem',
    '& > *': {
      transform: 'rotateX(180deg)'
    },
    '&::-webkit-scrollbar': {
      width: '1.5rem',
      height: '1.5rem'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      // borderRadius: '5px',
      backgroundColor: theme.palette.grey[80]
    }
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: '0 5px',
    color: theme.palette.text.main,
    fontWeight: 300,
    '& td,& th': {
      textAlign: 'left',
      padding: '2rem 1rem'
    },
    '& th': {
      color: theme.palette.grey[80],
      fontWeight: 350,
      fontSize: theme.typography.heading?.fontSize
    },
    '&>tbody:last-child': {
      borderBottom: `1px solid ${theme.palette.catSkillWhite.main}`
    }
  },
  tbody: {
    color: theme.palette.text.main
  },
  tbodyAlternating: {
    color: theme.palette.text.main,
    '&>tr:nth-child(odd)': {
      background: hexToRGB(theme.palette.catSkillWhite.main, 0.5)
    }
  }
}));

interface CommonProps {
  className?: string;
  alternateRowColours?: boolean;
  renderRows?: () => React.ReactNode;
}

type TableHeaderProps =
  | {
      renderHead?: () => React.ReactNode;
      columnHeaders?: never;
    }
  | {
      renderHead?: undefined;
      columnHeaders: string[];
    };

export type TableProps = CommonProps & TableHeaderProps;

const Table = React.forwardRef<HTMLTableElement, TableProps>(
  ({ columnHeaders, renderRows, renderHead, className, alternateRowColours }, ref) => {
    const classes = useStyles();

    const getHead = () => {
      if (renderHead) {
        return renderHead();
      }
      return (
        <tr>
          {columnHeaders?.map((field, index) => {
            return <th key={index}>{field}</th>;
          })}
        </tr>
      );
    };

    const getBody = () => {
      if (renderRows) {
        return renderRows();
      }
      return undefined;
    };

    return (
      <div ref={ref} className={classes.tableContainer}>
        <table className={clsx(classes.table, className)}>
          <thead>{getHead()}</thead>
          <tbody className={alternateRowColours ? classes.tbodyAlternating : classes.tbody}>{getBody()}</tbody>
        </table>
      </div>
    );
  }
);

export default Table;
